import { useContext, useEffect, useState } from "react";
import { Context } from "../context/store/Store";
import { SET_GROUPS, SET_All_TRANSFERS, SET_TRANSFERS, SET_IS_LOADING } from "../context/store/Constants";
import request from "../services/Http";
import { getPageProperties, getTableColumns } from "../services/Helpers";
import { usePagination } from "./usePagination";
import { useIsLoading } from "./useIsLoading";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

export default function useManageTransfers(routeKey) {

  const [{ groups, allTransfers, pagesAndPermissions }, dispatch] = useContext(Context)
  const { totalCount, setTotalCount, hasMore, setHasMore, page, setPage, pageSize, setPageSize } = usePagination()
  const { isLoading, setIsLoading } = useIsLoading()
  const [transferDetails, setTransferDetails] = useState({});
  const [approvedHCS, setApprovedHCS] = useState({});
  const search = useLocation().search;
  const dgId = new URLSearchParams(search).get("dg");

  useEffect(() => {
    return () => {
      setTotalCount(0)
      setHasMore(true)
      setPage(1)
      setPageSize(10)
    }
  }, [])

  async function getAllTransferGroups() {

    dispatch({ type: SET_TRANSFERS, payload: {} })
    dispatch({ type: SET_All_TRANSFERS, payload: {} })
    if (isLoading || !hasMore) {
      return;
    }

    dispatch({ type: SET_IS_LOADING, payload: true })
    setIsLoading(true)

    // if (groups?.rows?.length <= 0) dispatch({ type: SET_IS_LOADING, payload: true })

    try {
      const res = await request.get('emp-transfer/group', { params: { page, pageSize } })
      let rows = res.data.data
      console.log('routeKey', routeKey)
      const properties = getPageProperties(pagesAndPermissions, routeKey)
      const table = getTableColumns(properties.page.table, routeKey)
      let columns = table.columns
      setTotalCount(res.data.totalCount);
      setPage(1);
      if (res.data.totalCount <= pageSize) setHasMore(false)
      if (res.data.data.length === 0) setHasMore(false)

      // const fRows = groups && groups?.rows?.length ? [...groups.rows, ...rows] : rows
      setTimeout(() => {
        dispatch({ type: SET_GROUPS, payload: { rows: [...new Map(rows.map(item => [item['id'], item])).values()], columns } })
      }, 500)

      setIsLoading(false)
      dispatch({ type: SET_IS_LOADING, payload: false })
    }
    catch (e) {
      setIsLoading(false)
      dispatch({ type: SET_IS_LOADING, payload: false })
    }

  }

  const getAllTransfers = async (skipLoading = false) => {
    dispatch({ type: SET_GROUPS, payload: {} });

    // Reset the states
    setTotalCount(0);
    setHasMore(true);
    setPage(1);

    if (!skipLoading) {
      setIsLoading(true);
      dispatch({ type: SET_IS_LOADING, payload: true });
    }

    try {
      const res = await request.get(`emp-transfer/all-emp`);
      if (res) {
        let rows = res.data.data;
        const properties = getPageProperties(pagesAndPermissions, 'manage-supervisors');
        const table = getTableColumns(properties.page.table, 'all-transfers');
        const transferFormColumns = getTableColumns(properties.page.table, 'all-transfers-form')?.columns;
        const fields = res.data.fields;
        let columns = table.columns;
        const dropdownValues = res.data.dropdownValues;
        let allHcFilters = properties?.page?.elements?.dropdowns?.allHcFilters;

        if (allTransfers && allTransfers.hasOwnProperty('apiDelete') && allTransfers.apiDelete) {
          rows = [...allTransfers.rows, ...rows];
        }

        const dataTableData = {
          columns: columns,
          rows: rows,
          dropdownValues: dropdownValues,
          transferFormColumns: transferFormColumns,
          fields: fields,
          apiDelete: false,
          allHcFilters
        };
        dispatch({
          type: SET_All_TRANSFERS,
          payload: dataTableData
        });
      }
    } catch (e) {
      console.error('Error fetching transfers:', e);
    }

    if (!skipLoading) {
      setIsLoading(false);
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  };

  async function getTransferDetails(transactionType = 'transaction_transfer', transactionId, returnData = false, delegatedUserId = null) {
    try {
      setTransferDetails({})
      const res = await request.post(`emp-transfer/detail${delegatedUserId ? `?dgId=${delegatedUserId}` : dgId !== null ? `?dgId=${dgId}` : ""}`, { transactionType: transactionType, transactionId: transactionId });
      if (res) {
        let data = res.data.data
        const dropdownData = res.data.dropdownData
        const fields = res.data.fields
        const leaverReplacementFields = res.data.leaverReplacementFields
        const dataTableData = { data: data, dropdownData: dropdownData, fields: fields, leaverReplacementFields: leaverReplacementFields, apiDelete: false }
        setTransferDetails(dataTableData)
        if (returnData) return dataTableData
      }
    }
    catch (e) { }
  }

  return { getAllTransferGroups, getAllTransfers, getTransferDetails, approvedHCS, transferDetails, isLoading, hasMore, pagesAndPermissions }

}
