import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import "devextreme/dist/css/dx.light.css";
import "../css/styles.scss"
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import loadingGif from "assets/images/gifs/loading.gif";
import { Image } from "antd";
import { Accordion, AccordionSummary, AccordionDetails } from "utils/services/Helpers";
import { ProgressSteps } from "components/CustomDataGridComponents";
import TransferHistoryTable from "./TransferHistoryTable"
import TransferDetailForm from "./TransferDetailForm";

const TransferDetailComponent = React.memo(({ props, columns, dropDownData, transferDetails, dataAccess, routeKey, permissions, apiCallBack, dataSource, setDataSource, dgInstance, canApproveReject, selectedRowKeys, setSelectedRowKeys, pagesAndPermissions }) => {
  const [masterHcData, setMasterHcData] = useState({});
  const [hcDropdownData, setHcDropdownData] = useState({});
  const [hcDataAccessValues, setHcDataAccessValues] = useState({});
  const [hcEditableFields, setHcEditableFields] = useState([]);
  const [hcReplacementEditableFields, setHcReplacementEditableFields] = useState([]);
  const [masterTransactionType, setMasterTransactionType] = useState(null);

  useEffect(() => {
    if (Object.keys(transferDetails).length && Object.keys(dataAccess).length) {
      let masterHcData = transferDetails?.data ? transferDetails.data : null
      let hcDropdownData = transferDetails?.dropdownData ? transferDetails.dropdownData : null
      let hcEditableFields = transferDetails?.fields ? transferDetails.fields : null
      let hcReplacementEditableFields = transferDetails?.leaverReplacementFields ? transferDetails.leaverReplacementFields : null
      let dataAccessValues = dataAccess ? dataAccess : null

      if (masterHcData) {
        // get the hc data
        setMasterHcData(masterHcData)

        if (masterHcData?.transactionType) {
          setMasterTransactionType(masterHcData.transactionType)
        }
      }

      if (hcDropdownData) {
        // get the dropdown data
        setHcDropdownData(hcDropdownData)
      }

      if (hcEditableFields) {
        // get the hc editable fields
        setHcEditableFields(hcEditableFields)
      }

      if (hcReplacementEditableFields) {
        // get the leaver editable fields
        setHcReplacementEditableFields(hcReplacementEditableFields)
      }

      if (dataAccessValues) {
        // get data access values - unique values only
        dataAccessValues.masterCountryId = [...new Map(dataAccessValues.masterCountryId?.map(item => [item.id, item])).values()];
        dataAccessValues.masterOrgVerticalId = [...new Map(dataAccessValues.masterOrgVerticalId?.map(item => [item.id, item])).values()];
        dataAccessValues.masterOrgFunctionId = [...new Map(dataAccessValues.masterOrgFunctionId?.map(item => [item.id, item])).values()];
        dataAccessValues.masterLevelId = [...new Map(dataAccessValues.masterLevelId?.map(item => [item.id, item])).values()];
        dataAccessValues.fLevels = [...new Map(dataAccessValues.fLevels?.map(item => [item.id, item])).values()];
        setHcDataAccessValues(dataAccessValues)
      }
    }
    // cleanup on unmount
    return () => {
      setMasterHcData({})
      setMasterTransactionType(null)
      setHcDropdownData({})
      setHcEditableFields([])
      setHcReplacementEditableFields([])
      setHcDataAccessValues({})
    }
  }, [transferDetails, dataAccess]);

  return (
    <React.Fragment>
      {Object.keys(transferDetails).length && Object.keys(dataAccess).length && Object.keys(masterHcData).length
        ? <Card>
          {Object.keys(masterHcData).length ?
            <MDBox px={3} py={3}>
              {masterHcData && masterHcData?.steps && masterHcData?.steps.length ?
                <MDBox py={1}>
                  <ProgressSteps steps={masterHcData.steps} />
                </MDBox>
                : null
              }
              {masterHcData && hcDataAccessValues && hcDropdownData && hcEditableFields && masterTransactionType ?
                <MDBox pt={2}>
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                      <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6">{"Supervisor Details"}</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails className="HcDetailsAccordionDetails">
                      <TransferDetailForm
                        formHcLeaverData={masterHcData}
                        hcDataAccessValues={hcDataAccessValues}
                        hcDropdownData={hcDropdownData}
                        hcEditableFields={hcEditableFields}
                        apiCallBack={apiCallBack}
                        permissions={permissions}
                        routeKey={routeKey}
                        dataSource={dataSource}
                        setDataSource={setDataSource}
                        dgInstance={dgInstance}
                        canApproveReject={canApproveReject}
                        selectedRowKeys={selectedRowKeys}
                        setSelectedRowKeys={setSelectedRowKeys}
                        columns={columns}
                      />
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
                : null
              }
              {masterHcData && masterHcData?.permanentToFreeLancer && Object.keys(masterHcData.permanentToFreeLancer).length ?
                <MDBox pt={2}>
                  <Accordion defaultExpanded={false}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                      <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6">Convert To Freelancer Details</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {masterHcData.permanentToFreeLancer?.steps && masterHcData.permanentToFreeLancer.steps.length ?
                        <MDBox pt={1}>
                          <ProgressSteps steps={masterHcData.permanentToFreeLancer.steps} />
                        </MDBox>
                        : null
                      }
                      {masterHcData.permanentToFreeLancer?.transaction_histories ?
                        <MDBox pt={1}>
                          {masterHcData.permanentToFreeLancer.transaction_histories.length !== undefined && masterHcData.permanentToFreeLancer.transaction_histories.length ?
                            <TransferHistoryTable
                              recordType={"permanentToFreeLancer"}
                              tableHcHistoryData={masterHcData.permanentToFreeLancer.transaction_histories}
                              hcDataAccessValues={hcDataAccessValues}
                              hcDropdownData={hcDropdownData} />
                            : null
                          }
                        </MDBox>
                        : null
                      }
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
                : null
              }
              {masterHcData && masterHcData?.hold_process && Object.keys(masterHcData.hold_process).length ?
                <MDBox pt={2}>
                  <Accordion defaultExpanded={false}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                      <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6">{masterTransactionType === "transaction_leaver" ? "Leaver Hold & ReActivate Details" : "Hold & ReActivate Details"}</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {masterHcData.hold_process?.steps && masterHcData.hold_process.steps.length ?
                        <MDBox pt={1}>
                          <ProgressSteps steps={masterHcData.hold_process.steps} />
                        </MDBox>
                        : null
                      }
                      {masterHcData.hold_process?.transaction_histories ?
                        <MDBox pt={1}>
                          {masterHcData.hold_process.transaction_histories.length !== undefined && masterHcData.hold_process.transaction_histories.length ?
                            <TransferHistoryTable
                              recordType={"hold_process"}
                              tableHcHistoryData={masterHcData.hold_process.transaction_histories}
                              hcDataAccessValues={hcDataAccessValues}
                              hcDropdownData={hcDropdownData} />
                            : null
                          }
                        </MDBox>
                        : null
                      }
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
                : null
              }
              {masterHcData && masterHcData?.reActivate_process && Object.keys(masterHcData.reActivate_process).length ?
                <MDBox pt={2}>
                  <Accordion defaultExpanded={false}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                      <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6">{masterTransactionType === "transaction_leaver" ? "Leaver Reactivate Details" : "Reactivate Details"}</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {masterHcData.reActivate_process?.steps && masterHcData.reActivate_process.steps.length ?
                        <MDBox pt={1}>
                          <ProgressSteps steps={masterHcData.reActivate_process.steps} />
                        </MDBox>
                        : null
                      }
                      {masterHcData.reActivate_process?.transaction_histories ?
                        <MDBox pt={1}>
                          {masterHcData.reActivate_process.transaction_histories.length !== undefined && masterHcData.reActivate_process.transaction_histories.length ?
                            <TransferHistoryTable
                              recordType={"reActivate_process"}
                              tableHcHistoryData={masterHcData.reActivate_process.transaction_histories}
                              hcDataAccessValues={hcDataAccessValues}
                              hcDropdownData={hcDropdownData} />
                            : null
                          }
                        </MDBox>
                        : null
                      }
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
                : null
              }
              {masterHcData && masterHcData?.cancel_process && Object.keys(masterHcData.cancel_process).length ?
                <MDBox pt={2}>
                  <Accordion defaultExpanded={false}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                      <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6">{masterTransactionType === "transaction_leaver" ? "Cancel Details" : "Cancel Details"}</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {masterHcData.cancel_process?.steps && masterHcData.cancel_process.steps.length ?
                        <MDBox pt={1}>
                          <ProgressSteps steps={masterHcData.cancel_process.steps} />
                        </MDBox>
                        : null
                      }
                      <MDBox pt={1}>
                        <TransferHistoryTable
                          recordType={"cancel_process"}
                          tableHcHistoryData={[{ cancel_reason: masterHcData?.cancel_reason, updatedAt: masterHcData?.updatedAt }]}
                          hcDataAccessValues={hcDataAccessValues}
                          hcDropdownData={hcDropdownData} />
                      </MDBox>
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
                : null
              }
            </MDBox>
            : <MDBox px={2} py={2} >
              <MDTypography variant="subtitle3">
                No data exist in the system for this request. Please connect with the admin.
              </MDTypography>
            </MDBox>
          }
        </Card>
        : <MDBox mt={0} mb={0} style={{ textAlign: "center" }}>
          <Image
            title="Loading"
            alt="Loading"
            width={70}
            src={loadingGif}
            preview={false}
          />
        </MDBox>
      }
    </React.Fragment>
  );
})

TransferDetailComponent.displayName = "TransferDetailComponent"
export default TransferDetailComponent;