import request from "../services/Http"
import { useContext } from "react";
import { Context } from "../context/store/Store";
import { SET_IS_LOADING, SET_EMPLOYEE } from "../context/store/Constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    isJSONValid,
    addKeyAndIndexFieldToGenericArrayOfObjects,
    getPageProperties, getTableColumns, handleUploadLogs, handleUploadResult, uploadFile
} from "../services/Helpers";
import { useIsLoading } from "./useIsLoading";
import { saveAs } from 'file-saver';

export default function useEmployee(routeKey) {

    const [{ employee, pagesAndPermissions }, dispatch] = useContext(Context)
    const { isLoading, setIsLoading } = useIsLoading()

    async function getEmployee() {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.get('employee')
            const properties = getPageProperties(pagesAndPermissions, routeKey ?? 'personnel-profiles')
            const table = getTableColumns(properties.page.table, 'personnel-profiles')
            let columns = table.columns
            let rows = isJSONValid(res.data.data) ?? {}
            let uploadTemplateLink = res.data.uploadTemplate ?? null
            let orgStructureLink = res.data.orgStructureLink ?? null
            let tableName = res.data.tableName ?? null
            let dropdownValues = isJSONValid(res.data.dropdownValues) ?? {}

            if (employee && employee.hasOwnProperty('apiDelete') && employee.apiDelete) {
                rows = [...employee.rows, ...rows]
            }

            const dgData = { columns, rows: addKeyAndIndexFieldToGenericArrayOfObjects(rows), dropdownValues, apiDelete: false, uploadTemplateLink, orgStructureLink, tableName }
            dispatch({
                type: SET_EMPLOYEE,
                payload: dgData
            });
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function updateOrCreateEmployee(data) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.post('employee', { data })
            if (res) {
                toast.success(res.data.data)
                await getEmployee()
            }
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    /**
     * @param data
     * @param val
     * function used to manage delete api call for org structure
     **/
    async function deleteEmployee(data) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.delete('employee', {
                data: { data }
            })
            if (res) {
                toast.success(res.data.data)
                await getEmployee()
            }
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    return { getEmployee, updateOrCreateEmployee, deleteEmployee, isLoading }
}