import { v4 as uuidv4 } from "uuid";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';

/**
 * @param props
 * Function used to show user attachments in datagrid
 **/
export const renderAttachment = (props) => {
  const text = {
    fontSize: "14px",
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
  let attachmentValue = null

  if (props && props.value && props.value.length) {
    attachmentValue = props.value
  }
  else if (props.data.attachments && props.data.attachments.length) {
    attachmentValue = props.data.attachments
  }

  return <>
    {
      attachmentValue
        ?
        <List sx={{ width: '100%', maxWidth: 360, paddingBottom: 5, whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {attachmentValue.map((v) => (
            <ListItem
              key={v.id}
              // disableGutters
              secondaryAction={
                <IconButton aria-label="download" href={v.fileUrl}>
                  <FileDownloadIcon />
                </IconButton>
              }
            >
              <ListItemText primaryTypographyProps={{ style: text }} primary={v.filePath} />
            </ListItem>
          ))}
        </List>
        : null
    }
  </>
}

/**
 * @param e
 * Function used to handle collapse of the row in data-grid
 * @param collapseCurrent
 * @param callback
 **/
export const onRowExpanding = (e, collapseCurrent = true, callback = null, collapseAll = true) => {
  e?.component?.collapseAll(-1);
  // if (collapseAll) e?.component?.collapseAll(-1);
  if (collapseCurrent) e?.component?.collapseRow(e?.key);
  if (callback !== null) callback(false)
}

/**
 * @param e
 * Screen jumps away from the detail of data-grid
 **/
export const onKeyDown = (e) => e.event.stopPropagation()

/**
 * @param e
 * @param callback
 * Function used to set state for auto width for data-grid component
 **/
export const onEditingStart = (e, callback) => callback(false)


/**
 * @param e
 * @param dataSource
 * @param setDataSource
 * @param isForDataAccess
 * @param dataSourceHasEmail
 * @param isForEmployee
 * @param isForWorkflow
 * @param dataSourceHasName
 * copy row
 **/
export function cloneIconClick(e, dataSource, setDataSource, isForDataAccess = false, dataSourceHasEmail = false, isForEmployee = false, isForWorkflow = false, dataSourceHasName = false, isForWorkflowHeader = false) {
  const ds = [...dataSource];
  let clonedItem = {}
  if (dataSourceHasEmail) {
    let updatedDataSource = { ...e.row.data };
    updatedDataSource.email = null;
    clonedItem = { ...updatedDataSource, id: uuidv4(), newRow: true };
  }
  else if (dataSourceHasName) {
    clonedItem = { ...e.row.data, id: uuidv4(), name: null, newRow: true };
  }
  else if (isForDataAccess)
    clonedItem = { ...e.row.data, id: uuidv4(), newRow: true, masterLevelId: [] };
  else if (isForEmployee)
    clonedItem = { ...e.row.data, id: uuidv4(), newRow: true, code: null, name: null, email: null };
  else if (isForWorkflowHeader)
    clonedItem = { ...e.row.data, name: null, newRow: true };
  else if (isForWorkflow)
    clonedItem = { ...e.row.data, name: null, id: uuidv4(), sequence: uuidv4(), newRow: true };
  else
    clonedItem = { ...e.row.data, id: uuidv4(), newRow: true };

  // const clonedItem = {...data};
  ds.splice(0, 0, clonedItem);
  setDataSource(ds);
  e.event.preventDefault();
}