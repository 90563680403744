import request from "../services/Http";
import { getPageProperties, getTableColumns, isJSONValid, setColumnsForDataTable } from "../services/Helpers";
import { useContext, useState } from "react";
import { Context } from "../context/store/Store";
import { SET_IS_LOADING, SET_REVISIONS } from "../context/store/Constants";
import { useIsLoading } from "./useIsLoading";
import { usePagination } from "./usePagination";

export default function useRevisions(routeKey){

  const [{revisions, pagesAndPermissions}, dispatch] = useContext(Context)
  const { isLoading, setIsLoading } = useIsLoading()
  const { totalCount, setTotalCount, hasMore, setHasMore, page, setPage, pageSize, setPageSize} = usePagination()
  const [revisionColumns, setRevisionsColumns] = useState([])

 async function fetchRevisions(){

   if (isLoading || !hasMore)
   {
     return;
   }

   setIsLoading(true)
   // dispatch({ type: SET_IS_LOADING, payload: true })

   if (revisions?.rows?.length <=0) dispatch({ type: SET_IS_LOADING, payload: true })

   try {
     const res = await request.get('revision',{ params: { page, pageSize } })
     if (res){
       const properties = getPageProperties(pagesAndPermissions, routeKey)
       const table = getTableColumns(properties.page.table, routeKey)
       let columns = table.columns
       let rows = isJSONValid(res.data.data)
       const cools = []

       for (const c of columns)
       {
         if (c.dataIndex === "id" || c.dataIndex === "username" || c.dataIndex === "createdAt")
           cools.push(c)
       }

       columns.map(d => {
         d.is_visible = !(d.dataIndex === "username" || d.dataIndex === "id" || d.dataIndex === "document");

         return d
       })
       if (cools.length) {
         cools.map(c => {
           if (c.dataIndex !== "id") c.is_visible = true

           return c
         })

         setRevisionsColumns(cools)
       }

       const dataTableData = { columns, rows }
       dispatch({
         type: SET_REVISIONS,
         payload: dataTableData
       })

     }
   }
   catch (e) {}
   setIsLoading(false)
   dispatch({ type: SET_IS_LOADING, payload: false })
  }

  return {fetchRevisions, isLoading, revisionColumns}
}