import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../../components/FormField";
import { getUserInfo } from "../../../../../utils/services/Helpers";

function UserInfo({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { Name, UserName, Email, Market, Practice } = formField;

  const {
    Name: NameV,
    UserName: UserNameV,
    Email: EmailV,
    Market: MarketV,
    Practice: PracticeV,
  } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">
          {localStorage.getItem('hasProfile') === "false" ? "Complete Profile and hit submit" : "About me"}
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={Name.type}
              label={"User Name"}
              name={Name.name}
              value={Name.value}
              placeholder={Name.placeholder}
              error={errors.Name && touched.Name}
              success={NameV && NameV.length > 0 && !errors.Name}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={UserName.type}
              label={"Email"}
              name={UserName.name}
              value={UserName.value}
              disabled={true}
              placeholder={UserName.placeholder}
              error={errors.UserName && touched.UserName}
              success={UserNameV && UserNameV.length > 0 && !errors.UserName}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
