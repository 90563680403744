import request from "../services/Http";
import { useIsLoading } from "./useIsLoading";
import { useContext, useState } from "react";
import { Context } from "../context/store/Store";
import { getPageProperties, getTableColumns } from "../services/Helpers";
import { toast } from "react-toastify";

export default function useDelegatedAccess(routeKey){
  const {isLoading, setIsLoading} = useIsLoading()
  const [delegatedAccesses, setDelegatedAccesses] = useState({})
  const [{ pagesAndPermissions }, dispatch] = useContext(Context)

  async function getDelegatedAccess(newRecords=[]){
    setIsLoading(true)
    try {
      const res = await request.get('delegated-access')
      if (res)
      {
        const properties = getPageProperties(pagesAndPermissions, routeKey)
        const table = getTableColumns(properties.page.table, routeKey)
        let columns = table.columns
        let rows = []
        if (newRecords?.length) rows = [...res.data.data, ...newRecords]
        else rows = res.data.data
        setDelegatedAccesses({
          rows: rows,
          columns: columns,
          dropDownData: res.data.dropdownData
        })
      }
    }catch (e) {}
    finally {
      setIsLoading(false)
    }
  }

  async function createOrUpdateDelegatedAccess(delegated_accesses){
    setIsLoading(true)
    try {
      const res = await request.post('delegated-access', { delegated_accesses })
      if (res)
      {
        toast.success(res.data.data)
        await getDelegatedAccess()
      }
    }
    catch (e) {}
    finally {
      setIsLoading(false)
    }
  }

  async function deleteDelegatedAccess(data, newRecords=[]){
    setIsLoading(true)
    try {
      if (!data?.length) await getDelegatedAccess(newRecords)
      else
      {
        const res = await request.delete('delegated-access', { data: {data} })
        if (res)
        {
          toast.success(res.data.data)
          await getDelegatedAccess(newRecords)
        }
      }
    }
    catch (e) {}
    finally {
      setIsLoading(false)
    }
  }

  return {
    delegatedAccesses, isLoading, getDelegatedAccess, createOrUpdateDelegatedAccess, deleteDelegatedAccess
  }
}