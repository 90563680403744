import MDBox from "components/MDBox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import { CategoriesItemsGrid } from "./categories-items-grid";
import { CategoriesLightsGrid } from "./categories-lights-grid";
import { useState } from "react";
import { a11yProps } from "utils/services/Helpers";
import MDTypography from "components/MDTypography";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={value === index ? {} : { display: "none" }}
    >
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  );
}

export const CategoriesDetailView = ({
  data,
  itemsColumns,
  permissions,
  lightsColumns,
  isLoading,
  dropDownData,
  setHasDataChanged,
  handleDelete,
  setAddedRowItems,
  setAddedRowLights,
  selectedTab = 0,
  setSelectedTab
}) => {
  const [tabValue, setTabValue] = useState(selectedTab);
  const [itemsRows, setItemsRows] = useState(data.master_cv_items ?? []);
  const [lightsRows, setLightsRows] = useState(data.master_cv_lights ?? []);

  const handleIsDataChanged = (isChanged, tabIndex) => {
    setHasDataChanged(isChanged);
    setSelectedTab(tabIndex);
  };

  const handleChange = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <MDBox>
        <Card>
          <MDBox>
            <MDBox py={2} px={3}>
              <MDTypography variant="h6" fontWeight="medium">
                {data.name}
              </MDTypography>
            </MDBox>
            <MDBox sx={{ width: "100%" }}>
              <MDBox sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="tabs">
                  <Tab label="Items" {...a11yProps(0)} />
                  <Tab label="Lights" {...a11yProps(1)} />
                </Tabs>
              </MDBox>
              <TabPanel value={tabValue} index={0}>
                <CategoriesItemsGrid
                  permissions={permissions}
                  handleDelete={handleDelete}
                  columns={itemsColumns}
                  isLoading={isLoading}
                  allowAdding
                  categoryData={data}
                  setAddedRowItems={setAddedRowItems}
                  setDataSource={setItemsRows}
                  dataSource={itemsRows}
                  setHasDataChanged={(e) => handleIsDataChanged(e, 0)}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <CategoriesLightsGrid
                  permissions={permissions}
                  columns={lightsColumns}
                  isLoading={isLoading}
                  dropDownData={dropDownData}
                  handleDelete={handleDelete}
                  allowAdding
                  setAddedRowLights={setAddedRowLights}
                  setDataSource={setLightsRows}
                  dataSource={lightsRows}
                  categoryData={data}
                  allowSelection
                  setHasDataChanged={(e) => handleIsDataChanged(e, 1)}
                />
              </TabPanel>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </div>
  );
};
