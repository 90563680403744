import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { msalInstance } from "../views/sign-in";
import useLogin from "../utils/hooks/useLogin";
import { encryptObject } from "../utils/services/Helpers";

export default function ({ children }) {
  const toastShownRef = useRef(false); // Using a ref to track if the toast has been shown
  const {doActionIfLoginSuccess} = useLogin()
  useEffect(() => {
    const activityStorageKey = 'lastActivity';
    let shouldLogout = false

    const setLastActivity = () => {
      const formattedTimestamp = new Date().toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      });
      localStorage.setItem(activityStorageKey, formattedTimestamp);
    };

    const checkActivity =  () => {
      const activityTimeout = new Date(localStorage.getItem('expiry')).getTime()
      const currentTime = new Date().getTime()
      const lastActivity = new Date(localStorage.getItem(activityStorageKey)).getTime();
      const ONE_HOUR_IN_MS = process.env.REACT_APP_SESSION_TIMEOUT * 60 * 60 * 1000; // hours in milliseconds
      console.log('currentTime - lastActivity',(currentTime - lastActivity) <= ONE_HOUR_IN_MS, currentTime >= activityTimeout)
      if ((currentTime - lastActivity) <= ONE_HOUR_IN_MS && currentTime >= activityTimeout)
      {
        const request = {
          scopes: ['https://graph.microsoft.com/User.Read'], // Define the scopes you need
          forceRefresh: true,
          account: msalInstance.getActiveAccount(),
          redirect: window.location.origin
        };
        msalInstance.initialize().then(res => {
          msalInstance.acquireTokenSilent(request).then((response) => {
            const d = doActionIfLoginSuccess(msalInstance, response, true)
            localStorage.setItem("_token", d._token)
            localStorage.setItem("expiry", d.expiry)
          }).catch((error) => shouldLogout = true);
        }).catch(err => shouldLogout = true)
      }
      else shouldLogout =  currentTime >= activityTimeout
    };

    const handleActivity = () => {

      if (shouldLogout)
      {
        if (!toastShownRef.current) toast.error('Session Expired.')
        toastShownRef.current = true; // Set the ref to true to prevent multiple toasts
        localStorage.clear()
        sessionStorage.clear()
        // Delay the reload to ensure the toast is displayed
        setTimeout(() => window.location.reload(), 1000)
      }
      else setLastActivity()
    };

    // Attach event listeners for user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    // Check user activity periodically
    const activityInterval = setInterval(checkActivity, 1000 * 5); // Check five seconds

    return () => {
      // Cleanup: remove event listeners and clear the interval
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      clearInterval(activityInterval);
    };
  }, []);
  return <>{children}</>
}
