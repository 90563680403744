// BriefingTemplateModal.jsx

import React, { useState, useRef } from "react";
import 'antd/dist/antd.css';
import { Modal } from 'antd';
import { DEButton } from "utils/services/Helpers";
import BriefingTemplateForm from "components/BriefingTemplateForm";

const BriefingTemplateModal = ({ title = "Briefing Template", btnTitle = "Briefing Template", id, hcData = null, apiCallBack = null }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const formRef = useRef();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        formRef.current.handleSubmit();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleStatusChange = (status = true, formChanged = false) => {
        setIsButtonDisabled(status || !formChanged);
    };

    return (
        <>
            <DEButton disabled={false} stylingMode={"contained"} type={"normal"} text={btnTitle} onClick={showModal} style={{ marginRight: "8px" }} isBtFilled={hcData?.isBtFilled} />
            <Modal maskClosable={false} title={title} width={1200} open={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                footer={
                    [
                        <DEButton key="submit" stylingMode={"contained"} type={"success"} text={"Submit"} disabled={isButtonDisabled} onClick={handleOk} style={{ marginRight: "8px" }} />,
                        <DEButton key="clear" stylingMode={"contained"} type={"normal"} text={"Clear"} disabled={isButtonDisabled} onClick={() => formRef.current.handleClear()} style={{ marginRight: "8px" }} />,
                        <DEButton key="cancel" stylingMode={"contained"} type={"danger"} text={"Cancel"} onClick={handleCancel} style={{ marginRight: "8px" }} />,
                    ]}>
                <BriefingTemplateForm ref={formRef} id={id} apiCallBack={apiCallBack} showButtons={false} onStatusChange={handleStatusChange} />
            </Modal>
        </>
    );
};

export default BriefingTemplateModal;
