import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../../utils/context/store/Store";
import { Modal } from "antd";
import MDTypography from "../../MDTypography";
import DataAccess from "../DataAccess/DataAccess";
import UserFunctionalAccess from "../UserFunctionalAccess/UserFunctionalAccess";
import Select from "react-select";
import { Grid } from "@mui/material";
import { selectCustomStyles, DEButton } from "../../../utils/services/Helpers";
import Link from "@mui/material/Link";
import { DataAccessProvider } from "../DataAccess/contexts/data-access-context";
import { makeStyles } from '@mui/styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  tab: {
    fontSize: '14px', // Adjust the font size as needed
  },
});

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RenderCompAndDropDown = ({ mod, lists, userId, permissions, routeKey }) => {
  const [value, setValue] = useState(null);
  const [displayValue, setDisplayValue] = useState(null);
  const [dValue, setDValue] = useState(null);

  useEffect(() => { }, [dValue]);
  useEffect(() => {
    setDisplayValue(null);
  }, []);
  useEffect(() => { }, [displayValue]);

  let options = lists[mod.name] ?? [];

  function renderComp(value) {
    switch (value) {
      case "DataAccess":
        return (
          <DataAccessProvider>
            <DataAccess
              userId={userId}
              masterModuleId={mod.id}
              permissions={permissions}
              routeKey={routeKey}
            />
          </DataAccessProvider>
        );
      case "UserFunctionalAccess":
        return (
          <UserFunctionalAccess userId={userId} masterModuleId={mod.id} permissions={permissions} />
        );
      default:
        return null;
    }
  }

  return (
    <>
      {options.length ? (
        <Grid item xs={6} sm={2}>
          <Select
            options={options}
            value={dValue}
            styles={selectCustomStyles}
            isSearchable
            menuPortalTarget={document.body}
            onChange={(val) => {
              setDValue(val);
              setValue(val.value);
              setDisplayValue(val.label);
            }}
          />
        </Grid>
      ) : null}
      {value ? renderComp(value) : null}
    </>
  );
};

export default function DataSecurity({
  children,
  title = "User Data Security",
  btnTitle = "Data Security",
  name,
  userId,
  isDisabled = false,
  permissions,
  dropdownList,
  routeKey,
}) {
  const [value, setValue] = React.useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [{ modules, users }, dispatch] = useContext(Context);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => { }, [isModalVisible]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setValue(0);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setValue(0);
    setIsModalVisible(false);
  };

  return (
    <>
      <DEButton
        disabled={isDisabled}
        stylingMode={"contained"}
        type={"normal"}
        text={btnTitle}
        onClick={showModal}
      />
      <Modal
        destroyOnClose
        maskClosable={false}
        title={title}
        width={1200}
        className="justify-content-center"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <DEButton
            stylingMode={"contained"}
            type={"success"}
            text={"Ok"}
            onClick={handleCancel}
          />,
        ]}
      >
        <MDTypography variant="h6">Name: {name}</MDTypography>
        <br />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              {modules.map((mod, index) => (
                <Tab label={mod.name} {...a11yProps(index)} className={classes.tab} />
              ))}
            </Tabs>
          </Box>
          {modules.map((mod, index) => (
            <TabPanel value={value} index={index}>
              <RenderCompAndDropDown
                lists={users.dropdownList}
                mod={mod}
                permissions={permissions}
                userId={userId}
                routeKey={routeKey}
              />
            </TabPanel>
          ))}
        </Box>
      </Modal>
    </>
  );
}
