import React, { useEffect, useContext, useState } from "react";
import { Context } from "../../utils/context/store/Store";
import BaseLayOut from "../../components/Layout/BaseLayOut";
import ClientVitalsView from './components/ClientVitalsView';
import useManageClientVitals from "../../utils/hooks/useManageClientVitals";
import usePagePermissions from "../../utils/hooks/usePagePermissions";

export default function ManageClientVitals({ routeKey, pageName }) {
  const { fetchClientVitals, isLoading } = useManageClientVitals(routeKey)
  const [{ clientVitals }, dispatch] = useContext(Context)
  const { getPermissionsForPage } = usePagePermissions(routeKey)
  const [tabValue, setTabValue] = useState(0)
  const permissions = getPermissionsForPage()

  useEffect(async () => {
    await fetchClientVitals()
    return () => {
      localStorage.removeItem('pageView')
    }
  }, [])

  useEffect(() => { }, [tabValue])

  return (
    <BaseLayOut pageTitle={pageName ?? "Client Vitals"} pageView={tabValue} setTabValue={setTabValue} toggleText={"Client"} isForCv={true}>
      <ClientVitalsView clientVitals={clientVitals} isLoading={isLoading} permissions={permissions} tabValue={tabValue} />
    </BaseLayOut>
  )
}
