import Divider from '@mui/material/Divider';
import { CustomDTag, CustomDateBox, CustomNumberBox } from 'components/CustomDataGridComponents';
import MDAlert from 'components/MDAlert';
import MDTypography from 'components/MDTypography';
import DataGrid, {
  AsyncRule,
  Button,
  Column,
  Export,
  HeaderFilter,
  Lookup,
  MasterDetail,
  Pager,
  Paging,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Selection
} from 'devextreme-react/data-grid';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactJson from 'react-json-view';
import { useNavigate } from 'react-router-dom';
import { isJSONValid, createSanitizeAsyncRule } from 'utils/services/Helpers';
import DocumentModal from '../DocumentModal';
import 'antd/dist/antd.css';
import 'assets/datatable-css/index.css';
import 'react-toastify/dist/ReactToastify.css';

const RevisionDataGrid = ({
  columns,
  rows,
  isLoading,
  fetchFromApi,
  handleDelete,
  canAdd = true,
  dropdownValues = null,
  childColumns = [],
  permissions
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const dataGridRef = useRef();
  let navigate = useNavigate();
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([]);
      setDataColumns([]);
    };
  }, []);

  useEffect(() => {
    setDataSource(rows);
  }, [rows]);

  useEffect(() => {
    setDataColumns(columns);
  }, [columns]);

  /**
   * @param selectedRowKeys
   * @param selectedRowsData
   * used to get selected rows detail of data-grid
   **/
  function onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
    setSelectedRowKeys(selectedRowsData);
  }

  const hasSelected = selectedRowKeys.length > 0;

  function renderField(dataColumns, dropdownValues) {
    return dataColumns.map((col, index) => {
      if (col.type === 'select') {
        if (!col.hasOwnProperty('filtrationKey')) {
          return (
            <Column
              key={index}
              allowEditing={col.editable}
              visible={col.is_visible}
              allowSearch={col.is_searchable}
              allowSorting={col.is_sortable}
              dataField={col.dataIndex}
              caption={col.title}
              setCellValue={function (rowData, value) {
                if (col.dataIndex === 'masterOrgDivisionId') {
                  rowData['masterOrgEntityId'] = null;
                  rowData['masterCountryId'] = null;
                  rowData['masterOrgVerticalId'] = null;
                  rowData['masterOrgFunctionId'] = null;
                  rowData['masterLevelId'] = null;
                }
                if (col.dataIndex === 'masterJobTypeId') {
                  let gridInstance = dataGridRef.current.instance;
                  let editRowKey = gridInstance.option('editing.editRowKey');
                  let index = gridInstance.getRowIndexByKey(editRowKey);
                  const jobType = gridInstance.cellValue(index, 'masterJobTypeId');
                  const status = gridInstance.cellValue(index, 'status');

                  if (!status && jobType) {
                    if (jobType !== value) {
                      if (value === 2) {
                        rowData['master_clients'] = [];
                        rowData['billable'] = false;
                        rowData['hireDate'] = null;
                        rowData['critical'] = false;
                      }

                      if (value === 1) {
                        rowData['masterGigId'] = [];
                        rowData['billable'] = false;
                        rowData['startDate'] = null;
                        rowData['endDate'] = null;
                        rowData['justification'] = null;
                      }
                    }
                  }
                }

                if (col.hasOwnProperty('bindedTo')) rowData[col.bindedTo] = null;
                this.defaultSetCellValue(rowData, value);
              }}
            >
              {col.required ? <RequiredRule /> : null}

              {col.dataIndex === 'masterLevelId' && col.required ? (
                <AsyncRule
                  message={'No JD found with the combination'}
                  validationCallback={async (e) => {
                    if (e && e.data && e.data.hasOwnProperty('jd_transaction')) {
                      return true;
                    } else {
                      let gridInstance = dataGridRef.current.instance;
                      let editRowKey = gridInstance.option('editing.editRowKey');
                      let index = gridInstance.getRowIndexByKey(editRowKey);
                      const masterOrgDivisionId = gridInstance.cellValue(
                        index,
                        'masterOrgDivisionId'
                      );
                      const masterOrgEntityId = gridInstance.cellValue(index, 'masterOrgEntityId');
                      const masterCountryId = gridInstance.cellValue(index, 'masterCountryId');
                      const masterOrgVerticalId = gridInstance.cellValue(
                        index,
                        'masterOrgVerticalId'
                      );
                      const masterOrgFunctionId = gridInstance.cellValue(
                        index,
                        'masterOrgFunctionId'
                      );
                      if (masterOrgDivisionId && masterOrgVerticalId && masterOrgFunctionId) {
                        const combination = {
                          masterOrgDivisionId,
                          masterOrgFunctionId,
                          masterOrgVerticalId,
                          masterLevelId: e.value
                        };

                        if (masterOrgEntityId) {
                          combination['masterOrgEntityId'] = masterOrgEntityId;
                        }
                        if (masterCountryId) {
                          combination['masterCountryId'] = masterCountryId;
                        }

                        const hasJd = await getJDByCombination(combination, false, null);
                        if (typeof hasJd === 'object' && Object.keys(hasJd).length) {
                          jdObj.transactionJdId = hasJd.transactionJdId;
                          jdObj.job_name = hasJd.job_name;
                          jdObj.view_jd = hasJd.job_name;
                          jdObj.hasJd = true;
                          e.data['transactionJdId'] = hasJd.transactionJdId;
                          e.data['hasJd'] = true;
                          return true;
                        } else {
                          delete e.data['transactionJdId'];
                          e.data['hasJd'] = false;
                          return false;
                        }
                      } else {
                        return false;
                      }
                    }
                  }}
                />
              ) : null}
              <Lookup
                allowClearing
                dataSource={
                  dropdownValues && dropdownValues.hasOwnProperty(col.dataIndex)
                    ? dropdownValues[col.dataIndex]
                    : []
                }
                displayExpr="label"
                valueExpr="id"
              />
            </Column>
          );
        } else if (col.hasOwnProperty('filtrationKey')) {
          if (col.dataIndex === 'masterOrgVerticalId') {
            return (
              <Column
                key={index}
                allowEditing={col.editable}
                visible={col.is_visible}
                allowSearch={col.is_searchable}
                allowSorting={col.is_sortable}
                dataField={col.dataIndex}
                caption={col.title}
                setCellValue={function (rowData, value) {
                  this.defaultSetCellValue(rowData, value);
                  if (col.hasOwnProperty('bindedTo')) {
                    rowData[col.bindedTo] = null;
                  }
                }}
              >
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return getVerticalsByEntityAndDivision(options);
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
                {col.required ? <RequiredRule /> : null}
              </Column>
            );
          } else if (col.dataIndex === 'masterOrgFunctionId') {
            return (
              <Column
                key={index}
                allowEditing={col.editable}
                visible={col.is_visible}
                allowSearch={col.is_searchable}
                allowSorting={col.is_sortable}
                dataField={col.dataIndex}
                caption={col.title}
                setCellValue={function (rowData, value) {
                  this.defaultSetCellValue(rowData, value);
                  if (col.hasOwnProperty('bindedTo')) {
                    rowData[col.bindedTo] = null;
                  }
                }}
              >
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return getFunctionsByVerticals(options);
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
                {col.required ? <RequiredRule /> : null}
              </Column>
            );
          } else if (col.dataIndex === 'masterCountryId') {
            return (
              <Column
                key={index}
                allowEditing={col.editable}
                visible={col.is_visible}
                allowSearch={col.is_searchable}
                allowSorting={col.is_sortable}
                dataField={col.dataIndex}
                caption={col.title}
                setCellValue={function (rowData, value) {
                  this.defaultSetCellValue(rowData, value);
                  if (col.dataIndex === 'masterCountryId') {
                    rowData['masterLevelId'] = null;
                    rowData['masterOrgVerticalId'] = null;
                    rowData['masterOrgFunctionId'] = null;
                  }
                  if (col.hasOwnProperty('bindedTo')) {
                    rowData[col.bindedTo] = null;
                  }
                }}
              >
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return getCountriesByDivisionOrEntity(options);
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
                {col.required ? <RequiredRule /> : null}
              </Column>
            );
          } else if (col.dataIndex === 'masterOrgEntityId') {
            return (
              <Column
                key={index}
                allowEditing={col.editable}
                visible={col.is_visible}
                allowSearch={col.is_searchable}
                allowSorting={col.is_sortable}
                dataField={col.dataIndex}
                caption={col.title}
                setCellValue={function (rowData, value) {
                  this.defaultSetCellValue(rowData, value);
                  if (col.dataIndex === 'masterOrgEntityId') {
                    rowData['masterLevelId'] = null;
                    rowData['masterCountryId'] = null;
                    rowData['masterOrgVerticalId'] = null;
                    rowData['masterOrgFunctionId'] = null;
                  }
                  if (col.hasOwnProperty('bindedTo')) {
                    rowData[col.bindedTo] = null;
                  }
                }}
              >
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return getEntitiesByDivision(options);
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
                {col.required ? <RequiredRule /> : null}
              </Column>
            );
          } else {
            return (
              <Column
                key={index}
                allowEditing={col.editable}
                visible={col.is_visible}
                allowSearch={col.is_searchable}
                allowSorting={col.is_sortable}
                dataField={col.dataIndex}
                caption={col.title}
                setCellValue={function (rowData, value) {
                  this.defaultSetCellValue(rowData, value);
                  if (col.hasOwnProperty('bindedTo')) {
                    rowData[col.bindedTo] = null;
                  }
                }}
              >
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return {
                      store:
                        dropdownValues && dropdownValues.hasOwnProperty(col.dataIndex)
                          ? dropdownValues[col.dataIndex]
                          : [],
                      filter: options.data
                        ? [col.filtrationKey, '=', options.data[col.filtrationKey]]
                        : null
                    };
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
                {col.required ? <RequiredRule /> : null}
              </Column>
            );
          }
        }
      } else if (col.type === 'multi-select') {
        return (
          <Column
            key={index}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            editCellComponent={CustomDTag}
            cellTemplate={(container, options) => {
              const noBreakSpace = '\u00A0';
              const text = (options.value || [])
                .map((element) => options.column.lookup.calculateCellValue(element))
                .join(', ');
              container.textContent = text || noBreakSpace;
              container.title = text;
            }}
            calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
              if (target === 'search' && typeof filterValue === 'string') {
                return [col.dataIndex, 'contains', filterValue];
              }
              return function (data) {
                return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
              };
            }}
          >
            {col.required ? <RequiredRule /> : null}
            <Lookup
              allowClearing
              dataSource={
                dropdownValues && dropdownValues.hasOwnProperty(col.dataIndex)
                  ? dropdownValues[col.dataIndex]
                  : null
              }
              displayExpr="label"
              valueExpr="id"
            />
          </Column>
        );
      } else if (col.type === 'checkbox' || col.type === 'toggle') {
        return (
          <Column
            key={index}
            dataType="boolean"
            showEditorAlways
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
            }}
          >
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      } else if (col.type === 'actions') {
        return (
          <Column
            key={index}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            type="buttons"
            dataField={col.dataIndex}
            caption={col.title}
            fixed={false}
          >
            <Button
              visible={(e) => e && e.row && e.row.data && e.row.data.hasOwnProperty('document')}
              component={(props) => {
                return <DocumentModal data={props.data.data.document} />;
              }}
            />
          </Column>
        );
      } else if (col.type === 'date') {
        return (
          <Column
            key={index}
            dataType={'date'}
            format={'dd-MM-yyyy HH:mm:ss'}
            editCellComponent={CustomDateBox}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
            {col.dataIndex === 'endDate' ? (
              <AsyncRule
                message="EndDate should be greater than StartDate"
                validationCallback={async (e) => {
                  return e.value > e.data.startDate;
                }}
              />
            ) : null}
          </Column>
        );
      } else if (col.type === 'int') {
        return (
          <Column
            key={index}
            dataType={col.type}
            editCellComponent={CustomNumberBox}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
            <AsyncRule
              message={'Invalid value or value too long'}
              validationCallback={async (e) => {
                return e.value.toString().length <= 10;
              }}
            />
          </Column>
        );
      } else if (col.type === 'json') {
        return (
          <Column
            key={index}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            cellComponent={({ data }) => (
              <code>
                <pre>{data.data.document}</pre>
              </code>
            )}
          >
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      } else if (col.type === 'string') {
        return (
          <Column
            key={index}
            allowEditing={col.editable}
            visible={
              childColumns?.length <= 0 && col.dataIndex === 'username' ? false : col.is_visible
            }
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
            <AsyncRule {...sanitizeAsyncRule} />
          </Column>
        );
      } else {
        return (
          <Column
            key={index}
            allowEditing={col.editable}
            visible={
              childColumns?.length <= 0 && col.dataIndex === 'username' ? false : col.is_visible
            }
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      }
    });
  }

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        disabled: !canAdd,
        visible: false,
        onClick: () => navigate('/views/job-description')
      }
    });
  }

  /**
   * custom function using useMemo to avoid re-renders unless the states listed are changed
   **/
  const Comp = useMemo(() => {
    try {
      return (
        <div id="data-grid-demo">
          {hasSelected > 0 ? (
            <React.Fragment>
              <br />
              <MDAlert color="light">
                <MDTypography variant="subtitle2">
                  {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? 'item' : 'items'
                    }`}
                </MDTypography>

                <Divider orientation="vertical" color="dark" flexItem />
              </MDAlert>
            </React.Fragment>
          ) : (
            ''
          )}
          <DataGrid
            onToolbarPreparing={onToolbarPreparing}
            showColumnLines
            showRowLines
            rowAlternationEnabled
            ref={dataGridRef}
            onSelectionChanged={onSelectionChanged}
            allowColumnResizing
            disabled={isLoading}
            dataSource={dataSource}
            key="id"
            keyExpr="id"
            showBorders
          >
            <Scrolling showScrollbar="always" mode="standard" />
            <Selection
              allowSelectAll
              mode="multiple"
              selectAllMode={'allPages'}
              showCheckBoxesMode={'always'}
            />
            <HeaderFilter visible allowSearch />
            <SearchPanel visible />
            <Paging defaultPageSize={25} />
            <Pager visible displayMode={'full'} showInfo showNavigationButtons />
            <Export enabled allowExportSelectedData />
            {childColumns?.length <= 0 ? null : (
              <MasterDetail
                autoExpandAll={false}
                enabled
                component={(props) => {
                  return (
                    <>
                      {props.data.data.hasOwnProperty('children') &&
                        props.data.data.children.length ? (
                        <RevisionDataGrid
                          loading={isLoading}
                          columns={childColumns}
                          rows={props.data.data.children}
                          fetchFromApi={fetchFromApi}
                          permissions={permissions}
                          childColumns={[]}
                        />
                      ) : props.data.data.hasOwnProperty('document') ? (
                        <ReactJson src={isJSONValid(props.data.data.document)} name={null} />
                      ) : (
                        'Nothing to show'
                      )}
                    </>
                  );
                }}
              />
            )}

            {dataColumns && dataColumns.length > 0
              ? renderField(dataColumns, dropdownValues)
              : null}
          </DataGrid>
        </div>
      );
    } catch (e) {
      console.log('error from hc', e);
    }
  }, [dataSource, dataColumns, dropdownValues, selectedRowKeys, isLoading, childColumns]);

  return <React.Fragment>{Comp}</React.Fragment>;
};

export default RevisionDataGrid;
