import React, { useEffect } from "react";
import 'antd/dist/antd.css';
import useElements from "../../utils/hooks/useElements";
import useSegments from "../../utils/hooks/useSegments";
import ElementsDataGrid from "./DataTable/ElementsDataGrid";

const ManageElements = ({ segment, segmentData, dataSource, page_permissions, routeKey, updateButtonStateOfParent, elementColumns }) => {

  const { getElementsBySegment, deleteElement, updateOrCreateElements, elements, isLoading } = useElements(segment)
  const { getSegments, updateOrCreateSegments } = useSegments()

  useEffect(async () => {
    if (segmentData.main_task && elementColumns && elementColumns.columns) {
      const newData = elementColumns.columns.filter((col) => {
        return col.dataIndex !== 'color'
      })
    }
  }, [])

  return (
    <>
      {
        segment
          ? <ElementsDataGrid loading={isLoading} routeKey={routeKey} defaultKey={"id"} rows={segmentData && segmentData.elements && segmentData.elements.length ? segmentData.elements : []} columns={
            elementColumns && elementColumns.columns && elementColumns.columns.length > 0
              ? elementColumns.columns.filter((col) => {
                if (!segmentData.main_task)
                  return col.dataIndex !== 'color'

                return col
              })
              : elementColumns.columns}
            segmentData={segmentData} functionalAccessId={segment} postData={updateOrCreateElements} handleDelete={deleteElement} permissions={page_permissions} allowAdding={true} showSubmitButton={false} allowUpdating={true} allowDeleting={true} allowSelection={true} updateButtonStateOfParent={updateButtonStateOfParent} />

          : <ElementsDataGrid loading={isLoading} routeKey={routeKey} defaultKey={"key"} rows={segmentData && segmentData.elements && segmentData.elements.length ? segmentData.elements : []} columns={
            elementColumns && elementColumns.columns && elementColumns.columns.length > 0
              ? elementColumns.columns.filter((col) => {
                if (!segmentData.main_task)
                  return col.dataIndex !== 'color'

                return col
              })
              : elementColumns.columns} functionalAccessId={segment} postData={updateOrCreateElements} handleDelete={deleteElement} isForNewSegment={true} showSubmitButton={false} allowDeleting={true} allowSelection={true} allowAdding={true} segmentData={segmentData} permissions={page_permissions} updateButtonStateOfParent={updateButtonStateOfParent} />
      }
    </>
  );
};

export default ManageElements;