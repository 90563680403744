import * as React from 'react';
import { useEffect, useState, useRef, useContext } from "react";
import 'devextreme/dist/css/dx.light.css';
import MDBox from '../../../../components/MDBox';
import { DEButton, getObjectDifferences, isObjectEmpty } from "utils/services/Helpers";
import { Form, GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import {
  FormFileUploader,
  FormTextArea,
  FormDateBox,
  FormTagBox,
  FormTextBox,
  CustomRadioGroup,
} from "../../../../components/CustomDataGridComponents";
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from "uuid";
import Swal from 'sweetalert2';
import { Context } from "../../../../utils/context/store/Store";
import { sanitizeInput } from 'utils/services/Helpers';

const HcLeaverForm = React.memo(({
  formHcLeaverData,
  columns,
  dropDownData,
  setDataSource,
  permissions,
  dataSource,
  setHasDataChanged,
  setDgData,
  setIsReplacementRequired
}) => {
  const [formHcNewData, setFormHcNewData] = useState({});
  const [prvsFormData, setPrvsFormData] = useState({})
  const [formDataChanged, setFormDataChanged] = useState(false);
  const [formClassName, setFormClassName] = useState(null);
  const [formErrorMsg, setFormErrorMsg] = useState(null);
  const formRef = useRef(null);
  const [{ headcountRequest }, dispatch] = useContext(Context)

  useEffect(() => {
    localStorage.setItem('resize', true)
    if (Object.keys(formHcLeaverData)?.length) setFormHcNewData(formHcLeaverData);
    if (formHcLeaverData?.canInitiatorEditAfterApproval && isObjectEmpty(prvsFormData)) setPrvsFormData(_.cloneDeep(formHcLeaverData))


    // cleanup on unmount
    return () => {
      localStorage.removeItem('resize')
      setFormHcNewData({});
      setFormDataChanged(false);
      setFormClassName(null);
    };
  }, []);
  useEffect(() => { }, [formHcNewData])

  /**
   * use to handle form submit
   **/
  const handleSubmit = () => {
    if (formRef.current.instance.validate().isValid) {
      if (dataSource && dataSource.length && formHcNewData && formHcNewData.hasOwnProperty('masterEmployeeId') && dataSource.some((d) => d.masterEmployeeId === formHcNewData.masterEmployeeId && d.id !== formHcNewData.id)) {
        toast.error('Duplicate Entry found for Employee');
      } else {
        // Validate custom fields
        if (formHcNewData?.master_clients === undefined || formHcNewData?.master_clients === null || formHcNewData?.master_clients === '' || !formHcNewData?.master_clients?.length) {
          setFormErrorMsg('Please Add Client!')
        }
        else if (formHcNewData.movingTo && !sanitizeInput(formHcNewData.movingTo)) {
          setFormErrorMsg("Invalid characters detected. Please remove any special characters from Moving To.")
        }
        else if (formHcNewData.justification && !sanitizeInput(formHcNewData.justification)) {
          setFormErrorMsg("Invalid characters detected. Please remove any special characters from Justification.")
        }
        else if (formHcNewData.handoverComments && !sanitizeInput(formHcNewData.handoverComments)) {
          setFormErrorMsg("Invalid characters detected. Please remove any special characters from 'Why proper handover was not possible?'.")
        }
        else if (formHcNewData.oooContactComments && !sanitizeInput(formHcNewData.oooContactComments)) {
          setFormErrorMsg("Invalid characters detected. Please remove any special characters from 'Why OOO with contact details is not sufficient?'.")
        }
        else if (formHcNewData.addDetails && !sanitizeInput(formHcNewData.addDetails)) {
          setFormErrorMsg("Invalid characters detected. Please remove any special characters from Additional Details.")
        }
        else {
          if (formHcNewData.hasOwnProperty('attachment')) {
            formHcNewData["hasFile"] = true
          }
          let d = formHcNewData.replacementRequired === 0 || formHcNewData.replacementRequired === null ? { ...formHcNewData, replacementRequired: 0 } : null
          if (formHcNewData.replacementRequired && formHcNewData.replacementRequired > 0)
          {
            // if (formHcNewData?.editedAfterApproval) formHcNewData['objectToExecuteWorkflow'] = getObjectDifferences(formHcNewData, prvsFormData)
            const data = { ...formHcNewData, columns: headcountRequest?.columns, dropDownData: headcountRequest.dropdownValues }
            d = updateHeadcounts(data, _.cloneDeep(dataSource));
          }
          const newData =
            dataSource &&
            dataSource?.map((item) => (item.id === d?.id ? d : item));
          setFormErrorMsg(null)
          setDataSource(newData);
          console.log('dataS', newData)
          localStorage.removeItem('LR')
          // localStorage.setItem('LR', JSON.stringify(d))
          setDgData(newData)

          if (!newData.some(e => !e.masterEmployeeId) && !newData.some(e => e.headcounts.length !== 0)) {
            setHasDataChanged(true)
          }
          else if (!newData.some(e => !e.masterEmployeeId)
            && newData.some(e => e.headcounts.length !== 0)
            && !newData.some(e => e.headcounts.some(i => !i.masterOrgDivisionId))) {
            setHasDataChanged(true)
          }
          else {
            setHasDataChanged(false)
          }

          formHcNewData.replacementRequired > 0 ? setIsReplacementRequired(true) : null
          setFormDataChanged(false);
        }
      }
    }
  };

  function updateHeadcounts(formHcNewData, dataSource) {
    if (formHcNewData.replacementRequired && formHcNewData.replacementRequired > 0) {
      const currentReplacementRequired = formHcNewData.replacementRequired;
      const existingData = dataSource.find(item => item.id === formHcNewData.id);
      if (existingData) {
        let existingReplacementRequired = existingData.headcounts.length || 0;

        if (existingData.division !== formHcNewData.division) {
          existingReplacementRequired = 0
          formHcNewData.headcounts = []
        }

        if (currentReplacementRequired === existingReplacementRequired) {
          if (formHcNewData?.editedAfterApproval && formHcNewData?.masterEmployeeId !== formHcLeaverData?.masterEmployeeId) formHcNewData.headcounts.map(h => {
              h.masterOrgDivisionId = null
              h.masterOrgEntityId = null
              h.masterCountryId = null
              h.masterOrgVerticalId = null
              h.masterOrgFunctionId = null
              h.masterOrgHfmId = null
              h.leaverDivisionId = formHcNewData.division
              return h
            });
        }
        else if (currentReplacementRequired > existingReplacementRequired) {
          // Add new objects to headcounts
          if (currentReplacementRequired === 0)
            formHcNewData.headcounts = []
          for (let i = existingReplacementRequired; i < currentReplacementRequired; i++) {
            formHcNewData.headcounts.push({
              leaverId: formHcNewData.id,
              leaverDivisionId: formHcNewData.division,
              leaverEntityId: formHcNewData.entity,
              leaverCountryId: formHcNewData.country,
              leaverLevelId: formHcNewData.level,
              id: uuidv4(),
              newRow: true,
              billable: false,
              critical: false,
              attachment: null,
              dStatus: false,
              onHold: false,
              isCancelled: false,
              re_activate: false,
              pToF: false,
              masterRequestTypeId: 2,
              masterHeadcountTypeId: 4,
              masterOrgDivisionId: null,
              masterOrgEntityId: null,
              masterCountryId: null,
              masterOrgVerticalId: null,
              masterOrgFunctionId: null,
              masterLevelId: null,
              masterRecruitmentStatusId: 1
            });
          }
        }
        else if (currentReplacementRequired < existingReplacementRequired) {
          // Remove items from the end of headcounts
          formHcNewData.headcounts.splice(currentReplacementRequired);
        }
      }
    }

    return formHcNewData
  }

  /**
   * use to handle form cancel
   **/
  const handleReset = () => {
    formHcLeaverData.masterEmployeeId = null
    formHcLeaverData.jobName = null
    formHcLeaverData.masterOrgDivisionId = null
    formHcLeaverData.division = null
    formHcLeaverData.masterOrgEntityId = null
    formHcLeaverData.entity = null
    formHcLeaverData.masterCountryId = null
    formHcLeaverData.country = null
    formHcLeaverData.masterLevelId = null
    formHcLeaverData.level = null
    formHcLeaverData.masterOrgHfmId = null
    formHcLeaverData.hfm = null
    formHcLeaverData.lastWorkingDay = null
    formHcLeaverData.leaverNotificationDate = null
    formHcLeaverData.master_clients = null
    formHcLeaverData.addDetails = null
    formHcLeaverData.handoverComments = null
    formHcLeaverData.masterManagementTypeId = null
    formHcLeaverData.regrettable = false
    formHcLeaverData.okToRehireId = null
    formHcLeaverData.justification = null
    formHcLeaverData.managerContact = null
    formHcLeaverData.proxyDelegate = null
    formHcLeaverData.oooContactComments = null
    formHcLeaverData.oooContactComments = null
    formHcLeaverData.redundancy = false
    formHcLeaverData.emailProxyRequired = false
    formHcLeaverData.masterEosReasonId = null
    formHcLeaverData.attachment = []
    formHcLeaverData.headcounts = []
    setFormHcNewData(formHcLeaverData);
    formRef.current.instance.resetValues()
    formHcLeaverData.replacementRequired = 0
    setHasDataChanged(false)
    setFormDataChanged(false);
    const newData =
      dataSource &&
      dataSource?.map((item) => (item.id === formHcLeaverData?.id ? formHcLeaverData : item));
    setFormErrorMsg(null)
    setDataSource(newData);
    setDgData(newData);
    localStorage.removeItem('LR')
  };

  const handleCancel = (e) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will delete this record',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      heightAuto: false,
      height: '200px'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updData = dataSource.filter((item) => item.id !== formHcLeaverData.id)
        setDataSource(updData)
        setHasDataChanged(false)
        setFormDataChanged(false)
        localStorage.removeItem('LR')

      }
    });
  }

  /**
   * Handle Form Data Change
   **/
  const formFieldDataChanged = (e) => {
    let obj = null;
    if (e.dataField === 'masterEmployeeId') {
      const emp = e.value
        ? dropDownData
          ? dropDownData['masterEmployeeId'].find((a) => a.id === e.value)
          : null
        : null;

      obj = emp
        ? {
          masterEmployeeId: emp.id,
          jobName: emp.jobName,
          masterOrgDivisionId: emp.masterOrgDivisionId,
          division: emp.division.id,
          masterOrgEntityId: emp.masterOrgEntityId,
          entity: emp.entity.id,
          masterCountryId: emp.masterCountryId,
          country: emp.country.id,
          masterLevelId: emp.masterLevelId,
          level: emp.level.id,
          masterOrgHfmId: emp.masterOrgHfmId,
          hfm: emp.hfm.id,
        }
        : {
          masterEmployeeId: null,
          jobName: null,
          masterOrgDivisionId: null,
          division: null,
          masterOrgEntityId: null,
          entity: null,
          masterCountryId: null,
          country: null,
          masterLevelId: null,
          level: null,
          masterOrgHfmId: null,
          hfm: null
        };

      const newData = dataSource && dataSource?.map((item) => (item.id === formHcNewData?.id ? { ...formHcNewData, ...obj } : item));
      setDataSource(newData);
      setDgData(newData)
    }

    setFormHcNewData(prevState => {
      const updatedObject = { ...prevState, ...obj };
      updatedObject[e.dataField] = e.value;
      if (formHcLeaverData?.canInitiatorEditAfterApproval) updatedObject.editedAfterApproval = true
      // localStorage.setItem('LR', JSON.stringify(updatedObject))
      return updatedObject;
    });
    setFormDataChanged(true)
    setHasDataChanged(false)

  };

  /**
   * @param columns
   * @param dropDownData
   * function use to handle rendering of form fields
   **/
  function renderFormFields(columns, dropDownData) {
    // return columns.map(renderFormField(columns, dropDownData, formHcNewData, formHcLeaverData));
    return columns.map((col, index) => {
      const isDisabled = formHcLeaverData?.canInitiatorEditAfterApproval ? true : col.disabled || !(formHcLeaverData.newRow || (!formHcLeaverData.hasRollback && !formHcLeaverData.isRollback && formHcLeaverData.isDraft) || (formHcLeaverData.hasRollback && formHcLeaverData.isRollback))
      if (col.type === 'select') {
        if (col.dataIndex === 'masterEosReasonId') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxSelectBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible} disabled={isDisabled}
              // disabled={
              //   !formHcNewData.hasOwnProperty('masterEosTypeId') || // 'masterEosTypeId' key is not present
              //   !formHcNewData.masterEosTypeId // 'masterEosTypeId' value is empty (null)
              // }
              editorOptions={{
                showClearButton: true,
                searchEnabled: true,
                dataSource:
                  dropDownData[col.dataIndex]?.filter(
                    (d) => d.masterEosTypeId === formHcNewData?.masterEosTypeId
                  ) ?? [],
                valueExpr: 'id',
                displayExpr: 'name'
              }}
            >
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          );
        }
        else if (col.dataIndex === 'proxyDelegate') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxSelectBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={
                col.is_form_visible &&
                formHcNewData &&
                formHcNewData.emailProxyRequired
              }
              disabled={isDisabled}
              editorOptions={{
                disabled: isDisabled,
                showClearButton: true,
                searchEnabled: true,
                dataSource: {
                  paginate: true,
                  pageSize: 10,
                  store: dropDownData?.[col.dataIndex] ?? []
                },
                valueExpr: 'id',
                displayExpr: 'name'
              }}
            >
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          );
        }
        else if (col.dataIndex === 'masterEmployeeId') {
          return (
            <GroupItem>
              <GroupItem>
                <SimpleItem key={index} name={col.dataIndex}
                  editorType="dxSelectBox"
                  dataField={col.dataIndex}
                  isRequired={col.required}
                  visible={col.is_form_visible}
                  disabled={isDisabled}
                  editorOptions={{
                    disabled: isDisabled,
                    showClearButton: true,
                    searchEnabled: true,
                    dataSource: {
                      paginate: true,
                      pageSize: 10,
                      store: dropDownData?.[col.dataIndex] ?? []
                    },
                    valueExpr: 'id',
                    displayExpr: 'name',
                    paging: true,
                    pageSize: 10
                  }}
                >
                  <Label text={col.title} for={col.dataIndex} />
                </SimpleItem>
              </GroupItem>

              <GroupItem colCount={2}>
                <Label text={" "} visible={true} showColon={false} for={col.dataIndex} />
                <p className='employee-field-message'>If the employee is transitioning internally, please refrain from adding the leaver and ensure to coordinate with the TA Lead as they are responsible for managing internal mobility cases on TEOS.</p>
              </GroupItem>
            </GroupItem>
          );
        }
        else {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxSelectBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              disabled={isDisabled}
              editorOptions={{
                disabled: isDisabled,
                showClearButton: true,
                searchEnabled: true,
                dataSource: {
                  paginate: true,
                  pageSize: 10,
                  store: dropDownData?.[col.dataIndex] ?? []
                },
                valueExpr: 'id',
                displayExpr: 'name',
                paging: true,
                pageSize: 10
              }}
            >
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          );
        }
      }
      else if (col.type === 'multi-select') {
        return (
          <SimpleItem key={index} name={col.dataIndex}
            dataField={col.dataIndex}
            isRequired={col.required}
            visible={col.is_form_visible} disabled={isDisabled}
            editorOptions={{
              disabled: isDisabled,
              setValue: (e, dataIndex) => {
                formHcNewData[dataIndex] = e
                if (formHcLeaverData?.canInitiatorEditAfterApproval) formHcNewData['editedAfterApproval'] = true
                setFormHcNewData(formHcNewData)
                setHasDataChanged(false)
                // setFormDataChanged(true)
                // localStorage.setItem('LR', JSON.stringify(formHcNewData))
              }
            }}
            component={(props) => FormTagBox(props, formHcNewData, dropDownData, col.editable)}>
            <Label text={col.title} for={col.dataIndex} />
          </SimpleItem>
        );
      }
      else if (col.type === 'toggle') {
        return (
          <SimpleItem key={index} name={col.dataIndex}
            editorType="dxSwitch"
            dataField={col.dataIndex}
            isRequired={col.required}
            visible={col.is_form_visible}
            disabled={isDisabled}
          >
            <Label text={col.title} for={col.dataIndex} />
          </SimpleItem>
        );
      }
      else if (col.type === 'checkbox') {
        if (col.dataIndex === 'redundancy') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxCheckBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={
                col.is_form_visible &&
                formHcNewData &&
                formHcNewData.masterEosTypeId === 2
              }
              disabled={isDisabled}
            >
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else if (col.dataIndex === 'replacementRequired') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxNumberBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              disabled={isDisabled}
              editorOptions={{
                disabled: isDisabled,
                showSpinButtons: true,
                min: 0
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxCheckBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              disabled={isDisabled}
            >
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          );
        }
      }
      else if (col.type === 'date') {
        return (
          <SimpleItem key={index} name={col.dataIndex}
            dataField={col.dataIndex}
            isRequired={col.required}
            visible={col.is_form_visible} disabled={isDisabled}
            component={(props) => FormDateBox(props, formHcNewData, col.editable)}
            editorOptions={{
              disabled: isDisabled,
              setValue: (e, dataIndex) => {
                // const d = { ...formHcNewData }
                // formHcLeaverData[dataIndex] = e
                // setFormHcNewData(prevState => {
                //   const updatedObject = { ...prevState, ...d };
                //   updatedObject[dataIndex] = e;
                //   return updatedObject;
                // });
                formHcNewData[dataIndex] = e
                if (formHcLeaverData?.canInitiatorEditAfterApproval) formHcNewData['editedAfterApproval'] = true
                setFormHcNewData(formHcNewData)
                setHasDataChanged(false)
                setFormDataChanged(true)

              }
            }}>
            <Label text={col.title} for={col.dataIndex} />
          </SimpleItem>
        );
      }
      else if (col.type === 'int') {
        return (
          <SimpleItem key={index} name={col.dataIndex}
            editorType="dxNumberBox"
            dataField={col.dataIndex}
            isRequired={col.required}
            visible={col.is_form_visible}
            disabled={isDisabled}
            component={SelectJD}>
            <Label text={col.title} for={col.dataIndex} />
          </SimpleItem>
        )
      }
      else if (col.type === 'file') {
        return (
          <SimpleItem key={index} name={col.dataIndex}
            dataField={col.dataIndex}
            isRequired={col.required}
            visible={col.is_form_visible} disabled={isDisabled}
            component={(props) => FormFileUploader(props, formHcNewData)}
            editorOptions={{
              disabled: isDisabled,
              setValue: (e, dataIndex) => {
                formHcNewData[dataIndex] = e
                if (formHcLeaverData?.canInitiatorEditAfterApproval) formHcNewData['editedAfterApproval'] = true
                setFormHcNewData(formHcNewData)
              }
            }}>
            <Label text={col.title} for={col.dataIndex} />
          </SimpleItem>
        );
      }
      else if (col.type === 'button') {
        return (
          <SimpleItem key={index} name={col.dataIndex}
            dataField={col.dataIndex}
            isRequired={col.required}
            visible={col.is_form_visible}
            disabled={isDisabled}
            component={SelectJD}
          >
            <Label text={col.title} for={col.dataIndex} />
          </SimpleItem>
        );
      }
      else if (col.type === 'textarea') {
        if (col.dataIndex === 'justification') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              dataField={col.dataIndex}
              isRequired={col.required} disabled={isDisabled}
              visible={
                col.is_form_visible &&
                formHcNewData &&
                formHcNewData.emailProxyRequired
              }
              component={(props) => FormTextArea(props, formHcNewData, col.editable)}
              editorOptions={{
                disabled: isDisabled,
                setValue: (e, dataIndex) => {
                  // const d = { ...formHcNewData }
                  // formHcLeaverData[dataIndex] = e
                  // setFormHcNewData(prevState => {
                  //   const updatedObject = { ...prevState, ...d };
                  //   updatedObject[dataIndex] = e;
                  //   return updatedObject;
                  // });
                  formHcNewData[dataIndex] = e
                  if (formHcLeaverData?.canInitiatorEditAfterApproval) formHcNewData['editedAfterApproval'] = true
                  setFormHcNewData(formHcNewData)
                  setHasDataChanged(false)
                  setFormDataChanged(true)
                  localStorage.setItem('LR', JSON.stringify(formHcNewData))

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          );
        } else if (col.dataIndex === 'handoverComments') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              dataField={col.dataIndex}
              isRequired={col.required} disabled={isDisabled}
              visible={
                col.is_form_visible &&
                formHcNewData &&
                formHcNewData.emailProxyRequired
              }
              component={(props) => FormTextArea(props, formHcNewData, col.editable)}
              editorOptions={{
                disabled: isDisabled,
                setValue: (e, dataIndex) => {
                  // const d = { ...formHcNewData }
                  // formHcLeaverData[dataIndex] = e
                  // setFormHcNewData(prevState => {
                  //   const updatedObject = { ...prevState, ...d };
                  //   updatedObject[dataIndex] = e;
                  //   return updatedObject;
                  // });
                  formHcNewData[dataIndex] = e
                  if (formHcLeaverData?.canInitiatorEditAfterApproval) formHcNewData['editedAfterApproval'] = true
                  setFormHcNewData(formHcNewData)
                  setHasDataChanged(false)
                  setFormDataChanged(true)
                  localStorage.setItem('LR', JSON.stringify(formHcNewData))

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          );
        } else if (col.dataIndex === 'oooContactComments') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              dataField={col.dataIndex}
              isRequired={col.required} disabled={isDisabled}
              visible={
                col.is_form_visible &&
                formHcNewData &&
                formHcNewData.emailProxyRequired
              }
              component={(props) => FormTextArea(props, formHcNewData, col.editable)}
              editorOptions={{
                disabled: isDisabled,
                setValue: (e, dataIndex) => {
                  // const d = { ...formHcNewData }
                  // formHcLeaverData[dataIndex] = e
                  // setFormHcNewData(prevState => {
                  //   const updatedObject = { ...prevState, ...d };
                  //   updatedObject[dataIndex] = e;
                  //   return updatedObject;
                  // });
                  formHcNewData[dataIndex] = e
                  if (formHcLeaverData?.canInitiatorEditAfterApproval) formHcNewData['editedAfterApproval'] = true
                  setFormHcNewData(formHcNewData)
                  setHasDataChanged(false)
                  setFormDataChanged(true)
                  localStorage.setItem('LR', JSON.stringify(formHcNewData))

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          );
        } else {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible} disabled={isDisabled}
              component={(props) => FormTextArea(props, formHcNewData, col.editable)}
              editorOptions={{
                disabled: isDisabled,
                setValue: (e, dataIndex) => {
                  // const d = { ...formHcNewData }
                  // formHcLeaverData[dataIndex] = e
                  // setFormHcNewData(prevState => {
                  //   const updatedObject = { ...prevState, ...d };
                  //   updatedObject[dataIndex] = e;
                  //   return updatedObject;
                  // });
                  formHcNewData[dataIndex] = e
                  if (formHcLeaverData?.canInitiatorEditAfterApproval) formHcNewData['editedAfterApproval'] = true
                  setFormHcNewData(formHcNewData)
                  setHasDataChanged(false)
                  setFormDataChanged(true)
                  localStorage.setItem('LR', JSON.stringify(formHcNewData))

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          );
        }
      }
      else if (col.type === 'radio-group') {
        return (
          <SimpleItem key={index} name={col.dataIndex}
            editorType="dxTextBox"
            dataField={col.dataIndex}
            isRequired={col.required} disabled={isDisabled}
            visible={col.is_form_visible}
            component={(props) => CustomRadioGroup(props, col.options)}
            editorOptions={{
              disabled: isDisabled,
              setValue: (e, dataIndex) => {
                formHcNewData[dataIndex] = e
                setFormHcNewData(formHcNewData)
                if (formHcLeaverData?.canInitiatorEditAfterApproval) formHcNewData['editedAfterApproval'] = true
                setHasDataChanged(false)
                setFormDataChanged(true)
                localStorage.setItem('LR', JSON.stringify(formHcNewData))
              }
            }}>
            <Label text={col.title} for={col.dataIndex} />
          </SimpleItem>
        )
      }
      else {
        // text & string types
        if (col.dataIndex === 'movingTo') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxTextBox"
              dataField={col.dataIndex}
              isRequired={col.required} disabled={isDisabled}
              visible={
                col.is_form_visible &&
                formHcNewData &&
                formHcNewData.masterEosTypeId === 1
              }
              component={(props) => FormTextBox(props, formHcNewData, col.editable)}
              editorOptions={{
                disabled: isDisabled || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback)),
                setValue: (e, dataIndex) => {
                  // const d = { ...formHcNewData }
                  // formHcLeaverData[dataIndex] = e
                  // setFormHcNewData(prevState => {
                  //   const updatedObject = { ...prevState, ...d };
                  //   updatedObject[dataIndex] = e;
                  //   return updatedObject;
                  // });
                  formHcNewData[dataIndex] = e
                  if (formHcLeaverData?.canInitiatorEditAfterApproval) formHcNewData['editedAfterApproval'] = true
                  setFormHcNewData(formHcNewData)
                  setHasDataChanged(false)
                  setFormDataChanged(true)
                  localStorage.setItem('LR', JSON.stringify(formHcNewData))

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          );
        } else {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxTextBox"
              dataField={col.dataIndex}
              isRequired={col.required} disabled={isDisabled}
              visible={col.is_form_visible}
              component={(props) => FormTextBox(props, formHcNewData, col.editable)}
              editorOptions={{
                disabled: isDisabled,
                setValue: (e, dataIndex) => {
                  // const d = { ...formHcNewData }
                  // formHcLeaverData[dataIndex] = e
                  // setFormHcNewData(prevState => {
                  //   const updatedObject = { ...prevState, ...d };
                  //   updatedObject[dataIndex] = e;
                  //   return updatedObject;
                  // });
                  formHcNewData[dataIndex] = e
                  if (formHcLeaverData?.canInitiatorEditAfterApproval) formHcNewData['editedAfterApproval'] = true
                  setFormHcNewData(formHcNewData)
                  setHasDataChanged(false)
                  setFormDataChanged(true)
                  localStorage.setItem('LR', JSON.stringify(formHcNewData))

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          );
        }
      }
    });
  }

  return <React.Fragment>
    {columns?.length ? (
      <MDBox id="HcLeaverFormContainer">
        <div style={{ padding: '16px' }} className={formClassName}>
          <Form
            id="HcLeaverForm"
            className="HcLeaverFormContent"
            key="id"
            ref={formRef}
            onFieldDataChanged={formFieldDataChanged}
            formData={formHcNewData}
            width={'100%'}
            style={{ marginBottom: '30px' }}
          >
            <GroupItem colCount={2}>
              {columns?.length > 0 && renderFormFields(columns, dropDownData)}
            </GroupItem>
          </Form>
          {formErrorMsg !== null ? <MDBox className="form-error">{formErrorMsg}</MDBox> : null}
          <MDBox style={{ textAlign: 'right' }}>
            {
              formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || formHcNewData?.canInitiatorEditAfterApproval
                ? <>
                  <DEButton
                    visible={permissions && permissions.canCreate}
                    stylingMode={'contained'}
                    type={'success'}
                    text={'Add'}
                    onClick={handleSubmit}
                    style={{ marginLeft: '10px' }}
                  />
                  <DEButton
                    visible={permissions && permissions.canCreate}
                    stylingMode={'contained'}
                    type={'danger'}
                    text={'Reset'}
                    onClick={handleReset}
                    style={{ marginLeft: '10px' }}
                  />
                  {
                    !formHcNewData.isRollback
                      ? <DEButton
                        visible={permissions && permissions.canCreate}
                        stylingMode={'contained'}
                        type={'default'}
                        text={'Cancel'}
                        onClick={handleCancel}
                        style={{ marginLeft: '10px' }}
                      />
                      : null
                  }
                </>
                : null
            }
          </MDBox>
        </div>
      </MDBox>
    ) : null}
  </React.Fragment>;
})

HcLeaverForm.displayName = "HcLeaverForm"
export default HcLeaverForm;
