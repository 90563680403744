import React, { useEffect, useState } from "react";
import 'antd/dist/antd.css';
import { Modal } from 'antd';
import MDTypography from "components/MDTypography";
import { DEButton } from "utils/services/Helpers";
import CustomSkelton from "../../components/Skelton/CustomSkelton";
import MDBox from "../../components/MDBox";
import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form";
import useFunctionalAccessPriority from "../../utils/hooks/useFunctionalAccessPriority";

const FunctionalAccessPriorityModal = ({ title = "Priority", btnTitle = "Manage Priority", role, roleName, isDisabled = false, permissions, functional_access_priority }) => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [priority, setPriority] = useState(functional_access_priority ?? { can_export: false, can_sort: false, can_view: false })
  const {createUpdatePriority, isLoading} = useFunctionalAccessPriority()

  useEffect( () => {}, [isModalVisible])

  useEffect(() => {}, [priority])

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  async function handleSubmit(e) {
    await createUpdatePriority({
      ...priority,
      functionalAccessId: role
    })
  }

  /**
   * Handle Form Data Change
   **/
  const formFieldDataChanged = (e) => {
    setPriority(prevState => {
      const updatedObject = { ...prevState, ...priority };
      updatedObject[e.dataField] = e.value;
      return updatedObject;
    })
  }

  return (
    <>
      <DEButton disabled={isDisabled} stylingMode={"contained"} type={"normal"} text={btnTitle} onClick={showModal} />
      <Modal maskClosable={false} title={title} width={1200} open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={role ?
        [
          <DEButton stylingMode={"contained"} type={"success"} text={"Ok"} onClick={handleCancel} />,
        ] : null}>
        <MDTypography variant="h6">
          Functional Security Name: {roleName}
        </MDTypography>
        <br />
        <CustomSkelton is_loading={isLoading}>
          <MDBox>
            <div style={{ padding: "16px" }}>
              <Form onFieldDataChanged={formFieldDataChanged} formData={priority} width={"100%"} style={{ marginBottom: "30px" }}>
                <GroupItem colCount={3}>

                  <SimpleItem dataField={"can_sort"} editorType="dxSwitch" name={"can_sort"} isRequired={false}
                              editorOptions={{ disabled: !permissions?.canCreate }}>
                    <Label text={"Can Sort"} />
                  </SimpleItem>

                  <SimpleItem dataField={"can_view"} editorType="dxSwitch" name={"can_view"} isRequired={false}
                              editorOptions={{ disabled: !permissions?.canCreate }}>
                    <Label text={"Can View"} />
                  </SimpleItem>

                  <SimpleItem dataField={"can_export"} editorType="dxSwitch" name={"can_export"} isRequired={false}
                              editorOptions={{ disabled: !permissions?.canCreate }}>
                    <Label text={"Can Export"} />
                  </SimpleItem>
                </GroupItem>
              </Form>
              <MDBox style={{ textAlign: "right" }}>
                <DEButton stylingMode={"contained"} type={"success"} text={"Submit"} onClick={handleSubmit} style={{ marginLeft: "10px" }} />
              </MDBox>
            </div>
          </MDBox>
        </CustomSkelton>
      </Modal>
    </>
  );
};

export default FunctionalAccessPriorityModal;
