import { useContext, useState } from "react";
import { useIsLoading } from "./useIsLoading";
import request from "../services/Http";
import { Context } from "../context/store/Store";
import { getPageProperties, getTableColumns } from "../services/Helpers";
import usePagePermissions from "./usePagePermissions";
import { toast } from "react-toastify";

export default function useCapability(routeKey){
  const [{ pagesAndPermissions }, dispatch] = useContext(Context)
  const [masterCapability, setMasterCapability] = useState({
    rows: [],
    columns: []
  })
  const {setIsLoading, isLoading} = useIsLoading()
  const permissions = usePagePermissions(routeKey).getPermissionsForPage()
  const API_END_POINT = 'master-capability'

  async function getAllMasterCapabilities(){
    setIsLoading(true)
    try {
      const res = await request.get(API_END_POINT)
      if (res)
      {
        const properties = getPageProperties(pagesAndPermissions, routeKey)
        const table = getTableColumns(properties?.page?.table, routeKey)
        let columns = table?.columns ?? []
        let rows = res.data.data
        setMasterCapability({
          rows: rows,
          columns: columns
        })
      }
    }
    catch (e){}
    finally {
      setIsLoading(false)
    }
  }

  async function createUpdateMasterCapabilities(capabilities){
    setIsLoading(true)
    try {
      const res = await request.post(API_END_POINT, {capabilities})
      if (res)
      {
        toast.success(res.data.data)
        await getAllMasterCapabilities()
      }
    }
    catch (e) {}
    finally {
      setIsLoading(false)
    }
  }

  async function deleteMasterCapabilities(ids){
    setIsLoading(true)
    try {
      const res = await request.delete(API_END_POINT, {
        data: {ids}
      })
      if (res)
      {
        toast.success(res.data.data)
        await getAllMasterCapabilities()
      }
    }
    catch (e) {}
    finally {
      setIsLoading(false)
    }
  }


  return {
    setMasterCapability, masterCapability,
    isLoading, getAllMasterCapabilities,
    permissions, createUpdateMasterCapabilities,
    deleteMasterCapabilities
  }
}