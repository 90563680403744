import { useContext, useState } from "react";
import { useIsLoading } from "./useIsLoading";
import request from "../services/Http";
import { Context } from "../context/store/Store";
import { getPageProperties, getTableColumns } from "../services/Helpers";
import usePagePermissions from "./usePagePermissions";
import { toast } from "react-toastify";

export default function useEntityVerticalCapability(routeKey){
  const [{ pagesAndPermissions }, dispatch] = useContext(Context)
  const [entityVerticalCapability, setEntityVerticalCapability] = useState({
    rows: [],
    columns: [],
    dropdownData: {}
  })
  const [graphsData, setGraphsData] = useState([])
  const {setIsLoading, isLoading} = useIsLoading()
  const permissions = usePagePermissions(routeKey).getPermissionsForPage()
  const API_END_POINT = 'entity-vertical-capability'

  async function getAllEntityVerticalCapabilities(){
    setIsLoading(true)
    try {
      const res = await request.get(API_END_POINT)
      if (res)
      {
        const properties = getPageProperties(pagesAndPermissions, routeKey)
        const table = getTableColumns(properties?.page?.table, routeKey)
        let columns = table?.columns ?? []
        let rows = res.data.data
        setEntityVerticalCapability({
          rows: rows,
          columns: columns,
          dropdownData: res.data.dropdownData
        })
      }
    }
    catch (e){}
    finally {
      setIsLoading(false)
    }
  }

  async function createUpdateEntityVerticalCapabilities(capabilities){
    setIsLoading(true)
    try {
      const res = await request.post(API_END_POINT, {capabilities})
      if (res)
      {
        toast.success(res.data.data)
        await getAllEntityVerticalCapabilities()
      }
    }
    catch (e) {}
    finally {
      setIsLoading(false)
    }
  }

  async function deleteEntityVerticalCapabilities(ids){
    setIsLoading(true)
    try {
      const res = await request.delete(API_END_POINT, {
        data: {ids}
      })
      if (res)
      {
        toast.success(res.data.data)
        await getAllEntityVerticalCapabilities()
      }
    }
    catch (e) {}
    finally {
      setIsLoading(false)
    }
  }

  async function getGraphsData(){
    setIsLoading(true)
    try {
      const res = await request.get(`${API_END_POINT}/graph-data`)
      if (res)
      {
        // setGraphsData(res.data.data)
        localStorage.setItem('dataNodes', JSON.stringify(res.data.data))
      }
    }
    catch (e){}
    finally {
      setIsLoading(false)
    }
  }

  return {
    setEntityVerticalCapability, entityVerticalCapability, isLoading,
    getAllEntityVerticalCapabilities, permissions, getGraphsData,
    createUpdateEntityVerticalCapabilities, deleteEntityVerticalCapabilities
  }
}