import React, { useMemo, useRef, useEffect } from "react";
import Editor from "components/Editor";
import { Switch } from "antd";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { DEButton } from "utils/services/Helpers";
import EmailSubject from "./notification-subject";
import { NotificationCreatorStep } from "./notification-creator-step";

export function NotificationAccessStep({
  isForCreator,
  setIsForCreator,
  isForHiringManager,
  setIsForHiringManager,
  sendPush,
  setSendPush,
  sendEmail,
  setSendEmail,
  subjectChips,
  subject,
  setSubject,
  handleSubmit,
  handleReset,
  emailBody,
  setEmailBody,
  isSubmitDisabled,
  workflowStepData,
  workflowStep,
  setWorkflowStep,
  isLoading,
  workflowAccessData,
  workflowAccesses,
  setWorkflowAccess,
  functionalAccessData,
  functionalAccesses,
  setFunctionalAccesses,
  approveDirectly,
  setApproveDirectly,
}) {
  const emailBodyRef = useRef(emailBody);

  const handleEditorValueChange = (value) => {
    emailBodyRef.current = value;
    setEmailBody(value);
  };

  const submitNotificationStep = () => {
    setEmailBody(emailBodyRef.current);
    handleSubmit(emailBodyRef.current);
  };

  useEffect(() => {
    emailBodyRef.current = emailBody;
  }, [emailBody]);

  const editorComponent = useMemo(() => {
    return (
      <>
        <MDBox mt={2} mb={2}>
          <Grid
            container
            rowSpacing={3}
            rowGap={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            alignItems="center"
          >
            <Grid item xs={12} sm={6} md={3}>
              <Grid container rowSpacing={1} columnSpacing={6} alignItems="center">
                <Grid item xs={1}>
                  <Switch
                    checked={isForCreator}
                    onChange={(c) => {
                      setIsForCreator((wasOpened) => !wasOpened);
                    }}
                    disabled={isForHiringManager}
                  />
                </Grid>
                <Grid item xs={1}>
                  <MDTypography variant="subtitle">For Creator</MDTypography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Grid container rowSpacing={1} columnSpacing={6} alignItems="center">
                <Grid item xs={1}>
                  <Switch
                    checked={isForHiringManager}
                    onChange={(c) => {
                      setIsForHiringManager((wasOpened) => !wasOpened);
                    }}
                    disabled={isForCreator}
                  />
                </Grid>
                <Grid item xs={1}>
                  <MDTypography variant="subtitle">For Hiring Manager</MDTypography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Grid container rowSpacing={1} columnSpacing={6} alignItems="center">
                <Grid item xs={1}>
                  <Switch
                    checked={sendPush}
                    onChange={(c) => {
                      setSendPush((wasOpened) => !wasOpened);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MDTypography variant="subtitle">Send Push Notification</MDTypography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <Grid container rowSpacing={1} columnSpacing={6} alignItems="center">
                <Grid item xs={1}>
                  <Switch
                    checked={sendEmail}
                    onChange={(c) => {
                      setSendEmail((wasOpened) => !wasOpened);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MDTypography variant="subtitle">Send Email</MDTypography>
                </Grid>
              </Grid>
            </Grid>
            {sendEmail && (
              <Grid item xs={12} sm={6} md={4}>
                <Grid container rowSpacing={1} columnSpacing={6} alignItems="center">
                  <Grid item xs={1}>
                    <Switch
                      checked={approveDirectly}
                      onChange={(c) => {
                        setApproveDirectly((wasOpened) => !wasOpened);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MDTypography variant="subtitle">Directly Approve</MDTypography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {!(isForCreator || isForHiringManager) && (
              <NotificationCreatorStep
                workflowStepData={workflowStepData}
                workflowStep={workflowStep}
                setWorkflowStep={setWorkflowStep}
                isLoading={isLoading}
                workflowAccessData={workflowAccessData}
                workflowAccesses={workflowAccesses}
                setWorkflowAccess={setWorkflowAccess}
                functionalAccessData={functionalAccessData}
                functionalAccesses={functionalAccesses}
                setFunctionalAccesses={setFunctionalAccesses}
              />
            )}
            {(sendPush || sendEmail) && (
              <EmailSubject subject={subject} setSubject={setSubject} subjectChips={subjectChips} />
            )}
            {sendEmail && (
              <Grid item xs={12}>
                <Editor
                  value={emailBody}
                  setValue={handleEditorValueChange}
                  variableValues={subjectChips}
                />
              </Grid>
            )}
          </Grid>
        </MDBox>

        <MDBox
          display="flex"
          justifyContent="right"
          alignItems="center"
          position="relative"
          zIndex={1}
        >
          <DEButton
            text="Add"
            disabled={isSubmitDisabled}
            onClick={submitNotificationStep}
            useSubmitBehavior
            stylingMode="contained"
            type="default"
            style={{ marginRight: "5px" }}
          />
          <DEButton
            text="Reset"
            disabled={isSubmitDisabled}
            onClick={handleReset}
            stylingMode="contained"
            type="danger"
          />
        </MDBox>
      </>
    );
  }, [
    sendEmail,
    isForCreator,
    isForHiringManager, 
    isSubmitDisabled,
    sendPush,
    subject,
    approveDirectly,
    isLoading,
    workflowAccesses,
    isLoading,
    workflowStep,
    functionalAccesses,
  ]);

  return <>{editorComponent}</>;
}
