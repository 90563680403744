import Select from "react-select";
import { useContext } from "react";
import { Context } from "utils/context/store/Store";
import { SET_HC_REQ_DIVISION, SET_HC_REQ_ENTITY, SET_HC_REQ_COUNTRY, SET_HC_REQ_VERTICAL, SET_HC_REQ_FUNCTION, SET_HC_REQ_LEVEL } from './../../utils/context/store/Constants';

export const customStyles = {

  control: styles => ({
    ...styles,
    borderBottom: -1,
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderRadius: 0
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none'
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 1000,
    fontSize: '14px'
  }),
};


export default function MultiSelect(options, selectedValues = null, isDisabled = false, isMulti = false, dataIndex) {

  const [{ masterOrgDivisionId, masterOrgEntityId, masterCountryId, masterOrgVerticalId, masterOrgFunctionId, masterLevelId }, dispatch] = useContext(Context)

  return (
    <Select options={options} value={selectedValues} defaultValue={selectedValues}
      styles={customStyles}
      isMulti={isMulti}
      isSearchable
      isDisabled={isDisabled}
      menuPortalTarget={document.body}
      onChange={val => {
        if (dataIndex === "masterOrgDivisionId") {
          dispatch({
            type: SET_HC_REQ_DIVISION,
            payload: val
          })
        }
        if (dataIndex === "masterOrgEntityId") {
          dispatch({
            type: SET_HC_REQ_ENTITY,
            payload: val
          })
        }
        if (dataIndex === "masterCountryId") {
          dispatch({
            type: SET_HC_REQ_COUNTRY,
            payload: val
          })
        }
        if (dataIndex === "masterOrgVerticalId") {
          dispatch({
            type: SET_HC_REQ_VERTICAL,
            payload: val
          })
        }
        if (dataIndex === "masterOrgFunctionId") {
          dispatch({
            type: SET_HC_REQ_FUNCTION,
            payload: val
          })
        }
        if (dataIndex === "masterLevelId") {
          dispatch({
            type: SET_HC_REQ_LEVEL,
            payload: val
          })
        }
      }}
    />
  )
}