import React from "react";
import { Switch } from "antd";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import Footer from "../../examples/Footer";
import CustomSkelton from "../Skelton/CustomSkelton";

export default function BaseLayOut({ children, pageTitle, isForJD = false, isForHc = false, pageView = null, setPageView = null, setTabValue = null, toggleText = "Bulk Display", isForCv = false, isForTransfer = false }) {
  const notificationMessage = process.env.REACT_APP_NOTIFICATION_MESSAGE;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CustomSkelton>
        {
          isForJD
            ? children
            : <>
              <MDBox my={3}>
                {isForHc && notificationMessage ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: notificationMessage }}
                    style={{
                      color: "#842029",
                      borderColor: "#f5c2c7",
                      backgroundColor: "#f8d7da",
                      padding: "0.5rem 1rem",
                      marginBottom: "1rem",
                      border: "1px solid transparent",
                      borderRadius: ".25rem",
                      fontSize: "14px"
                    }}
                  />
                ) : null}
                <Card>
                  <MDBox pt={3} pl={3} pr={3} pb={1} lineHeight={1}>
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                          {pageTitle.replace(/-/g, " ")}
                        </MDTypography>
                      </Grid>
                      {isForHc || isForCv || isForTransfer ? (
                        <Grid item>
                          <Grid container spacing={3} alignItems="center">
                            {
                              isForCv ? <Grid item className="padding-left-10">
                                  <MDTypography style={{fontSize: "14px"}} variant="subtitle">
                                    Agency/Practice View
                                  </MDTypography>
                                </Grid> : ""
                            }
                            <Grid item className="padding-left-10">
                              <Switch
                                style={isForCv ? { backgroundColor: '#1890ff', width: '30px', height: '8px' } : {}}
                                checked={Boolean(pageView)}
                                className={isForCv ? '__toggle__btn__narrow__height__' : ""}
                                onChange={(e) => {
                                  if (setPageView) {
                                    setPageView(!pageView);
                                    localStorage.setItem('pageView', !pageView)
                                    if (!pageView) setTabValue(0);
                                    else setTabValue(0);
                                  }
                                  if (setPageView === null && setTabValue) setTabValue(e ? 1 : 0)
                                }}
                              />
                            </Grid>
                            <Grid item className="padding-left-10">
                              <MDTypography style={{ fontSize: isForCv ? "14px" : undefined }} variant="subtitle">
                                {isForCv ? `${toggleText} View` : toggleText}
                                {/*{`${isForCv ? (pageView ? toggleText : 'Agency/Practice') : toggleText} View`}*/}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null}
                    </Grid>
                  </MDBox>



                  <MDBox pt={1} pl={3} pr={3} pb={1}>
                    {
                      children
                    }
                  </MDBox>
                </Card>
              </MDBox>
            </>
        }
      </CustomSkelton>
      <Footer />
    </DashboardLayout>
  )
}
