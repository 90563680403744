import request from "../services/Http";
import { useContext } from "react";
import { addKeyAndIndexFieldToGenericArrayOfObjects, setColumnsForDataTable, isJSONValid } from "../services/Helpers";
import useRoles from "./useRoles";
import { toast } from 'react-toastify'
import useNavigation from "./useNavigation";
import { useIsLoading } from "./useIsLoading";
import { Context } from "../context/store/Store";
import { SET_ROLE_PERMISSIONS } from "../context/store/Constants";
import { v4 as uuidv4 } from "uuid";

export default function usePermissions(role, masterModuleId, routeKey) {

  const { isLoading, setIsLoading } = useIsLoading()
  const { fetchNavigations } = useNavigation()
  const [{role_permissions}, dispatch] = useContext(Context)
  const { getRoles } = useRoles(routeKey)

  async function getPermissionsByRole(role, modUle) {
    setIsLoading(true)
    const mId = typeof modUle === "object" ? modUle.id : modUle
    try {
      const res = await request.get(`functional-access/${role}/${mId}/permission`)
      let columns = res.data.object.columns
      let rows = res.data.data
      let newRowObject = isJSONValid(res.data.object.row.defaultObj) ?? {}
      const apiDelete = false

      if (role_permissions && role_permissions.hasOwnProperty('apiDelete') && role_permissions.apiDelete){
        rows = [...role_permissions.rows, ...rows]
      }

      rows.map((r) => {
        if (!r.hasOwnProperty('id'))
        {
          r.newRow = true
          r.id =  uuidv4()
        }
        r.key = uuidv4()
      })

      dispatch({
        type: SET_ROLE_PERMISSIONS,
        payload:{ columns: columns, rows: addKeyAndIndexFieldToGenericArrayOfObjects(rows), newRowObject: newRowObject, apiDelete: apiDelete }
      })
    }
    catch (e) {}
    setIsLoading(false)
  }

  async function updateOrCreatePermissions(functionalAccessId, data) {
    setIsLoading(true)
    try {
      const res = await request.put(`functional-access/${functionalAccessId}`, {
        permissions: data
      })
      await getPermissionsByRole(functionalAccessId, masterModuleId)
      await getRoles()
      await fetchNavigations()
      toast.success(res.data.data)
    }
    catch (e) {}
    setIsLoading(false)
  }

  async function deletePermissions(permissions, functionalAccessId = null) {
    setIsLoading(true)
    try {
      const res = await request.delete('permission', {
        data: {
          functionalAccessId: functionalAccessId,
          permissions: permissions
        }
      })
      if (res) {
        await getRoles()
        await getPermissionsByRole(functionalAccessId, masterModuleId)
        await fetchNavigations()
        toast.success(res.data.data)
      }
    }
    catch (e) {}
    setIsLoading(false)
  }

  return { getPermissionsByRole, deletePermissions, updateOrCreatePermissions, role_permissions, isLoading }
}