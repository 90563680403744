import { Accordion, AccordionDetails, AccordionSummary, DEButton } from "../../../utils/services/Helpers";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import HcForm from "./components/HcForm";
import * as React from "react";
import { useState } from "react";
import "../components/styles.scss"


const LeaverReplacementAccordion = React.memo(({ expandedKey, data, columns, dropDownData, permissions, setDataSource, setDgData, dataSource, setHasDataChanged, apiCallBack, routeKey, isDraft, isCloned = false, handleDelete, leaverDetails }) => {
    const [expandedIndex, setExpandedIndex] = useState(null);

    return <MDBox pt={2}>
        <Accordion defaultExpanded={expandedIndex} expanded={expandedKey === expandedIndex} onChange={(e) => {
            setExpandedIndex(expandedKey === expandedIndex ? null : expandedKey)
        }}>
            <AccordionSummary aria-controls={`panel${expandedKey + 1}-content`} id={`panel${expandedKey + 1}-header`}>
                <MDBox display="flex" justifyContent="space-between" width="100%" alignItems="center">
                    <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6">Replacement {expandedKey + 1} Details</MDTypography>
                    <div className="del-icon">
                        <DEButton disabled={!(data.newRow || (!data.hasRollback && !data.isRollback && data.isDraft) || (data.hasRollback && data.isRollback))} hint="Delete" stylingMode="contained" type="danger" icon="trash" onClick={() => handleDelete(expandedKey)} />
                    </div>
                </MDBox>
            </AccordionSummary>
            <AccordionDetails className="HcDetailsAccordionDetails">
                <HcForm
                    setExpandedIndex={setExpandedIndex}
                    expandedIndex={expandedIndex}
                    formHcData={data}
                    columns={columns}
                    dropDownData={dropDownData}
                    permissions={permissions}
                    setDataSource={setDataSource}
                    setDgData={setDgData}
                    dataSource={dataSource}
                    setHasDataChanged={setHasDataChanged}
                    apiCallBack={apiCallBack}
                    routeKey={routeKey}
                    isReplacement={true}
                    isDraft={isDraft}
                    isCloned={isCloned}
                    leaverDetails={leaverDetails}
                />
            </AccordionDetails>
        </Accordion>
    </MDBox>
}, (prevProps, nextProps) => {
    // Compare someArrayProp for changes
    return (
        prevProps.headcounts.length === nextProps.headcounts.length &&
        prevProps.headcounts.every((value, index) => value === nextProps.headcounts[index])
    );
})

LeaverReplacementAccordion.displayName = "LeaverReplacementAccordion"

export default LeaverReplacementAccordion
