import { useRef, useEffect } from 'react';

/**
 * Hook that provides a way to check if a component is currently mounted.
 * This is useful to avoid setting state or performing other side effects on a component that has unmounted.
 * 
 * @returns {Function} - Function that returns a boolean indicating if the component is currently mounted.
 */
export function useIsMounted() {
  // Reference to keep track of the component's mounted status
  const isMountedRef = useRef(false);

  // Effect to set the component's status as mounted when it mounts and as unmounted when it unmounts
  useEffect(() => {
    // Set the component as mounted
    isMountedRef.current = true;

    // Cleanup function to set the component as unmounted when it unmounts
    return () => (isMountedRef.current = false);
  }, []);

  // Return a function that, when called, returns the current mounted status of the component
  return () => isMountedRef.current;
}
