import React, { useEffect, useState } from "react";
import 'devextreme/dist/css/dx.light.css';
import 'antd/dist/antd.css';
import { Button } from 'devextreme-react/button';
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import SelectBox from "devextreme-react/select-box";
import { DEButton } from "utils/services/Helpers";
import { Modal, Switch } from 'antd';
import MDTypography from "../../../components/MDTypography";

const ManageOnBehalf = ({ data, users, onBehalfUserId, postData, dataChange = () => { } }) => {

  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [managingId, setManagingId] = useState(onBehalfUserId);
  const [status, setStatus] = useState(false);

  useEffect(() => { }, [managingId, status]);
  useEffect(() => { }, [status]);

  /**
   * function use to manage state to view/hide popup
   **/
  const togglePopup = () => {
    // if (!isPopupVisible) setStatus(true)

    setPopupVisibility(!isPopupVisible);
  };

  /**
   * function use to call the api to post data
   **/
  const handleSubmit = async (e) => {
    e.preventDefault()
    data['statusChanged'] = data?.onBehalfUserId !== managingId && status
    data['onBehalfUserId'] = managingId
    data['status'] = false
    // dataChange(true)
    await postData([data])
    togglePopup()
  }

  return (
    <>
      <Modal destroyOnClose={true} className="recruitment-stage" maskClosable={false} title={"Deactivate User & Manage on Behalf"} bodyStyle={{ paddingBottom: '25px' }} width={1000} height={500} open={isPopupVisible} onCancel={togglePopup}
        footer={null}>
        <div className="container">
          <form onSubmit={handleSubmit} noValidate>
            <MDBox py={3}>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <MDTypography variant="body1">
                    You are deactivating a user from the system, do you want to select another user to manage on their behalf?
                  </MDTypography>
                </Grid>
              </Grid>
              <MDBox p={1}>
                <Grid container alignItems="center" style={{ height: "50px" }}>
                  <Grid item xs={12} sm={1}>
                    {status ? "Yes" : "No"}
                  </Grid>

                  <Grid item xs={12} sm={1} style={{ marginLeft: "-30px" }}>
                    <Switch
                      hint={"status"}
                      title={"status"}
                      value={status}
                      onChange={(e) => {
                        setStatus(e);
                        if (!e) setManagingId(null);
                      }}
                    />
                  </Grid>

                  {status ? (
                    <Grid item xs={12} sm={10}>
                      <SelectBox
                        disabled={!users?.length || !status}
                        searchEnabled={true}
                        showClearButton={true}
                        placeholder={"Select User"}
                        value={managingId}
                        onValueChanged={(e) => {
                          setManagingId(e.value);
                        }}
                        displayExpr="username"
                        valueExpr="id"
                        dataSource={users}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </MDBox>
              <MDBox display="flex" justifyContent="right" alignItems="center" position="relative" zIndex={1}
                sx={{ marginBottom: 5 }}>
                <MDBox mr={1}>
                  <Button disabled={status && managingId === null} stylingMode="contained" type="success" useSubmitBehavior={true} text="Ok,Continue" />
                  &nbsp;&nbsp;
                  <DEButton stylingMode={"contained"} type={"danger"} text={"Cancel"} onClick={togglePopup} />
                </MDBox>
              </MDBox>
            </MDBox>
          </form>
        </div>
      </Modal>
      <Button
        stylingMode={"contained"} type={data?.status ? "danger" : "success"}
        name={"internal-mobility"} text={data?.status ? "Deactivate User" : "Activate User"}
        onClick={togglePopup}
      />
    </>
  );
}

export default ManageOnBehalf;