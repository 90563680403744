import { toast } from "react-toastify";
import request from "../services/Http";
import { useNavigate } from 'react-router-dom';
import { convertToLocalDateTime, encryptObject, getLocalDateTime, initFireBase } from "../services/Helpers";

export default function useLogin(msalInstance) {

  const history = useNavigate()

  async function handleLogin() {
    try {
      await msalInstance.initialize()
      const res = await msalInstance.loginPopup({
        scopes: ['User.Read', 'openid', 'profile', 'offline_access'],
        prompt: "select_account",
      })
      if (res) doActionIfLoginSuccess(msalInstance, res)
    }catch (e) {
      console.log('err', e)
      return toast.error('Sorry, failed to authenticate user.')
    }
  }

  function doActionIfLoginSuccess (instance, res, loadPage=false) {
    instance.setActiveAccount(res.account)
    const dateTime = convertToLocalDateTime(res.expiresOn)
    const _token = encryptObject(res.accessToken)
    if (loadPage) return {
      _token, expiry: dateTime
    }
    localStorage.setItem("_token", _token)
    localStorage.setItem("expiry", dateTime)
    fetchUserDetail(null, false, res.accessToken, dateTime, loadPage)
  }

  function storeUser(user) {
    request.post('user/profile', user)
      .then((res) => {
        toast.success('Profile Updated Successfully')
        fetchUserDetail(user, true)
      })
  }

  function fetchUserDetail(userInfo, redirectToProfile = false, token = null, expiresOn = null, loadPage=false) {
    request.post('user/find-by-email', userInfo, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'E-T': `${expiresOn}`
      }
    }).then(async (res) => {
      if (res) {
        const redirectTo = localStorage.getItem('redirectPath') ?? null
        localStorage.setItem('loggedInTime', getLocalDateTime())
        localStorage.setItem('cleared', 'true')
        await initFireBase()
        localStorage.setItem('isLoggedIn', 'true')
        res?.data?.data ? localStorage.setItem('hasProfile', 'true') : localStorage.setItem('hasProfile', 'false')
        if (process.env.NODE_ENV !== 'development') res?.data?.data ? localStorage.setItem("user", encryptObject(res.data.data)) : null
        else res?.data?.data ? localStorage.setItem('user', JSON.stringify(res.data.data)) : null
        if (!loadPage) history(redirectTo !== null ? redirectTo : redirectToProfile ? '/views/profile' : '/views/home')
        history(redirectTo !== null ? redirectTo : redirectToProfile ? '/views/profile' : '/views/home')
        localStorage.removeItem('redirectPath')
        window.location.reload();
      }
    })
  }

  return { handleLogin, storeUser, doActionIfLoginSuccess }
}
