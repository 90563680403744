import { useContext, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SET_IS_LOADING } from "../context/store/Constants";
import {
  getPageProperties,
  getTableColumns,
  isJSONValid,
} from "../services/Helpers";
import request from "../services/Http";
import { useIsLoading } from "./useIsLoading";
import { Context } from "../context/store/Store";

const CV_LIGHT_TYPE_ENDPOINT = "master-cv-light-type";

export default function useMasterCVLightType(routeKey) {
  const [cvLightTypes, setCVLightTypes] = useState({ })
  const { isLoading, setIsLoading } = useIsLoading();
  const [{ pagesAndPermissions }, dispatch] = useContext(Context);

  async function getCVLightTypes() {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const res = await request.get(CV_LIGHT_TYPE_ENDPOINT);
      const properties = getPageProperties(pagesAndPermissions, routeKey ?? "master-cv-light-type");
      const table = getTableColumns(properties.page.table, routeKey);
      let columns = table.columns;
      let rows = res.data.data;
      let dropdownValues = isJSONValid(res?.data?.dropdownValues) ?? {};
      if (cvLightTypes && cvLightTypes.hasOwnProperty("apiDelete") && cvLightTypes.apiDelete) {
        rows = [...cvLightTypes.rows, ...rows];
      }
      const dgData = {
        columns, rows, dropdownValues, apiDelete: false
      };
      setCVLightTypes(dgData)
    } catch (e) { }
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  async function updateOrCreateCVLightType(data) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.post(CV_LIGHT_TYPE_ENDPOINT, { lightTypes: data });
      if (res) {
        toast.success(res.data.data);
        await getCVLightTypes();
      }
    } catch (e) { }
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  /**
   * @param data
   * @param val
   * function used to manage delete api call for manage lights
   **/
  async function deleteCVLightType(data) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.delete(CV_LIGHT_TYPE_ENDPOINT, {
        data: { lightTypes: data },
      });
      if (res) {
        toast.success(res.data.data);
        await getCVLightTypes();
      }
    } catch (e) { }
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  return { getCVLightTypes, updateOrCreateCVLightType, deleteCVLightType, isLoading, cvLightTypes, setCVLightTypes };
}
