import { useIsLoading } from "./useIsLoading";
import { useContext } from "react";
import request from "../services/Http";
import { Context } from "../context/store/Store";
import { SET_USER_FUNCTIONAL_ACCESS } from "../context/store/Constants";
import { toast } from "react-toastify";

export default function useUserRoles(userId, masterModuleId){
  const {isLoading, setIsLoading} = useIsLoading()
  const [{ userFunctionalAccess }, dispatch] = useContext(Context)

  async function fetchUserFunctionalAccess() {
    setIsLoading(true)
    try {
      const res = await request.get(`user-functional-access/${userId}/${masterModuleId}`)

      if (res){
        const rows = res.data.data
        const dropDownData = res.data.dropdownValues
        const dataTableData = {rows: rows, dropDownData: dropDownData, }
        dispatch({
          type: SET_USER_FUNCTIONAL_ACCESS,
          payload: dataTableData
        })
      }
    }
    catch (e) {}
    setIsLoading(false)
  }

  async function createOrUpdateUserFunctionalAccess(roles){
    setIsLoading(true)
    try {
      const d = {
        functionalAccesses: roles,
        userId: userId,
        masterModuleId: masterModuleId
      }
      const res = await request.post('user-functional-access', d)
      if (res){
        toast.success(res.data.data)
        await fetchUserFunctionalAccess()
      }
    }
    catch (e) {}
    setIsLoading(false)
  }

  async function deleteUserFunctionalAccess(deleteAll = false) {
    setIsLoading(true)
    try {
      const res = await request.delete('user-functional-access', { data: { userId: userId, deleteAll: deleteAll} })
      if (res){
        toast.success(res.data.data);
        await fetchUserFunctionalAccess()
      }
    }
    catch (e) {}
    setIsLoading(false)
  }

  return {fetchUserFunctionalAccess, createOrUpdateUserFunctionalAccess, deleteUserFunctionalAccess, isLoading}
}