export const validateLevelCountry = (value, prevValues, userDataAccess) => {
  const { masterOrgDivisionId, masterOrgEntityId, masterOrgVerticalId, masterLevelId } =
    value.formula;

  if (masterOrgDivisionId) {
    strategies.divisionSelected(value, prevValues, userDataAccess);
  }
  if (masterOrgEntityId) {
    strategies.entitySelected(value, prevValues, userDataAccess);
  }

  if (masterOrgVerticalId) {
    strategies.verticalSelected(value, prevValues, userDataAccess);
  }
  if (masterLevelId) {
    strategies.levelSelected(value, prevValues, userDataAccess);
  }

  const countryIds = userDataAccess["masterCountryId"]
    .filter(
      (e) =>
        prevValues["masterOrgDivisionId"].includes(e.masterOrgDivisionId) &&
        prevValues["masterOrgEntityId"].includes(e.masterOrgEntityId)
    )
    .map((e) => e.masterCountryId);

  prevValues["masterCountryId"] = countryIds;
  return prevValues;
};

const strategies = {
  divisionSelected: (value, prevValues, userDataAccess) => {
    const divisionIds = value.transactions.map((e) => e.masterOrgDivisionId);
    prevValues["masterOrgDivisionId"] = divisionIds;

    const entityIds = filterAndMap(
      userDataAccess["masterOrgEntityId"],
      "masterOrgDivisionId",
      "masterOrgEntityId",
      divisionIds
    );
    prevValues["masterOrgEntityId"] = entityIds;
  },

  entitySelected: (value, prevValues, userDataAccess) => {
    const entityIds = value.transactions.map((e) => e.masterOrgEntityId);
    prevValues["masterOrgEntityId"] = entityIds;

    const divisionIds = filterAndMap(
      userDataAccess["masterOrgEntityId"],
      "masterOrgEntityId",
      "masterOrgDivisionId",
      entityIds
    );
    prevValues["masterOrgDivisionId"] = divisionIds;
  },

  verticalSelected: (value, prevValues, userDataAccess) => {
    const verticalIds = value.transactions.map((e) => e.masterOrgVerticalId);

    const filteredVerticals = userDataAccess["masterOrgVerticalId"].filter((e) =>
      verticalIds.includes(e["masterOrgVerticalId"])
    );

    prevValues["masterOrgVerticalId"] = filteredVerticals.map((e) => e.masterOrgVerticalId);

    prevValues["masterOrgEntityId"] = filteredVerticals.map((e) => e.masterOrgEntityId);

    prevValues["masterOrgDivisionId"] = filteredVerticals.map((e) => e.masterOrgDivisionId);
  },

  levelSelected: (value, prevValues, userDataAccess) => {
    const levelsIds = value.transactions.map((e) => e.masterLevelId);

    const filteredLevels = userDataAccess["masterLevelId"].filter((e) =>
      levelsIds.includes(e["masterLevelId"])
    );

    let verticalIds = [];
    if (value.formula.masterOrgVerticalId) {
      verticalIds = value.transactions.map((e) => e.masterOrgVerticalId);
    } else {
      verticalIds = filteredLevels.map((e) => e.masterOrgVerticalId);
    }

    let filteredVerticals = userDataAccess["masterOrgVerticalId"].filter((e) =>
      verticalIds.includes(e["masterOrgVerticalId"])
    );

    if (value.formula.masterOrgEntityId) {
      const entityIds = value.transactions.map((e) => e.masterOrgEntityId);
      filteredVerticals = filteredVerticals.filter((e) => entityIds.includes(e.masterOrgEntityId));
    } else if (value.formula.masterOrgDivisionId) {
      const divisionIds = value.transactions.map((e) => e.masterOrgDivisionId);
      filteredVerticals = filteredVerticals.filter((e) =>
        divisionIds.includes(e.masterOrgDivisionId)
      );
    }
    
    prevValues["masterOrgVerticalId"] = filteredVerticals.map((e) => e.masterOrgVerticalId);

    let filteredLevelsByVertical = userDataAccess["masterLevelId"].filter((e) =>
      prevValues["masterOrgVerticalId"].includes(e["masterOrgVerticalId"])
    );

    if (value.formula.masterOrgEntityId) {
      const entityIds = value.transactions.map((e) => e.masterOrgEntityId);
      filteredLevelsByVertical = filteredLevelsByVertical.filter((e) => entityIds.includes(e.masterOrgEntityId));
    } else if (value.formula.masterOrgDivisionId) {
      const divisionIds = value.transactions.map((e) => e.masterOrgDivisionId);
      filteredLevelsByVertical = filteredLevelsByVertical.filter((e) => divisionIds.includes(e.masterOrgDivisionId));
    }

    prevValues["masterOrgEntityId"] = filteredLevelsByVertical.map((e) => e.masterOrgEntityId);

    prevValues["masterOrgDivisionId"] = filteredLevelsByVertical.map((e) => e.masterOrgDivisionId);
  },
};

const filterAndMap = (data, filterField, mapField, filterValues) =>
  data.filter((e) => filterValues.includes(e[filterField])).map((e) => e[mapField]);
