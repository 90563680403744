import React, { useContext, useEffect } from "react";
import { Context } from "utils/context/store/Store";
import useSegments from 'utils/hooks/useSegments';
import usePagePermissions from "../../utils/hooks/usePagePermissions";
import BaseLayOut from "../../components/Layout/BaseLayOut";
import SegmentsDataGrid from "./DataTable/SegmentsDataGrid";
import useBulkUpload from "utils/hooks/useBulkUpload";

function ManageSegments({ routeKey, pageName }) {

  const { getSegments, deleteSegment, updateOrCreateSegments, isLoading } = useSegments(routeKey)
  const { bulkUpload } = useBulkUpload(routeKey)
  const [{ segments }, dispatch] = useContext(Context)
  const { getPermissionsForPage } = usePagePermissions(routeKey)

  useEffect(() => { }, [segments])

  useEffect(async () => {
    await getSegments()
  }, [])

  return (
    <BaseLayOut pageTitle={pageName ?? "Manage Segments"}>
      <SegmentsDataGrid loading={isLoading} columns={segments.columns} rows={segments.rows} dropDownData={segments.dropdownValues} objToAdd={segments.newRowObject} postData={updateOrCreateSegments} handleDelete={deleteSegment} isSegments={true} routeKey={routeKey} permissions={getPermissionsForPage()} allowSelection={false} allowAdding={true} allowDeleting={true} allowUpdating={true} elementColumns={segments && segments.elementColumns}
        bulkUploadApi={bulkUpload} apiCallback={getSegments} tableName={segments.tableName}
        uploadTemplateLink={segments.uploadTemplateLink} orgStructureLink={segments.orgStructureLink} allowBulkUploading={true} />
    </BaseLayOut>
  );
}

export default ManageSegments;