import { useContext } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SET_IS_LOADING, SET_MANAGE_ENTITY_CLIENTS } from "../context/store/Constants";
import { Context } from "../context/store/Store";
import {
  addKeyAndIndexFieldToGenericArrayOfObjects,
  getPageProperties,
  getTableColumns,
  isJSONValid,
} from "../services/Helpers";
import request from "../services/Http";
import { useIsLoading } from "./useIsLoading";
import useManageClientVitals from "./useManageClientVitals";

const API_ENDPOINT = "department-clients";

export default function useManageDepartmentClients(routeKey) {
  const { fetchClientVitals } = useManageClientVitals()
  const [{ entityClients, pagesAndPermissions }, dispatch] = useContext(Context);
  const { isLoading, setIsLoading } = useIsLoading();

  async function getEntityClients() {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.get(API_ENDPOINT);
      const properties = getPageProperties(pagesAndPermissions, routeKey ?? "manage-department-clients");
      const table = getTableColumns(properties.page.table, "manage-department-clients");
      let columns = table.columns;
      let rows = isJSONValid(res.data.data) ?? {};
      let dropdownValues = isJSONValid(res.data.dropdownValues) ?? {};
      let uploadTemplateLink = res.data.uploadTemplate ?? null
      let orgStructureLink = res.data.orgStructureLink ?? null
      let tableName = res.data.tableName ?? null
      if (entityClients && entityClients.hasOwnProperty("apiDelete") && entityClients.apiDelete) {
        rows = [...entityClients.rows, ...rows];
      }

      const dgData = {
        columns,
        rows: addKeyAndIndexFieldToGenericArrayOfObjects(rows),
        dropdownValues,
        apiDelete: false,
        uploadTemplateLink, orgStructureLink, tableName
      };
      dispatch({
        type: SET_MANAGE_ENTITY_CLIENTS,
        payload: dgData,
      });
    } catch (e) { }
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  async function updateOrCreateEntityClients(data) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.post(API_ENDPOINT, { entityClients: data });
      if (res) {
        toast.success(res.data.data);
        await getEntityClients();
      }
    } catch (e) { }
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  /**
   * @param data
   * @param val
   * function used to manage delete api call for manage entityClients
   **/
  async function deleteEntityClients(data) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.delete(API_ENDPOINT, {
        data: { entityClients: data },
      });
      if (res) {
        toast.success(res.data.data);
        await getEntityClients();
      }
    } catch (e) { }
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  /**
 * @param data
 * function used to manage disable api call for entityClients
 **/
  async function disableEntityClients(data, dropdownValue, tabValue) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.post(`${API_ENDPOINT}/disable`, { entityClients: data });
      if (res) {
        toast.success(res.data.data);
        await fetchClientVitals(dropdownValue, tabValue);
      }
    } catch (e) { }
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  return { getEntityClients, updateOrCreateEntityClients, deleteEntityClients, disableEntityClients, isLoading };
}
