import { useContext, useEffect, useRef, useState } from 'react';
import { Tree } from 'react-d3-tree';
import { CapabilityContext } from '../../contexts/capability-context';
import { rootNodes } from '../../models/nodes.model';
import './org-chart.scss';
import Logo from "assets/images/logos/publicismedia.png";

export const OrgChart = () => {
  const { selectedCompany, setShowContactInfo, setSelectedNode } = useContext(CapabilityContext);
  const [orgChartData, setOrgChartData] = useState(rootNodes);
  const [expandedNode, setExpandedNode] = useState(null);

  const [transition, setTransition] = useState({ x: 500, y: 70 });
  const nodeSize = { x: 200, y: 200 };
  const ref = useRef(null);
  const chartRef = useRef(null);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


  const customElement = ({ nodeDatum, toggleNode }) => {
    const handleToggle = () => {
      toggleNode();
      if (!nodeDatum.children.length) {
        setSelectedNode(nodeDatum);
        setShowContactInfo(true);
      }
    };
    return (
      <foreignObject x={-50} y={-50} width={200} height={200}>
        <div
          style={{
            boxShadow: `1px 1px 2px ${nodeDatum?.color}`,
            border: `1px solid ${nodeDatum?.color}`,
            position: isSafari ? "fixed" : "",
            transformOrigin: isSafari ? "0px 0px" : ""
          }}
          className={`org-chart__container ${nodeDatum.name === expandedNode ? 'org-chart__item--expanded' : ''}`} onClick={handleToggle}>
          <div className="org-chart__item">
            {nodeDatum.name === 'Parent Company' ? <img src={Logo} className="org-chart__logo" /> : nodeDatum?.name}
            {nodeDatum.children?.length > 0 && (
              <p
                style={{
                  boxShadow: `1px 1px 2px ${nodeDatum?.color}`,
                  border: `1px solid ${nodeDatum?.color}`
                }}
                className="org-chart__collapse"
              >
                {nodeDatum.__rd3t.collapsed ? '+' : '-'}
              </p>
            )}
          </div>
        </div>
      </foreignObject>
    );
  };

  useEffect(() => {
    if (selectedCompany && chartRef) {
      const newChartData = chartRef.current?.state.data[0];
      expandAncestors(newChartData, selectedCompany);
    } else {
      setOrgChartData(rootNodes);
      setExpandedNode(null);
    }
  }, [selectedCompany, chartRef]);

  function expandAncestors(node, selectedCompany) {
    if (node.name === selectedCompany) {
      node.__rd3t = { ...node.__rd3t, collapsed: false };
      return true;
    }

    if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        if (expandAncestors(node.children[i], selectedCompany)) {
          setExpandedNode(selectedCompany);
          node.__rd3t = { ...node.__rd3t, collapsed: false };
          return true;
        }
      }
    }
    return false;
  }

  useEffect(() => {
    if (!ref.current) return;
    const width = ref.current.clientWidth;
    setTransition({ x: width / 2, y: 122 });
  }, []);

  return (
    <div className="org-chart" ref={ref}>
      {orgChartData?.name && (
        <Tree
          ref={chartRef}
          data={orgChartData}
          pathFunc={'step'}
          key={selectedCompany}
          nodeSize={nodeSize}
          zoomable
          orientation="vertical"
          shouldCollapseNeighborNodes
          translate={transition}
          initialDepth={1}
          dimensions={{ height: 500, width: transition.x * 2 }}
          enableLegacyTransitions
          transitionDuration={500}
          renderCustomNodeElement={customElement}
        />
      )}
    </div>
  );
};
