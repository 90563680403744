import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import DataGrid, {
  AsyncRule,
  Column,
  Editing,
  Export,
  HeaderFilter,
  Pager,
  Paging,
  RequiredRule,
  Scrolling,
  SearchPanel,
  EmailRule, RowDragging,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import {
  CustomDateBox,
  CustomFileInput,
  CustomNumberBox,
  CustomTextArea,
} from "../../../../components/CustomDataGridComponents";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import CustomSkelton from "components/Skelton/CustomSkelton";
const _ = require('lodash')

export default function PriorityDataGrid({ rows, columns, isLoading, permissions, postData }) {

  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [hasDataChanged, setHasDataChanged] = useState(false)
  const dataGridRef = useRef();

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
    }
  }, []);
  useEffect(() => { }, [dataSource]);
  useEffect(() => { }, [dataColumns]);
  useEffect(() => { setDataSource(rows) }, [rows]);
  useEffect(() => { setDataColumns(columns) }, [columns]);

  /**
   * @param col
   * function use to handle rendering of fields
   **/
  function renderField(col) {
    if (col.type === "date") {
      return <Column alignment={"left"} dataType={"date"} editCellComponent={CustomDateBox} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        format={"yyyy-MM-dd"}
        // format={'dd-MM-yyyy'}
        caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        {
          col.dataIndex === "endDate" ? <AsyncRule
            message="end date cannot be less than start date"
            validationCallback={async (e) => {
              if (e && e.data) {
                if (e.data.startDate && e.data.endDate) {
                  return e?.data?.startDate ? e.data.endDate >= e.data.startDate : true
                }
                else
                  return e?.value && e.data?.startDate ? e.value >= e.data.startDate : true
              }

            }}
          /> : null
        }
      </Column>;
    }
    else if (col.type === "int") {
      return <Column dataType={col.type} /* editCellComponent={CustomNumberBox} */ allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} editCellComponent={(props) => <CustomNumberBox props={props.data} canEdit={col.editable} />}>
        {
          col.required ? <RequiredRule /> : null
        }
        {
          col.dataIndex !== "id"
            ? <AsyncRule message={"Value should not exceed more than 15 digits"} validationCallback={async (e) => {
              return e && e.value && e.value.toString().length <= 15
            }} />
            : null
        }
      </Column>;
    }
    else if (col.type === "file") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} type={"buttons"} fixed={false} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomFileInput} />;
    }
    else if (col.type === "textarea") {
      return <Column editCellComponent={CustomTextArea} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }
    else if (col.type === "string") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.dataIndex === "email" ? <EmailRule /> : null
        }
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.dataIndex === "email" ? <EmailRule /> : null
        }
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
  }

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SUBMIT",
          disabled: !hasDataChanged,
          visible: permissions?.can_sort,
          onClick: async function () {
            const ds = [...dataSource]
            const d = ds?.filter(s => s.rowEdited === true)?.map(r => ({
              id: r.id,
              sequence: r.sequence,
            }))
            await postData('hc_transaction', d)
            setHasDataChanged(false)
          },
        }
      }
    );
  }

  const onReorder = (e) => {
    const visibleRows = e.component.getVisibleRows();
    const newData = [...dataSource];
    const toIndex = newData.indexOf(visibleRows[e.toIndex].data);
    const fromIndex = newData.indexOf(e.itemData);
    newData.splice(fromIndex, 1);
    newData.splice(toIndex, 0, e.itemData);
    // Update sequence values
    const updatedData = newData.map((item, index) => ({
      ...item,
      sequence: e.itemData.id === item.id || (item?.sequence !== null && item?.sequence !== undefined) ? index + 1 : null,
      rowEdited: e.itemData.id === item.id || (item?.sequence !== null && item?.sequence !== undefined)
    }));
    console.log('newData', updatedData)
    setDataSource(updatedData);
    setHasDataChanged(true)
  };

  /**
   * custom function using useMemo to avoid re-renders unless the states listed are changed
   **/
  const Comp = useMemo(() => {
    try {
      return <div id="data-grid-demo" style={{ height: '600px' }}>
        <DataGrid
          id="grid"
          onToolbarPreparing={onToolbarPreparing}
          showBorders={true}
          showColumnLines={true}
          showRowLines={true}
          rowAlternationEnabled={true}
          ref={dataGridRef}
          allowColumnResizing={true}
          disabled={isLoading}
          dataSource={dataSource}
          key="id"
          keyExpr="id"
          height="100%"  // Ensure the DataGrid takes the full height of the container
        >
          <Paging enabled={false} />
          {permissions?.can_sort ? (
            <RowDragging allowReordering={true} onReorder={onReorder} />
          ) : null}
          <Scrolling
            showScrollbar="always"
            mode="virtual"
            rowRenderingMode="virtual"
            columnRenderingMode="virtual"
          />
          <HeaderFilter visible={true} allowSearch={true} />
          <SearchPanel visible={true} />
          {
            permissions?.can_export ? <Export enabled={true} allowExportSelectedData={true} /> : null
          }
          {
            dataColumns && dataColumns.length ? dataColumns.map((d) => renderField(d)) : null
          }
        </DataGrid>
      </div>
    }
    catch (e) {
      console.log('error from Priority', e)
    }
  }, [dataSource, dataColumns, hasDataChanged, isLoading])

  return (
    <React.Fragment>
      <DetectNavigationBlocker setIsDataChanged={setHasDataChanged} isDataChanged={hasDataChanged} />
      <CustomSkelton>
        {Comp}
      </CustomSkelton>
    </React.Fragment>
  );
}
