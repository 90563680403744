import BaseLayOut from "../../../components/Layout/BaseLayOut";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../../utils/context/store/Store";
import usePagePermissions from "../../../utils/hooks/usePagePermissions";
import useManageHeadCounts from "../../../utils/hooks/useManageHeadCounts";
import useDataAccess from "../../../utils/hooks/useDataAccess";
import useHcRequestType from 'utils/hooks/useHcRequestType';
import GroupsDataGrid from "components/Datagrid/GroupsDataGrid";
import AllHcDataGrid from "./Components/AllHcDataGrid";
import { getUserInfo, a11yProps } from "utils/services/Helpers";
import { SET_GROUPS, SET_All_HEADCOUNTS, SET_IS_LOADING } from "../../../utils/context/store/Constants";
import MDBox from "components/MDBox";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from "@mui/material/Card";
import TabPanel from "components/TabPanel";
import loadingGif from "assets/images/gifs/loading.gif";
import { Image } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import "./all-hc.scss";
import ApprovedHCModal from "./Components/ApprovedHCModal";

export default function ManageHeadCounts({ routeKey, pageName }) {

  const [{ groups, allHeadcounts, isLoading, dataAccess }, dispatch] = useContext(Context)
  const { getPermissionsForPage } = usePagePermissions(routeKey)
  const permissions = getPermissionsForPage()
  const { getAllGroups, getAllHeadCounts, hasMore } = useManageHeadCounts(routeKey)
  const { getUserDataAccessByModule } = useDataAccess(routeKey)
  const { getHcRequestType, modalPermissions } = useHcRequestType(routeKey)
  const [tabValue, setTabValue] = useState(0);
  const [pageView, setPageView] = useState(localStorage.getItem('pageView') === "true" ?? false);
  const [dataSource, setDataSource] = useState([]);
  const [allHcDs, setAllHcDs] = useState([]);
  const [requestIdFilter, setRequestIdToFilter] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const navigate = useNavigate()
  const search = useLocation().search;
  const rId = new URLSearchParams(search).get("rId") ?? localStorage.getItem('rId') ?? null;
  const dgId = new URLSearchParams(search).get("dg") ?? localStorage.getItem('dgId') ?? null;
  const transactionType = new URLSearchParams(search).get("transactionType") ?? localStorage.getItem('transactionType') ?? null;
  if (rId && transactionType) {
    localStorage.setItem('rId', rId)
    localStorage.setItem('transactionType', transactionType)
  }

  useEffect(() => {
    dispatch({ type: SET_GROUPS, payload: {} })
    localStorage.removeItem('clonedHCS');

    if (localStorage.getItem('rId') && localStorage.getItem('dgId') && localStorage.getItem('transactionType')) {
      setTabValue(transactionType === "hc_transaction" ? 0 : 1)
      setRequestIdToFilter(parseInt(rId))
    }
    /*// commented because it would always go to headcount view tab on every component mount
    if ((rId !== null || rId !== undefined) && (transactionType !== null || transactionType !== undefined)) {
      setTabValue(transactionType === "hc_transaction" ? 0 : 1)
      setRequestIdToFilter(parseInt(rId))
      localStorage.removeItem('rId');
      localStorage.removeItem('dgId');
      localStorage.removeItem('transactionType');
    }*/

    // cleanup on unmount
    return () => {
      // localStorage.removeItem('clonedHCS');
      localStorage.removeItem('pageView');
      localStorage.removeItem('rId');
      localStorage.removeItem('dgId');
      localStorage.removeItem('transactionType');
      dispatch({ type: SET_GROUPS, payload: {} })
      dispatch({ type: SET_All_HEADCOUNTS, payload: {} })
    }
  }, [])
  useEffect(() => {
    const fetchData = async (transactionType = null) => {
      dispatch({ type: SET_IS_LOADING, payload: true });

      try {
        await Promise.all([
          getAllHeadCounts(transactionType, true),
          getUserDataAccessByModule(getUserInfo().id, 3, true),
          getHcRequestType(true)
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        dispatch({ type: SET_IS_LOADING, payload: false });
      }
    };

    setSelectedStatuses([]);
    if ((rId !== null && rId !== "null" && rId !== undefined) && (transactionType !== null && transactionType !== "null" && transactionType !== undefined))
    {
      setTabValue(transactionType === "hc_transaction" ? 0 : 1);
      setRequestIdToFilter(parseInt(rId));
      fetchData(transactionType);
      localStorage.removeItem('rId');
      localStorage.removeItem('dgId');
      localStorage.removeItem('transactionType');
    }
    else if ((transactionType !== null && transactionType !== "null" && transactionType !== undefined))
    {
      setTabValue(transactionType === "hc_transaction" ? 0 : 1);
      fetchData(transactionType);
      localStorage.removeItem('rId');
      localStorage.removeItem('dgId');
      localStorage.removeItem('transactionType');
    }
    else
    {
      if (!pageView && !tabValue) {
        fetchData("hc_transaction");
      } else if (!pageView && tabValue) {
        fetchData("transaction_leaver");
      } else if (pageView) {
        localStorage.removeItem('rId');
        localStorage.removeItem('dgId');
        getAllGroups();
      }
    }
  }, [tabValue, pageView]);
  useEffect(() => {
    setDataSource(groups?.rows);
  }, [groups])
  useEffect(() => {
    setAllHcDs(allHeadcounts?.rows)
  }, [allHeadcounts])
  useEffect(() => { }, [dataSource])
  useEffect(() => { }, [requestIdFilter]);
  useEffect(() => {
    let data = allHeadcounts?.rows;

    if (selectedStatuses.length) {
      data = filterByActiveStatus(selectedStatuses, data);
    }

    setAllHcDs(data);
  }, [selectedStatuses, allHeadcounts]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    if (!pageView && (newValue !== 1 || newValue !== 0)) setRequestIdToFilter(null)
    if (rId) navigate(`/views/manage-headcounts${dgId !== null ? `?dg=${dgId}` : ""}`, { replace: true })
  };

  const handleLegendClicked = (filter) => {
    if (selectedStatuses.includes(filter.value)) {
      setSelectedStatuses((prevValue) => {
        return prevValue.filter((e) => e !== filter.value);
      });
    }
    else {
      setSelectedStatuses((prevValue) => {
        return [...prevValue, filter.value];
      });
    }
  };

  const filterByActiveStatus = (activeStatuses, arr) => {
    return arr && arr.length && arr?.filter(e => activeStatuses?.includes(e.filterStatus))
  };

  return (
    <BaseLayOut pageTitle={pageName === "manage-hc" ? "manage-Headcount" : "Manage Headcounts"} isForHc={true} pageView={pageView} setPageView={setPageView} setTabValue={setTabValue}>
      <MDBox my={2}>
        <Card>
          <MDBox>
            <MDBox sx={{ width: "100%" }}>
              <MDBox sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="tabs">
                  {
                    pageView ? <Tab label="Request View" {...a11yProps(0)} /> : ""
                  }
                  {
                    !pageView ? <Tab label="Headcount View" {...a11yProps(0)} /> : ""
                  }
                  {
                    !pageView ? <Tab label="Leaver View" {...a11yProps(1)} /> : ""
                  }
                  {
                    modalPermissions?.can_view ?
                      <>
                        <div style={{ position: "absolute", right: 0, transform: "translateY(-50%)", top: "50%" }}>
                          <ApprovedHCModal permissions={modalPermissions} />
                        </div>
                        <br />
                      </> : null
                  }
                </Tabs>
              </MDBox>
              <TabPanel value={tabValue} index={pageView ? 0 : null}>
                <GroupsDataGrid rows={dataSource} columns={groups && groups.columns ? groups.columns : []} routeKey={routeKey} permissions={permissions} isLoading={isLoading} hasMore={hasMore} setTab={setTabValue} setrIdToFilter={setRequestIdToFilter} setPage={setPageView} fetchDataFromApi={getAllGroups} />
              </TabPanel>
              <TabPanel value={tabValue} index={!pageView ? 0 : null}>
                <>
                  <div className="lights-legend__container">
                    {allHeadcounts?.allHcFilters?.map((filter) => (
                      <div
                        key={filter.value}
                        onClick={() => {
                          handleLegendClicked(filter);
                        }}
                        className={`lights-legend__item ${selectedStatuses?.includes(filter.value) ? "lights-legend__item--active" : ""
                          }`}
                      >
                        <div
                          className="lights-legend__color"
                          style={{
                            backgroundColor: filter?.color ? filter.color : "transparent",
                          }}
                        ></div>
                        {filter?.value}
                      </div>
                    ))}
                  </div>
                  <AllHcDataGrid dataAccess={dataAccess} transactionType={"hc_transaction"}
                    setrIdToFilter={setRequestIdToFilter} requestIdFilter={requestIdFilter}
                    rows={requestIdFilter !== null && allHcDs?.length ? allHcDs?.filter(l => l?.transactionHeadcountGroupId === requestIdFilter) : allHcDs}
                    columns={allHeadcounts && allHeadcounts.columns ? allHeadcounts.columns : []}
                    dropDownData={allHeadcounts && allHeadcounts.dropdownValues ? allHeadcounts.dropdownValues : {}}
                    leaversColumns={allHeadcounts && allHeadcounts.leaversColumns ? allHeadcounts.leaversColumns : {}}
                    fields={allHeadcounts && allHeadcounts.fields ? allHeadcounts.fields : {}}
                    routeKey={routeKey} permissions={permissions} isLoading={isLoading}
                    apiCallBack={async () => {
                      await getAllHeadCounts("hc_transaction");
                      await getUserDataAccessByModule(getUserInfo().id, 3);
                      await getHcRequestType();
                    }} />
                </>
              </TabPanel>
              <TabPanel value={tabValue} index={!pageView ? 1 : null}>
                <>
                  <div className="lights-legend__container">
                    {allHeadcounts?.allHcFilters?.map((filter) => (
                      <div
                        key={filter.value}
                        onClick={() => {
                          handleLegendClicked(filter);
                        }}
                        className={`lights-legend__item ${selectedStatuses?.includes(filter.value) ? "lights-legend__item--active" : ""
                          }`}
                      >
                        <div
                          className="lights-legend__color"
                          style={{
                            backgroundColor: filter?.color ? filter.color : "transparent",
                          }}
                        ></div>
                        {filter?.value}
                      </div>
                    ))}
                  </div>
                  <AllHcDataGrid dataAccess={dataAccess} transactionType={"transaction_leaver"}
                    setrIdToFilter={setRequestIdToFilter} requestIdFilter={requestIdFilter}
                    rows={requestIdFilter !== null && allHcDs?.length ? allHcDs?.filter(l => l?.transactionHeadcountGroupId === requestIdFilter) : allHcDs}
                    columns={allHeadcounts?.leaversColumns?.columns ?? []}
                    dropDownData={allHeadcounts && allHeadcounts.dropdownValues ? allHeadcounts.dropdownValues : {}}
                    leaversColumns={allHeadcounts && allHeadcounts.leaversColumns ? allHeadcounts.leaversColumns : {}}
                    fields={allHeadcounts && allHeadcounts.fields ? allHeadcounts.fields : {}}
                    routeKey={routeKey} permissions={permissions} isLoading={isLoading}
                    apiCallBack={async () => {
                      await getAllHeadCounts("transaction_leaver");
                      await getUserDataAccessByModule(getUserInfo().id, 3)
                      await getHcRequestType()
                    }} />
                </>
              </TabPanel>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </BaseLayOut>
  )
}
