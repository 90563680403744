import React, { useState, useEffect } from 'react';
import { Modal, Row } from 'antd';
import { Button } from 'devextreme-react';
import DownloadTemplateCard from './DownloadTemplateCard';
import UploadTemplateCard from './UploadTemplateCard';

const BulkUploaderModal = ({ title, isModalVisible, setIsModalVisible, bulkUploadApi, apiCallback, tableName, downloadLink, orgStructureLink = null, valueToFetch = null }) => {
  const [file, setFile] = useState(null);

  useEffect(() => {
    setFile(null);

    // cleanup on unmount
    return () => {
      setFile(null);
    };
  }, []);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFileChange = (event) => {
    setFile(event.value[0]);
  };

  const handleFileUpload = async () => {
    await bulkUploadApi(file, tableName, apiCallback, valueToFetch);
    setIsModalVisible(false);
  };

  const modalFooter = (
    <Button stylingMode="contained" type="danger" text="Cancel" onClick={handleCancel} />
  );

  return (
    <div>
      <Modal
        className="bulk-upload"
        maskClosable={false}
        title={title}
        width={900}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={modalFooter}
      >
        <Row justify="center" align="middle" gutter={[24, 24]} style={{ marginBottom: '24px' }}>
          <DownloadTemplateCard downloadLink={downloadLink} title={"Download Template"} />
          {
            orgStructureLink
              ? <DownloadTemplateCard downloadLink={orgStructureLink} title={"Org Structure Template"} />
              : null
          }
        </Row>
        <Row justify="center" align="middle" gutter={[24, 24]}>
          <UploadTemplateCard handleFileChange={handleFileChange} handleFileUpload={handleFileUpload} />
        </Row>
      </Modal>
    </div>
  );
};

export default BulkUploaderModal;
