import { createContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
export const DataAccessContext = createContext(undefined);

export const DataAccessProvider = ({ children }) => {
  const [workflowApprovalList, setWorkflowApprovalList] = useState([]);
  const [dataAccesses, setDataAccesses] = useState(new Map([[uuidv4(), {}]]));
  const [dataAccessValidations, setDataAccessValidations] = useState({});
  const [selectedWorkFlowApprovals, setSelectedWorkFlowApprovals] = useState([]);
  const [isUpdated, setIsUpdate] = useState(false);

  const handleAddDataAccess = () => {
    setDataAccesses((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.set(uuidv4(), {});
      return newMap;
    });
  };

  const handleUpdateDataAccess = (key, value) => {
    setDataAccesses((prevMap) => {
      // Clone the existing map
      const newMap = new Map(prevMap);

      // Get the existing value, or default to an empty object
      const existingValue = newMap.get(key) || {};

      // Merge existing and new values
      const updatedValue = { ...existingValue, ...value };

      // Set the updated value in the new map
      newMap.set(key, updatedValue);

      return newMap;
    });
  };

  const getDataAccessValue = (key) => {
    return dataAccesses.get(key);
  };

  const handleRemoveDataAccess = (key) => {
    setDataAccesses((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.delete(key);
      return newMap;
    });
  };

  const handleRemoveDataAccessValidation = (key) => {
    setDataAccessValidations((prevState) => {
      const { [key]: _, ...newState } = prevState;
      return newState;
    });
  };

  const removeKeyDataValidation = (outerKey, transactionKey, innerKey) => {
    setDataAccessValidations((prevState) => {
      const newState = { ...prevState };

      if (
        newState[outerKey] &&
        newState[outerKey][innerKey] &&
        newState[outerKey][innerKey].hasOwnProperty(transactionKey)
      ) {
        delete newState[outerKey][innerKey][transactionKey];
      }

      return newState;
    });
  };

  const removeFromNestedMap = (k, nestedK) => {
    setDataAccesses((prevMap) => {
      const newMap = new Map(prevMap);
      let item = newMap.get(k);
      if (item) {
        const wfApproval = item.wfApproval;
        const nestedMap = item.combinations;
        nestedMap.delete(nestedK);
        newMap.set(k, { wfApproval, combinations: new Map(nestedMap) });
      }
      return newMap;
    });
  };

  const getSelectedApprovals = () => {
    if (!dataAccesses) return [];
    return [...dataAccesses.entries()].map(([key, value]) => value.wfApproval);
  };

  const handleAddCombination = (k, combinationKey, combinationValue) => {
    setDataAccesses((prevMap) => {
      const newMap = new Map(prevMap);
      let item = newMap.get(k);
      if (!item.combinations) {
        item = { combinations: new Map([[combinationKey, combinationValue]]) };
      }
      const nestedMap = item.combinations;
      nestedMap.set(combinationKey, combinationValue);
      newMap.set(k, { ...getDataAccessValue(k), ...{ combinations: new Map(nestedMap) } });
      return newMap;
    });
  };

  const handleUpdateCombination = (k, nestedK, nestedV) => {
    setDataAccesses((prevMap) => {
      const newMap = new Map(prevMap);
      let item = newMap.get(k);
      if (item) {
        const nestedMap = item.combinations;
        nestedMap.set(nestedK, { ...getCombination(k, nestedK), ...nestedV });
        newMap.set(k, { ...getDataAccessValue(k), ...{ combinations: new Map(nestedMap) } });
      }
      return newMap;
    });
  };

  const getCombination = (k, nestedK) => {
    const item = dataAccesses.get(k);
    if (item) {
      const nestedMap = item.combinations;
      return nestedMap.get(nestedK);
    }
    return undefined;
  };

  const getCheckedNodes = (k, nestedK) => {
    const item = dataAccesses.get(k);
    return item?.combinations?.get(nestedK)?.formula?.checkedNodes || [];
  };

  const getCombinationsList = (accordionID) => {
    if (!dataAccessValidations.combinationsData) return [];
    const selectedTypes = Object.values(dataAccessValidations[accordionID]?.selectedTypes || {});
    return dataAccessValidations.combinationsData.filter((e) => !selectedTypes?.includes(e.value))?.sort((a, b) => (a['label'] || '').localeCompare(b['label'] || ''));
  };

  const updateCombinationAndEmptyTransactions = (k, nestedK, nestedV) => {
    setDataAccesses((prevMap) => {
      const newMap = new Map(prevMap);
      let item = newMap.get(k);
      if (item) {
        let nestedMap = new Map(item.combinations);

        // Empty the transactions
        let combination = nestedMap.get(nestedK);
        if (combination) {
          combination = { ...combination, transactions: [] };
          nestedMap.set(nestedK, combination);
        }

        // Apply the updates from nestedV
        nestedMap.set(nestedK, { ...combination, ...nestedV });

        // Update the outer map
        newMap.set(k, { ...item, combinations: nestedMap });
      }
      return newMap;
    });
  };

  const removeCheckedNodes = (k, nestedK) => {
    setDataAccesses((prevDataAccesses) => {
      const item = prevDataAccesses.get(k);
      if (item) {
        const combination = item.combinations.get(nestedK);
        if (combination && combination.formula) {
          // Create a new object without the checkedNodes property
          const { checkedNodes, selectedCombination, ...newFormula } = combination.formula;

          // Create a new combination object with the updated formula
          const newCombination = new Map(item.combinations);
          newCombination.set(nestedK, { ...combination, formula: newFormula });

          // Create a new item object with the updated combination
          const newItem = { ...item, combinations: newCombination };

          // Create a new dataAccesses Map with the updated item
          const newDataAccesses = new Map(prevDataAccesses);
          newDataAccesses.set(k, newItem);

          return newDataAccesses;
        }
      }
      return prevDataAccesses; // Return the previous state if no changes were made
    });
  };

  function findByType(typeValue, accordionID) {
    const combinations = getDataAccessValue(accordionID)?.combinations;
    if (!combinations) return null;
    for (const [key, value] of combinations.entries()) {
      if (value.type.value === typeValue) {
        return { key, value };
      }
    }
    return null; // Return null if the type is not found
  }

  useEffect(() => { }, [dataAccessValidations]);

  useEffect(() => { }, [dataAccesses]);

  return (
    <DataAccessContext.Provider
      value={{
        dataAccesses,
        getDataAccessValue,
        updateCombinationAndEmptyTransactions,
        workflowApprovalList,
        setWorkflowApprovalList,
        handleAddDataAccess,
        handleRemoveDataAccess,
        handleUpdateDataAccess,
        handleAddCombination,
        handleUpdateCombination,
        removeFromNestedMap,
        dataAccessValidations,
        setDataAccessValidations,
        getCombinationsList,
        selectedWorkFlowApprovals,
        setSelectedWorkFlowApprovals,
        getCombination,
        setDataAccesses,
        handleRemoveDataAccessValidation,
        removeKeyDataValidation,
        getCheckedNodes,
        removeCheckedNodes,
        findByType,
        isUpdated,
        setIsUpdate,
        getSelectedApprovals,
      }}
    >
      {children}
    </DataAccessContext.Provider>
  );
};
