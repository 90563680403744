import React, { useContext, useEffect, useState } from "react"
import { Context } from "utils/context/store/Store";
import useAttributeManagement from 'utils/hooks/useAttributeManagement';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import usePagePermissions from "utils/hooks/usePagePermissions";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Select from "react-select";
import { Grid } from "@mui/material";
import { selectCustomStyles, a11yProps } from "../../utils/services/Helpers";
import AttributeManagementDataGrid from "./DataTable/AttributeManagementDataGrid";
import useBulkUpload from "utils/hooks/useBulkUpload";

function AttributeManagement({ routeKey, pageName }) {

  const { getAttributeManagementList, getAttributeManagement, deleteAttributeManagement, updateOrCreateAttributeManagement, isLoading } = useAttributeManagement(routeKey)
  const { bulkUpload } = useBulkUpload(routeKey)
  const [{ attributeManagement, attributeManagementList }, dispatch] = useContext(Context)
  const { getPermissionsForPage } = usePagePermissions(routeKey)

  const [dValue, setDValue] = useState(null);
  const [value, setValue] = useState(null);
  const [displayValue, setDisplayValue] = useState(null);

  const tablesForBulkUpload = ['client', 'budget-status', 'gig', 'management-type', 'recruitment-status', 'eos-type', 'eos-reason']

  useEffect(async () => {
    await getAttributeManagementList()
    if (value !== null)
      await getAttributeManagement(value)
  }, [])
  useEffect(async () => {
    if (value !== null)
      await getAttributeManagement(value)
  }, [value])
  useEffect(() => { }, [attributeManagementList])
  useEffect(() => { }, [attributeManagement])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox pt={3} pl={3} pr={3} pb={2} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
              {pageName.replace(/-/g, " ") ?? "Common Attributes Management"}
            </MDTypography>
          </MDBox>
          <MDBox pt={2} pl={3} pr={3} pb={3}>
            <Grid item xs={6} sm={2}>
              <Select options={attributeManagementList ?? []} value={dValue} styles={selectCustomStyles} isSearchable menuPortalTarget={document.body} onChange={(val) => {
                setDValue(val)
                setValue(val.value)
                setDisplayValue(val.label)
              }} />
            </Grid>
            {value
              ?
              <>
                <br />
                <MDTypography variant="h5" fontWeight="medium">
                  Manage {displayValue}
                </MDTypography>
                <AttributeManagementDataGrid isLoading={isLoading} rows={attributeManagement.rows ?? []} columns={attributeManagement.columns}
                  permissions={getPermissionsForPage(routeKey)} dropDownData={attributeManagement.dropdownValues} postData={updateOrCreateAttributeManagement}
                  handleDelete={deleteAttributeManagement} valueToFetch={value} allowSelection={true} allowAdding={true}
                  allowDeletingFromApi={true} bulkUploadApi={bulkUpload} apiCallback={getAttributeManagement} tableName={attributeManagement.tableName}
                  uploadTemplateLink={attributeManagement.uploadTemplateLink} orgStructureLink={attributeManagement.orgStructureLink} allowBulkUploading={tablesForBulkUpload.indexOf(value) !== -1} />
              </>
              : null
            }
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AttributeManagement;