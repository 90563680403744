import RevisionDataGrid from './datatable/RevisionDataGrid'; 
import BaseLayOut from '../../components/Layout/BaseLayOut';
import { useContext, useEffect } from 'react';
import { Context } from '../../utils/context/store/Store';
import useRevisions from '../../utils/hooks/useRevisions';
import usePagePermissions from '../../utils/hooks/usePagePermissions';

export default function Revision({ routeKey, pageName }) {
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const [{ revisions }, dispatch] = useContext(Context);
  const { fetchRevisions, isLoading, revisionColumns } = useRevisions(routeKey); 

  useEffect(async () => {
    await fetchRevisions();
  }, []);

  return (
    <BaseLayOut pageTitle={pageName ?? 'Manage Activity Log'}>
      <RevisionDataGrid
        loading={isLoading}
        columns={revisionColumns}
        rows={revisions.rows}
        fetchFromApi={fetchRevisions}
        handleDelete={() => console.log('sss')}
        permissions={getPermissionsForPage(routeKey)}
        childColumns={revisions?.columns}
      />
    </BaseLayOut>
  );
}
