import request from "../services/Http";
import { useContext } from "react";
import { Context } from "../context/store/Store";
import {
  addKeyAndIndexFieldToGenericArrayOfObjects,
  getPageProperties, getTableColumns
} from "../services/Helpers";
import { SET_IS_LOADING, SET_JOB_TYPE } from "../context/store/Constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIsLoading } from "./useIsLoading";

export default function useJobType(routeKey) {
  const [{ jobType, pagesAndPermissions }, dispatch] = useContext(Context)
  const { isLoading, setIsLoading } = useIsLoading()

  async function fetchJobType() {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })

    try {
      const res = await request.get('job-type')
      if (res) {
        let rows = res.data.data
        const properties = getPageProperties(pagesAndPermissions, routeKey)
        const table = getTableColumns(properties.page.table, routeKey)
        let columns = table.columns
        let uploadTemplateLink = res.data.uploadTemplate ?? null
        let tableName = res.data.tableName ?? null
        if (jobType && jobType.hasOwnProperty('apiDelete') && jobType.apiDelete) {
          rows = [...jobType.rows, ...rows]
        }

        const dataTableData = { columns: columns, rows: addKeyAndIndexFieldToGenericArrayOfObjects(rows), apiDelete: false, uploadTemplateLink, tableName }

        dispatch({
          type: SET_JOB_TYPE,
          payload: dataTableData
        })
      }
    }
    catch (e) { }

    setIsLoading(false)
    dispatch({ type: SET_IS_LOADING, payload: false })
  }

  async function updateOrCreateJobType(data) {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })

    try {
      const res = await request.post('job-type', {
        jobType: data
      })
      toast.success(res.data.data)
      await fetchJobType()
    }
    catch (e) { }

    setIsLoading(false)
    dispatch({ type: SET_IS_LOADING, payload: false })
  }

  async function deleteJobType(jobType) {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })

    try {
      const res = await request.delete('job-type', {
        data: { jobType }
      })
      if (res) {
        await fetchJobType()
        toast.success(res.data.data)
      }
    }
    catch (e) { }

    setIsLoading(false)
    dispatch({ type: SET_IS_LOADING, payload: false })
  }

  return { fetchJobType, updateOrCreateJobType, deleteJobType, isLoading }
}