import CustomSkelton from "../../../../components/Skelton/CustomSkelton";
import BaseLayOut from "../../../../components/Layout/BaseLayOut";
import { useEffect } from "react";
import useEntityVerticalCapability from "../../../../utils/hooks/useEntityVerticalCapability";
import EntityVerticalCapabilityDataGrid from "./EntityVerticalCapabilityDataGrid";

export default function EntityVerticalCapability({routeKey, pageName}){

  const {getAllEntityVerticalCapabilities, createUpdateEntityVerticalCapabilities, deleteEntityVerticalCapabilities, setEntityVerticalCapability, entityVerticalCapability, permissions, isLoading} = useEntityVerticalCapability(routeKey)

  useEffect( () => {
    const fn = async () => await getAllEntityVerticalCapabilities()
    fn()

    return () => {
      setEntityVerticalCapability({rows: [], columns: [], dropdownData: {}})
    }
  }, []);

  useEffect( () => {}, [entityVerticalCapability]);

  return <CustomSkelton is_loading={isLoading}>
    <BaseLayOut pageTitle={pageName ?? "Entity Vertical Capability"}>
      <EntityVerticalCapabilityDataGrid rows={entityVerticalCapability?.rows} columns={entityVerticalCapability?.columns} dropDownData={entityVerticalCapability?.dropdownData} isLoading={isLoading} postAPI={createUpdateEntityVerticalCapabilities} handleDelete={deleteEntityVerticalCapabilities} permissions={permissions}/>
    </BaseLayOut>
  </CustomSkelton>
}