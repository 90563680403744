import React, { useEffect, useContext } from 'react'
import { Context } from "../../utils/context/store/Store";
import BaseLayOut from "../../components/Layout/BaseLayOut";
import HcTypeDataGrid from '../manage-headcount-type/DataGrid/HcTypeDataGrid'
import useJobType from "../../utils/hooks/useJobType";
import usePagePermissions from "../../utils/hooks/usePagePermissions";
import useBulkUpload from 'utils/hooks/useBulkUpload';

export default function ManageJobType({ routeKey, pageName }) {
  const { fetchJobType, updateOrCreateJobType, deleteJobType, isLoading } = useJobType(routeKey)
  const { bulkUpload } = useBulkUpload(routeKey)
  const [{ jobType }, dispatch] = useContext(Context)
  const { getPermissionsForPage } = usePagePermissions(routeKey)
  const permissions = getPermissionsForPage()

  useEffect(async () => {
    await fetchJobType()
  }, [])

  return (
    <BaseLayOut pageTitle={pageName ?? "Manage Job Type"}>
      <HcTypeDataGrid
        columns={jobType && jobType.columns ? jobType.columns : []}
        rows={jobType && jobType.rows ? jobType.rows : []}
        isLoading={isLoading}
        permissions={permissions}
        hitApi={updateOrCreateJobType}
        handleDelete={deleteJobType}
        bulkUploadApi={bulkUpload} apiCallback={fetchJobType} tableName={jobType?.tableName}
        uploadTemplateLink={jobType?.uploadTemplateLink}
      />
    </BaseLayOut>
  )
}