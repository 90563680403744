// Actions types
const SET_REQUEST_TYPE = 'SET_REQUEST_TYPE';
const SET_HEADCOUNT_TYPE = 'SET_HEADCOUNT_TYPE';
const SET_REQUEST_TYPE_LIST = 'SET_REQUEST_TYPE_LIST';
const SET_HEADCOUNT_TYPE_LIST = 'SET_HEADCOUNT_TYPE_LIST';
const SET_INTERNAL_EMP_DATA = 'SET_INTERNAL_EMP_DATA';
const SET_CLONED_HEADCOUNTS = 'SET_CLONED_HEADCOUNTS';
const FILTER_HEADCOUNT_TYPE_LIST = 'FILTER_HEADCOUNT_TYPE_LIST';

// initial state
export const initialState = {
  selectedRequestType: null,
  selectedHeadcountType: null,
  requestTypeList: [],
  headcountTypeList: [],
  clonedHeadcounts: {},
  internalEmpData: null
};

// reducers
export function reducer(state, action) {
  switch (action.type) {
    case SET_REQUEST_TYPE:
      return { ...state, selectedRequestType: action.payload };
    case SET_HEADCOUNT_TYPE:
      return { ...state, selectedHeadcountType: action.payload };
    case SET_REQUEST_TYPE_LIST:
      return { ...state, requestTypeList: action.payload };
    case SET_HEADCOUNT_TYPE_LIST:
      return { ...state, headcountTypeList: action.payload };
    case SET_INTERNAL_EMP_DATA:
      return { ...state, internalEmpData: action.payload };
    case SET_CLONED_HEADCOUNTS:
      return { ...state, clonedHeadcounts: action.payload };
    case FILTER_HEADCOUNT_TYPE_LIST:
      return {
        ...state,
        headcountTypeList: state.headcountTypeList.filter((e) => e.id !== action.payload)
      };
    default:
      return state;
  }
}
