import React, { useEffect, useState } from "react";
import 'devextreme/dist/css/dx.light.css';
import { Button } from 'devextreme-react/button';
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import SelectBox from "devextreme-react/select-box";
import { TextBox, ValidationGroup, Validator } from "devextreme-react";
import { NumberBox } from "devextreme-react/number-box";
import DateBox from "devextreme-react/date-box";
import moment from "moment";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import IconButton from "@mui/material/IconButton";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { CustomRule } from "devextreme-react/form";
import { v4 as uuidv4 } from "uuid";
import { EmailRule, RequiredRule } from "devextreme-react/data-grid";
import FileUploader from "devextreme-react/file-uploader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MDTypography from "../../../../components/MDTypography";

const text = {
  fontSize: "14px"
}
const currentDate = moment()//.format('DD-MM-YYYY')
const FreelancerDetails = ({ id, disableInternalSubmitButton = false, apiCallBack = null, masterCurrencyId, masterFreelancerId, freelancerDetails, startDate, endDate, submitHandler }) => {

  const [freelancerDetail, setFreeLancerDetail] = useState(freelancerDetails)
  const disable = currentDate.isSameOrAfter(endDate) /*|| disableInternalSubmitButton*/

  useEffect(() => {
    setFreeLancerDetail(freelancerDetails)

    if (freelancerDetails?.freelancerId && masterFreelancerId?.length) {
      const fd = masterFreelancerId?.find(f => f.id === freelancerDetails?.freelancerId)
      if (fd) {
        const fdc = { ...fd }
        delete fdc['id']
        delete fdc['label']
        fdc['freelancerId'] = freelancerDetails?.freelancerId
        fdc['candidateType'] = "External"
        setFreeLancerDetail(prvState => ({ ...prvState, ...fdc }))
      }
    }
  }, []);
  useEffect(() => { }, [freelancerDetail]);

  const rateTypes = [
    {
      "id": "Hourly",
      "label": "Hourly",
    },
    {
      "id": "Project",
      "label": "Project",
    }
  ]

  /**
   * function use to call the api to post data
   **/
  const handleSubmit = async (e) => {
    e.preventDefault()
    const fdCopy = { ...freelancerDetail }
    fdCopy['id'] = id
    delete fdCopy['noc']
    delete fdCopy['cv']
    delete fdCopy['contract']
    await submitHandler(fdCopy, apiCallBack)
  }

  function onCustomItemCreating(e) {
    try {
      if (e?.text) {
        // setFreeLancerDetail(prevState => ({ ...prevState, name: e.text }))
        const d = {
          id: uuidv4(),
          label: e.text,
          customItem: true
        }
        e.customItem = d
        masterFreelancerId.push(d)
      }
    } catch (error) {
      console.log('error on onCustomItemCreating', error)
    }
  }

  return (
    <ValidationGroup>
      <form id={"freelancer-details"} key={"freelancer-details"} onSubmit={handleSubmit} noValidate>
        <MDBox>
          <MDBox p={2}>
            <MDBox mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SelectBox validationGroup={"f_form_group"} name={"freelancerId"} acceptCustomValue={true}
                             onCustomItemCreating={onCustomItemCreating} searchEnabled={true}
                             disabled={disable}
                             showClearButton={true}
                             placeholder={"Select or type freelancer name*"}
                             value={freelancerDetail?.freelancerId}
                             onValueChanged={(e) => {
                               const fd = masterFreelancerId?.find(f => f.id === e.value)
                               if (fd) {
                                 const fdc = { ...fd }
                                 delete fdc['id']
                                 delete fdc['label']
                                 fdc['freelancerId'] = e.value
                                 if (fd?.customItem) {
                                   console.log('fdc', fd)
                                   fdc['customItem'] = true
                                   fdc['name'] = fd?.label
                                   setFreeLancerDetail(fdc)
                                 } else setFreeLancerDetail(prvState => ({ ...prvState, ...fdc }))
                               } else setFreeLancerDetail({})
                             }} displayExpr="label" valueExpr="id" dataSource={masterFreelancerId}>
                    <Validator name={"freelancerId"}>
                      <RequiredRule message={"Please select freelancer or type freelancer name"} />
                    </Validator>
                  </SelectBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextBox name={"freelancer_email"} disabled={disable} value={freelancerDetail?.email} required={false}
                           mode={"text"}
                           placeholder={"Freelancer email*"} showClearButton={freelancerDetails.name === null}
                           onValueChanged={(e) => setFreeLancerDetail(prevState => ({ ...prevState, email: e.value }))}>
                    <Validator name={"freelancer_email"}>
                      <RequiredRule message={"Please enter a freelancer email"} />
                      <EmailRule message="Please enter a valid email" />
                    </Validator>
                  </TextBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextBox disabled={disable} value={freelancerDetail?.workEmail} required={true} mode={"email"}
                           placeholder={"Freelancer work email"}
                           showClearButton={freelancerDetails.workEmail === null}
                           onValueChanged={(e) => setFreeLancerDetail(prevState => ({
                             ...prevState,
                             workEmail: e.value
                           }))}>
                    <Validator>
                      <EmailRule message="Please enter a valid email" />
                    </Validator>
                  </TextBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextBox disabled={disable} value={freelancerDetail?.phoneNumber} required={false} mode={"tel"}
                           placeholder={"Phone number e.g +971xxxxxxxxx"}
                           showClearButton={freelancerDetails.phoneNumber === null}
                           onValueChanged={(e) => setFreeLancerDetail(prevState => ({
                             ...prevState,
                             phoneNumber: e.value
                           }))}>
                    <Validator>
                      <CustomRule
                        validationCallback={(e) => !e?.value ? true : parsePhoneNumberFromString(e?.value || "")?.isValid()}
                        message="Please enter a valid phone number (e.g., +971xxxxxxxxx)" />
                    </Validator>
                  </TextBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextBox disabled={disable} value={freelancerDetail?.skills} required={false} mode={"text"}
                           placeholder={"Freelancer skill set"}
                           showClearButton={freelancerDetails.skills === null}
                           onValueChanged={(e) => setFreeLancerDetail(prevState => ({
                             ...prevState,
                             skills: e.value
                           }))} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextBox disabled={disable} value={freelancerDetail?.address} required={false} mode={"text"}
                           placeholder={"Freelancer home address"}
                           showClearButton={freelancerDetails.address === null}
                           onValueChanged={(e) => setFreeLancerDetail(prevState => ({
                             ...prevState,
                             address: e.value
                           }))} />
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SelectBox disabled={disable}
                             showClearButton={freelancerDetails.rateType === null} placeholder={"Select rate type"}
                             value={freelancerDetail?.rateType}
                             onValueChanged={(e) => setFreeLancerDetail(prevState => ({
                               ...prevState,
                               rateType: e.value
                             }))} displayExpr="label" valueExpr="id" dataSource={rateTypes} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SelectBox disabled={disable}
                             showClearButton={freelancerDetails.masterFRCurrencyId === null}
                             placeholder={"Select currency"} value={freelancerDetail?.masterFRCurrencyId}
                             onValueChanged={(e) => {
                               setFreeLancerDetail(prevState => ({ ...prevState, masterFRCurrencyId: e.value }));
                             }} displayExpr="label" valueExpr="id" dataSource={masterCurrencyId} />
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <NumberBox min={0} disabled={disable} value={freelancerDetail?.rate} required={false}
                             placeholder={"Freelancer rate"} showClearButton={freelancerDetails.rate === null}
                             onValueChanged={(e) => setFreeLancerDetail(prevState => ({
                               ...prevState,
                               rate: e.value
                             }))} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateBox placeholder="Select Start Date" disabled={disable} displayFormat={"dd-MM-yyyy"}
                           showClearButton={true} defaultValue={startDate} type="date"
                           pickerType="calendar" /* min={new Date()} */ openOnFieldClick={true}
                           onValueChanged={(e) => setFreeLancerDetail(prevState => ({
                             ...prevState,
                             startDate: e.value
                           }))} />
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <DateBox placeholder="Select End Date" disabled={disable} displayFormat={"dd-MM-yyyy"}
                           showClearButton={true} defaultValue={endDate} type="date" pickerType="calendar"
                           min={startDate} openOnFieldClick={true}
                           max={startDate ? new Date(startDate).setMonth(new Date(startDate).getMonth() + 3) : null}
                           onValueChanged={(e) => setFreeLancerDetail(prevState => ({
                             ...prevState,
                             endDate: e.value
                           }))} />
                </Grid>
                <Grid item xs={12} sm={6}>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={2}>
              <MDTypography style={{
                "margin-top": "20px",
                "margin-bottom": "15px",
                "font-size": "14px",
              }} fontWeight={"bold"}>
                Attachments:
              </MDTypography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={freelancerDetail?.cvFile?.length > 0 ? 12 : freelancerDetail?.cv ? 6 : 12}>
                  <FileUploader
                    className={`__file__upload__btn__ ${freelancerDetail?.cvFile?.length > 0 && `__file__upload__details`}`}
                    value={freelancerDetail?.cvFile ?? []}
                    multiple={false}
                    readOnly={disable}
                    accept={process.env.REACT_APP_ALLOWED_MIME_TYPES.split(',').join(',')}
                    uploadMode={"useButton"}
                    onValueChanged={(e) => setFreeLancerDetail(prevState => ({ ...prevState, cvFile: e.value }))}
                    selectButtonText={"Upload CV"}
                  />
                </Grid>
                {
                  freelancerDetail?.cvFile?.length === 0 && freelancerDetail?.cv &&
                  <Grid item xs={12} sm={6}>
                    <List style={{ maxWidth: '100%' }}
                          sx={{ width: 'fit-content', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                      <ListItem key={uuidv4()}>
                        <ListItemText className={'__file__upload__text__'} primaryTypographyProps={{ style: text }}
                                      primary={freelancerDetail?.cvFileName} />
                        <IconButton aria-label="download" href={freelancerDetail?.cv}>
                          <FileDownloadIcon />
                        </IconButton>
                      </ListItem>
                    </List>
                  </Grid>
                }
                <Grid item xs={12}
                      sm={freelancerDetail?.contractFile?.length > 0 ? 12 : freelancerDetail?.contractFileName ? 6 : 12}>
                  <FileUploader
                    className={`__file__upload__btn__ ${freelancerDetail?.contractFile?.length > 0 && `__file__upload__details`}`}
                    value={freelancerDetail?.contractFile ?? []}
                    multiple={false}
                    readOnly={disable}
                    accept={process.env.REACT_APP_ALLOWED_MIME_TYPES.split(',').join(',')}
                    uploadMode={"useButton"}
                    onValueChanged={(e) => setFreeLancerDetail(prevState => ({ ...prevState, contractFile: e.value }))}
                    selectButtonText={"Upload contract"}
                  />
                </Grid>
                {
                  freelancerDetail?.contractFile?.length === 0 && freelancerDetail?.contract &&
                  <Grid item xs={12} sm={6}>
                    <List style={{ maxWidth: '100%' }}
                          sx={{ width: 'fit-content', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                      <ListItem key={uuidv4()}>
                        <ListItemText className={'__file__upload__text__'} primaryTypographyProps={{ style: text }}
                                      primary={freelancerDetail?.contractFileName} />
                        <IconButton aria-label="download" href={freelancerDetail?.contract}>
                          <FileDownloadIcon />
                        </IconButton>
                      </ListItem>
                    </List>
                  </Grid>
                }
                <Grid item xs={12}
                      sm={freelancerDetail?.nocFile?.length > 0 ? 12 : freelancerDetail?.nocFileName ? 6 : 12}>
                  <FileUploader
                    className={`__file__upload__btn__ ${freelancerDetail?.nocFile?.length > 0 && `__file__upload__details`}`}
                    value={freelancerDetail?.nocFile ?? []}
                    multiple={false}
                    readOnly={disable}
                    accept={process.env.REACT_APP_ALLOWED_MIME_TYPES.split(',').join(',')}
                    uploadMode={"useButton"}
                    onValueChanged={(e) => setFreeLancerDetail(prevState => ({ ...prevState, nocFile: e.value }))}
                    selectButtonText={"Upload NOC"} />
                </Grid>
                {
                  freelancerDetail?.nocFile?.length === 0 && freelancerDetail?.noc &&
                  <Grid item xs={12} sm={6}>
                    <List style={{ maxWidth: '100%' }}
                          sx={{ width: 'fit-content', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                      <ListItem key={uuidv4()}>
                        <ListItemText className={'__file__upload__text__'} primaryTypographyProps={{ style: text }}
                                      primary={freelancerDetail?.nocFileName} />
                        <IconButton aria-label="download" href={freelancerDetail?.noc}>
                          <FileDownloadIcon />
                        </IconButton>
                      </ListItem>
                    </List>
                  </Grid>
                }
              </Grid>
            </MDBox>
          </MDBox>
          <MDBox display="flex" justifyContent="right" alignItems="center" position="relative" zIndex={1}
                 sx={{ marginBottom: 5 }}>
            <MDBox mr={1}>
              <Button disabled={disable} stylingMode="contained" type="success" useSubmitBehavior={true}
                      text="Submit" />
            </MDBox>
          </MDBox>
        </MDBox>
      </form>
    </ValidationGroup>
  );
}

export default FreelancerDetails;
