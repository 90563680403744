import React from 'react';
import TreeList, {
  Column,
  RowDragging,
  Button,
  Editing,
  Selection,
  Scrolling
} from 'devextreme-react/tree-list';
import MDAlert from 'components/MDAlert';
import Divider from '@mui/material/Divider';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { DEButton } from 'utils/services/Helpers';
import { Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

export function NotificationStepTreelist({
  isLoading,
  dataSource,
  setDataSource,
  expandedRowKeys,
  onSelectionChanged,
  allowDeleting,
  onDragChange,
  permissions,
  onMainReorder,
  selectedRowKeys,
  manageDelete,
  onFocusedRowChanged
}) {
  return (
    <>
      {selectedRowKeys.length > 0 && (
        <>
          <br />
          <MDAlert color="light">
            <MDTypography variant="subtitle2">
              {`Selected ${selectedRowKeys.length} ${
                selectedRowKeys.length === 1 ? 'item' : 'items'
              }`}
            </MDTypography>

            <Divider orientation="vertical" color="dark" flexItem />

            <MDBox>
              <Grid container spacing={2}>
                {permissions && permissions.canDelete ? (
                  <Grid item>
                    <DEButton
                      hint="Delete"
                      icon="trash"
                      onClick={() => {
                        manageDelete();
                      }}
                      stylingMode="contained"
                      type="danger"
                    />
                  </Grid>
                ) : null}
              </Grid>
            </MDBox>
          </MDAlert>
        </>
      )}
      <div>
        <TreeList
          id="main"
          disabled={isLoading}
          dataSource={dataSource}
          rootValue={-1}
          keyExpr="id"
          showRowLines
          showBorders
          parentIdExpr="parentId"
          defaultExpandedRowKeys={expandedRowKeys}
          wordWrapEnabled
          focusedRowEnabled
          onSelectionChanged={onSelectionChanged}
          columnAutoWidth
          repaintChangesOnly
          onRowDblClick={permissions && permissions.canCreate ? onFocusedRowChanged : null}
        >
          <Editing allowDeleting={allowDeleting} />
          <Selection recursive={'all'} allowSelectAll mode="multiple" />
          <RowDragging
            onDragChange={onDragChange}
            onReorder={permissions && permissions.canCreate ? onMainReorder : null}
            allowDropInsideItem={false}
            allowReordering
            showDragIcons
          />
          <Scrolling showScrollbar="always" mode="standard" />
          <Column dataField="conditionType" caption="Access Type" width="150px" />
          <Column dataField="preDefined" caption="Predefined Value" width="150px" />
          <Column dataField="ddworkflowAccessId" caption="Access Id" />
          <Column dataField="tableName" />
          <Column dataField="tableColumnName" />
          <Column dataField="columnOperation" />
          <Column dataField="conditionValue" />
          <Column dataField="isForCreator" />
          <Column dataField="isForHiringManager" />
          <Column dataField="sendEmail" />
          <Column dataField="sendPush" />
          <Column dataField="directlyApprove" caption="Directly Approve" />
          <Column type="buttons" caption={'Actions'}>
            <Button name="delete" icon={'trash'} />
            <Button
              hint="Clone"
              icon={'copy'}
              visible={(e) => permissions && permissions.canCreate}
              onClick={(e) => {
                const ds = [...dataSource];
                let clonedItem = {
                  ...e.row.data,
                  id: uuidv4(),
                  newRow: true,
                  name: '',
                  tableId: null,
                  uniqueKey: e.row.data.notificationId
                };
                ds.splice(0, 0, clonedItem);
                setDataSource(ds);
                e.event.preventDefault();
                onFocusedRowChanged(e);
              }}
            />
          </Column>
        </TreeList>
      </div>
    </>
  );
}
