import React, { useEffect, useContext, useState } from 'react';
import useDataAccess from '../../../utils/hooks/useDataAccess';
import DataAccessAccordion from './DataAccessAccordion';
import { DEButton } from '../../../utils/services/Helpers';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Card from '@mui/material/Card';
import Swal from 'sweetalert2';
import { DataAccessContext } from './contexts/data-access-context';
import {
  updateSelectedTypesDataAccessValidations,
  updateDataAccessValidations,
  consolidateData
} from './helpers/tree-data-helper';
import { mapToObj, objToMap } from './helpers';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './data-access.css';
import CustomSkelton from 'components/Skelton/CustomSkelton';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function DataAccess({ masterModuleId, userId, permissions, routeKey }) {
  const {
    getUserDataAccess,
    updateOrCreateDataAccess,
    deleteDataAccess,
    isLoading,
    userDataAccess,
    deleteDataAccessCombination
  } = useDataAccess(routeKey);
  const {
    setWorkflowApprovalList,
    dataAccesses,
    setDataAccesses,
    handleAddDataAccess,
    handleRemoveDataAccess,
    getDataAccessValue,
    setSelectedWorkFlowApprovals,
    setDataAccessValidations,
    handleRemoveDataAccessValidation
  } = useContext(DataAccessContext);

  useEffect(async () => {
    await getUserDataAccess(userId, masterModuleId);
  }, []);

  useEffect(() => {
    setWorkflowApprovalList(
      userDataAccess && userDataAccess.dropDownData
        ? userDataAccess.dropDownData?.workflowAccess
        : []
    );

    if (userDataAccess?.data?.length) {
      setDataAccessValidations({});
      setDataAccesses();

      const dataAccesses = userDataAccess.data;
      const filteredDataAccess = dataAccesses.map((data, dataId) => {
        const workFlowApproval = userDataAccess.dropDownData?.workflowAccess.find(
          (e) => e.id === data.workflowAccessId
        );

        if (workFlowApproval) {
          setSelectedWorkFlowApprovals((prevValue) => {
            return [...prevValue, workFlowApproval];
          });
        }
        return {
          ...data,
          wfApproval: workFlowApproval ?? null,
          combinations: data.combinations
            .sort((a, b) => a.id - b.id)
            .map((comb, combId) => {
              const selectedType = userDataAccess.dataAccessDropdowns.find(
                (e) => e.value === comb.type
              );
              if (selectedType) {
                updateSelectedTypesDataAccessValidations(
                  dataId.toString(),
                  combId.toString(),
                  selectedType,
                  setDataAccessValidations,
                  userDataAccess.dataAccessDropdowns
                );
                if (comb.transactions) {
                  if (selectedType.value !== 'HFMS' && selectedType.value !== 'market' && selectedType.value !== 'master_employee')
                  {
                    updateDataAccessValidations(
                      dataId.toString(),
                      combId.toString(),
                      consolidateData(comb.transactions),
                      setDataAccessValidations,
                      userDataAccess.dataAccessDropdowns
                    );
                  }
                }
              }

              return {
                ...comb,
                type: selectedType,
                submitted: true,
                prevTransactions: comb.transactions
              };
            })
        };
      });
      setDataAccesses(objToMap(filteredDataAccess));
    } else {
      setDataAccesses(new Map([[uuidv4(), {}]]));
      setDataAccessValidations({});
    }
  }, [userDataAccess]);

  const handleRemoveAccordion = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will delete this data access',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      heightAuto: false,
      height: '200px'
    }).then(async (result) => {
      if (result.isConfirmed) {
        handleRemoveDataAccessValidation(id);
        const dataAccess = getDataAccessValue(id);
        const workflowValue = dataAccess.wfApproval;
        if (workflowValue) {
          setSelectedWorkFlowApprovals((prevValue) => {
            return prevValue.filter((e) => e.id !== workflowValue.id);
          });
        }
        if (dataAccess.id) {
          await deleteDataAccess([dataAccess.id], userId, false, masterModuleId);
          return;
        }
        handleRemoveDataAccess(id);
      }
    });
  };

  const submitDataAccess = async () => {
    const dataAccess = mapToObj(dataAccesses);
    let isFormulaEmpty = false;
    const formattedDataAccess = dataAccess.map((e) => {
      const seen = new Set();
      const data = {
        masterModuleId,
        userId,
        workflowAccessId: e.wfApproval?.value ?? null,
        combinations: e.combinations?.map((comb) => ({
          newRow: !comb.dataAccessId,
          rowEdited: Boolean(comb.dataAccessId),
          type: comb.type?.value,
          formula: comb.formula,
          transactions: comb.transactions?.map((item) => {
            // Check if the orgDataAccessId is duplicated
            if (seen.has(item.orgDataAccessId)) {
              item.orgDataAccessId = null;
              item.transactionId = null;
            }
            else {
              seen.add(item.orgDataAccessId);
              seen.add(item.transactionId);
            }

            let newObj = {
              transactionId: item.transactionId,
              orgDataAccessId: item.orgDataAccessId,
              dataAccessCombinationId: item.dataAccessCombinationId,
              newRow: comb.type?.value === 'HFMS' || comb.type?.value === 'market' || comb.type?.value === 'master_employee' || comb.type?.value === 'master_departments' || comb.type?.value === 'master_client' ? !comb.dataAccessId : !item.transactionId,
              rowEdited:
                comb.type?.value === 'HFMS' || comb.type?.value === 'market' || comb.type?.value === 'master_employee' || comb.type?.value === 'master_departments' || comb.type?.value === 'master_client'
                  ? Boolean(comb.dataAccessId)
                  : Boolean(item.transactionId)
            };
            if (comb.type?.value === 'HFMS' || comb.type?.value === 'market' || comb.type?.value === 'master_employee' || comb.type?.value === 'master_departments' || comb.type?.value === 'master_client') {
              return { ...item, ...newObj };
            }
            Object.keys(item)
              .filter((key) => comb.formula.hasOwnProperty(key))
              .forEach((filteredKey) => {
                if (!item[filteredKey]) {
                  isFormulaEmpty = true;
                }
                newObj[filteredKey] = item[filteredKey];
              });
            return newObj;
          }),
          dataAccessId: comb.dataAccessId ?? null,
          id: comb.id ?? null
        }))
      };
      const isEditMode = e.combinations.some((e) => e.dataAccessId);
      if (isEditMode) {
        data['rowEdited'] = true;
        data['id'] = e.id ?? e.combinations.find((e) => e.dataAccessId)?.dataAccessId;
      } else {
        data['newRow'] = true;
      }
      return data;
    });
    if (dataAccess.filter((e) => !e.wfApproval).length > 1) {
      toast.error('Please add an approval to continue!');
    } else if (isFormulaEmpty) {
      toast.error('Incorrect combination!');
    } else if (
      formattedDataAccess.flatMap((e) => e.combinations).some((e) => !e.transactions?.length)
    ) {
      toast.error('Please add a combination to continue!');
    } else {
      await updateOrCreateDataAccess({ accesses: formattedDataAccess }, userId, masterModuleId);
    }
  };

  return (
    <MDBox py={3}>
      <Card>
        <Card>
          <CustomSkelton>
            <MDBox p={2}>
              <MDTypography variant="h5" fontWeight="medium">
                User Data Access
              </MDTypography>

              <MDBox mt={3} style={{ position: 'relative' }}>
                <MDBox my={3}>
                  <DEButton
                    stylingMode={'contained'}
                    type={'default'}
                    icon="add"
                    text={'Add Data Access'}
                    onClick={handleAddDataAccess}
                  ></DEButton>
                </MDBox>
                {dataAccesses && dataAccesses.size > 0 && (
                  <>
                    {[...dataAccesses.keys()].map((key) => (
                      <DataAccessAccordion
                        key={key}
                        userDataAccess={
                          userDataAccess && userDataAccess.dropDownData
                            ? userDataAccess.dropDownData
                            : []
                        }
                        combinationsData={
                          userDataAccess && userDataAccess.dataAccessDropdowns
                            ? userDataAccess.dataAccessDropdowns
                            : []
                        }
                        dataAccessFormulasData={
                          userDataAccess && userDataAccess.dataAccessFormulas
                            ? userDataAccess.dataAccessFormulas
                            : []
                        }
                        accordionID={key}
                        handleRemoveAccordion={handleRemoveAccordion}
                        isLoading={isLoading}
                        permissions={permissions}
                        deleteDataAccessCombination={deleteDataAccessCombination}
                        userId={userId}
                        masterModuleId={masterModuleId}
                      />
                    ))}
                    <MDBox mb={1}>
                      <DEButton
                        disabled={false}
                        stylingMode={'contained'}
                        type={'success'}
                        text={'Submit'}
                        onClick={submitDataAccess}
                      ></DEButton>
                    </MDBox>
                  </>
                )}

                {isLoading && (
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                )}
              </MDBox>
            </MDBox>
          </CustomSkelton>
        </Card>
      </Card>
    </MDBox>
  );
}
