import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import DataGrid, {
  AsyncRule,
  Button,
  Column,
  Editing,
  Export,
  HeaderFilter,
  Lookup, MasterDetail, Pager, Paging,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Selection, RowDragging
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import {
  CustomDateBox, CustomFileInput,
  CustomNumberBox, CustomTextArea,
} from "../../../components/CustomDataGridComponents";
import { handleDeleteRequest, DEButton, hasDuplicates, createSanitizeAsyncRule } from "../../../utils/services/Helpers";
import MDAlert from "../../../components/MDAlert";
import MDTypography from "../../../components/MDTypography";
import Divider from "@mui/material/Divider";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import { cloneIconClick, onRowExpanding } from "../../../utils/services/DatagridHelpers";
import DeleteIcon from "@mui/icons-material/Delete";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import "assets/datatable-css/datagrid.css"
import { toast } from "react-toastify";
import ManageElements from "../ManageElement";
import BulkUploaderModal from "components/Modal/BulkUploader/BulkUploaderModal";
const _ = require('lodash')

export default function SegmentsDataGrid({ rows, columns, dropDownData, defaultKey = "id", isLoading, permissions, allowAdding = true, allowUpdating = true, allowSelection = false, allowDeleting = true, postData, handleDelete, masterModuleId, userId, routeKey, elementColumns, bulkUploadApi, uploadTemplateLink, orgStructureLink, apiCallback, tableName, allowBulkUploading = false }) {

  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasDataChanged, setHasDataChanged] = useState(false)
  const [autoWidth, setAutoWidth] = useState(true)
  const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState(false)
  const dataGridRef = useRef();
  const [addEditMode, setAddEditMode] = useState(false)
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
    }
  }, []);
  useEffect(() => { }, [dataSource]);
  useEffect(() => { }, [dataColumns]);
  useEffect(() => { setDataSource(rows) }, [rows]);
  useEffect(() => {
    setDataColumns(columns)
  }, [columns]);

  /**
   * @param e
   * function use perform operation when data-grid editor is not prepared e.g enable/disable field etc
   **/
  async function onEditorPreparing(e) {

    if (e && e.dataField === "masterOrgEntityId") {
      let gridInstance = dataGridRef.current.instance;
      let editRowKey = gridInstance.option("editing.editRowKey");
      let index = gridInstance.getRowIndexByKey(editRowKey);
      const masterOrgDivisionId = gridInstance.cellValue(index, "masterOrgDivisionId");
      if (!masterOrgDivisionId)
        e.editorOptions.disabled = true
      else
        e.editorOptions.disabled = false
    }

    if (e && e.dataField === "masterLevelId") {
      let gridInstance = dataGridRef.current.instance;
      let editRowKey = gridInstance.option("editing.editRowKey");
      let index = gridInstance.getRowIndexByKey(editRowKey);
      const masterOrgDivisionId = gridInstance.cellValue(index, "masterOrgDivisionId");

      if (!masterOrgDivisionId)
        e.editorOptions.disabled = true
      else
        e.editorOptions.disabled = false

    }

    if (e && e.dataField === "masterCountryId") {
      let gridInstance = dataGridRef.current.instance;
      let editRowKey = gridInstance.option("editing.editRowKey");
      let index = gridInstance.getRowIndexByKey(editRowKey);
      const masterOrgEntityId = gridInstance.cellValue(index, "masterOrgEntityId");
      if (!masterOrgEntityId)
        e.editorOptions.disabled = true
      else
        e.editorOptions.disabled = false
    }

    if (e && e.dataField === "masterOrgVerticalId") {
      let gridInstance = dataGridRef.current.instance;
      let editRowKey = gridInstance.option("editing.editRowKey");
      let index = gridInstance.getRowIndexByKey(editRowKey);
      const masterCountryId = gridInstance.cellValue(index, "masterCountryId");
      if (!masterCountryId)
        e.editorOptions.disabled = true
      else
        e.editorOptions.disabled = false
    }

  }

  /**
   * @param selectedRowKeys
   * @param selectedRowsData
   * used to get selected rows detail of data-grid
   **/
  function onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
    setSelectedRowKeys(selectedRowsData)
  }

  /**
   * get selected rows
   **/
  const hasSelected = selectedRowKeys.length > 0

  /**
   * @param col
   * @param dropDownData
   * function use to handle rendering of fields
   **/
  function renderField(col, dropDownData) {
    if (col.type === "select") {
      if (!col.hasOwnProperty("filtrationKey")) {
        return <Column editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
          allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
          setCellValue={
            function (rowData, value) {
              if (col.hasOwnProperty("bindedTo"))
                rowData[col.bindedTo] = null;
              this.defaultSetCellValue(rowData, value);
            }
          } >
          {
            col.required ? <RequiredRule /> : null
          }
          < Lookup allowClearing
            dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : []
            }
            displayExpr="label" valueExpr="id" />
        </Column>;
      }
      else if (col.hasOwnProperty("filtrationKey")) {
        return <Column editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
          allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
            this.defaultSetCellValue(rowData, value);
            if (col.hasOwnProperty("bindedTo")) {
              rowData[col.bindedTo] = null;
            }
          }}>
          <Lookup allowClearing dataSource={(options) => {
            return {
              store: dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : [],
              filter: options.data ? [col.filtrationKey, "=", options.data[col.filtrationKey]] : null,
            };
          }} displayExpr="label" valueExpr="id" />
          {
            col.required ? <RequiredRule /> : null
          }
        </Column>;
      }
    }
    else if (col.type === "multi-select") {
      return <Column width={"250"} editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomDTagThisComp}
        cellTemplate={(container, options) => {
          const noBreakSpace = "\u00A0";
          const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(", ");
          container.textContent = text || noBreakSpace;
          container.title = text;
        }}
        calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
          if (target === "search" && typeof (filterValue) === "string") {
            return [col.dataIndex, "contains", filterValue];
          }
          return function (data) {
            return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
          };
        }}
      >
        <Lookup allowClearing
          dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : null}
          displayExpr="label" valueExpr="id" />
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "checkbox" || col.type === "toggle") {
      return <Column dataType="boolean"        
        showEditorAlways={true} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value)
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "actions") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable}
        type="buttons" dataField={col.dataIndex} caption={col.title} fixed={false} bestFitWidth={true}>
        <Button name="delete" icon={'trash'} visible={(e) => e && e.row && e.row.data && e.row.data.hasOwnProperty('newRow')} />
        <Button hint="Clone" icon="copy" visible={(e) => permissions && permissions.canCreate} onClick={(e) => cloneIconClick(e, dataSource, setDataSource)} />
      </Column>
    }
    else if (col.type === "date") {
      return <Column dataType={"date"} editCellComponent={CustomDateBox} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "int") {
      return <Column alignment={'left'} dataType={col.type} /* editCellComponent={CustomNumberBox} */ allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} editCellComponent={(props) => <CustomNumberBox props={props.data} canEdit={col.editable} />}>
        {
          col.required ? <RequiredRule /> : null
        }
        {
          col.dataIndex !== "id"
            ? <AsyncRule message={"Value should not exceed more than 15 digits"} validationCallback={async (e) => {
              return e && e.value && e.value.toString().length <= 15
            }} />
            : null
        }
      </Column>;
    }
    else if (col.type === "file") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} type={"buttons"} fixed={false} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomFileInput} />;
    }
    else if (col.type === "textarea") {
      return <Column encodeHtml={col.encodeHtml} editCellComponent={CustomTextArea} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>
    }
    else if (col.type === "string"){
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>;
    }
    else {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
  }

  /**
   * @param e
   * initialize new row in the data-grid
   **/
  const onInitNewRow = (e) => {
    window.scrollTo(0, 0)
    e.data.sequence = dataSource.length + 1
    e.data.main_task = false
    e.data.status = true
    e.data.newRow = true
    e.data.description = ""
    e.data.elements = []
    setAutoWidth(false)
    setAddEditMode(true)
  }

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "upload",
          text: "BULK UPLOAD",
          visible: permissions && permissions.canCreate && allowBulkUploading,
          onClick: function () { setBulkUploadModalVisible(true) },
        }
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SUBMIT",
          disabled: !hasDataChanged,
          visible: permissions && permissions.canCreate && allowAdding,
          onClick: function () { pushData() },
        }
      }
    );
  }

  /**
   * @param e
   * Manage post api call to save data and validation for if any request is missing headcount while having replacement true
   **/
  function onSave(e) {
    if (e && e.changes.length) {
      const updatedData = [...dataSource];
      const updateIndex = updatedData.findIndex((row) => row.id === e.changes[0].key);

      if (e.changes[0].type === "remove") {
        const dsCopy = [...dataSource]
        const filteredDs = dsCopy.length ? dsCopy.filter(ds => ds.id !== e.changes[0].key) : []
        if (filteredDs && filteredDs.length) {
          setDataSource(filteredDs)
          setHasDataChanged(true)
        }
        else {
          setDataSource([])
          setHasDataChanged(false)
        }
      } else if (e.changes[0].type === "update") {

        updatedData[updateIndex] = e.changes[0].data;
      } else if (e.changes[0].type === "insert") {
        const newRow = e.changes[0].data;
        const existingIndex = updatedData.findIndex((row) => row.id === newRow.id);
        if (existingIndex !== -1) {
          updatedData.splice(existingIndex, 1);
        }
        updatedData.push(newRow);
      }

      if (e.changes[0].type === "insert" || e.changes[0].type === "update") {

        // Update sequence column in ascending order
        updatedData.forEach((row, index) => {
          row.sequence = index + 1;
        });

        // Check if the current row's main_task is true
        const checkedRow = updatedData.find((row) => row.id === e.changes[0].key && row.main_task);

        if (checkedRow) {
          // Uncheck main_task for all other rows
          updatedData.forEach((row, index) => {
            if (index !== updateIndex && row.main_task) {
              updatedData[index] = { ...row, main_task: false };
            }
          });
        }
      }


      setDataSource(updatedData);
      setHasDataChanged(true);
    }

    setAutoWidth(true);
    setAddEditMode(false);
  }

  /**
* function use to call the api to post data
**/
  const pushData = async () => {
    const newDs = [...dataSource];
    const dup = newDs.filter((ds) => ds.hasOwnProperty('newRow') && ds?.elements?.length === 0);

    if (dup && dup.length) {
      toast.error(`${dup.length} of your ${dup.length > 1 ? "segments have" : "segment has"} missing elements. Please add elements to continue!`);
    } else if (hasDuplicates(newDs, 'name')) {
      toast.error(`Duplicate entry found for NAME. It cannot be duplicated!`)
    } else {
      let hasError = false;

      if (hasError) {
        toast.error('Please enter color values for main task');
      } else {
        newDs.sort((a, b) => a.sequence - b.sequence);

        newDs.forEach((segment, segmentIndex) => {
          segment.sequence = segmentIndex + 1;
          if (segment.elements && segment.elements.length > 0) {
            // segment.elements.sort((a, b) => a.sequence - b.sequence);
            segment.elements.forEach((element, elementIndex) => {
              element.sequence = elementIndex + 1;
            });
          }
        });

        await postData(newDs)
        setHasDataChanged(false)
        setSelectedRowKeys([])
      }
    }
  }

  /**
   * @param e
   * validate row before saving
   **/
  function onRowValidating(e) {
    if (e && e.isValid) {
      // if (e.newData)
      // {
      //   const { elements } = e.newData
      //   if (elements && elements.length <= 0)
      //   {
      //     e.isValid = false;
      //     e.errorText = "Please create permission before moving further";
      //   }
      // }
    }
  }

  /**
   * function used to handle delete part of data-grid
   **/
  const manageDelete = async () => {
    const newRecords = dataSource.filter(ds => ds.hasOwnProperty('newRow'))
    let newData = [...dataSource]
    handleDeleteRequest(async () => {
      const deleteFromApi = selectedRowKeys.filter(a => a.hasOwnProperty('id') && !a.hasOwnProperty('newRow'))
      const deleteFromTable = selectedRowKeys.filter(a => a.hasOwnProperty('newRow'))
      const result = deleteFromApi.map(a => a.id);
      if (deleteFromApi.length > 0 && deleteFromTable.length > 0) {
        await handleDelete(result, userId, selectedRowKeys.length === dataSource.length, masterModuleId)
      }
      else if (deleteFromApi && deleteFromApi.length > 0) {
        await handleDelete(result, userId, selectedRowKeys.length === dataSource.length, masterModuleId)
      }
      else if (deleteFromTable && deleteFromTable.length > 0) {
        deleteFromTable.map(a => {
          newData = newData.filter((item) => item.key !== a.key);
        })
        setDataSource(newData)
      }
      setHasDataChanged(false)
      setSelectedRowKeys([])
    })
  };

  /**
 * function used to reorder rows based on sequence
 **/
  const onReorder = (e) => {
    const visibleRows = e.component.getVisibleRows();
    const newTasks = [...dataSource];

    const toIndex = newTasks.findIndex((item) => item.sequence === visibleRows[e.toIndex].data.sequence);
    const fromIndex = newTasks.findIndex((item) => item.sequence === e.itemData.sequence);

    newTasks.splice(fromIndex, 1);
    newTasks.splice(toIndex, 0, e.itemData);

    // Update the sequence property of each item based on its new index
    newTasks.forEach((item, index) => {
      item.sequence = index + 1;
    });

    setDataSource(newTasks)
    setHasDataChanged(true)
  }

  /**
 * custom function using useMemo to avoid re-renders unless the states listed are changed
 **/
  const Comp = useMemo(() => {
    try {
      return <div id="data-grid-demo">
        {
          hasSelected > 0
            ?
            <React.Fragment>
              <br />
              <MDAlert color="light">

                <MDTypography variant="subtitle2">
                  {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? "item" : "items"}`}
                </MDTypography>

                <Divider orientation="vertical" color="dark" flexItem />

                <MDBox>
                  <Grid container spacing={2}>
                    {
                      permissions && permissions.canDelete
                        ? <Grid item >
                          <DEButton stylingMode={"contained"} type={"danger"} icon="trash" onClick={() => manageDelete()} />
                        </Grid> : null
                    }
                  </Grid>
                </MDBox>
              </MDAlert>
            </React.Fragment>
            : null
        }
        <BulkUploaderModal title={"Segments/Elements - Bulk Upload"} isModalVisible={bulkUploadModalVisible} setIsModalVisible={setBulkUploadModalVisible} bulkUploadApi={bulkUploadApi} apiCallback={apiCallback} tableName={tableName} downloadLink={uploadTemplateLink} orgStructureLink={orgStructureLink} />
        <DataGrid id="grid"
          onToolbarPreparing={onToolbarPreparing} showBorders={true}
          onRowExpanding={onRowExpanding}
          columnAutoWidth={true} onSaved={onSave}
          showColumnLines={true} showRowLines={true} rowAlternationEnabled={true}
          ref={dataGridRef} onInitNewRow={onInitNewRow}
          onSelectionChanged={onSelectionChanged} allowColumnResizing={true}
          disabled={isLoading} dataSource={dataSource} key={defaultKey ?? "id"} keyExpr={defaultKey ?? "id"}
          onEditorPreparing={onEditorPreparing}
          onRowValidating={onRowValidating}>
          {
            addEditMode ? null : <Scrolling showScrollbar="always" mode="standard" />
          }

          {
            allowSelection ?
              <Selection allowSelectAll={true} mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} />
              : null
          }
          <RowDragging allowReordering={true} onReorder={onReorder} />
          <HeaderFilter visible={true} allowSearch={true} />
          <SearchPanel visible={true} />
          <Paging defaultPageSize={25} />
          <Pager visible={true} showNavigationButtons={true} showInfo={true} displayMode={"full"} />
          <Export enabled={true} allowExportSelectedData={true} />
          <Editing newRowPosition={"last"} refreshMode={"repaint"} mode="cell" allowUpdating={permissions && permissions.canCreate && allowUpdating} allowAdding={permissions && permissions.canCreate && allowAdding} allowDeleting={allowDeleting} />
          {
            dataColumns && dataColumns.length ? dataColumns.map((d) => renderField(d, dropDownData)) : null
          }
          <MasterDetail autoExpandAll={false} enabled={true} component={(props) => {
            return <ManageElements elementColumns={elementColumns} routeKey={routeKey} segment={props.data.data.hasOwnProperty('newRow') ? null : props.data.data.id} segmentName={props.data.data.name} segmentData={props.data.data} page_permissions={permissions} updateButtonStateOfParent={setHasDataChanged} />
          }} />
        </DataGrid>
      </div>
    }
    catch (e) { }
  }, [dataSource, dataColumns, dropDownData, hasDataChanged, selectedRowKeys, isLoading, autoWidth, addEditMode, bulkUploadModalVisible])

  return (
    <React.Fragment>
      <DetectNavigationBlocker setIsDataChanged={setHasDataChanged} isDataChanged={hasDataChanged} />
      {Comp}
    </React.Fragment>
  );
}