import * as React from "react";
import { useEffect } from "react";
import "devextreme/dist/css/dx.light.css";
import "../components/styles.scss"
import MDBox from "../../../components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import loadingGif from "assets/images/gifs/loading.gif";
import { Image } from "antd";
import { Accordion, AccordionSummary, AccordionDetails } from "../../../utils/services/Helpers";
import { ProgressSteps } from "../../../components/CustomDataGridComponents";
import TransferForm from "./TransferForm";

const TransferFormComponent = React.memo(({ data, columns, dropDownData, masterTransactionType, permissions, dataSource, setDataSource, setHasDataChanged, routeKey, apiCallBack, setDgData }) => {

  useEffect(() => {
    const element = document?.querySelector('.css-e6tepc')
    const wdth = localStorage.getItem('hcFormWidth')
    const hght = localStorage.getItem('hcFormHeight')
    const screenSize = localStorage.getItem('screenSize')
    if (element && !wdth && !hght && !screenSize) {
      localStorage.setItem('hcFormWidth', element.offsetWidth)
      localStorage.setItem('hcFormHeight', element.offsetHeight)
      localStorage.setItem('screenSize', window.innerWidth)
    }
    return () => {
      localStorage.removeItem('hcFormWidth')
      localStorage.removeItem('hcFormHeight')
      localStorage.removeItem('screenSize')
    }
  }, []);

  return (
    <React.Fragment>
      {Object.keys(data).length
        ?
        <Card>
          {Object.keys(data).length ?
            <MDBox px={3} py={3}>
              {data && data?.steps && data?.steps.length ?
                <MDBox py={1}>
                  <ProgressSteps steps={data.steps} />
                </MDBox>
                : null
              }
              {data && dropDownData && masterTransactionType ?
                <MDBox pt={2}>
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                      <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6">{"Supervisor Details"}</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails className="TransferDetailsAccordionDetails">
                      <TransferForm
                        formHcLeaverData={Object.keys(data).length ? data : localStorage.getItem('LR') ? JSON.parse(localStorage.getItem('LR')) : data}
                        columns={columns}
                        dropDownData={dropDownData}
                        permissions={permissions}
                        setDataSource={setDataSource}
                        setDgData={setDgData}
                        dataSource={dataSource}
                        setHasDataChanged={setHasDataChanged}
                        apiCallBack={apiCallBack}
                        routeKey={routeKey}
                      />
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
                : null
              }
            </MDBox>
            : <MDBox px={2} py={2} >
              <MDTypography variant="subtitle3">
                No data exist in the system for this request. Please connect with the admin.
              </MDTypography>
            </MDBox>
          }
        </Card>
        : <MDBox mt={0} mb={0} style={{ textAlign: "center" }}>
          <Image
            title="Loading"
            alt="Loading"
            width={70}
            src={loadingGif}
            preview={false}
          />
        </MDBox>
      }
    </React.Fragment>
  );
}, (prevProps, nextProps) => {
  return prevProps.hasDataChanged === nextProps.hasDataChanged;
})

TransferFormComponent.displayName = "TransferFormComponent"

export default TransferFormComponent;
