import BaseLayOut from "../../components/Layout/BaseLayOut";
import ReportViewer from "react-lighthouse-viewer";
import usePerformanceMetric from "../../utils/hooks/usePerformanceMetric";
import React, { useEffect, useState } from "react";
import MDBox from "../../components/MDBox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { a11yProps, DEButton } from "../../utils/services/Helpers";
import TabPanel from "../../components/TabPanel";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CustomSkelton from "../../components/Skelton/CustomSkelton";

const PerformanceMetric = ({ routeKey, pageName }) => {
  const { getMetrics, metrics, isLightHouseRunning, isLoading, startAudit } = usePerformanceMetric();
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const fn = async () => await getMetrics();
    fn();
  }, []);
  useEffect(() => { }, [metrics, isLightHouseRunning, isLoading]);
  useEffect(() => { }, [tabValue]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return <BaseLayOut pageTitle={pageName ?? "Performance Metrics"}>
    <CustomSkelton is_loading={isLoading}>
      <MDBox my={2}>
        <Box display="flex" justifyContent="flex-end">
          <DEButton disabled={isLightHouseRunning}
            stylingMode={"contained"} type={"success"}
            onClick={async () => await startAudit()}
            text={isLightHouseRunning ? "Audit is in Progress" : "Start Audit"} />
        </Box>
        <br />
        <Card>
          <MDBox>
            <MDBox sx={{ width: '100%' }}>
              <MDBox sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="tabs">
                  {
                    metrics?.map((d, i) => {
                      return <Tab key={i} label={d.url} {...a11yProps(i)} />
                    })
                  }
                </Tabs>
              </MDBox>
              {
                metrics?.map((d, i) => {
                  return <TabPanel value={tabValue} index={i}>
                    <ReportViewer json={metrics[tabValue]?.report} />
                  </TabPanel>
                })
              }

            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </CustomSkelton>
  </BaseLayOut>;
};

export default PerformanceMetric;