import BaseLayOut from "../../components/Layout/BaseLayOut";
import useDelegatedAccess from "../../utils/hooks/useDelegatedAccess";
import CustomSkelton from "../../components/Skelton/CustomSkelton";
import { useEffect } from "react";
import DelegatedAccessDataGrid from "./DataGrid/DelegatedAccessDataGrid";
import usePagePermissions from "../../utils/hooks/usePagePermissions";

export default function DelegatedAccess({pageName, routeKey }){
  const  {delegatedAccesses, getDelegatedAccess, isLoading, createOrUpdateDelegatedAccess, deleteDelegatedAccess} = useDelegatedAccess(routeKey)
  const { getPermissionsForPage } = usePagePermissions(routeKey)

  useEffect(() => {
    const fn = async () => await getDelegatedAccess()
    fn()
  },[])

  useEffect(() => {},[delegatedAccesses])

  return <CustomSkelton is_loading={isLoading}>
    <BaseLayOut pageTitle={pageName ?? "DelegatedAccess"}>
      <DelegatedAccessDataGrid rows={delegatedAccesses?.rows ?? []} columns={delegatedAccesses?.columns ?? []} dropDownData={delegatedAccesses?.dropDownData ?? {}} isLoading={isLoading} permissions={getPermissionsForPage(routeKey)} postAPI={createOrUpdateDelegatedAccess} handleDelete={deleteDelegatedAccess}/>
    </BaseLayOut>
  </CustomSkelton>
}