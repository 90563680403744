import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import DataGrid, {
  AsyncRule,
  Button,
  Column,
  Editing,
  Export,
  HeaderFilter,
  Lookup, MasterDetail, Pager, Paging,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import {
  CustomDateBox, CustomFileInput,
  CustomNumberBox, CustomTextArea,
} from "../../../components/CustomDataGridComponents";
import { handleDeleteRequest, DEButton, createSanitizeAsyncRule } from "../../../utils/services/Helpers";
import MDAlert from "../../../components/MDAlert";
import MDTypography from "../../../components/MDTypography";
import Divider from "@mui/material/Divider";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import { cloneIconClick, onRowExpanding } from "../../../utils/services/DatagridHelpers";
import DeleteIcon from "@mui/icons-material/Delete";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import "assets/datatable-css/datagrid.css"
import PermissionModal from "../../../components/Modal/Permission/PermissionModal";
import FAVariableModal from "../../../components/Modal/Variable/FAVariableModal";
import { toast } from "react-toastify";
import FunctionalAccessFilterPreferenceModal from "../FunctionalAccessFilterPreferenceModal";
import FunctionalAccessPriorityModal from "../FunctionalAccessPriorityModal";
const _ = require('lodash')

export default function FunctionalAccessDataGrid({ rows, columns, dropDownData, defaultKey = "id", isLoading, permissions, allowAdding = true, allowUpdating = true, allowSelection = false, allowDeleting = true, postData, handleDelete, masterModuleId, userId, showRestButtons = false, rolesObj, routeKey }) {

  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasDataChanged, setHasDataChanged] = useState(false)
  const [autoWidth, setAutoWidth] = useState(true)
  const dataGridRef = useRef();
  const [addEditMode, setAddEditMode] = useState(false)
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
    }
  }, []);
  useEffect(() => { }, [dataSource]);
  useEffect(() => { }, [dataColumns]);
  useEffect(() => { setDataSource(rows) }, [rows]);
  useEffect(() => {
    setDataColumns(columns)
  }, [columns]);

  /**
   * @param e
   * function use perform operation when data-grid editor is not prepared e.g enable/disable field etc
   **/
  async function onEditorPreparing(e) {
    if (e && e.dataField === "masterModuleId") {
      e.editorOptions.disabled = (!e.row.data.hasOwnProperty('newRow'))
    }
  }

  /**
   * @param selectedRowKeys
   * @param selectedRowsData
   * used to get selected rows detail of data-grid
   **/
  function onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
    setSelectedRowKeys(selectedRowsData)
  }

  /**
   * get selected rows
   **/
  const hasSelected = selectedRowKeys.length > 0

  /**
   * @param col
   * @param dropDownData
   * function use to handle rendering of fields
   **/
  function renderField(col, dropDownData) {
    if (col.type === "select") {
      if (!col.hasOwnProperty("filtrationKey")) {
        return <Column editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
          allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
          setCellValue={
            function (rowData, value) {
              if (col.hasOwnProperty("bindedTo"))
                rowData[col.bindedTo] = null;
              this.defaultSetCellValue(rowData, value);
            }
          } >
          {
            col.required ? <RequiredRule /> : null
          }
          <Lookup allowClearing
            dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : []
            }
            displayExpr="label" valueExpr="id" />
        </Column>;
      }
      else if (col.hasOwnProperty("filtrationKey")) {
        return <Column editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
          allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
            this.defaultSetCellValue(rowData, value);
            if (col.hasOwnProperty("bindedTo")) {
              rowData[col.bindedTo] = null;
            }
          }}>
          <Lookup allowClearing dataSource={(options) => {
            return {
              store: dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : [],
              filter: options.data ? [col.filtrationKey, "=", options.data[col.filtrationKey]] : null,
            };
          }} displayExpr="label" valueExpr="id" />
          {
            col.required ? <RequiredRule /> : null
          }
        </Column>;
      }
    }
    else if (col.type === "multi-select") {
      return <Column width={"250"} editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomDTagThisComp}
        cellTemplate={(container, options) => {
          const noBreakSpace = "\u00A0";
          const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(", ");
          container.textContent = text || noBreakSpace;
          container.title = text;
        }}
        calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
          if (target === "search" && typeof (filterValue) === "string") {
            return [col.dataIndex, "contains", filterValue];
          }
          return function (data) {
            return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
          };
        }}
      >
        <Lookup allowClearing
          dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : null}
          displayExpr="label" valueExpr="id" />
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "checkbox" || col.type === "toggle") {
      return <Column dataType="boolean"
        showEditorAlways={true} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value)
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "actions") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable}
        type="buttons" dataField={col.dataIndex} caption={col.title} fixed={false} bestFitWidth={true} width={"auto"} >
        <Button name="delete" icon={'trash'} visible={(e) => e && e.row && e.row.data && e.row.data.hasOwnProperty('newRow')} />
        <Button hint="Clone" icon="copy" visible={(e) => permissions && permissions.canCreate && e && e.row && e.row.data && e.row.data.hasOwnProperty('newRow')} onClick={(e) => cloneIconClick(e, dataSource, setDataSource)} />
        <Button name={"extra-btns"} visible={e => permissions && permissions.canCreate} component={(props) => {
          return (
            <>
              <FAVariableModal routeKey={routeKey} title={`Manage Variables`} masterModuleId={props.data.data.masterModuleId} btnTitle={"Manage Page Variables"} role={props.data.data.id} roleName={props.data.data.name} roleData={props.data.data}
                dataSource={rows} page_permissions={permissions} permissions={permissions} rolesObj={rolesObj} isDisabled={props.data.data.hasOwnProperty('newRow') || props.data.data.masterModuleId !== 3} />

              <FunctionalAccessFilterPreferenceModal routeKey={routeKey} masterModuleId={props.data.data.masterModuleId}
                role={props.data.data.id} roleName={props.data.data.name} functional_access_filter_preferences={props.data.data?.functional_access_filter_preferences}
                page_permissions={permissions} isDisabled={props.data.data.hasOwnProperty('newRow') || props.data.data.masterModuleId !== 3} />

              <FunctionalAccessPriorityModal routeKey={routeKey} masterModuleId={props.data.data.masterModuleId}
                                             role={props.data.data.id} roleName={props.data.data.name} functional_access_priority={props.data.data?.functional_access_priority}
                                             permissions={permissions} isDisabled={props.data.data.hasOwnProperty('newRow') || props.data.data.masterModuleId !== 3} />
            </>
          )
        }} />

      </Column>
    }
    else if (col.type === "date") {
      return <Column dataType={"date"} editCellComponent={CustomDateBox} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "int") {
      return <Column dataType={col.type} /* editCellComponent={CustomNumberBox} */ allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} editCellComponent={(props) => <CustomNumberBox props={props.data} canEdit={col.editable} />}>
        {
          col.required ? <RequiredRule /> : null
        }
        {
          col.dataIndex !== "id"
            ? <AsyncRule message={"Value should not exceed more than 15 digits"} validationCallback={async (e) => {
              return e && e.value && e.value.toString().length <= 15
            }} />
            : null
        }
      </Column>;
    }
    else if (col.type === "file") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} type={"buttons"} fixed={false} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomFileInput} />;
    }
    else if (col.type === "textarea") {
      return <Column encodeHtml={col.encodeHtml} editCellComponent={CustomTextArea} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>
    }
    else if (col.type === "string"){
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>;
    }
    else {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
  }

  /**
   * @param e
   * initialize new row in the data-grid
   **/
  const onInitNewRow = (e) => {
    window.scrollTo(0, 0)
    e.data.status = true
    e.data.newRow = true
    e.data.description = ""
    e.data.page_permissions = []
    setAutoWidth(false)
    setAddEditMode(true)
  }

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SUBMIT",
          disabled: !hasDataChanged,
          visible: permissions && permissions.canCreate && allowAdding,
          onClick: async () => {
            const newDs = [...dataSource];
            const dup = newDs.filter((ds) => ds.hasOwnProperty('newRow') && ds?.page_permissions?.length <= 0);
            if (dup && dup.length) {
              toast.error(`${dup.length} of your ${dup.length > 1 ? "functional access are" : "functional access is"} missing Permissions. Please add Permission to continue`);
            }
            else {
              await postData(dataSource)
              setHasDataChanged(false)
            }
          }
        }
      }
    );
  }

  /**
   * @param e
   * Manage post api call to save data and validation for if any request is missing headcount while having replacement true
   **/
  function onSave(e) {
    if (e && e.changes.length) {
      if (e.changes[0].type === "remove") {
        const dsCopy = [...dataSource]
        const filteredDs = dsCopy.length ? dsCopy.filter(ds => ds.id !== e.changes[0].key) : []
        if (filteredDs && filteredDs.length) {
          setDataSource(filteredDs)
          setHasDataChanged(filteredDs.some(fd => fd.hasOwnProperty('newRow')))
        }

      }
      else {
        if (e && e.changes[0].type === "update")
          e.changes[0]["data"]["rowEdited"] = true

        const dd = e.changes[0].data;
        let finalData = []
        finalData.push(dd)
        let result = []

        if (dataSource && dataSource.length) {
          result = _.unionBy(finalData, dataSource);
        }
        else result.push(dd)

        setDataSource(result)
        setHasDataChanged(true)
      }
    }
    setAutoWidth(true)
    setAddEditMode(false)
  }

  /**
   * @param e
   * validate row before saving
   **/
  function onRowValidating(e) {
    if (e && e.isValid) {
      // if (e.newData)
      // {
      //   const { page_permissions } = e.newData
      //   if (page_permissions && page_permissions.length <= 0)
      //   {
      //     e.isValid = false;
      //     e.errorText = "Please create permission before moving further";
      //   }
      // }
    }
  }

  /**
   * function used to handle delete part of data-grid
   **/
  const manageDelete = async () => {
    const newRecords = dataSource.filter(ds => ds.hasOwnProperty('newRow'))
    let newData = [...dataSource]
    handleDeleteRequest(async () => {
      const deleteFromApi = selectedRowKeys.filter(a => a.hasOwnProperty('id') && !a.hasOwnProperty('newRow'))
      const deleteFromTable = selectedRowKeys.filter(a => a.hasOwnProperty('newRow'))
      const result = deleteFromApi.map(a => a.id);
      if (deleteFromApi.length > 0 && deleteFromTable.length > 0) {
        await handleDelete(result, userId, selectedRowKeys.length === dataSource.length, masterModuleId)
      }
      else if (deleteFromApi && deleteFromApi.length > 0) {
        await handleDelete(result, userId, selectedRowKeys.length === dataSource.length, masterModuleId)
      }
      else if (deleteFromTable && deleteFromTable.length > 0) {
        deleteFromTable.map(a => {
          newData = newData.filter((item) => item.key !== a.key);
        })
        setDataSource(newData)
      }
      setHasDataChanged(false)
      setSelectedRowKeys([])
    })
  };

  /**
 * custom function using useMemo to avoid re-renders unless the states listed are changed
 **/
  const Comp = useMemo(() => {
    try {
      return <div id="data-grid-demo">
        {
          hasSelected > 0
            ?
            <React.Fragment>
              <br />
              <MDAlert color="light">

                <MDTypography variant="subtitle2">
                  {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? "item" : "items"}`}
                </MDTypography>

                <Divider orientation="vertical" color="dark" flexItem />

                <MDBox>
                  <Grid container spacing={2}>
                    {
                      permissions && permissions.canDelete
                        ? <Grid item >
                          <DEButton stylingMode={"contained"} type={"danger"} icon="trash" onClick={() => manageDelete()} />
                        </Grid> : null
                    }
                  </Grid>
                </MDBox>
              </MDAlert>
            </React.Fragment>
            : null
        }
        <DataGrid id="grid"
          onToolbarPreparing={onToolbarPreparing} showBorders={true}
          onRowExpanding={onRowExpanding}
          columnAutoWidth={true} onSaved={onSave}
          showColumnLines={true} showRowLines={true} rowAlternationEnabled={true}
          ref={dataGridRef} onInitNewRow={onInitNewRow}
          onSelectionChanged={onSelectionChanged} allowColumnResizing={true}
          disabled={isLoading} dataSource={dataSource} key={defaultKey ?? "id"} keyExpr={defaultKey ?? "id"}
          onEditorPreparing={onEditorPreparing} onRowValidating={onRowValidating}>
          {
            addEditMode ? null : <Scrolling showScrollbar="always" mode="standard" />
          }

          {
            allowSelection ?
              <Selection allowSelectAll={true} mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} />
              : null
          }
          <HeaderFilter visible={true} allowSearch={true} />
          <SearchPanel visible={true} />
          <Paging defaultPageSize={25} />
          <Pager visible={true} showNavigationButtons={true} showInfo={true} displayMode={"full"} />
          <Export enabled={true} allowExportSelectedData={true} />
          <Editing newRowPosition={"first"} refreshMode={"repaint"} mode="cell" allowUpdating={permissions && permissions.canCreate && allowUpdating} allowAdding={permissions && permissions.canCreate && allowAdding} allowDeleting={allowDeleting} />
          {
            dataColumns && dataColumns.length ? dataColumns.map((d) => renderField(d, dropDownData)) : null
          }
          <MasterDetail autoExpandAll={false} enabled={true} component={(props) => {
            return <PermissionModal routeKey={routeKey} masterModuleId={props.data.data.masterModuleId} role={props.data.data.hasOwnProperty('newRow') ? null : props.data.data.id} roleName={props.data.data.name} roleData={props.data.data} page_permissions={permissions} />
          }} />
        </DataGrid>
      </div>
    }
    catch (e) {
      console.log('error from ORG STRUCTURE', e)
    }
  }, [dataSource, dataColumns, dropDownData, hasDataChanged, selectedRowKeys, isLoading, autoWidth, addEditMode])

  return (
    <React.Fragment>
      <DetectNavigationBlocker setIsDataChanged={setHasDataChanged} isDataChanged={hasDataChanged} />
      {Comp}
    </React.Fragment>
  );
}
