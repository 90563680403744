import React, { useEffect, useContext } from "react";
import BaseLayOut from "../../components/Layout/BaseLayOut";
import { Context } from "utils/context/store/Store";
import useManageNotifications from "utils/hooks/useManageNotifications";
import usePagePermissions from "utils/hooks/usePagePermissions";
import ManageNotificationGrid from "./components/manage-notification-grid";
import "./notification-management.scss";

const NotificationManagement = ({ routeKey, pageName }) => {
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const [{ notificationsManagement }] = useContext(Context);
  const { getNotifications, updateOrCreateNotifications, isLoading } =
    useManageNotifications(routeKey);

  const getNotificationsList = async () => {
    await getNotifications();
  };

  useEffect(() => {
    getNotificationsList();
  }, []);

  return (
    <BaseLayOut pageTitle={pageName ?? "Manage Notification"}>
      <ManageNotificationGrid
        columns={notificationsManagement.columns}
        rows={notificationsManagement.rows}
        loading={isLoading}
        dropDownData={notificationsManagement.dropdownValues}
        permissions={getPermissionsForPage(routeKey)}
        routeKey={routeKey}
        hitApi={updateOrCreateNotifications}
      />
    </BaseLayOut>
  );
};

export default NotificationManagement;
