import React, { useEffect, useState } from "react";
import 'antd/dist/antd.css';
import { Modal } from 'antd';
import MDTypography from "components/MDTypography";
import { DEButton, selectCustomStyles } from "utils/services/Helpers";
import CustomSkelton from "../../components/Skelton/CustomSkelton";
import MDBox from "../../components/MDBox";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import useFunctionalAccessFilterPreference from "../../utils/hooks/useFunctionalAccessFilterPreference";

const FunctionalAccessFilterPreferenceModal = ({ title = "Filter Preferences", btnTitle = "Manage Filters", role, masterModuleId, roleName, isDisabled = false, page_permissions, routeKey, functional_access_filter_preferences }) => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filters, setFilters] = useState([])
  const {createUpdateFilterPreferences, isLoading} = useFunctionalAccessFilterPreference()

  useEffect(async () => {
    if(isModalVisible && functional_access_filter_preferences?.length)
      setFilters(functional_access_filter_preferences)

    // return () => {
    //   setFilters([])
    // }
  }, [isModalVisible])

  useEffect(() => {}, [filters])

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  async function handleSubmit(e) {
    e.preventDefault()
    const finalFilters = []
    filters.map(f => {
      finalFilters.push({
        functionalAccessId: role,
        filter: f.value,
      })
    })
    await createUpdateFilterPreferences(finalFilters, role)
  }

  function getOptions() {
    return  [
      { label: "Select All", value: "all" },
      { label: "Pending", value: "Pending" },
      { label: "In Progress", value: "In Progress" },
      { label: "Completed", value: "Completed" },
      // { label: "Closed", value: "Closed" },
    ].sort((a, b) => a.label === "Select All" ? -1 : b.label === "Select All" ? 1 : a.label.localeCompare(b.label));
  }


  return (
    <>
      <DEButton disabled={isDisabled} stylingMode={"contained"} type={"normal"} text={btnTitle} onClick={showModal} />
      <Modal maskClosable={false} title={title} width={1200} open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={role ?
        [
          <DEButton stylingMode={"contained"} type={"success"} text={"Ok"} onClick={handleCancel} />,
        ] : null}>
        <MDTypography variant="h6">
          Functional Security Name: {roleName}
        </MDTypography>
        <br />
        <CustomSkelton is_loading={isLoading}>
          <div className="container">
            <form onSubmit={handleSubmit}>
              <MDBox py={3}>
                <Card>
                  <Card>
                    <MDBox p={3}>
                      <MDBox mt={2}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Select
                              placeholder="Please Select functional Access..."
                              isMulti={true}
                              isClearable={true}
                              isRtl={false}
                              styles={selectCustomStyles}
                              onChange={(val) => {
                                if (val.length && val.find(option => option.value === "all"))
                                {
                                  const r = [...getOptions().slice(1)]
                                  setFilters(r)
                                  return r
                                }

                                setFilters(val)
                                return val
                              }}
                              value={filters}
                              options={getOptions()}
                              className={"input-error"} />
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox display="flex" justifyContent="right" alignItems="center" position="relative" zIndex={1} >
                        {
                          page_permissions && page_permissions?.canCreate
                            ?
                            <>
                              <DEButton stylingMode="contained" type="success" disabled={isLoading} useSubmitBehavior={true} text="Submit" />
                            </>

                            : null
                        }
                      </MDBox>
                    </MDBox>
                  </Card>
                </Card>
              </MDBox>
            </form>
          </div>
        </CustomSkelton>
      </Modal>
    </>
  );
};

export default FunctionalAccessFilterPreferenceModal;