import request from "../services/Http"
import { useContext } from "react";
import { Context } from "../context/store/Store";
import {
    SET_HOMEPAGE_TASKS,
    SET_DASHBOARD_OBJECT,
    SET_IS_LOADING,
    SET_HC_REQUEST,
    SET_HC_LEAVER,
    SET_TASKS,
} from "../context/store/Constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIsLoading } from "./useIsLoading";
import { decryptObject, getPageProperties, getTableColumns, isObjectEmpty } from "../services/Helpers";
import { useParams } from "react-router-dom";

export default function useMyTasks(routeKey, isForHome = true) {

    const [{ tasks, myTasks, pagesAndPermissions }, dispatch] = useContext(Context)
    const { isLoading, setIsLoading } = useIsLoading()
    const { masterModuleId } = useParams()

    async function getMyTasks() {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })

        try {
            const res = await request.get('my-task')
            if (res)
            {
                let rows = isForHome ? res.data.tasks : res.data.data
                rows.forEach((row) => {
                    row.sequence = row.id
                    row.index = row.id
                    row.key = row.id
                })

                if (!isForHome && masterModuleId) {
                    const properties = getPageProperties(pagesAndPermissions, routeKey)
                    const table = getTableColumns(properties.page.table, routeKey)
                    let columns = table.columns
                    rows = rows.filter(mt => mt.masterModuleId === Number(masterModuleId)) ?? []
                    const dataTableData = { columns: columns, rows: rows }
                    dispatch({ type: SET_TASKS, payload: dataTableData });
                }
                else {
                    dispatch({ type: SET_TASKS, payload: {} });
                    dispatch({ type: SET_HOMEPAGE_TASKS, payload: {} });
                    dispatch({ type: SET_HC_REQUEST, payload: {} });
                    dispatch({ type: SET_HC_LEAVER, payload: {} })
                    const properties = getPageProperties(pagesAndPermissions, routeKey)
                    const table = getTableColumns(properties.page.table, routeKey)
                    let columns = table.columns
                    let rows = isForHome ? res.data.tasks : res.data.data
                    const dataTableData = { columns: columns, rows: rows }
                    dispatch({ type: SET_HOMEPAGE_TASKS, payload: dataTableData });
                }

                setIsLoading(false)
                dispatch({ type: SET_IS_LOADING, payload: false })
            }
        }
        catch (e) {
            setIsLoading(false)
            dispatch({ type: SET_IS_LOADING, payload: false })
        }

    }

    async function getDashboardObject() {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.get('my-task/dashboard')
            dispatch({ type: SET_DASHBOARD_OBJECT, payload: res.data.dashboardObject });
            return res.data.dashboardObject
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function deleteMyTasks(data) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.delete('my-task', {
                data: {
                    jd: data
                }
            })
            await getMyTasks()
            toast.success(res.data.data);
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    return { getMyTasks, getDashboardObject, deleteMyTasks, isLoading }
}