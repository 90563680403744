import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'antd';
import { HtmlTooltip } from 'components/CustomDataGridComponents';
import MDBox from 'components/MDBox';
import Card from "@mui/material/Card";
import TabPanel from "components/TabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useManageClientVital from "utils/hooks/useManageClientVital";
import { a11yProps } from "utils/services/Helpers";
import usePagePermissions from "utils/hooks/usePagePermissions";
import ManageClientVitalGrid from 'views/manage-client-vital/components/manage-cv-grid';
import ViewClientVitalGrid from 'views/manage-client-vital/components/view-cv-grid';
import { Context } from "utils/context/store/Store";
import useManageClientVitals from 'utils/hooks/useManageClientVitals';
import { Backdrop, CircularProgress } from '@mui/material';
import { showDialogBox } from 'utils/services/Helpers';

const ClientVitalDetailModal = ({ idx = null, title = null, lights = null, activeLight = null, renderColor = null, name = null, mClientId = null, mEntityId = null, categoryId, routeKey = "manage-client-vital", id = null, dropdownValue, viewTabValue }) => {

  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const [{ clientVital }] = useContext(Context);
  const { getClientVitals, deleteClientVital, updateOrCreateClientVital, isLoading, localClientVitals } =
    useManageClientVital(routeKey);
  const { fetchClientVitals } = useManageClientVitals(routeKey)
  const [isModalVisible, setIsModalVisible] = useState(null);
  const [clients, setClients] = useState([]);
  const [verticals, setVerticals] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [hasDataChanged, setHasDataChanged] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [rows, setRows] = useState([]);
  const [viewCVRows, setViewCVRows] = useState([]);
  const [apiCallStatus, setApiCallStatus] = useState('idle');
  const [delayRender, setDelayRender] = useState(false);
  const [hasDataChangedInDg, setHasDataChangedInDg] = useState(false);

  useEffect(() => {

    // cleanup on unmount
    return () => {
      setHasDataChangedInDg(false)
    };
  }, []);

  const getClientVital = async () => {
    setApiCallStatus('loading');
    try {
      await getClientVitals(true, true, categoryId);
      setApiCallStatus('success');
    } catch (error) {
      console.error('Error fetching client vital:', error);
      setApiCallStatus('error');
    }
  };

  useEffect(async () => {
    // if (isModalVisible) {
    //   getClientVital();
    // }
  }, [isModalVisible])

  useEffect(() => {
    if (localClientVitals && localClientVitals.dropdownValues) {
      const { masterClientId, masterDepartmentId, masterCvCategoryId } = localClientVitals.dropdownValues;

      setClients(masterClientId);
      setCategories(masterCvCategoryId);
      setVerticals(masterDepartmentId.map((e) => ({ label: e.label, value: e.id })));

      if (categoryId !== undefined) {
        const tabVal = parseInt(categoryId)
        setSelectedCategory(masterCvCategoryId[localClientVitals?.tabValue ?? tabVal])
        setTabValue(localClientVitals?.tabValue ?? tabVal)
      } else {
        setSelectedCategory(masterCvCategoryId[localClientVitals?.tabValue ?? 0])
        setTabValue(localClientVitals?.tabValue ?? 0);
      }
      setSelectedClient(masterClientId.find((e) => e.value === Number(mClientId)));
      setSelectedEntity(masterDepartmentId.find((e) => e.id === Number(mEntityId)));
    }
  }, [localClientVitals]);

  useEffect(() => {
    if (apiCallStatus === 'success') {
      setIsModalVisible(true);
      setTimeout(() => {
        setDelayRender(true);
      }, 200);
    }
  }, [apiCallStatus]);

  useEffect(() => {
    if (localClientVitals?.dropdownValues && mEntityId && mClientId && selectedCategory && selectedClient) {
      const transactionView = localClientVitals.data.find(
        (e) =>
          e.masterClientId === selectedClient.value &&
          e.masterDepartmentId === Number(mEntityId)
      );
      const transactionViewItems = transactionView?.transaction_cv_items.filter(
        (e) => e.masterCvCategoryId === selectedCategory.id
      );
      if (transactionViewItems?.length) {
        //masterClientId
        const transactionIds = transactionViewItems
          .map((e) => e.transactionCvItemId)
          .filter((e) => e);

        const manageCvRows = transactionViewItems
          .filter((e) => !transactionIds.includes(e.id))
          .map((e) => ({
            ...e,
            masterCvLightId: e.masterCvLightId,
            name: selectedCategory.master_cv_items.find((item) => item.id === e.masterCvItemId)
              ?.name,
            sequence: selectedCategory.master_cv_items.find((item) => item.id === e.masterCvItemId)
              ?.sequence,
          }));

        setRows(manageCvRows?.sort((a, b) => a.sequence - b.sequence));
        const keysWithValue = Array.from(
          new Set(
            transactionViewItems.flatMap(e =>
              Object.keys(e).filter(key =>
                key.startsWith('masterCvLightId-')
              )
            )
          )
        );

        const groupId = localClientVitals.data.filter(s => !s.newRow && s.masterClientId === mClientId && s.masterDepartmentId === mEntityId)?.[0]?.transaction_cv_items?.[0]?.transactionCvGroupId
        const histories = localClientVitals.transactionHistories.filter(t => t.masterCvCategoryId === selectedCategory.id && t.transactionCvGroupId === groupId)
        const viewCvRows = transactionViewItems.every((e) => !e.masterCvLightId) ? []
          : histories.map((e) => {
            const objToReturn = {
              ...e,
              masterCvLightId: selectedCategory.master_cv_lights.find(
                (light) => light.id === e.masterCvLightId
              )?.name,
              color: selectedCategory.master_cv_lights.find((light) => light.id === e.masterCvLightId)
                ?.color,
              userName: e.createdByUser?.name,
              masterCvItemId: selectedCategory.master_cv_items.find(
                (item) => item.id === e.masterCvItemId
              )?.name,
              sequence: selectedCategory.master_cv_items.find(
                (item) => item.id === e.masterCvItemId)
                ?.sequence,
            }
            return objToReturn
          });
        setViewCVRows(viewCvRows?.sort((a, b) => a.updatedAt - b.updatedAt) ?? []);
      } else {
        const addViewRows = selectedCategory.master_cv_items.map((e) => ({
          ...e,
          masterCvLightId: null,
          comments: "",
          masterCvItemId: e.id,
        }));
        setRows(addViewRows);
        setViewCVRows([]);
      }
    } else if (
      localClientVitals?.dropdownValues &&
      !mEntityId &&
      selectedEntity &&
      selectedCategory &&
      selectedClient
    ) {
      const transactionView = localClientVitals.data.find(
        (e) =>
          e.masterClientId === selectedClient.value &&
          e.masterDepartmentId === selectedEntity.value
      );
      const transactionViewItems = transactionView?.transaction_cv_items.filter(
        (e) => e.masterCvCategoryId === selectedCategory.id
      );

      if (transactionViewItems?.length) {
        const transactionIds = transactionViewItems
          .map((e) => e.transactionCvItemId)
          .filter((e) => e);
        const manageCvRows = transactionViewItems
          .filter((e) => !transactionIds.includes(e.id))
          .map((e) => ({
            ...e,
            masterCvLightId: e.masterCvLightId,
            name: selectedCategory.master_cv_items.find((item) => item.id === e.masterCvItemId)
              ?.name,
            sequence: selectedCategory.master_cv_items.find((item) => item.id === e.masterCvItemId)
              ?.sequence,
          }));
        setRows(manageCvRows?.sort((a, b) => a.sequence - b.sequence));

        const viewCvRows = transactionViewItems
          .filter((e) => transactionIds.includes(e.id))
          .map((e) => ({
            ...e,
            masterCvLightId: selectedCategory.master_cv_lights.find(
              (light) => light.id === e.masterCvLightId
            )?.name,
            color: selectedCategory.master_cv_lights.find((light) => light.id === e.masterCvLightId)
              ?.color,
            userName: e.createdByUser?.name,
            masterCvItemId: selectedCategory.master_cv_items.find(
              (item) => item.id === e.masterCvItemId
            )?.name,
            sequence: selectedCategory.master_cv_items.find(
              (item) => item.id === e.masterCvItemId)
              ?.sequence,
          }));
        setViewCVRows(viewCvRows?.sort((a, b) => a.sequence - b.sequence));

      } else {
        const addViewRows = selectedCategory.master_cv_items.map((e) => ({
          ...e,
          masterCvLightId: null,
          comments: "",
          masterCvItemId: e.id,
        }));
        setRows(addViewRows);
        setViewCVRows([]);
      }
    }
  }, [
    selectedClient,
    selectedEntity,
    selectedCategory,
    localClientVitals,
    tabValue,
  ]);

  const showModal = async () => {
    getClientVital();
  };

  const handleChange = (event, newValue) => {
    if (hasDataChangedInDg) {
      showDialogBox(async () => {
        setSelectedCategory(categories.at(newValue));
        setTabValue(newValue);
        setHasDataChangedInDg(false)
      }, 'warning', 'Yes, continue', null, 'You have unsaved changes here. Do you want to switch tabs without saving your changes?')
    } else {
      setSelectedCategory(categories.at(newValue));
      setTabValue(newValue);
      setHasDataChangedInDg(false)
    }
  };

  const handleSubmit = async (data) => {
    // Edit Mode
    let finalData = []
    if (data && data.length && (data.some(e => e.hasOwnProperty('existingTransactionCvGroupId')) || data.some(e => e.hasOwnProperty('transactionCvGroupId') && e.transactionCvGroupId))) {
      finalData.push({
        existingTransactionCvGroupId: data[0]?.existingTransactionCvGroupId ?? data[0]?.transactionCvGroupId,
        items: [
          ...data.map((data) => ({
            ...data,
            transactionCvItemId: data.hasOwnProperty('transactionCvGroupId') && data.transactionCvGroupId && data.hasOwnProperty('rowEdited') && data.rowEdited ? data.id : null,
          })),
        ]
      })
    } else {
      finalData.push({
        masterClientId: mClientId,
        masterDepartmentId: mEntityId,
        newRow: true,
        items: data
      })
    }

    await updateOrCreateClientVital(finalData, tabValue);
    setHasDataChanged(true)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <HtmlTooltip id={idx} key={idx} title={title}>
        <a id={"cvItemID"} className="cvItem" onClick={showModal}>
          <div className="itemLights">
            {renderColor(lights, activeLight)}
          </div>
          <div className="itemName">{name}</div>
        </a>
      </HtmlTooltip>
      <Modal
        className="client-vital-detail"
        maskClosable={false}
        title={`${selectedClient?.label ?? ""} - ${selectedEntity?.label ?? ""}`}
        width={1200}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
        afterClose={async () => hasDataChanged ? await fetchClientVitals(dropdownValue, viewTabValue) : null}
      >
        {mClientId && mEntityId && (
          <MDBox my={3}>
            <Card>
              <MDBox pb={3}>
                <MDBox sx={{ width: "100%" }}>
                  {delayRender && (<>
                    <MDBox sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs value={tabValue} onChange={handleChange} aria-label="tabs" variant="fullWidth">
                        {categories.map((obj, index) => {
                          return (
                            <Tab
                              key={`${obj.name}-${index}`}
                              label={obj.name}
                              {...a11yProps(index)}
                            />
                          );
                        })}
                      </Tabs>
                    </MDBox>
                    {categories.map((obj, index) => {
                      return (
                        <TabPanel value={tabValue} key={`${obj.name}-${index}`} index={index}>
                          <MDBox sx={{ borderBottom: 1, borderColor: "divider" }} pb={5} mb={5}>
                            <ManageClientVitalGrid
                              masterClientId={selectedClient?.label}
                              masterDepartmentId={selectedEntity?.label}
                              isLoading={isLoading}
                              categoryData={obj}
                              rows={rows}
                              colors={obj.master_cv_lights?.sort((a, b) => a.sequence - b.sequence)}
                              permissions={getPermissionsForPage(routeKey)}
                              columns={localClientVitals.columns}
                              allowAdding
                              dropDownData={localClientVitals.dropdownValues}
                              postData={handleSubmit}
                              handleDelete={deleteClientVital}
                              hasDataChanged={hasDataChangedInDg}
                              setHasDataChanged={setHasDataChangedInDg}
                            />
                          </MDBox>
                          {viewCVRows?.length > 0 && (
                            <MDBox>
                              <ViewClientVitalGrid
                                rows={viewCVRows}
                                columns={localClientVitals.viewClientVitalsColumns}
                                isLoading={isLoading} />
                            </MDBox>
                          )}
                        </TabPanel>
                      );
                    })}
                  </>)}
                </MDBox>
              </MDBox>
            </Card>
          </MDBox>
        )}
      </Modal>
      {isLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
};

export default ClientVitalDetailModal;
