import { useContext, useState } from "react";
import { useIsLoading } from "./useIsLoading";
import request from "../services/Http";
import { Context } from "../context/store/Store";
import { generateFileData, getPageProperties, getTableColumns } from "../services/Helpers";
import usePagePermissions from "./usePagePermissions";
import { toast } from "react-toastify";
import useHeadcountRequest from "./useHeadcountRequest";

export default function useTransactionCapability(routeKey){
  const [{ pagesAndPermissions }, dispatch] = useContext(Context)
  const [transactionCapability, setTransactionCapability] = useState({
    rows: [],
    columns: [],
    dropdownData: {},
    customColumns: [],
  })
  const {setIsLoading, isLoading} = useIsLoading()
  const permissions = usePagePermissions(routeKey).getPermissionsForPage()
  const {handleFileUpload} = useHeadcountRequest()
  const API_END_POINT = 'transaction-capability'

  async function getAllTransactionCapabilities(){
    setIsLoading(true)
    try {
      const res = await request.get(API_END_POINT)
      if (res)
      {
        const properties = getPageProperties(pagesAndPermissions, routeKey)
        const table = getTableColumns(properties?.page?.table, routeKey)
        let columns = table?.columns ?? []
        let rows = res.data.data
        let customColumns = res.data.columns
        setTransactionCapability({
          rows: rows,
          columns: columns,
          dropdownData: res.data.dropdownData,
          customColumns
        })
      }
    }
    catch (e){}
    finally {
      setIsLoading(false)
    }
  }

  async function createUpdateTransactionCapabilities(capabilities){
    setIsLoading(true)
    try {
      const headcountsFormData = new FormData();
      headcountsFormData.append('data', JSON.stringify(capabilities))
      const capabilitiesWithAttachment = capabilities.filter(l => l.hasOwnProperty('attachment') && l?.attachment !== null) ?? []
      if (capabilitiesWithAttachment?.length)
      {
        const { fileDetails, attachments} = await generateFileData(capabilitiesWithAttachment, 'transaction_capabilities')
        headcountsFormData.append('fileDetails', JSON.stringify(fileDetails))
        for (const a of attachments)
        {
          if (a instanceof File) headcountsFormData.append('attachment', a)
        }
      }
      const res = await request.post(API_END_POINT, headcountsFormData, { headers: { 'Content-Type': 'multipart/form-data' } })

      if (res)
      {
        toast.success(res.data.data)
        await getAllTransactionCapabilities()
      }
    }
    catch (e) {}
    finally {
      setIsLoading(false)
    }
  }

  async function deleteTransactionCapabilities(ids){
    setIsLoading(true)
    try {
      const res = await request.delete(API_END_POINT, {
        data: {ids}
      })
      if (res)
      {
        toast.success(res.data.data)
        await getAllTransactionCapabilities()
      }
    }
    catch (e) {}
    finally {
      setIsLoading(false)
    }
  }


  return {
    setTransactionCapability, transactionCapability, isLoading,
    getAllTransactionCapabilities, permissions,
    createUpdateTransactionCapabilities, deleteTransactionCapabilities
  }
}
