import React, { useContext, useEffect, useState } from "react";
import 'antd/dist/antd.css';
import { Modal } from 'antd';
import useFAVariable from "utils/hooks/useFAVariable";
import useRoles from 'utils/hooks/useRoles';
import MDTypography from "components/MDTypography";
import { Context } from "../../../utils/context/store/Store";
import VariableDataGrid from "./DataTable/VariableDataGrid";
import { DEButton } from "utils/services/Helpers";
import Link from "@mui/material/Link";

const FAVariableModal = ({ title = "Manage Variables", btnTitle = "Manage Variables", role, masterModuleId, roleName, roleData, dataSource, isDisabled = false, page_permissions, routeKey }) => {

  const [{ faVariable }, dispatch] = useContext(Context)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { getRoles, updateOrCreateRoles } = useRoles(routeKey)
  const { getVariableByFA, deleteVariable, updateOrCreateVariable, isLoading } = useFAVariable(role, masterModuleId, routeKey)


  useEffect(async () => {
    if (isModalVisible) {
      await getVariableByFA(role, masterModuleId)
    }
  }, [isModalVisible])

  useEffect(() => { }, [faVariable])

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <DEButton disabled={isDisabled} stylingMode={"contained"} type={"normal"} text={btnTitle} onClick={showModal} style={{ marginRight: "8px" }} />
      <Modal maskClosable={false} title={title} width={1200} open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={role ?
        [
          <DEButton stylingMode={"contained"} type={"success"} text={"Ok"} onClick={handleCancel} />,
        ] : null}>
        <MDTypography variant="h6">
          Functional Security Name: {roleName}
        </MDTypography>
        <br />
        {
          role
            ? <VariableDataGrid loading={isLoading} routeKey={routeKey} rows={faVariable.rows ?? []} columns={faVariable.columns}
              rolesDataSource={dataSource} roleData={roleData} isPermissions={true} masterModuleId={masterModuleId}
              fetchFromApi={() => getVariableByFA(role)} functionalAccessId={role} hitApi={updateOrCreateVariable}
              handleDelete={deleteVariable} permissions={page_permissions} isDeleteable={false} dropDownData={faVariable.dropDownData} />
            : <VariableDataGrid loading={isLoading} routeKey={routeKey} dropDownData={faVariable.dropDownData}
              rows={roleData && roleData.page_permissions && roleData.page_permissions.length ? roleData.page_permissions : faVariable.rows}
              columns={faVariable.columns} rolesDataSource={dataSource} isPermissions={true} masterModuleId={masterModuleId}
              fetchFromApi={getRoles} functionalAccessId={role} hitApi={updateOrCreateRoles} handleDelete={deleteVariable}
              isForNewRole={true} roleData={roleData} permissions={page_permissions} closeModal={() => handleCancel()} isDeleteable={false} />
        }
      </Modal>
    </>
  );
};

export default FAVariableModal;