import { Context } from 'utils/context/store/Store';
import useHcRequestType from 'utils/hooks/useHcRequestType';
import { useContext, useEffect, useReducer } from 'react';
import useHeadCountLeaver from 'utils/hooks/useHeadCountLeaver';
import { isObjectEmpty, getUserInfo } from 'utils/services/Helpers';
import useHeadcountRequest from 'utils/hooks/useHeadcountRequest';
import { reducer, initialState } from '../state/reducer';
import { setHeadcountValue } from '../utils/helpers';
import { INTERNAL_MOBILITY_ID, LEAVER_HEADCOUNT_ID, HEADCOUNT_MODULE_ID } from '../utils/constants';

export function useHeadcountManagement(routeKey, id, requestId, headcountTypeId, notificationId) {
  const { getHCRequestWithDataAccess } = useHcRequestType(routeKey);
  const { getHcLeaverDetails } = useHeadCountLeaver(routeKey);
  const { getHeadcountRequest, isLoading } = useHeadcountRequest(
    id ? 'manage-headcount' : routeKey
  );
  const [{ hcRequestType, headcountRequest, hcLeaver }] = useContext(Context);

  const isRequestTypeDisabled = id || headcountRequest?.rows?.length || hcLeaver?.rows?.length;
  const isHeadcountTypeDisabled = id || headcountRequest?.rows?.length;
  const [state, dispatch] = useReducer(reducer, initialState);

  const onRequestTypeChange = (requestId) => {
    dispatch({ type: 'SET_REQUEST_TYPE', payload: requestId });
    if (state.selectedHeadcountType) {
      dispatch({ type: 'SET_HEADCOUNT_TYPE', payload: null });
    }
  };

  const onHeadcountChange = (headcountId) => {
    dispatch({ type: 'SET_HEADCOUNT_TYPE', payload: headcountId });
  };

  const checkLocalStorageValues = () => {
    const storageClonedHC = JSON.parse(localStorage.getItem('clonedHCS'));
    if (storageClonedHC) dispatch({ type: 'SET_CLONED_HEADCOUNTS', payload: storageClonedHC });

    const storageInternalEmpData = JSON.parse(localStorage.getItem('internalEmpData'));
    if (storageInternalEmpData)
      dispatch({ type: 'SET_INTERNAL_EMP_DATA', payload: storageInternalEmpData });
  };

  const onUnmount = () => {
    localStorage.removeItem('clonedHCS');
    localStorage.removeItem('internalEmpData');
  };

  const fetchHeadcountTypes = async () => {
    await getHCRequestWithDataAccess(getUserInfo().id, HEADCOUNT_MODULE_ID);
  };

  useEffect(() => {
    fetchHeadcountTypes().then(() => {
      if (requestId) dispatch({ type: 'SET_REQUEST_TYPE', payload: parseInt(requestId) });
      if (headcountTypeId !== 'leaver') {
        dispatch({ type: 'SET_HEADCOUNT_TYPE', payload: parseInt(headcountTypeId) });
      }

      checkLocalStorageValues();
    });

    return onUnmount;
  }, [id, requestId, headcountTypeId, notificationId]);

  useEffect(() => {
    dispatch({ type: 'SET_REQUEST_TYPE_LIST', payload: hcRequestType });
  }, [hcRequestType]);

  useEffect(() => {
    if (state.selectedRequestType) {
      setHeadcountValue(
        state.requestTypeList,
        state.selectedRequestType,
        state.internalEmpData,
        headcountRequest,
        dispatch
      );

      if (state.selectedRequestType === LEAVER_HEADCOUNT_ID) {
        if (requestId && parseInt(requestId) === LEAVER_HEADCOUNT_ID) {
          getHcLeaverDetails(id);
        } else getHcLeaverDetails();
      }
    }
  }, [state.selectedRequestType]);

  useEffect(() => {
    if (
      state.internalEmpData ||
      headcountRequest?.rows?.some((e) => e.internalHeadcountId !== null)
    ) {
      dispatch({
        type: 'SET_REQUEST_TYPE',
        payload: 1
      });
      dispatch({ type: 'SET_HEADCOUNT_TYPE', payload: INTERNAL_MOBILITY_ID });
    }

    if (state.clonedHeadcounts && !isObjectEmpty(state.clonedHeadcounts)) {
      dispatch({ type: 'SET_REQUEST_TYPE', payload: state.clonedHeadcounts.requestId });
      dispatch({ type: 'SET_HEADCOUNT_TYPE', payload: state.clonedHeadcounts.headcountTypeId });
    }
  }, [state.clonedHeadcounts, state.internalEmpData]);

  useEffect(() => {
    if (state.selectedHeadcountType) {
      if (id) {
        getHeadcountRequest(false, id);
      } else getHeadcountRequest();
    }
  }, [state.selectedHeadcountType]);

  useEffect(() => {
    if (!headcountRequest || !state.headcountTypeList?.length) return;
    // if (
    //   !state.internalEmpData &&
    //   (!id || headcountRequest?.rows?.every((e) => !e.isInternalMobilty))
    // ) {
    //   dispatch({ type: 'FILTER_HEADCOUNT_TYPE_LIST', payload: INTERNAL_MOBILITY_ID });
    // }
  }, [headcountRequest]);

  return {
    ...state,
    isHeadcountTypeDisabled,
    isRequestTypeDisabled,
    onRequestTypeChange,
    onHeadcountChange,
    isLoading,
    headcountRequest,
    getHeadcountRequest,
    hcLeaver,
    getHcLeaverDetails
  };
}
