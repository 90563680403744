import checkout from "../schemas/form";
import { getUserInfo } from "utils/services/Helpers";

const {
  formField: {
    Name, UserName, Email, Market, Practice
  },
} = checkout;

const initialValues = {
  [Name.name]: getUserInfo()?.name,
  [UserName.name]: getUserInfo()?.username,
  [Email.name]: getUserInfo()?.email ?? null,
  [Market.name]: getUserInfo()?.market ?? null,
  [Practice.name]: getUserInfo()?.practice ?? null,
};

export default initialValues;
