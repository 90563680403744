import React, { useEffect, useMemo, useState } from 'react';
import { DEButton } from 'utils/services/Helpers';
import 'assets/datatable-css/index.css';
import 'react-toastify/dist/ReactToastify.css';
import DataGrid, {
  AsyncRule,
  Button,
  Column,
  LoadPanel,
  Export,
  HeaderFilter,
  Pager,
  Paging,
  RequiredRule,
  Scrolling,
  SearchPanel
} from 'devextreme-react/data-grid';
import {
  CustomDateBox,
  CustomNumberBox,
  CustomTextArea
} from 'components/CustomDataGridComponents';
import { Link } from 'react-router-dom';
import { createSanitizeAsyncRule } from 'utils/services/Helpers';

const HomeAndMyTaskDataGrid = ({ columns, rows, isLoading, permissions, isForHome = false }) => {
  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);
    // cleanup on unmount
    return () => {
      setDataSource([]);
      setDataColumns([]);
    };
  }, []);

  useEffect(() => {
    setDataSource(rows);
  }, [rows]);

  useEffect(() => {
    setDataColumns(columns);
  }, [columns]);

  /**
   * @param dataColumns
   * function use to handle rendering of fields
   **/
  function renderField(dataColumns) {
    return dataColumns.map((col, index) => {
      if (col.type === 'checkbox' || col.type === 'toggle') {
        return (
          <Column
            key={index}
            dataType="boolean"
            showEditorAlways={true}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
            }}
          >
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      } else if (col.type === 'actions') {
        return (
          <Column
            key={index}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            type="buttons"
            dataField={col.dataIndex}
            caption={col.title}
            fixed={false}
          >
            <Button
              name="view"
              icon={'eye'}
              visible={true}
              component={(props) => {
                return (
                  <>
                    {permissions && (permissions.canCreate || permissions.canView) ? (
                      isForHome ? (
                        <Link
                          to={`/views/my-tasks/${props.data.data.masterModuleId}`}
                          state={props.data.data}
                        >
                          <DEButton stylingMode={'contained'} type={'normal'} text={'View'} />
                        </Link>
                      ) : (
                        <Link
                          to={
                            props.data.data.type === 'transaction_transfer'
                              ? `/views/manage-supervisors?rId=${props.data.data.groupId}&transactionType=${props.data.data.type}${props.data.data?.delegatedUserId ? `&dg=${props.data.data?.delegatedUserId}` : ""}`
                              : props.data.data.type === 'hc_transaction' ||
                                props.data.data.type === 'transaction_leaver'
                                ? `/views/manage-headcounts?rId=${props.data.data.groupId}&transactionType=${props.data.data?.isReplacement ? "transaction_leaver" : props.data.data.type}${props.data.data?.delegatedUserId ? `&dg=${props.data.data?.delegatedUserId}` : ""}`
                                : `/views/job/${props.data.data.task_code}/version/${props.data.data.version}${props.data.data?.delegatedUserId ? `?dg=${props.data.data?.delegatedUserId}` : ""}`
                          }
                          state={props.data.data}
                        >
                          <DEButton stylingMode={'contained'} type={'normal'} text={'View'} />
                        </Link>
                      )
                    ) : null}
                  </>
                );
              }}
            />
          </Column>
        );
      } else if (col.type === 'date') {
        return (
          <Column
            key={index}
            alignment={'left'}
            dataType={'date'}
            format={'dd-MM-yyyy hh:mm:ss a'}
            editCellComponent={CustomDateBox}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      } else if (col.type === 'int') {
        return (
          <Column
            key={index}
            dataType={col.type}
            editCellComponent={CustomNumberBox}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
            <AsyncRule
              message={'Invalid value or value too long'}
              validationCallback={async (e) => {
                return e.value.toString().length <= 10;
              }}
            />
          </Column>
        );
      } else if (col.type === 'textarea') {
        return (
          <Column
            key={index}
            alignment={'left'}
            encodeHtml={col.encodeHtml}
            editCellComponent={CustomTextArea}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
            <AsyncRule {...sanitizeAsyncRule} />
          </Column>
        );
      } else if (col.type === 'string') {
        return (
          <Column
            key={index}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
            <AsyncRule {...sanitizeAsyncRule} />
          </Column>
        );
      } else {
        return (
          <Column
            key={index}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      }
    });
  }

  /**
   * custom function using useMemo to avoid re-renders unless the states listed are changed
   **/
  const Comp = useMemo(() => {
    try {
      return (
        <div id="MyTasksDataGrid">
          {dataColumns && dataColumns.length && dataSource && dataSource.length ? (
            <DataGrid
              allowColumnResizing={true}
              dataSource={dataSource}
              keyExpr="id"
              showBorders={true}
              showColumnLines={true}
              showRowLines={true}
              rowAlternationEnabled={true}
            >
              <LoadPanel enabled={isLoading} visible={isLoading} />
              <Scrolling showScrollbar="always" mode="standard" />
              <HeaderFilter visible={true} allowSearch={true} />
              <SearchPanel visible={true} />
              <Paging defaultPageSize={25} />
              <Pager
                visible={true}
                showNavigationButtons={true}
                showInfo={true}
                displayMode={'full'}
              />
              {dataColumns && dataColumns.length ? renderField(dataColumns) : null}
              <Export enabled={true} allowExportSelectedData={true} />
            </DataGrid>
          ) : null}
        </div>
      );
    } catch (e) {
      console.log('error from hc', e);
    }
  }, [dataSource, dataColumns, isLoading]);

  return <React.Fragment>{Comp}</React.Fragment>;
};

export default HomeAndMyTaskDataGrid;
