import logo from "assets/images/logos/publicismedia.png";
import * as d3 from 'd3';
import tippy from 'tippy.js';

export const getParentColor = (d) =>
  d.target.parent.data.color ? d.target.parent.data.color : d.target.parent.parent.data.color;

export const getFillColor = (d) =>
  d.parent
    ? d.parent.data.color
      ? d.data.color
        ? d.data.color
        : d.parent.data.color
      : d.parent.parent.data.color
    : d.data.color;

export const createLinkGenerator = (g, information) => {
  const linkGenerator = d3
    .linkRadial()
    .angle((d) => d.x)
    .radius((d) => d.y);

  g.selectAll('.link')
    .data(information.links())
    .enter()
    .append('path')
    .attr('class', 'link')
    .attr('id', (d) => `link-${d.target.data.id}`) // assign each link a unique id
    .attr('d', linkGenerator)
    .attr('stroke', getParentColor)
    .attr('stroke-width', 3)
    .attr('fill', 'none');
};

export const createLinkLabel = (g, information) => {
  const linkLabel = g
    .selectAll('.link-label')
    .data(information.links())
    .enter()
    .filter((d) => d.target.depth !== 3) // only select links connecting to company nodes
    .append('text')
    .attr('dy', '-7')
    .attr('dx', '2px')
    .attr('class', 'link-label')
    .append('textPath')
    .attr('fill', getParentColor)
    .attr('href', (d) => `#link-${d.target.data.id}`)
    .style('text-anchor', 'middle')
    .style('font-size', '20px')
    .attr('startOffset', '70%')
    .style('font-weight', 'bold')
    .text((d) => d.target.data.id);
  wrapText(linkLabel, 280);
};

export const createNodes = (g, information, callback) => {
  const nodes = g
    .selectAll('.node')
    .data(information.descendants())
    .enter()
    .append('g')
    .attr('class', 'node')
    .attr('id', (d) => 'node-' + d.depth)
    .on('click', (_, d) => {
      callback && callback(d);
    })
    .attr(
      'transform',
      (d) => `rotate(${d.depth === 0 ? 0 : (d.x * 180) / Math.PI - 90}) translate(${d.depth === 0 ? '0,0' : d.y + 10})`
    );

  nodes
    .append('circle')
    .attr('r', (d) => (d.depth === 0 ? 80 : d.children ? 20 : 15))
    .attr('stroke', (d) => (d.depth !== 0 ? (d.children ? getFillColor(d) : '#fff') : '#212129'))
    .attr('fill', (d) => (d.depth === 0 ? '#fff' : d.children ? '#fff' : getFillColor(d)))
    .on('mouseover', (e, d) => {
      if (!d.children?.length) {
        const circleElement = d3.select(e.currentTarget).node();
        tippy(circleElement, {
          content: d.data.id,
          allowHTML: true, // allows you to insert HTML content in the tooltip
          duration: [200, 500], // controls the duration of the tooltip appearance and disappearance
          offset: [0, 20] // offset of the tooltip from the circle
        });
      }
    })
    .attr('stroke-width', '2px')
    .attr('dx', '10');

  nodes.on('mouseover', function () {
    d3.select(this.children[0])
      .filter((d) => Boolean(d.children) && d.depth !== 0)
      .attr('fill', getFillColor);

    d3.select(this.children[1])
      .filter((d) => Boolean(d.children))
      .attr('fill', '#fff');
  });

  nodes.on('mouseout', function () {
    d3.select(this.children[0])
      .filter((d) => Boolean(d.children))
      .attr('fill', '#fff');

    d3.select(this.children[1])
      .filter((d) => Boolean(d.children))
      .attr('fill', '#000');
  });

  nodes
    .append('text')
    .filter((d) => Boolean(d.children) && d.depth !== 0)
    .text((d) => `${d.children?.length}`)
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'middle')
    .attr('fill', '#000')
    .attr('font-size', '14px');

  nodes
    .append('text')
    .filter((d) => d.depth === 3)
    .text((d) => d.data.id)
    .attr('x', 0)
    .attr('y', 2)
    .attr('dx', 20)
    .attr('font-size', '20px')
    .attr('font-weight', 'bold')
    .attr('dominant-baseline', 'middle')
    .attr('fill', getFillColor);

  const logoWidth = 125,
    logoHeight = 125;

  nodes
    .append('image')
    .filter((d) => d.depth === 0)
    .attr('xlink:href', logo)
    .attr('width', logoWidth)
    .attr('height', logoHeight)
    .attr('x', -(logoWidth / 2)) // offset from the center of the node
    .attr('y', -(logoHeight / 2)); // offset from the center of the node

  return nodes;
};

function wrapText(text, width) {
  text.each(function () {
    const text = d3.select(this),
      words = text.text().split(/\s+/).reverse(),
      lineHeight = 1.1, // ems
      y = text.attr('y'),
      dy = -7;

    let line = [],
      lineNumber = 0,
      word,
      tspan = text.text(null).append('tspan').attr('x', 0).attr('y', y).attr('dy', `${dy}px`);
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(' ')).attr('dy', `${dy + (tspan.node().getComputedTextLength() > width ? -4 : 0)}px`);
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(' '));
        line = [word];
        tspan = text
          .append('tspan')
          .attr('x', 0)
          .attr('dy', `${++lineNumber * lineHeight + dy + 32}px`)
          .text(word);
      }
    }
  });
}
