import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDTypography from "../MDTypography";
import { DEButton } from "utils/services/Helpers";

0

export const LeaveCurrentPage = ({ showDialog, setShowDialog, cancelNavigation, confirmNavigation, }) => {
  const handleDialogClose = () => {
    setShowDialog(false);
  };

  return (
    <Dialog fullWidth open={showDialog} onClose={handleDialogClose}>
      <DialogTitle>
        <MDTypography variant="h2" align="center" fontWeight="bold">Hey, Leaving?</MDTypography>
      </DialogTitle>
      <DialogContent>
        <MDTypography variant="h5" fontWeight="medium">There are some unsaved changes!</MDTypography>
        <MDTypography variant="h5" fontWeight="medium">If you proceed your changes will be lost!</MDTypography>
        <MDTypography variant="h5" fontWeight="medium">Are you sure you want to proceed?</MDTypography>
      </DialogContent>
      <DialogActions>
        <DEButton stylingMode={"contained"} type={"success"} text={"No"} onClick={cancelNavigation} />
        <DEButton stylingMode={"contained"} type={"danger"} text={"Yes"} onClick={confirmNavigation} />
      </DialogActions>
    </Dialog>
  );
}


export default LeaveCurrentPage;