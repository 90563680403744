import React, { useEffect, useState } from "react";
import 'devextreme/dist/css/dx.light.css';
import 'antd/dist/antd.css';
import { Button } from 'devextreme-react/button';
import useHeadcountRequest from "../../../utils/hooks/useHeadcountRequest";
import { AccordionSummary, AccordionDetails } from "utils/services/Helpers";
import { Modal } from 'antd';
import MDTypography from "components/MDTypography";
import FreelancerDetails from "./FreelancerDetails/FreelancerDetails";
import RecruitmentDetails from "./RecruitmentDetails";
import { Accordion } from "@mui/material";

const InternalMobilityPopup = ({ hcData, masterRequestTypeId, user, id, type, cName, employee, mrId, mrsId, masterEmployees, jDate, masterRecruiters, masterRecruitmentStatus, routeKey, transactionHeadcountGroupId, disableInternalSubmitButton = false, apiCallBack = null, internalReplacementRequired = false, masterCurrencyId, masterFreelancerId, freelancerDetails }) => {

  const [isPopupVisible, setPopupVisibility] = useState(false);
  const { updateInternalMobilityDetails, isLoading, headcountRequest, createUpdateFreeLancerDetails } = useHeadcountRequest(routeKey);

  useEffect(() => {}, []);
  useEffect(() => { }, [isLoading]);

  /**
 * function use to manage state to view/hide popup
 **/
  const togglePopup = () => {
    setPopupVisibility(!isPopupVisible);
  };

  return (
    <>
      <Modal className="recruitment-stage rst-hght" keyboard={false} destroyOnClose={true} maskClosable={false} title={"Recruitment Stage"} bodyStyle={{ paddingBottom: '25px' }} width={1000} height={500} open={isPopupVisible} onCancel={togglePopup} footer={null}>
        <div className="container">
          <Accordion key={1} defaultExpanded={true}>
            <AccordionSummary className={"__rst_summary"} aria-controls="panel4d-content" id="panel4d-header">
              <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6"> Recruitment Details </MDTypography>
            </AccordionSummary>
            <AccordionDetails >
              <RecruitmentDetails key={"rd"} updateInternalMobilityDetails={updateInternalMobilityDetails} headcountRequest={headcountRequest} isLoading={isLoading} hcData={hcData} masterRequestTypeId={masterRequestTypeId} user={user} id={id} type={type} cName={cName} employee={employee} mrId={mrId} mrsId={mrsId} masterEmployees={masterEmployees} jDate={jDate} masterRecruiters={masterRecruiters} masterRecruitmentStatus={masterRecruitmentStatus} routeKey={routeKey} transactionHeadcountGroupId={transactionHeadcountGroupId} disableInternalSubmitButton={disableInternalSubmitButton} apiCallBack={apiCallBack} internalReplacementRequired={internalReplacementRequired} masterCurrencyId={masterCurrencyId}/>
            </AccordionDetails>
          </Accordion>
          <br/>
          {
            hcData?.pToF || hcData?.masterJobTypeId === 2
              ?
              <Accordion key={2} className={'__accordion_container'} defaultExpanded={true}>
                <AccordionSummary className={"__rst_summary"} aria-controls="panel4d-content" id="panel4d-header">
                  &nbsp;<MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6"> {hcData?.pToF ? "Permanent HC Converted To Freelancer" : "Freelancer"} Details </MDTypography>
                </AccordionSummary>
                <AccordionDetails >
                  <FreelancerDetails key={"fd"} id={id} disableInternalSubmitButton={disableInternalSubmitButton} submitHandler={createUpdateFreeLancerDetails} apiCallBack={apiCallBack} startDate={hcData?.startDate} endDate={hcData?.endDate} masterFreelancerId={masterFreelancerId} masterCurrencyId={masterCurrencyId} freelancerDetails={freelancerDetails}/>
                </AccordionDetails>
              </Accordion>
              : null
          }
        </div>
      </Modal>
      <Button
        stylingMode={"contained"} type={"success"}
        name={"internal-mobility"} text={"Recruitment Stage"}
        onClick={togglePopup}
      />
    </>
  );
}

export default InternalMobilityPopup;
