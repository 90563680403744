import request from "../services/Http"
import { useContext, useState } from "react";
import { Context } from "../context/store/Store";
import {
    SET_IS_LOADING,
    SET_TRANSFERS,
} from "../context/store/Constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isJSONValid, getPageProperties, getTableColumns, generateFileData } from "../services/Helpers";
import { useIsLoading } from "./useIsLoading";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import _ from "lodash";

export default function useManageTransfer(routeKey) {

    const [{ transfers, pagesAndPermissions, dataAccess }, dispatch] = useContext(Context)
    const { isLoading, setIsLoading } = useIsLoading()
    const [localTransfers, setLocalTransfers] = useState({})
    const navigate = useNavigate()
    const { id } = useParams()
    const search = useLocation().search;
    const dgId = new URLSearchParams(search).get("dg");

    async function getTransferRequest(setLocalState = false, groupId = null) {
        if (setLocalState) {
            setLocalTransfers({})
        } else {
            dispatch({
                type: SET_TRANSFERS,
                payload: {}
            });
        }
        setIsLoading(true)
        if (!setLocalState) {
            dispatch({ type: SET_IS_LOADING, payload: true })
        }
        try {
            const url = groupId ? `emp-transfer?groupId=${groupId}${dgId !== null ? `&dgId=${dgId}` : ""}` : 'emp-transfer'
            const res = await request.get(url)
            const properties = getPageProperties(pagesAndPermissions, routeKey)
            const table = getTableColumns(properties.page.table, 'manage-supervisor')
            let columns = _.cloneDeep(table.columns)
            let rows = res.data.data
            let addFields = res.data.fields
            let canAction = res.data.canAction
            let isDraft = res.data.isDraft
            let newRowObject = isJSONValid(table.row.defaultObj) ?? {}
            let dropdownValues = isJSONValid(res.data.dropdownValues) ?? {}
            const filteredCountries = dataAccess && dataAccess.jdCountries ? dataAccess.jdCountries : []
            const filteredVerticals = dataAccess && dataAccess.Verticals ? dataAccess.Verticals : []
            filteredVerticals.map((ver) => {
                ver.id = ver.masterOrgVerticalId
                ver.label = ver['name'] ?? ver['vertical.name']

            })
            const filteredCap = dataAccess && dataAccess.functions ? dataAccess.functions : []
            filteredCap.map((cap) => {
                cap.id = cap.masterOrgFunctionId
                cap.label = cap.name ?? cap.masterOrgFunctionName ?? cap.function_name

                return cap
            })
            const sortedDivision = dataAccess.divisions && dataAccess.divisions.length
                ? dataAccess.divisions.sort((a, b) => a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase()))
                : []
            delete dataAccess['market']
            delete dataAccess['master_employee']
            dropdownValues = {
                ...dropdownValues,
                ...dataAccess
            }

            if (transfers && transfers.rows) {
                rows.concat(transfers.rows)
            }

            const showButtonToCreatorAfterApproval = rows.some(o => o.status)

            const dataTableData = {
                columns: columns, rows: rows, dropdownValues: dropdownValues, apiDelete: false,
                orgColumns: _.cloneDeep(table.columns), canAction: canAction, showButtonToCreatorAfterApproval: showButtonToCreatorAfterApproval,
                isDraft
            }

            if (setLocalState) {
                setLocalTransfers(dataTableData)
            }
            else {
                dispatch({
                    type: SET_TRANSFERS,
                    payload: dataTableData
                });
            }

            setIsLoading(false)

        }
        catch (e) { }
        setIsLoading(false)
        if (!setLocalState)
            dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function updateOrCreateTransferRequest(data, isDraft = true, callback = null) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        localStorage.removeItem('isInternal')
        try {
            const transferFormData = new FormData();
            const transfersWithAttachment = data.filter(l => l.hasOwnProperty('attachment') && l?.attachment !== null && l?.attachment?.length) ?? []
            const body = { transfers: data, isDraft }
            transferFormData.append('data', JSON.stringify(body))
            const { fileDetails, attachments } = await generateFileData(transfersWithAttachment, 'transaction_transfer')
            transferFormData.append('fileDetails', JSON.stringify(fileDetails))

            for (const a of attachments) {
                if (a instanceof File) transferFormData.append('attachment', a)
            }

            // const res = await request.post('emp-transfer', { transfers: data, isDraft })
            const res = await request.post('emp-transfer', transferFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
            if (res) {
                localStorage.removeItem('clonedHCS');
                localStorage.removeItem('HC')
                localStorage.removeItem('LR')
                dispatch({
                    type: SET_TRANSFERS,
                    payload: { rows: [], columns: transfers.columns, dropdownValues: transfers.dropdownValues }
                })
                toast.success(res.data.data)
                setIsLoading(false);
                dispatch({ type: SET_IS_LOADING, payload: false })
                if (callback) callback()
                else return navigate('/views/manage-supervisors')
            }
        }
        catch (e) { }
        setIsLoading(false);
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    return { getTransferRequest, updateOrCreateTransferRequest, isLoading, localTransfers }
}