import React, { useState, useEffect, useMemo, useRef } from "react";
import DataGrid, {
  Button,
  AsyncRule,
  Column,
  Lookup,
  LoadPanel,
  Paging,
  Pager,
  Scrolling, RequiredRule,
} from "devextreme-react/data-grid";
import CustomSkelton from "components/Skelton/CustomSkelton";
import { useNavigate } from "react-router-dom";
import { CustomTextArea, RenderCvItemsData } from "components/CustomDataGridComponents";
import { DEButton, createSanitizeAsyncRule } from "../../../utils/services/Helpers";
import MDTypography from "components/MDTypography";
import "./client-vitals.scss";
import { DateBox, TagBox } from "devextreme-react";
import moment from "moment";
import { Popover } from 'devextreme-react/popover';
import useManageDepartmentClients from "../../../utils/hooks/useManageDepartmentClients";
import { createLookupSortValueFunction } from "components/CustomDataGridComponents";

export default function ClientVitalsDataGrid({
  columns,
  rows,
  dropdownValues = null,
  isLoading,
  dropdownValue,
  tabValue
}) {
  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [colorsList, setColorsList] = useState([]);
  const [statusList, setStatusList] = useState([{ id: 1, name: 'Active', value: true }, { id: 2, name: 'Inactive', value: false }]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([true]);
  const [selectOptionsName, setSelectOptionsName] = useState([]);
  const [selectOptionsEntityOrClient, setSelectOptionsEntity] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [selectedEntityOrClient, setSelectedEntityOrClient] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const { disableEntityClients } = useManageDepartmentClients()
  const dataGridRef = useRef();
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  let navigate = useNavigate();

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([]);
      setDataColumns([]);
    };
  }, []);

  useEffect(() => {
    setDataSource(rows);
    setSelectOptionsName(generateSelectOptionsNames(rows));
    setSelectOptionsEntity(generateSelectOptionsEntity(rows));
  }, [rows]);

  useEffect(() => {
    setDataColumns(columns);
  }, [columns]);

  useEffect(() => {
    let data = rows;

    if (selectedColors.length) {
      data = filterByActiveLightId(selectedColors, data);
    }

    if (selectedNames.length) {
      data = filterDataSourceByNames(selectedNames, data);
    }

    if (selectedEntityOrClient.length) {
      data = filterDataSourceByEntityOrClient(selectedEntityOrClient, data);
    }

    if (selectedToDate) {
      data = filterDataSourceByDate(
        selectedFromDate,
        selectedToDate,
        data
      );
    }

    if (selectedStatus.length) {
      data = filterDataSourceByStatus(selectedStatus, data);
    }

    setDataSource(data);
  }, [selectedColors, selectedNames, selectedEntityOrClient, selectedToDate, selectedStatus, rows]);

  useEffect(() => {
    if (!dropdownValues || !dropdownValues?.masterCvCategoryId?.length) {
      return;
    }

    const categoryItems = dropdownValues.masterCvCategoryId;
    const colorItems = categoryItems.map((e) => e.master_cv_lights);
    const uniqueColors = colorItems.reduce((acc, array) => {
      array.forEach((obj) => {
        if (!acc.find((item) => item.id === obj.id)) {
          obj.label = obj.name
          obj.value = obj.id
          acc.push(obj);
        }
      });
      return acc;
    }, []);
    setColorsList(uniqueColors.sort((a, b) => a.sequence - b.sequence));
  }, [dropdownValues]);

  useEffect(() => { setSelectedToDate(null) }, [selectedFromDate])

  const filterByActiveLightId = (activeLightIds, arr) => {
    return arr
      .map((item) => {
        const newItem = { ...item };

        Object.keys(item).forEach((key) => {
          if (Array.isArray(item[key])) {
            newItem[key] = item[key].filter((subItem) =>
              activeLightIds.includes(subItem.activeLight)
            );
          }
        });

        return newItem;
      })
      .filter((item) =>
        Object.values(item).some((value) => Array.isArray(value) && value.length > 0)
      );
  };

  const filterDataSourceByNames = (names, arr) => {
    return arr
      .map((item) => {
        const newItem = { ...item };

        Object.keys(item).forEach((key) => {
          if (Array.isArray(item[key])) {
            newItem[key] = item[key].filter((subItem) => names.includes(subItem.name));
          }
        });

        return newItem;
      })
      .filter((item) =>
        Object.values(item).some((value) => Array.isArray(value) && value.length > 0)
      );
  };

  const filterDataSourceByEntityOrClient = (entitiyOrClient, arr) => {
    return arr.filter((item) => entitiyOrClient.includes(tabValue ? item.masterClientId : item.masterDepartmentId))
  };

  const filterDataSourceByStatus = (status, arr) => {
    return arr.filter((item) => status.includes(item.status))
  };

  const filterDataSourceByDate = (fromDate, toDate, arr) => {
    const fromDateFormatted = moment(fromDate).format('DD-MM-YYYY');
    const toDateFormatted = moment(toDate).format('DD-MM-YYYY');

    return arr.filter(item => {
      const itemDateTimestamp = item?.updatedAt
      if (!fromDate && toDate) return itemDateTimestamp <= toDateFormatted
      else return itemDateTimestamp && itemDateTimestamp >= fromDateFormatted && itemDateTimestamp <= toDateFormatted;
    });
  };

  function generateSelectOptionsNames(data) {
    const result = [];
    data.forEach((item) => {
      Object.values(item)
        .filter((value) => Array.isArray(value))
        .forEach((valueArray) => {
          valueArray.forEach((innerItem) => {
            if (typeof innerItem === "object" && !result.some((r) => r.name === innerItem.name)) {
              innerItem.label = innerItem.name;
              innerItem.value = innerItem.name;
              result.push(innerItem);
            }
          });
        });
    });
    return result?.sort((a,b) => {
      if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) return -1
      if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) return 1
      return 0
    });
  }

  function generateSelectOptionsEntity(data) {
    const result = [];
    const key = tabValue ? "masterClientId" : "masterDepartmentId"
    data.forEach((item) => {
      if (!result.some((r) => r.value === item[key])) {
        result.push({
          label: tabValue ? item?.master_client?.name1 : item?.department?.name,
          value: item[key]
        })
      }
    });
    return result?.sort((a,b) => {
      if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) return -1
      if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) return 1
      return 0
    });
  }

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "add",
        // disabled: false,
        visible: false,
        onClick: () => navigate("/views/manage-client-vital"),
      },
    });
  }

  // handle rendering of data-grid fields
  const renderField = (col, dropdownValues) => {
    if (col.type === "data") {
      return (
        <Column
          // width={"auto"}
          // minWidth={"200"}
          // bestFitWidth
          visible={col.is_visible}
          allowEditing={col.editable}
          allowSearch={col.is_searchable}
          allowFiltering={false}
          key={col.dataIndex}
          dataField={col.dataIndex}
          allowSorting={false}
          caption={col.title}
          alignment={"left"}
          cellRender={(props) => <RenderCvItemsData props={props} dropdownValue={dropdownValue} tabValue={tabValue} />}
          headerFilter={{
            dataSource: (data) => {
              data.dataSource.postProcess = function (results) {
                const newResult = [];
                const selectedRowData = dataSource.flatMap((e) => e[col.dataIndex]);

                results.forEach((result) => {
                  result.key?.forEach((e) => {
                    let row = selectedRowData.find((item) => item.name === e.name);

                    newResult.push({
                      key: row.name,
                      value: row,
                      text: row.name,
                    });
                  });
                });
                const uniqueNames = Array.from(new Set(newResult.map((item) => item.key)));
                const filteredArray = uniqueNames.map((name) =>
                  newResult.find((item) => item.key === name)
                );
                return filteredArray;
              };
            },
          }}
        />
      );
    }
    else if (col.type === "int") {
      return (
        <Column
          width={250}
          // minWidth={"200"}
          // bestFitWidth
          key={col.dataIndex}
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          alignment={"left"}
          setCellValue={function (rowData, value) {
            this.defaultSetCellValue(rowData, value);
          }}
          cellRender={({ data }) => (
            <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "normal", wordWrap: "break-word" }}>
              {dropdownValues && dropdownValues[col.dataIndex]
                ? dropdownValues[col.dataIndex].find((item) => item.id === data[col.dataIndex])?.label || ""
                : ""}
            </div>
          )}
          // Custom sorting based on lookup label
          calculateSortValue={createLookupSortValueFunction(dropdownValues && dropdownValues[col.dataIndex] ? dropdownValues[col.dataIndex] : [], col.dataIndex)}
        >
          <Lookup
            dataSource={
              dropdownValues && dropdownValues.hasOwnProperty(col.dataIndex)
                ? dropdownValues[col.dataIndex]
                : []
            }
            displayExpr="label"
            valueExpr="id"
          />
        </Column>
      );
    }
    else if (col.type === "button") {
      return (
        <Column
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          type="buttons"
          dataField={col.dataIndex}
          caption={col.title}
          fixed={false}
          width={"60"}
        >
          <Button
            component={(props) => {
              const id = `popover-${props.data.rowIndex}-${props.data.data.masterDepartmentId}`;
              const popoverRef = useRef(null);
              const [popoverVisible, setPopoverVisible] = useState(false);

              const showPopover = () => {
                setPopoverVisible(true);
              };

              const hidePopover = () => {
                setPopoverVisible(false);
              };

              return (
                <div>
                  <button
                    id={id}
                    className={`dx-icon dx-icon-${props.data.data.status ? 'unlock' : 'lock'}`}
                    title={props.data.data.status ? 'Disable' : 'Enable'}
                    onClick={(e) => { e.stopPropagation(); showPopover(); }}
                    style={{ fontSize: '20px', background: 'none', border: 'none', cursor: 'pointer', color: !props.data.data.status ? '#d9534f' : '#5cb85c' }}
                  />
                  <Popover
                    ref={popoverRef}
                    target={`#${id}`}
                    showEvent="none"
                    position="right"
                    width={300}
                    shading={true}
                    shadingColor="rgba(0, 0, 0, 0.5)"
                    visible={popoverVisible}
                    onHiding={hidePopover}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <p>Are you sure you want to {props.data.data.status ? 'disable' : 'enable'} this record?</p>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <DEButton
                          text="Yes"
                          type={props.data.data.status ? "danger" : 'success'}
                          onClick={async () => {
                            const data = {
                              masterDepartmentId: props.data.data.masterDepartmentId,
                              masterClientId: props.data.data.masterClientId,
                              status: !(props.data.data.status)
                            };
                            await disableEntityClients(data, dropdownValue, tabValue);
                            hidePopover();
                          }}
                        />
                        <div style={{ width: '10px' }} />
                        <DEButton
                          text="Cancel"
                          onClick={hidePopover}
                        />
                      </div>
                    </div>
                  </Popover>
                </div>
              );
            }}
          />
        </Column>
      );
    }
    else if (col.type === "string") {
      return (
        <Column
          width={"auto"}
          minWidth={"200"}
          bestFitWidth
          visible={col.is_visible}
          allowSorting={col.is_sortable}
          allowSearch={col.is_searchable}
          allowEditing={col.editable}
          key={col.dataIndex}
          dataField={col.dataIndex}
          caption={col.title}
          alignment={"left"}
        >
          <AsyncRule {...sanitizeAsyncRule} />
        </Column>
      );
    }
    else if (col.type === "textarea") {
      return <Column alignment={"left"} key={index} cssClass={"textAreaColumnWrap"} width={"auto"} editCellComponent={CustomTextArea} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }
    else {
      return (
        <Column
          width={"auto"}
          // minWidth={"200"}
          // bestFitWidth
          visible={col.is_visible}
          allowSorting={col.is_sortable}
          allowSearch={col.is_searchable}
          allowEditing={col.editable}
          key={col.dataIndex}
          dataField={col.dataIndex}
          caption={col.title}
          alignment={"left"}
        />
      );
    }
  };

  const handleLegendClicked = (light) => {
    if (selectedColors.includes(light.id)) {
      setSelectedColors((prevValue) => {
        return prevValue.filter((e) => e !== light.id);
      });
    }
    else {
      setSelectedColors((prevValue) => {
        return [...prevValue, light.id];
      });
    }
  };

  const enableDisableDiv = (isDisabled) => {
    const allDivs = document.querySelectorAll('[id="cvItemID"]');
    allDivs?.forEach(myDiv => {
      if (!isDisabled)
      {
        setTimeout(() => {
          myDiv.style.pointerEvents = 'auto';
          myDiv.style.opacity = '1';
        }, 500)
      }
      else
      {
        myDiv.style.pointerEvents = 'none';
        myDiv.style.opacity = '0.5';
      }
    })
  }
  const handleStartDateChange = (e) => {
    setSelectedFromDate(e.value === "" ? null : e.value)
    setSelectedToDate(null)
  }

  const tagTemplate = (tagData) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        className="lights-legend__color"
        style={{
          backgroundColor: tagData.color ? tagData.color : 'transparent',
          width: '20px',
          height: '20px',
          marginRight: '10px',
        }}
      ></div>
      <span>{tagData.label}</span>
    </div>
  );

  const colorTemplate = (light) => {

    return <div key={light.id} title={light.description}
      className={`lights-legend__item ${selectedColors.includes(light.id) ? "lights-legend__item--active" : ""
        }`}>
      <div className="lights-legend__color" style={{
        backgroundColor: light?.color ? light.color : "transparent",
      }} />
      {light?.name}
    </div>;
  }

  const Comp = useMemo(() => {
    try {
      return (
        <div className="cv-grid">
          <div className="filters-container">
            <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
              Filters
            </MDTypography>
            <div className="header-filters">
              <div className="header-filters__select">
                <TagBox
                  dropDownOptions={{ width: "auto" }}
                  placeholder={`Select ${tabValue ? "Client..." : "Agency/Practice.."}`}
                  dataSource={selectOptionsEntityOrClient}
                  value={selectedEntityOrClient}
                  defaultValue={selectedEntityOrClient}
                  valueExpr={"value"}
                  displayExpr={"label"}
                  showSelectionControls={true}
                  maxDisplayedTags={3}
                  showMultiTagOnly={false}
                  applyValueMode="instantly"
                  searchEnabled={true}
                  onValueChanged={(e) => setSelectedEntityOrClient(e.value)} />
              </div>
              <div className="header-filters__select">
                <TagBox
                  dropDownOptions={{ width: "auto" }}
                  placeholder={"Select KPIs..."}
                  dataSource={selectOptionsName}
                  value={selectedNames}
                  defaultValue={selectedNames}
                  valueExpr={"value"}
                  displayExpr={"label"}
                  showSelectionControls={true}
                  maxDisplayedTags={3}
                  showMultiTagOnly={false}
                  applyValueMode="instantly"
                  searchEnabled={true}
                  onValueChanged={(e) => setSelectedNames(e.value)} />
              </div>
              <div className="header-filters__select">
                <TagBox
                  dropDownOptions={{ width: "auto" }}
                  placeholder={"Select Colors..."}
                  dataSource={colorsList}
                  value={selectedColors}
                  defaultValue={selectedColors}
                  valueExpr={"id"}
                  displayExpr={"name"}
                  showSelectionControls={true}
                  maxDisplayedTags={5}
                  showMultiTagOnly={true}
                  applyValueMode="instantly"
                  searchEnabled={true}
                  itemRender={colorTemplate}
                  onValueChanged={(e) => setSelectedColors(e.value)} />
              </div>
              <div className="header-filters__select">
                <TagBox
                  dropDownOptions={{ width: "auto" }}
                  placeholder={"Select Status..."}
                  dataSource={statusList}
                  value={selectedStatus}
                  defaultValue={selectedStatus}
                  valueExpr={"value"}
                  displayExpr={"name"}
                  showSelectionControls={true}
                  maxDisplayedTags={5}
                  showMultiTagOnly={true}
                  applyValueMode="instantly"
                  searchEnabled={true}
                  onValueChanged={(e) => setSelectedStatus(e.value)} />
              </div>
              <div className="header-filters__select">
                <DateBox onOpened={e => enableDisableDiv(true)} onClosed={e => enableDisableDiv(false)} placeholder="Select From Date" displayFormat={"dd-MM-yyyy"} showClearButton={true}
                  value={selectedFromDate} onValueChanged={handleStartDateChange} type="date"
                  pickerType="calendar" openOnFieldClick={true} />
              </div>
              <div className="header-filters__select">
                <DateBox onOpened={e => enableDisableDiv(true)} onClosed={e => enableDisableDiv(false)} placeholder="Select To Date" displayFormat={"dd-MM-yyyy"} showClearButton={true}
                  value={selectedToDate}
                  onValueChanged={(e) => setSelectedToDate(e.value === "" ? null : e.value)} type="date"
                  pickerType="calendar" min={selectedFromDate} openOnFieldClick={true} />
              </div>
              <DEButton onClick={() => {
                setSelectedEntityOrClient([]);
                setSelectedNames([]);
                setSelectedFromDate(null);
                setSelectedToDate(null);
                setSelectedColors([]);
                setSelectedStatus([]);
              }} icon={"clear"}
                disabled={!selectedEntityOrClient?.length && !selectedNames?.length && !selectedToDate && !selectedFromDate && !selectedColors?.length && !selectedStatus?.length}
                title={"Clear filters"} />
            </div>
            {/*<div className="lights-legend__container filter-margin-top-10">
              {colorsList?.map((light) => (
                <div
                  key={light.id}
                  title={light.description}
                  onClick={() => {
                    handleLegendClicked(light);
                  }}
                  className={`lights-legend__item ${selectedColors.includes(light.id) ? "lights-legend__item--active" : ""
                  }`}
                >
                  <div
                    className="lights-legend__color"
                    style={{
                      backgroundColor: light?.color ? light.color : "transparent",
                    }}
                  ></div>
                  {light?.name}
                </div>
              ))}
            </div>*/}
          </div>
          <DataGrid
            id="ClientVitalsDataGrid"
            width={"100%"}
            onToolbarPreparing={onToolbarPreparing}
            allowColumnReordering
            showColumnLines
            showRowLines
            rowAlternationEnabled={false}
            showBorders
            allowColumnResizing
            dataSource={dataSource}
            keyExpr="id"
            disabled={isLoading}
            ref={dataGridRef}>
            <LoadPanel enabled={isLoading} visible={isLoading} />
            <Paging defaultPageSize={25} />
            <Pager visible showNavigationButtons showInfo displayMode={"full"} />
            <Scrolling showScrollbar="always" mode="standard" />
            {dataColumns && dataColumns.length
              ? dataColumns.map((col) => {
                return renderField(col, dropdownValues);
              })
              : null}
          </DataGrid>
        </div>
      );
    } catch (e) {
      console.log("error from Client Vitals Datagrid", e);
    }
  }, [dataSource, dataColumns, isLoading, selectedColors, selectedNames, selectedEntityOrClient, selectedFromDate, selectedToDate, selectedStatus]);

  return (
    <React.Fragment>
      <CustomSkelton>{Comp}</CustomSkelton>
    </React.Fragment>
  );
}
