import React from "react";
import { Grid } from "@mui/material";
import Select from "react-select";
import { selectCustomStyles } from "utils/services/Helpers";



export function NotificationCreatorStep({
  workflowStepData,
  workflowStep,
  isLoading,
  setWorkflowStep,
  workflowAccessData,
  workflowAccesses,
  setWorkflowAccess,
  functionalAccessData,
  functionalAccesses,
  setFunctionalAccesses
}) {
  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Select
            options={workflowStepData}
            isClearable
            value={workflowStep}
            styles={selectCustomStyles}
            isSearchable
            isLoading={isLoading}
            placeholder="Please Select Access Type..."
            menuPortalTarget={document.body}
            onChange={setWorkflowStep}
          />
        </Grid>
        {workflowStep && (
          <Grid item xs={12} sm={6}>
            {workflowStep.value === "workflow" ? (
              <Select
                options={workflowAccessData}
                isClearable
                value={workflowAccesses}
                styles={selectCustomStyles}
                isSearchable
                isLoading={isLoading}
                placeholder="Please Select Value..."
                menuPortalTarget={document.body}
                onChange={setWorkflowAccess}
                isMulti
              />
            ) : (
              <Select
                options={
                functionalAccessData
              }
                isClearable
                value={functionalAccesses}
                styles={selectCustomStyles}
                isSearchable
                isMulti
                isLoading={isLoading}
                placeholder="Please Select Value..."
                menuPortalTarget={document.body}
                onChange={setFunctionalAccesses}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
