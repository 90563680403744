import React, { useContext, useEffect, useState } from "react";
import 'antd/dist/antd.css';
import usePermissions from "utils/hooks/usePermissions";
import useRoles from "utils/hooks/useRoles";
import { Context } from "../../../utils/context/store/Store";
import PermissionDataGrid from "./DataTable/PermissionDataGrid";
import { SET_ROLE_PERMISSIONS } from "utils/context/store/Constants";
import { ConsoleSqlOutlined } from "@ant-design/icons";

const PermissionsModal = ({ role, masterModuleId, roleData, page_permissions, routeKey }) => {

  const [{ role_permissions }, dispatch] = useContext(Context)
  const { updateOrCreateRoles } = useRoles(routeKey)
  const { getPermissionsByRole, deletePermissions, updateOrCreatePermissions, isLoading } = usePermissions(role, masterModuleId, routeKey)
  let rolePermissions = role_permissions ? role_permissions : {}

  useEffect(async () => {
    dispatch({
      type: SET_ROLE_PERMISSIONS,
      payload: {}
    })
    await getPermissionsByRole(role, masterModuleId)
  }, [])

  useEffect(() => { }, [role, roleData, masterModuleId])

  return (
    <>
      {
        role
          ? <PermissionDataGrid loading={isLoading} routeKey={routeKey} defaultKey={role ? "id" : "key"} rows={rolePermissions ? rolePermissions.rows : {}} columns={rolePermissions ? rolePermissions.columns : {}} roleData={roleData} functionalAccessId={role} postData={updateOrCreatePermissions} handleDelete={deletePermissions} permissions={page_permissions} allowAdding={false} showSubmitButton={true} allowUpdating={true} allowDeleting={false} allowSelection={false} />
          : <PermissionDataGrid loading={isLoading} routeKey={routeKey} defaultKey={"key"} rows={roleData && roleData.page_permissions && roleData.page_permissions.length ? roleData.page_permissions : rolePermissions.rows} columns={rolePermissions.columns} functionalAccessId={role} postData={updateOrCreateRoles} handleDelete={deletePermissions} isForNewRole={true} showSubmitButton={false} allowDeleting={false} allowSelection={false} allowAdding={false} roleData={roleData} permissions={page_permissions} />
      }
    </>
  );
};

export default PermissionsModal;