import { useEffect } from 'react';
import BaseLayOut from '../../components/Layout/BaseLayOut';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import ModuleDataGrid from './DataTable/ModuleDataGrid';
import useModule from '../../utils/hooks/useModule';

export default function ModuleManagement({ routeKey, pageName }) {
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const permissions = getPermissionsForPage();

  const { getModules, updateOrCreateModules, setMasterModules, masterModules, isLoading } =
    useModule(routeKey);

  useEffect(() => {
    const fn = async () => await getModules();
    fn();
    return () => {
      setMasterModules({});
    };
  }, []);

  return (
    <BaseLayOut pageTitle={pageName}>
      <ModuleDataGrid
        rows={masterModules?.rows?.length ? masterModules.rows : []}
        columns={masterModules?.columns?.length ? masterModules.columns : []}
        permissions={permissions}
        isLoading={isLoading}
        dropDownData={masterModules.dropdownData}
        postData={updateOrCreateModules}
      />
    </BaseLayOut>
  );
}
