import { useState } from "react";
import request from "../services/Http";
import { useIsLoading } from "./useIsLoading";
import { toast } from "react-toastify";

export default function usePerformanceMetric(){
  const [metrics, setMetrics] = useState([])
  const [isLightHouseRunning, setIsLightHouseRunning] = useState(false)
  const {isLoading, setIsLoading} = useIsLoading()

  async function getMetrics(){
    setIsLoading(true)
    try {
      const res = await request.get('metrics')
      if (res)
      {
        setMetrics(res.data.data)
        setIsLightHouseRunning(res.data.isLightHouseRunning)
      }
    }
    catch (e) {}
    finally {
      setIsLoading(false)
    }
  }

  async function startAudit(){
    setIsLoading(true)
    try {
      const res = await request.post('metrics/audit-fe')
      if (res)
      {
        toast.success(res.data.data)
        await getMetrics()
        setIsLightHouseRunning(true)
      }
    }
    catch (e) {}
    finally {
      setIsLoading(false)
    }
  }

  return {metrics, getMetrics, isLoading, isLightHouseRunning, startAudit}
}