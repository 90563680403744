import { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';
import usePagePermissions from 'utils/hooks/usePagePermissions';
import './components/manage-transfer.scss';
import BaseLayOut from 'components/Layout/BaseLayOut';
import TransferDataGrid from './components/TransferDataGrid';
import { Context } from "utils/context/store/Store";
import useManageTransfer from "utils/hooks/useManageTransfer";
import { SET_TRANSFERS } from "utils/context/store/Constants";

export default function ManageTransfer({ routeKey, pageName }) {
  const { id } = useParams();
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const { getTransferRequest, isLoading, } = useManageTransfer('manage-supervisor');
  const [dgData, setDgData] = useState([]);
  const [{ transfers }, dispatch] = useContext(Context);
  const pageTitle = pageName === 'manage-hc' ? 'manage-supervisor' : 'Manage Supervisor';

  useEffect(async () => {
    localStorage.removeItem("resize");
    localStorage.removeItem("LR");
    localStorage.removeItem("HC");
    await getTransferRequest(false, id ?? null)
    return () => {
      localStorage.removeItem("resize");
      localStorage.removeItem("LR");
      localStorage.removeItem("HC");
      dispatch({
        type: SET_TRANSFERS,
        payload: {}
      })
    }
  }, []);
  useEffect(() => { }, [dgData]);

  return (
    <BaseLayOut pageTitle={pageTitle}>
      <div className="manage-headcount">
        {
          transfers
            ? <TransferDataGrid
              columns={transfers?.columns || []}
              rows={transfers?.rows ?? []}
              dropDownData={transfers?.dropdownValues || {}}
              isLoading={isLoading}
              routeKey={routeKey}
              permissions={getPermissionsForPage(routeKey)}
              allowAdding={transfers?.isDraft ? true : !id}
              showButton={!id}
              allowSelection={!!id}
              apiCallBack={async () => await getTransferRequest(id)}
              isDraft={transfers?.isDraft}
              groupId={id}
              setDgData={setDgData}
            />
            : null
        }
      </div>
    </BaseLayOut>
  );
}
