import * as React from "react";
import { useEffect, useMemo, useRef, useState, useContext } from "react";
import DataGrid, {
  AsyncRule,
  Button,
  Column,
  Editing,
  Export,
  HeaderFilter,
  Lookup, Pager, Paging,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import {
  CustomDateBox, CustomFileInput,
  CustomNumberBox, CustomTextArea,
} from "../../../components/CustomDataGridComponents";
import { handleDeleteRequest, DEButton, showDialogBox } from "../../../utils/services/Helpers";
import MDAlert from "../../../components/MDAlert";
import MDTypography from "../../../components/MDTypography";
import Divider from "@mui/material/Divider";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import { onRowExpanding } from "../../../utils/services/DatagridHelpers";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import { Context } from "utils/context/store/Store";
const _ = require('lodash')
import "assets/datatable-css/datagrid.css"
import DataSecurity from "../../../components/Modal/DataSecurity/DataSecurity";
import { toast } from "react-toastify";
import BulkUploaderModal from "components/Modal/BulkUploader/BulkUploaderModal";
import ManageOnBehalf from "../modal/ManageOnBehalf";
import { createSanitizeAsyncRule } from "utils/services/Helpers";


export default function UserDataGrid({ data, rows, columns, dropDownData, defaultKey = "id", isLoading, permissions, allowAdding = true, allowUpdating = true, allowSelection = false, allowDeleting = true, postData, handleDelete, masterModuleId, userId, isForDataAccess = false, isForLevelAccess = false, isForUser = false, isForFunctionalAccess = false, showRestButtons = false, orgRows, routeKey, bulkUploadApi, uploadTemplateLink, orgStructureLink, apiCallback, tableName, allowBulkUploading = false }) {

  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasDataChanged, setHasDataChanged] = useState(false)
  const [autoWidth, setAutoWidth] = useState(true)
  const dataGridRef = useRef();
  const [addEditMode, setAddEditMode] = useState(false)
  const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState(false)
  const [{ users }, dispatch] = useContext(Context)
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
    }
  }, []);
  useEffect(() => { }, [dataSource]);
  useEffect(() => { }, [dataColumns]);
  useEffect(() => { setDataSource(rows) }, [rows]);
  useEffect(() => {
    setDataColumns(columns)
  }, [columns]);

  /**
   * @param e
   * function use perform operation when data-grid editor is not prepared e.g enable/disable field etc
   **/
  async function onEditorPreparing(e) {

    if (e && e.dataField === "masterOrgEntityId") {
      let gridInstance = dataGridRef.current.instance;
      let editRowKey = gridInstance.option("editing.editRowKey");
      let index = gridInstance.getRowIndexByKey(editRowKey);
      const masterOrgDivisionId = gridInstance.cellValue(index, "masterOrgDivisionId");
      if (!masterOrgDivisionId)
        e.editorOptions.disabled = true
      else
        e.editorOptions.disabled = false
    }

    if (e && e.dataField === "masterLevelId") {
      let gridInstance = dataGridRef.current.instance;
      let editRowKey = gridInstance.option("editing.editRowKey");
      let index = gridInstance.getRowIndexByKey(editRowKey);
      const masterOrgDivisionId = gridInstance.cellValue(index, "masterOrgDivisionId");

      if (!masterOrgDivisionId)
        e.editorOptions.disabled = true
      else
        e.editorOptions.disabled = false

    }

    if (e && e.dataField === "masterCountryId") {
      let gridInstance = dataGridRef.current.instance;
      let editRowKey = gridInstance.option("editing.editRowKey");
      let index = gridInstance.getRowIndexByKey(editRowKey);
      const masterOrgEntityId = gridInstance.cellValue(index, "masterOrgEntityId");
      if (!masterOrgEntityId)
        e.editorOptions.disabled = true
      else
        e.editorOptions.disabled = false
    }

    if (e && e.dataField === "masterOrgVerticalId") {
      let gridInstance = dataGridRef.current.instance;
      let editRowKey = gridInstance.option("editing.editRowKey");
      let index = gridInstance.getRowIndexByKey(editRowKey);
      const masterCountryId = gridInstance.cellValue(index, "masterCountryId");
      if (!masterCountryId)
        e.editorOptions.disabled = true
      else
        e.editorOptions.disabled = false
    }

  }

  /**
   * @param selectedRowKeys
   * @param selectedRowsData
   * used to get selected rows detail of data-grid
   **/
  function onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
    setSelectedRowKeys(selectedRowsData)
  }

  /**
   * get selected rows
   **/
  const hasSelected = selectedRowKeys.length > 0

  /**
   * @param col
   * @param dropDownData
   * function use to handle rendering of fields
   **/
  function renderField(col, dropDownData) {
    if (col.type === "select") {
      if (!col.hasOwnProperty("filtrationKey")) {
        return <Column editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
          allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
          setCellValue={
            function (rowData, value) {

              if (col.dataIndex === "masterOrgDivisionId") {
                rowData['masterOrgEntityId'] = null
                rowData['masterCountryId'] = null
                rowData['masterOrgVerticalId'] = null
                rowData['masterLevelId'] = []
              }

              if (col.hasOwnProperty("bindedTo"))
                rowData[col.bindedTo] = null;
              this.defaultSetCellValue(rowData, value);
            }
          } >
          {
            col.required ? <RequiredRule /> : null
          }
          < Lookup allowClearing
            dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : []
            }
            displayExpr="label" valueExpr="id" />
        </Column>;
      }
      else if (col.hasOwnProperty("filtrationKey")) {
        return <Column editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
          allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
            this.defaultSetCellValue(rowData, value);
            if (col.hasOwnProperty("bindedTo")) {
              rowData[col.bindedTo] = null;
            }
          }}>
          <Lookup allowClearing dataSource={(options) => {
            return {
              store: dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : [],
              filter: options.data ? [col.filtrationKey, "=", options.data[col.filtrationKey]] : null,
            };
          }} displayExpr="label" valueExpr="id" />
          {
            col.required ? <RequiredRule /> : null
          }
        </Column>;
      }
    }
    else if (col.type === "multi-select") {
      return <Column width={"250"} editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomDTagThisComp}
        cellTemplate={(container, options) => {
          const noBreakSpace = "\u00A0";
          const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(", ");
          container.textContent = text || noBreakSpace;
          container.title = text;
        }}
        calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
          if (target === "search" && typeof (filterValue) === "string") {
            return [col.dataIndex, "contains", filterValue];
          }
          return function (data) {
            return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
          };
        }}
      >
        <Lookup allowClearing
          dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : null}
          displayExpr="label" valueExpr="id" />
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "checkbox") {
      return <Column dataType="boolean"        
        showEditorAlways={true} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value)
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "actions") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable}
        type="buttons" dataField={col.dataIndex} caption={col.title} fixed={false} width={"auto"}>
        <Button name="delete" icon={'trash'} visible={(e) => e && e.row && e.row.data && e.row.data.newRow === true && showRestButtons} />
        <Button name="view" icon={'eye'} visible={!showRestButtons} component={(props) => {
          return <>
            {
              permissions && (permissions.canCreate || permissions.canView)
                ?
                <>
                  <DataSecurity isDisabled={props.data.data.hasOwnProperty('newRow') || !props.data.data.status} permissions={permissions} name={props.data.data.name} userId={props.data.data.id} routeKey={routeKey} />
                </>
                : null
            }
          </>
        }} />
        <Button hint="Clone" icon="copy" visible={(e) => permissions && (permissions.canCreate || permissions.canView)} component={(props) => {
          return <>
            {
              props?.data?.data?.status
                ? <ManageOnBehalf dataChange={setHasDataChanged} data={props.data.data} users={rows.filter(r => r.id !== props.data.data.id && r.status === true)} onBehalfUserId={props.data.data?.onBehalfUserId} postData={postData} />
                : <DEButton stylingMode={'contained'} type={'success'} text={'Activate User'} onClick={() => showDialogBox(() => {
                  props.data.data.status = true
                  const f = async () => await postData([props.data.data])
                  f()
                  // setHasDataChanged(true)
                }, 'warning', 'Yes, continue', null, 'Are you sure you want to activate this user?')}
                />
            }
          </>
        }} />
      </Column>
    }
    else if (col.type === "date") {
      return <Column dataType={"date"} editCellComponent={CustomDateBox} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "datetime") {
      return <Column dataType={"datetime"} editCellComponent={CustomDateBox} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "int") {
      return <Column dataType={col.type} /* editCellComponent={CustomNumberBox} */ allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} editCellComponent={(props) => <CustomNumberBox props={props.data} canEdit={col.editable} />}>
        {
          col.required ? <RequiredRule /> : null
        }
        {
          col.dataIndex !== "id"
            ? <AsyncRule message={"Value should not exceed more than 15 digits"} validationCallback={async (e) => {
              return e && e.value && e.value.toString().length <= 15
            }} />
            : null
        }
      </Column>;
    }
    else if (col.type === "file") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} type={"buttons"} fixed={false} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomFileInput} />;
    }
    else if (col.type === "textarea") {
      return <Column encodeHtml={col.encodeHtml} editCellComponent={CustomTextArea} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>
    }
    else if (col.type === "string") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>
    }
    else {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
  }

  /**
   * @param e
   * initialize new row in the data-grid
   **/
  const onInitNewRow = (e) => {
    window.scrollTo(0, 0)
    e.data.status = true
    e.data.isRecruiter = false
    e.data.newRow = true
    e.data.userId = userId
    e.data.masterModuleId = masterModuleId
    setAutoWidth(false)
    setAddEditMode(true)
  }

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "upload",
          text: "BULK UPLOAD",
          visible: permissions && permissions.canCreate && allowBulkUploading,
          onClick: function () { setBulkUploadModalVisible(true) },
        }
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SUBMIT",
          disabled: !hasDataChanged,
          visible: allowAdding,
          onClick: async () => {
            if (dataSource.length !== new Set(dataSource.map(user => user.username)).size) {
              toast.error("Duplicate email addresses found!")
            }
            else {
              if (dataSource?.filter(d => d.hasOwnProperty('rowEdited') && !d.status && d?.onBehalfUserId === null)?.length) {
                // toast.error("Please fill Manage on behalf as you are deactivating user")
                showDialogBox(async () => {
                  await postData(dataSource)
                  setHasDataChanged(false)
                }, 'warning', 'No, continue', null, 'You are deactivating some users from the system and they will not be able to access it hereafter!')
              }
              // else showDialogBox(async () => {
              //   await postData(dataSource)
              //   setHasDataChanged(false)
              // }, 'warning', 'Yes, continue', null, 'You are deactivating some users from the system and they will not be able to access it hereafter!')
              else {
                await postData(dataSource)
                setHasDataChanged(false)
              }
            }
          }
        }
      }
    );
  }

  /**
   * @param e
   * Manage post api call to save data and validation for if any request is missing headcount while having replacement true
   **/
  function onSave(e) {
    if (e && e.changes.length) {
      if (e.changes[0].type === "remove") {
        const dsCopy = [...dataSource]
        const filteredDs = dsCopy.length ? dsCopy.filter(ds => ds.id !== e.changes[0].key) : []
        if (filteredDs && filteredDs.length) {
          setDataSource(filteredDs)
          setHasDataChanged(true)
        }

      }
      else {
        if (e && e.changes[0].type === "update")
          e.changes[0]["data"]["rowEdited"] = true

        const dd = e.changes[0].data;
        let finalData = []
        finalData.push(dd)
        let result = []

        if (dataSource && dataSource.length) {
          result = _.unionBy(finalData, dataSource);
        }
        else result.push(dd)

        setDataSource(result)
        setHasDataChanged(true)
      }
    }
    setAutoWidth(true)
    setAddEditMode(false)
  }

  /**
   * @param e
   * validate row before saving
   **/
  function onRowValidating(e) {
    // if (e && e.isValid) {
    //   if (e.newData) {
    //     const { username } = e.newData
    //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    //     if (username && emailRegex.test(username)) {
    //       const d = [...dataSource];
    //       if (username && d.length) {
    //         const c = d.some((r) => r.username === username);
    //         if (c) {
    //           e.isValid = false;
    //           e.errorText = "Duplicate Combination Found";
    //         }

    //       }
    //     }
    //     else {
    //       e.isValid = false;
    //       e.errorText = "Invalid username";
    //     }

    //     /*const f = d.filter(s => s.id !== e.key && s.masterOrgDivisionId === masterOrgDivisionId && s.masterOrgEntityId === masterOrgEntityId && s.masterCountryId === masterCountryId && s.masterOrgVerticalId === masterOrgVerticalId)
    //     if (f && f.length){
    //       e.isValid = false;
    //       e.errorText = "Duplicate Combination Found"
    //     }*/
    //   }
    // }
  }

  /**
   * function used to handle delete part of data-grid
   **/
  const manageDelete = async () => {
    const newRecords = dataSource.filter(ds => ds.hasOwnProperty('newRow'))
    let newData = [...dataSource]
    const deleteFromApi = selectedRowKeys.filter(a => a.hasOwnProperty('id') && !a.hasOwnProperty('newRow') && a.isUserDeleteAble)
    const deleteFromTable = selectedRowKeys.filter(a => a.hasOwnProperty('newRow'))
    const result = deleteFromApi.map(a => a.id);
    const notDeleteAbleUser = selectedRowKeys.filter(a => a.hasOwnProperty('id') && !a.hasOwnProperty('newRow') && !a.isUserDeleteAble)
    if (notDeleteAbleUser?.length) toast.error(`${notDeleteAbleUser?.length} users cannot be deleted as they have performed activities in the system. Please review your selection.`)
    else
      handleDeleteRequest(async () => {
        if (deleteFromApi.length > 0 && deleteFromTable.length > 0) await handleDelete(result, userId, selectedRowKeys.length === dataSource.length, masterModuleId)
        else if (deleteFromApi && deleteFromApi.length > 0) await handleDelete(result, userId, selectedRowKeys.length === dataSource.length, masterModuleId)
        else if (deleteFromTable && deleteFromTable.length > 0) {
          deleteFromTable.map(a => {
            newData = newData.filter((item) => item.key !== a.key);
          })
          setDataSource(newData)
        }
        setHasDataChanged(false)
        setSelectedRowKeys([])
      })
  };

  /**
   * custom function using useMemo to avoid re-renders unless the states listed are changed
   **/
  const Comp = useMemo(() => {
    try {
      return <div id="data-grid-demo">
        {
          hasSelected > 0
            ?
            <React.Fragment>
              <br />
              <MDAlert color="light">

                <MDTypography variant="subtitle2">
                  {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? "item" : "items"}`}
                </MDTypography>

                <Divider orientation="vertical" color="dark" flexItem />

                <MDBox>
                  <Grid container spacing={2}>
                    {
                      permissions && permissions.canDelete
                        ? <Grid item >
                          <DEButton stylingMode={"contained"} type={"danger"} icon="trash" onClick={() => manageDelete()} />
                        </Grid> : null
                    }
                  </Grid>
                </MDBox>
              </MDAlert>
            </React.Fragment>
            : null
        }
        <BulkUploaderModal title={"User Profile - Bulk Upload"} isModalVisible={bulkUploadModalVisible} setIsModalVisible={setBulkUploadModalVisible} bulkUploadApi={bulkUploadApi} apiCallback={apiCallback} tableName={tableName} downloadLink={uploadTemplateLink} orgStructureLink={orgStructureLink} />
        <DataGrid id="grid"
          onToolbarPreparing={onToolbarPreparing} showBorders={true}
          onRowExpanding={onRowExpanding}
          columnAutoWidth={true} onSaved={onSave}
          showColumnLines={true} showRowLines={true} rowAlternationEnabled={true}
          ref={dataGridRef} onInitNewRow={onInitNewRow}
          onSelectionChanged={onSelectionChanged} allowColumnResizing={true}
          disabled={isLoading} dataSource={dataSource} key={defaultKey ?? "id"} keyExpr={defaultKey ?? "id"}
          onEditorPreparing={onEditorPreparing}
          onRowValidating={onRowValidating}>
          {
            addEditMode ? null : <Scrolling showScrollbar="always" mode="standard" />
          }

          {
            allowSelection ?
              <Selection allowSelectAll={true} mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} />
              : null
          }
          <HeaderFilter visible={true} allowSearch={true} />
          <SearchPanel visible={true} />
          <Paging defaultPageSize={25} />
          <Pager visible={true} showNavigationButtons={true} showInfo={true} displayMode={"full"} />
          <Export enabled={true} allowExportSelectedData={true} />
          <Editing newRowPosition={"first"} refreshMode={"repaint"} mode="cell" allowUpdating={allowUpdating} allowAdding={allowAdding} allowDeleting={allowDeleting} />
          {
            dataColumns && dataColumns.length ? dataColumns.map((d) => renderField(d, dropDownData)) : null
          }
        </DataGrid>
      </div>
    }
    catch (e) {
    }
  }, [dataSource, dataColumns, dropDownData, hasDataChanged, selectedRowKeys, data, isLoading, autoWidth, addEditMode, bulkUploadModalVisible])

  return (
    <React.Fragment>
      <DetectNavigationBlocker setIsDataChanged={setHasDataChanged} isDataChanged={hasDataChanged} />
      {Comp}
    </React.Fragment>
  );
}