import { useIsLoading } from "./useIsLoading";
import request from "../services/Http";
import { toast } from "react-toastify";
import useRoles from "./useRoles";
import { useState } from "react";

export default function useFunctionalAccessFilterPreference(routeKey){
  const {isLoading, setIsLoading} = useIsLoading()
  const { getRoles } = useRoles(routeKey)
  const [filters, setFilters] = useState([])

  async function getUserFilters()
  {
    setIsLoading(true)
    try {
      const res = await request.get('functional-access-filter-preference')
      if (res)
      {
        setIsLoading(false)
        setFilters(res.data.data)
      }
    }catch (e){
      setIsLoading(false)
    }
  }

  async function createUpdateFilterPreferences(filters, functionalAccessId)
  {
    setIsLoading(true)
    try {
      const res = await request.post('functional-access-filter-preference', {filters, functionalAccessId})
      if (res)
      {
        toast.success(res.data.data)
        setIsLoading(false)
        // await getRoles()
      }
    }catch (e) {}
    setIsLoading(false)

  }

  return {getUserFilters, createUpdateFilterPreferences, isLoading, filters}
}