import { createContext, useState } from 'react';

export const CapabilityContext = createContext(undefined);

export const CapabilityProvider = ({ children }) => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedPractice, setSelectedPractice] = useState('');
  const [selectedCapability, setSelectedCapability] = useState('');
  const [selectedVertical, setSelectedVertical] = useState('');
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [selectedNode, setSelectedNode] = useState();

  const [isReset, setIsReset] = useState(false);

  const reset = () => {
    setSelectedPractice('');
    setSelectedCapability('');
    setSelectedVertical('');
    setSelectedCompany(null);
  };

  const hardReset = () => {
    reset();
    setIsReset(true);
  };

  return (
    <CapabilityContext.Provider
      value={{
        selectedCompany,
        setSelectedCompany,
        selectedPractice,
        setSelectedPractice,
        selectedCapability,
        setSelectedCapability,
        selectedVertical,
        setSelectedVertical,
        reset,
        hardReset,
        isReset,
        setIsReset,
        showContactInfo,
        setShowContactInfo,
        selectedNode,
        setSelectedNode
      }}
    >
      {children}
    </CapabilityContext.Provider>
  );
};
