const filterAndMap = (data, filterField, mapField, filterValues) =>
  data.filter((e) => filterValues.includes(e[filterField])).map((e) => e[mapField]);

const strategies = {
  divisionSelected: (value, prevValues, userDataAccess) => {
    const divisionIds = value.transactions.map((e) => e.masterOrgDivisionId);
    prevValues["masterOrgDivisionId"] = divisionIds;

    const entityIds = filterAndMap(
      userDataAccess["masterOrgEntityId"],
      "masterOrgDivisionId",
      "masterOrgEntityId",
      divisionIds
    );
    prevValues["masterOrgEntityId"] = entityIds;
  },

  entitySelected: (value, prevValues, userDataAccess) => {
    const entityIds = value.transactions.map((e) => e.masterOrgEntityId);
    prevValues["masterOrgEntityId"] = entityIds;

    const divisionIds = filterAndMap(
      userDataAccess["masterOrgEntityId"],
      "masterOrgEntityId",
      "masterOrgDivisionId",
      entityIds
    );
    prevValues["masterOrgDivisionId"] = divisionIds;
  },

  verticalSelected: (value, prevValues, userDataAccess) => {
    const verticalIds = value.transactions.map((e) => e.masterOrgVerticalId);

    let filteredVerticals = userDataAccess["masterOrgVerticalId"].filter((e) =>
      verticalIds.includes(e["masterOrgVerticalId"])
    );

    if (value.formula.masterOrgEntityId) {
      const entityIds = value.transactions.map((e) => e.masterOrgEntityId);
      filteredVerticals = filteredVerticals.filter((e) => entityIds.includes(e.masterOrgEntityId));
    } else if (value.formula.masterOrgDivisionId) {
      const divisionIds = value.transactions.map((e) => e.masterOrgDivisionId);
      filteredVerticals = filteredVerticals.filter((e) =>
        divisionIds.includes(e.masterOrgDivisionId)
      );
    }

    prevValues["masterOrgVerticalId"] = filteredVerticals.map((e) => e.masterOrgVerticalId);
    if (!value.formula.masterOrgEntityId) {
      prevValues["masterOrgEntityId"] = filteredVerticals.map((e) => e.masterOrgEntityId);
    }
    if (!value.formula["masterOrgDivisionId"])
      prevValues["masterOrgDivisionId"] = filteredVerticals.map((e) => e.masterOrgDivisionId);
  },

  functionSelected: (value, prevValues, userDataAccess, combinations) => {
    const functionIds = value.transactions.map((e) => e.masterOrgFunctionId);

    const filteredFunctions = userDataAccess["masterOrgFunctionId"].filter((e) =>
      functionIds.includes(e["masterOrgFunctionId"])
    );
    let divisionExists = false;
    const levelObject = [...combinations.entries()]
      .map(([key, value]) => value)
      .find((e) => e.type.value === "LEVEL");

    if (levelObject && Object.keys(levelObject).length) {
      divisionExists =
        levelObject.formula?.masterOrgDivisionId || levelObject.formula?.masterOrgEntityId;
    }

    prevValues["masterOrgFunctionId"] = filteredFunctions.map((e) => e.masterOrgFunctionId);
    if (!value.formula.masterOrgEntityId && !divisionExists)
      prevValues["masterOrgEntityId"] = filteredFunctions.map((e) => e.masterOrgEntityId);
    if (!value.formula.masterOrgDivisionId && !divisionExists)
      prevValues["masterOrgDivisionId"] = filteredFunctions.map((e) => e.masterOrgDivisionId);
  },
};

export const validateOrgCountry = (value, prevValues, userDataAccess, combinations) => {
  const { masterOrgDivisionId, masterOrgEntityId, masterOrgVerticalId, masterOrgFunctionId } =
    value.formula;

  if (masterOrgDivisionId) {
    strategies.divisionSelected(value, prevValues, userDataAccess);
  }
  if (masterOrgEntityId) {
    strategies.entitySelected(value, prevValues, userDataAccess);
  }
  if (masterOrgVerticalId) {
    strategies.verticalSelected(value, prevValues, userDataAccess);
  }
  if (masterOrgFunctionId) {
    strategies.functionSelected(value, prevValues, userDataAccess, combinations);
  }

  const countryIds = userDataAccess["masterCountryId"]
    .filter(
      (e) =>
        prevValues["masterOrgDivisionId"].includes(e.masterOrgDivisionId) &&
        prevValues["masterOrgEntityId"].includes(e.masterOrgEntityId)
    )
    .map((e) => e.masterCountryId);

  prevValues["masterCountryId"] = countryIds;

  return prevValues;
};
