import * as d3 from 'd3';

export const isDescendant = (node, target) => {
  if (!node) return false;
  if (node.data.id === target.data.id) return true;
  if (node.parent) return isDescendant(node.parent, target);
  return false;
};

export const getDimensions = (svg) => {
  const width = +svg.style('width').replace('px', '');
  const height = +svg.style('height').replace('px', '');
  return { width, height };
};

export const createGElement = (svg, width, height) =>
  svg.append('g').attr('transform', `translate(${width / 2}, ${height / 2}) scale(0.4) rotate(0)`);

export const handleZoom = (svg, g, scale, transition) => {
  const zoom = d3.zoom().scaleExtent([0.3, 2]);
  const { width, height } = getDimensions(svg);
  let zoomTransform = {
    k: scale || 1,
    x: transition?.x || width / 2,
    y: transition?.y || height / 2
  };

  svg
    .call(zoom.transform, d3.zoomIdentity.translate(zoomTransform.x, zoomTransform.y).scale(zoomTransform.k || 0.4))
    .call(
      zoom.on('zoom', (event) => {
        const transformString = g.attr('transform');
        const match = /rotate\((-?\d+(.\d+)?)/.exec(transformString);
        g.attr(
          'transform',
          `translate(${event.transform.x}, ${event.transform.y}) scale(0.4) rotate(${
            match ? parseFloat(match[1]) : 0
          }) scale(${event.transform.k})`
        );
        zoomTransform = {
          k: event.transform.k,
          x: event.transform.x,
          y: event.transform.y
        };
      })
    );
};

export const zoomOut = (gElement, svgElement, currentRotation, callback) => {
  if (!svgElement) return;
  const { width, height } = getDimensions(svgElement);
  const zoomTransform = {
    k: 0.4,
    x: width / 2,
    y: height / 2
  };
  gElement
    .transition()
    .duration(1700)
    .attr('transform', `translate(${zoomTransform?.x}, ${zoomTransform?.y}) scale(0.4) rotate(${currentRotation})`)
    .on('end', callback);
};

export const rotateParentNode = (rotate) => {
  const parentNode = document.getElementById('node-0');
  const parentNodeTransform = `rotate(${-rotate}) translate(0,0)`;
  parentNode?.setAttribute('transform', parentNodeTransform);
};

export const getSvgDimensions = (svgElement) => ({
  width: +svgElement.style('width').replace('px', ''),
  height: +svgElement.style('height').replace('px', '')
});

export const normalizeRotation = (rotation) => ((rotation % 360) + 360) % 360;
