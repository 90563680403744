import request from "../services/Http"
import React, { useContext } from "react";
import { Context } from "../context/store/Store";
import { SET_IS_LOADING, SET_ROLES, SET_ROLES_COLUMNS } from "../context/store/Constants";
import {
    setColumnsForDataTable,
    isJSONValid,
    getTableColumns,
    getPageProperties,
} from "../services/Helpers";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIsLoading } from "./useIsLoading";

export default function useRoles(routeKey) {

    const [{ roles, pagesAndPermissions }, dispatch] = useContext(Context)
    const { isLoading, setIsLoading } = useIsLoading()

    async function getRoles() {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.get('functional-access')
            if (res)
            {
                const properties = getPageProperties(pagesAndPermissions, routeKey)
                const table = getTableColumns(properties.page.table, routeKey)
                let rows = res.data.data
                let newRowObject = isJSONValid(table.row.defaultObj)
                let dropdownValues = res.data.dropdownValues
                let columns = table.columns
                rows.forEach((row) => {
                    row.sequence = row.id
                    row.index = row.id
                    row.key = row.id
                    row.page_permissions_count = row.page_permissions.length
                    return row
                })
                const dataTableData = { columns: setColumnsForDataTable(columns, dropdownValues), rows: rows, newRowObject: newRowObject, dropdownValues: dropdownValues }
                dispatch({ type: SET_ROLES_COLUMNS, payload: setColumnsForDataTable(columns, dropdownValues) });
                dispatch({ type: SET_ROLES, payload: dataTableData });
            }
        }
        catch (e) {}
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function updateOrCreateRoles(data) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.post('functional-access', { roles: data })
            if (res)
            {
                toast.success(res.data.data)
                await getRoles()
            }
        }
        catch (e) {}
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function deleteRoles(roles) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.delete('functional-access', {
                data: {
                    roles: roles
                }
            })
            if (res)
            {
                await getRoles()
                toast.success(res.data.data);
            }

        }
        catch (e) {}
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    return { getRoles, updateOrCreateRoles, deleteRoles, isLoading }
}