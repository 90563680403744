import { useEffect } from "react";

/**
 * Custom react hook that detects when a click has occurred outside of the provided element ref
 * @param {React.RefObject} ref - Ref object of the element
 * @param {Function} callback - Function to be called when a click outisde is detected
 */
export const useClickOutside = (ref, callback) => {
  // handler for pointerdown event on document
  const handleClick = (e) => {
    if ((ref.current, !ref.current.contains(e.target))) {
      callback();
    } else if (
      e.target instanceof SVGElement &&
      ref.current &&
      !ref.current.contains(e.target.parentNode)
    ) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("pointerdown", handleClick);
    return () => {
      // unbind event listener on cleanup
      document.removeEventListener("pointerdown", handleClick);
    };
  });
};
