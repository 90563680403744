import request from "../services/Http";
import { useContext } from "react";
import { Context } from "../context/store/Store";
import {
  addKeyAndIndexFieldToGenericArrayOfObjects,
  getPageProperties, getTableColumns
} from "../services/Helpers";
import { SET_IS_LOADING, SET_REQUEST_TYPE } from "../context/store/Constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIsLoading } from "./useIsLoading";

export default function useRequestType(routeKey) {
  const [{ requestType, pagesAndPermissions }, dispatch] = useContext(Context)
  const { isLoading, setIsLoading } = useIsLoading()

  async function fetchRequestType() {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })

    try {
      const res = await request.get('request-type')
      if (res) {
        let rows = res.data.data
        const properties = getPageProperties(pagesAndPermissions, routeKey)
        const table = getTableColumns(properties.page.table, routeKey)
        let columns = table.columns
        let uploadTemplateLink = res.data.uploadTemplate ?? null
        let tableName = res.data.tableName ?? null

        if (requestType && requestType.hasOwnProperty('apiDelete') && requestType.apiDelete) {
          rows = [...requestType.rows, ...rows]
        }

        const dataTableData = { columns: columns, rows: addKeyAndIndexFieldToGenericArrayOfObjects(rows), apiDelete: false, uploadTemplateLink, tableName }
        dispatch({
          type: SET_REQUEST_TYPE,
          payload: dataTableData
        })
      }
    }
    catch (e) { }

    setIsLoading(false)
    dispatch({ type: SET_IS_LOADING, payload: false })
  }

  async function updateOrCreateRequestType(data) {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })

    try {
      const res = await request.post('request-type', {
        reqType: data
      })
      toast.success(res.data.data)
      await fetchRequestType()
    }
    catch (e) { }

    setIsLoading(false)
    dispatch({ type: SET_IS_LOADING, payload: false })
  }

  async function deleteRequestType(reqType) {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })

    try {
      const res = await request.delete('request-type', {
        data: { reqType }
      })
      if (res) {
        await fetchRequestType()
        toast.success(res.data.data)
      }
    }
    catch (e) { }

    setIsLoading(false)
    dispatch({ type: SET_IS_LOADING, payload: false })
  }

  return { fetchRequestType, updateOrCreateRequestType, deleteRequestType, isLoading }
}