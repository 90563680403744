import React, { useState, useEffect, useMemo } from 'react'
import { cloneIconClick } from "../../../utils/services/DatagridHelpers";
import { DEButton, createSanitizeAsyncRule } from "../../../utils/services/Helpers";
import DataGrid, { AsyncRule, Column, Button, Export, Paging, Pager, Scrolling, Selection, RequiredRule, SearchPanel, HeaderFilter, Editing, Lookup } from "devextreme-react/data-grid";
import { CustomHTMLEditor } from "../../../components/CustomDataGridComponents";
import MDAlert from "components/MDAlert";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import CustomSkelton from "components/Skelton/CustomSkelton";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "devextreme/dist/css/dx.light.css";
import BulkUploaderModal from 'components/Modal/BulkUploader/BulkUploaderModal';
const _ = require('lodash')

export default function HcTypeDataGrid({ columns, rows, dropdownValues = null, isLoading, permissions, hitApi, handleDelete, allowSelection = true, bulkUploadApi, uploadTemplateLink, apiCallback, tableName }) {
  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [hasCount, setHasCount] = useState(0);
  const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState(false)
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
    }
  }, []);

  useEffect(() => {
    setDataSource(rows);
  }, [rows]);

  useEffect(() => {
    setDataColumns(columns);
  }, [columns]);


  // initialize new row in the data-grid
  const onInitNewRow = (e) => {
    e.data.newRow = true
    e.data.status = true
    //e.data.masterRequestTypeId = 1
    setIsDataChanged(false)
  }

  // save new row in the data-grid
  const onSave = (e) => {
    if (e && e.changes.length) {
      if (e.changes[0].type === "remove") {
        const dataSourceArray = [...dataSource]
        const deleteItemId = e.changes[0].key
        if (deleteItemId) {
          const deleteFromTable = dataSourceArray.length ? dataSourceArray.filter(data => data.id !== deleteItemId) : []
          setDataSource(deleteFromTable)
        }
      }
      else {
        const updatedData = e.changes[0].data;
        let finalResult = []
        if (dataSource && dataSource.length) {
          finalResult = _.unionBy(updatedData, dataSource);
        }
        else { finalResult.push(updatedData) }
        setDataSource(finalResult)
        setIsDataChanged(true)
      }
    }
  }

  // prepare data-grid toolbar 
  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "upload",
          text: "BULK UPLOAD",
          visible: permissions && permissions.canCreate,
          onClick: function () { setBulkUploadModalVisible(true) },
        }
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SUBMIT",
          disabled: !isDataChanged,
          onClick: async () => {
            // handle API create and update of data-grid
            await hitApi(dataSource)
            setIsDataChanged(false)
          },
        }
      }
    );
  }

  // handle API delete of data-grid
  const manageDelete = () => {
    if (hasCount > 0) {
      toast.error(`${hasCount} records cannot be deleted as it exists in a relationship!`);
    }
    else {
      const msg = "You won't be able to revert this!"
      Swal.fire({
        title: 'Are you sure?',
        text: msg,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Yes, delete it!",
        heightAuto: false,
        height: '200px'
      }).then(async (res) => {
        let dataSourceArray = [...dataSource]
        if (res.isConfirmed) {
          const deleteFromApi = selectedRowKeys.filter(a => a.hasOwnProperty('id') && !a.hasOwnProperty('newRow'))
          const deleteFromTable = selectedRowKeys.filter(a => a.hasOwnProperty('newRow'))

          if (deleteFromApi && deleteFromApi.length) {
            const deleteResult = deleteFromApi.map(a => a.id);
            await handleDelete(deleteResult)
          }
          else if (deleteFromTable && deleteFromTable.length) {
            deleteFromTable.map(a => {
              dataSourceArray = dataSourceArray.filter((item) => item.id !== a.id);
            })
            setDataSource(dataSourceArray)
          }
          setIsDataChanged(false)
          setSelectedRowKeys([]);
        }
      })
    }
  }

  // get selected rows detail of data-grid
  const onSelectionChanged = ({ selectedRowsData }) => {
    const itemCount = selectedRowsData.filter((val) => val.count > 0);
    setHasCount(itemCount.length);
    setSelectedRowKeys(selectedRowsData)
  }

  // handle rendering of data-grid fields
  const renderField = (col, dropdownValues) => {
    if (col.type === "actions") {
      return <Column key={col.dataIndex} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable}
        type="buttons" dataField={col.dataIndex} caption={col.title} fixed={false} width={"auto"}>
        <Button hint="Clone" icon="copy" onClick={
          (e) => cloneIconClick(e, dataSource, setDataSource, false, false, false, false, true)} visible={(e) => permissions && permissions.canCreate} />
        <Button name="delete" icon={'trash'} visible={(e) => e && e.row && e.row.data && e.row.data.newRow === true && permissions && permissions.canDelete} />
      </Column>
    }
    else if (col.type === "checkbox") {
      return <Column dataType="boolean" key={col.dataIndex}
        showEditorAlways={true} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value)
        }}>
      </Column>
    }
    else if (col.type === "html") {
      return <Column key={col.dataIndex} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} fixed={false} encodeHtml={false} width={"500px"} dataField={col.dataIndex} caption={col.title}
        alignment={"left"} editCellComponent={CustomHTMLEditor} />;
    }
    else if (col.dataIndex === "masterRequestTypeId") {
      return <Column key={col.dataIndex} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} alignment={"left"} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value);
        }}>
        <Lookup
          dataSource={dropdownValues && dropdownValues.hasOwnProperty(col.dataIndex) ? dropdownValues[col.dataIndex] : []}
          displayExpr="label" valueExpr="id" />
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }
    else if (col.type === "string") {
      
      return <Column allowEditing={col.editable} key={col.dataIndex} dataField={col.dataIndex} caption={col.title} encodeHtml={col.hasOwnProperty('encodeHtml') ? col.encodeHtml : true} alignment={"left"}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>
    }
    else if (col.dataIndex !== "id") {
      return <Column allowEditing={col.editable} key={col.dataIndex} dataField={col.dataIndex} caption={col.title} encodeHtml={col.hasOwnProperty('encodeHtml') ? col.encodeHtml : true} alignment={"left"}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }
    else {
      return null
    }
  }

  const Comp = useMemo(() => {
    try {
      return <div id="hc-type-data-grid">
        {
          selectedRowKeys.length > 0
            ?
            <>
              <br />
              <MDAlert color="light">
                <MDTypography variant="subtitle2">
                  {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? "item" : "items"}`}
                </MDTypography>
                <Divider orientation="vertical" color="dark" flexItem />
                <MDBox>
                  <Grid container spacing={2}>
                    {
                      permissions && permissions.canDelete
                        ? <Grid item >
                          <DEButton hint="Delete" icon={'trash'} type="danger" onClick={() => { manageDelete() }} />
                        </Grid>
                        : null
                    }
                  </Grid>
                </MDBox>
              </MDAlert>
            </>
            : ""
        }
        <BulkUploaderModal title={"Request Type - Bulk Upload"} isModalVisible={bulkUploadModalVisible} setIsModalVisible={setBulkUploadModalVisible} bulkUploadApi={bulkUploadApi} apiCallback={apiCallback} tableName={tableName} downloadLink={uploadTemplateLink} orgStructureLink={null} />

        <DataGrid
          allowColumnReordering={true}
          onInitNewRow={onInitNewRow}
          onSaved={onSave}
          onSelectionChanged={onSelectionChanged}
          onToolbarPreparing={onToolbarPreparing}
          showColumnLines={true}
          showRowLines={true}
          rowAlternationEnabled={true}
          showBorders={true}
          allowColumnResizing={true}
          dataSource={dataSource}
          keyExpr="id"
          disabled={isLoading}>
          <HeaderFilter visible={true} disabled={false}
            allowSearch={true} />
          <SearchPanel visible={true} />
          <Paging defaultPageSize={25} />
          <Editing
            mode="cell"
            newRowPosition={"first"}
            refreshMode={"repaint"}
            allowAdding={permissions && permissions.canCreate}
            allowUpdating={permissions && permissions.canCreate}
            allowDeleting={permissions && permissions.canDelete} />
          <Pager visible={true} showNavigationButtons={true} showInfo={true} displayMode={"full"} />
          <Scrolling showScrollbar="always" mode="standard" />
          {
            dataColumns && dataColumns.length ? dataColumns.map((col) => {
              return renderField(col, dropdownValues);
            }) : null
          }
          {
            allowSelection ?
              <Selection allowSelectAll={true} mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} />
              : null
          }
          <Export enabled={true} allowExportSelectedData={true} />
        </DataGrid>
      </div>
    }
    catch (e) {
      console.log('error from ORG STRUCTURE', e)
    }
  }, [dataSource, dataColumns, dropdownValues, selectedRowKeys, isDataChanged, isLoading, permissions, allowSelection, bulkUploadModalVisible]);

  return (
    <React.Fragment>
      <DetectNavigationBlocker setIsDataChanged={setIsDataChanged} isDataChanged={isDataChanged} />
      <CustomSkelton>
        {Comp}
      </CustomSkelton>
    </React.Fragment>
  );
}