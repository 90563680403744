import BaseLayOut from "../Layout/BaseLayOut";
import MDTypography from "../MDTypography";
import MDBox from "../MDBox";

export default function NotFound({title= "404", text="Oops The page you are looking is not found."}){
  return <BaseLayOut pageTitle={title}>
    <MDBox p={3} lineHeight={1}>
      <MDTypography variant="h2" fontWeight="medium">
        {text}
      </MDTypography>
    </MDBox>
  </BaseLayOut>
}
