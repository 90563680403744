/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Breadcrumbs({ icon, activePath, routes, light }) {
  let lastRoute = routes.length ? routes[routes.length - 1] : null
  if (activePath !== lastRoute && lastRoute !== null) {
    routes.pop()
  }

  // special case for manage jobs view
  if (activePath === "job") {
    activePath = "job-description"
  }

  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}>
        {
          routes && routes.map((route, index) => (
            typeof route === "string" ?
              <MDTypography
                key={index}
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                color={light ? "white" : "dark"}
                sx={{ lineHeight: 0 }}
              >
                {route === 'ui-management' ? "UI Management" : route.replace(/-/g, " ").replace(/and/g, "&")}
              </MDTypography>
              : null
          ))
        }
        {
          activePath !== lastRoute && typeof lastRoute === "string" ?
            <Link to={`/views/${lastRoute}`}>
              <MDTypography
                variant="button"
                fontWeight="medium"
                textTransform="capitalize"
                color={light ? "white" : "dark"}
                sx={{ lineHeight: 0 }}
              >
                {lastRoute.replace(/-/g, " ").replace(/and/g, "&")}
              </MDTypography>
            </Link>
            : null
        }
        {
          activePath !== lastRoute && typeof activePath === "string" ?
            <MDTypography
              key={activePath}
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? "white" : "dark"}
              sx={{ lineHeight: 0 }}
            >
              {activePath.replace(/-/g, " ").replace(/and/g, "&")}
            </MDTypography>
            : null
        }
      </MuiBreadcrumbs>
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  activePath: PropTypes.string.isRequired,
  routes: PropTypes.array.isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
