import { CapabilityContext } from "../../contexts/capability-context";
import { useContext, useRef } from "react";
import { useClickOutside } from "../../../../utils/hooks/useClickOutside";
import "./contact-modal.scss";

const Modal = ({ showModal, children, title }) => {
  const modalRef = useRef(null);
  useClickOutside(modalRef, () => showModal(false));

  return (
    <div className="contact-modal">
      <div className="contact-modal__wrapper" ref={modalRef}>
        <div className="contact-modal__content">
          <h2 className="contact-modal__title">{title}</h2>
          <div className="contact-modal__body">{children}</div>
        </div>
        <button className="contact-modal--close" onClick={() => showModal(false)}>
          X
        </button>
      </div>
    </div>
  );
};

export const ContactInfoModal = () => {
  const { showContactInfo, setShowContactInfo, setIsReset, selectedNode, setSelectedCompany } = useContext(CapabilityContext);
  
  const handleModalChange = (visible) => {
    setShowContactInfo(visible);
    if(!visible) {
      setSelectedCompany(null);
      setIsReset(true);
    }
  }

  return showContactInfo ? (
    <Modal showModal={handleModalChange} title={selectedNode?.id || ""}>
      {selectedNode && (
        <div className="contact-info">
          <label>
            <span>Name:</span>
            {selectedNode.contactInfo?.lead}
          </label>
          <label>
            <span>Email:</span>
            <a href={`mailto:${selectedNode.contactInfo?.email}`}>
              {selectedNode.contactInfo?.email}
            </a>
          </label>
        </div>
      )}
    </Modal>
  ) : (
    <></>
  );
};
