import React, { useContext, useEffect } from "react";
import { Context } from "../../utils/context/store/Store";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function CustomSkelton({ children, is_loading = false }) {

  const [{ isLoading }, dispatch] = useContext(Context)

  useEffect(() => { }, [isLoading])

  return (
    <>
      {
        isLoading || is_loading
          ?
          <>
            <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={isLoading || is_loading}><CircularProgress color="inherit" /></Backdrop>
            {
              children
            }
          </> : children
      }
    </>
  )
}