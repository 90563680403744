import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import DataGrid, {
  AsyncRule,
  Button,
  Column,
  Editing,
  Export,
  HeaderFilter,
  Lookup,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Selection
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import {
  CustomDateBox,
  CustomFileInput,
  CustomNumberBox,
  CustomTextArea
} from '../CustomDataGridComponents';
import {
  getLevels,
  handleDeleteRequest,
  isObjectEmpty,
  DEButton
} from '../../utils/services/Helpers';
import MDAlert from '../MDAlert';
import MDTypography from '../MDTypography';
import Divider from '@mui/material/Divider';
import MDBox from '../MDBox';
import Grid from '@mui/material/Grid';
import { cloneIconClick, onRowExpanding } from '../../utils/services/DatagridHelpers';
import { TagBox } from 'devextreme-react/tag-box';
import DetectNavigationBlocker from 'components/navigationdetector/DetectNavigationBlocker';
const _ = require('lodash');
import 'assets/datatable-css/datagrid.css';
import { createSanitizeAsyncRule } from '../../utils/services/Helpers';

export default function DataGridTable({
  data,
  rows,
  columns,
  dropDownData,
  defaultKey = 'id',
  isLoading,
  permissions,
  allowAdding = true,
  allowUpdating = true,
  allowSelection = false,
  allowDeleting = true,
  postData,
  handleDelete,
  masterModuleId,
  userId,
  isForHome = false,
  showRestButtons = false
}) {
  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasDataChanged, setHasDataChanged] = useState(false);
  const [autoWidth, setAutoWidth] = useState(true);
  const [addEditMode, setAddEditMode] = useState(false);
  const dataGridRef = useRef();
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([]);
      setDataColumns([]);
    };
  }, []);

  useEffect(() => {
    setDataSource(rows);
  }, [rows]);

  useEffect(() => {
    setDataColumns(columns);
  }, [columns]);

  /**
   * @param e
   * function use perform operation when data-grid editor is not prepared e.g enable/disable field etc
   **/
  async function onEditorPreparing(e) {
    if (e && e.dataField === 'masterOrgEntityId') {
      let gridInstance = dataGridRef.current.instance;
      let editRowKey = gridInstance.option('editing.editRowKey');
      let index = gridInstance.getRowIndexByKey(editRowKey);
      const masterOrgDivisionId = gridInstance.cellValue(index, 'masterOrgDivisionId');
      if (!masterOrgDivisionId) e.editorOptions.disabled = true;
      else e.editorOptions.disabled = false;
    }

    if (e && e.dataField === 'masterLevelId') {
      let gridInstance = dataGridRef.current.instance;
      let editRowKey = gridInstance.option('editing.editRowKey');
      let index = gridInstance.getRowIndexByKey(editRowKey);
      const masterOrgDivisionId = gridInstance.cellValue(index, 'masterOrgDivisionId');

      if (!masterOrgDivisionId) e.editorOptions.disabled = true;
      else e.editorOptions.disabled = false;
    }

    if (e && e.dataField === 'masterCountryId') {
      let gridInstance = dataGridRef.current.instance;
      let editRowKey = gridInstance.option('editing.editRowKey');
      let index = gridInstance.getRowIndexByKey(editRowKey);
      const masterOrgEntityId = gridInstance.cellValue(index, 'masterOrgEntityId');
      if (!masterOrgEntityId) e.editorOptions.disabled = true;
      else e.editorOptions.disabled = false;
    }

    if (e && e.dataField === 'masterOrgVerticalId') {
      let gridInstance = dataGridRef.current.instance;
      let editRowKey = gridInstance.option('editing.editRowKey');
      let index = gridInstance.getRowIndexByKey(editRowKey);
      const masterCountryId = gridInstance.cellValue(index, 'masterCountryId');
      if (!masterCountryId) e.editorOptions.disabled = true;
      else e.editorOptions.disabled = false;
    }
  }

  /**
   * @param selectedRowKeys
   * @param selectedRowsData
   * used to get selected rows detail of data-grid
   **/
  function onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
    setSelectedRowKeys(selectedRowsData);
  }

  /**
   * get selected rows
   **/
  const hasSelected = selectedRowKeys.length > 0;

  /**
   * @param dataColumns
   * function use to handle rendering of fields
   **/
  function renderField(dataColumns) {
    return dataColumns.map((col, index) => {
      if (col.type === 'select') {
        if (!col.hasOwnProperty('filtrationKey')) {
          return (
            <Column
              key={index}
              editorOptions={{ dropDownOptions: { width: 'auto' } }}
              allowEditing={col.editable}
              visible={col.is_visible}
              allowSearch={col.is_searchable}
              allowSorting={col.is_sortable}
              dataField={col.dataIndex}
              caption={col.title}
              setCellValue={function (rowData, value) {
                if (col.dataIndex === 'masterOrgDivisionId') {
                  rowData['masterOrgEntityId'] = null;
                  rowData['masterCountryId'] = null;
                  rowData['masterOrgVerticalId'] = null;
                  rowData['masterLevelId'] = [];
                }

                if (col.hasOwnProperty('bindedTo')) rowData[col.bindedTo] = null;
                this.defaultSetCellValue(rowData, value);
              }}
            >
              {col.required ? <RequiredRule /> : null}
              <Lookup
                allowClearing
                dataSource={
                  dropDownData && dropDownData.hasOwnProperty(col.dataIndex)
                    ? dropDownData[col.dataIndex]
                    : []
                }
                displayExpr="label"
                valueExpr="id"
              />
            </Column>
          );
        } else if (col.hasOwnProperty('filtrationKey')) {
          if (col.dataIndex === 'masterOrgEntityId') {
            return (
              <Column
                key={index}
                editorOptions={{ dropDownOptions: { width: 'auto' } }}
                allowEditing={col.editable}
                visible={col.is_visible}
                allowSearch={col.is_searchable}
                allowSorting={col.is_sortable}
                dataField={col.dataIndex}
                caption={col.title}
                setCellValue={function (rowData, value) {
                  this.defaultSetCellValue(rowData, value);
                  if (col.dataIndex === 'masterOrgEntityId') {
                    rowData['masterCountryId'] = null;
                    rowData['masterOrgVerticalId'] = null;
                    rowData['masterLevelId'] = [];
                  }
                  if (col.hasOwnProperty('bindedTo')) {
                    rowData[col.bindedTo] = null;
                  }
                }}
              >
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return getEntitiesByDivision(options);
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
                {col.required ? <RequiredRule /> : null}
              </Column>
            );
          } else if (col.dataIndex === 'masterCountryId') {
            return (
              <Column
                key={index}
                editorOptions={{ dropDownOptions: { width: 'auto' } }}
                allowEditing={col.editable}
                visible={col.is_visible}
                allowSearch={col.is_searchable}
                allowSorting={col.is_sortable}
                dataField={col.dataIndex}
                caption={col.title}
                setCellValue={function (rowData, value) {
                  this.defaultSetCellValue(rowData, value);
                  if (col.dataIndex === 'masterCountryId') {
                    rowData['masterOrgVerticalId'] = null;
                    rowData['masterLevelId'] = [];
                  }
                  if (col.hasOwnProperty('bindedTo')) {
                    rowData[col.bindedTo] = null;
                  }
                }}
              >
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return getCountriesByDivisionOrEntity(options);
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
                {col.required ? <RequiredRule /> : null}
              </Column>
            );
          } else if (col.dataIndex === 'masterOrgVerticalId') {
            return (
              <Column
                key={index}
                editorOptions={{ dropDownOptions: { width: 'auto' } }}
                allowEditing={col.editable}
                visible={col.is_visible}
                allowSearch={col.is_searchable}
                allowSorting={col.is_sortable}
                dataField={col.dataIndex}
                caption={col.title}
                setCellValue={function (rowData, value) {
                  rowData['masterOrgVerticalId'] = null;
                  rowData['masterLevelId'] = [];
                  this.defaultSetCellValue(rowData, value);
                  if (col.hasOwnProperty('bindedTo')) {
                    rowData[col.bindedTo] = null;
                  }
                }}
              >
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return getVerticalsByEntityAndDivision(options);
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
                {col.required ? <RequiredRule /> : null}
              </Column>
            );
          } else {
            return (
              <Column
                key={index}
                editorOptions={{ dropDownOptions: { width: 'auto' } }}
                allowEditing={col.editable}
                visible={col.is_visible}
                allowSearch={col.is_searchable}
                allowSorting={col.is_sortable}
                dataField={col.dataIndex}
                caption={col.title}
                setCellValue={function (rowData, value) {
                  this.defaultSetCellValue(rowData, value);
                  if (col.hasOwnProperty('bindedTo')) {
                    rowData[col.bindedTo] = null;
                  }
                }}
              >
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return {
                      store:
                        dropDownData && dropDownData.hasOwnProperty(col.dataIndex)
                          ? dropDownData[col.dataIndex]
                          : [],
                      filter: options.data
                        ? [col.filtrationKey, '=', options.data[col.filtrationKey]]
                        : null
                    };
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
                {col.required ? <RequiredRule /> : null}
              </Column>
            );
          }
        }
      } else if (col.type === 'multi-select') {
        return (
          <Column
            key={index}
            width={'250'}
            editorOptions={{ dropDownOptions: { width: 'auto' } }}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            editCellComponent={CustomDTagThisComp}
            cellTemplate={(container, options) => {
              const noBreakSpace = '\u00A0';
              const text = (options.value || [])
                .map((element) => options.column.lookup.calculateCellValue(element))
                .join(', ');
              container.textContent = text || noBreakSpace;
              container.title = text;
            }}
            calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
              if (target === 'search' && typeof filterValue === 'string') {
                return [col.dataIndex, 'contains', filterValue];
              }
              return function (data) {
                return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
              };
            }}
          >
            <Lookup
              allowClearing
              dataSource={
                dropDownData && dropDownData.hasOwnProperty(col.dataIndex)
                  ? dropDownData[col.dataIndex]
                  : null
              }
              displayExpr="label"
              valueExpr="id"
            />
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      } else if (col.type === 'checkbox') {
        return (
          <Column
            key={index}
            dataType="boolean"
            showEditorAlways={true}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
            }}
          >
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      } else if (col.type === 'actions') {
        return (
          <Column
            key={index}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            type="buttons"
            dataField={col.dataIndex}
            caption={col.title}
            fixed={false}
            width={'250'}
          >
            <Button
              name="delete"
              icon={'trash'}
              visible={(e) =>
                e && e.row && e.row.data && e.row.data.newRow === true && showRestButtons
              }
            />
            <Button
              hint="Clone"
              icon="copy"
              visible={(e) => permissions && permissions.canCreate && showRestButtons}
              onClick={(e) => cloneIconClick(e, dataSource, setDataSource, true)}
            />
            <Button
              name="view"
              icon={'eye'}
              visible={!showRestButtons}
              component={(props) => {
                return (
                  <>
                    {permissions && (permissions.canCreate || permissions.canView) ? (
                      isForHome ? (
                        <DEButton
                          stylingMode={'contained'}
                          type={'normal'}
                          text={'View'}
                          to={`/views/my-tasks/${props.data.data.masterModuleId}`}
                          state={props.data.data}
                        />
                      ) : (
                        <DEButton
                          stylingMode={'contained'}
                          type={'normal'}
                          text={'View'}
                          to={
                            props.data.data.type === 'hc_transaction' ||
                              props.data.data.type === 'transaction_leaver'
                              ? `/views/group/${props.data.data.groupId}/request/${props.data.data.masterRequestTypeId}/type/${props.data.data.masterHeadcountTypeId}/manage-headcount`
                              : `/views/job/${props.data.data.id}`
                          }
                          state={props.data.data}
                        />
                      )
                    ) : null}
                  </>
                );
              }}
            />
          </Column>
        );
      } else if (col.type === 'date') {
        return (
          <Column
            key={index}
            alignment={'left'}
            dataType={'date'}
            format={'dd-MM-yyyy hh:mm:ss a'}
            editCellComponent={CustomDateBox}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      } else if (col.type === 'int') {
        return (
          <Column
            key={index}
            alignment={'left'}
            dataType={col.type}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            editCellComponent={(props) => (
              <CustomNumberBox props={props.data} canEdit={col.editable} />
            )}
          >
            {col.required ? <RequiredRule /> : null}
            {col.dataIndex !== 'id' ? (
              <AsyncRule
                message={'Value should not exceed more than 15 digits'}
                validationCallback={async (e) => {
                  return e && e.value && e.value.toString().length <= 15;
                }}
              />
            ) : null}
          </Column>
        );
      } else if (col.type === 'file') {
        return (
          <Column
            key={index}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            type={'buttons'}
            fixed={false}
            dataField={col.dataIndex}
            caption={col.title}
            editCellComponent={CustomFileInput}
          />
        );
      } else if (col.type === 'textarea') {
        return (
          <Column
            key={index}
            alignment={'left'}
            encodeHtml={col.encodeHtml}
            editCellComponent={CustomTextArea}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
            <AsyncRule {...sanitizeAsyncRule} />
          </Column>
        );
      } else if (col.type === 'string') {
        return (
          <Column
            key={index}
            alignment={'left'}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
            <AsyncRule {...sanitizeAsyncRule} />
          </Column>
        );
      } else {
        return (
          <Column
            key={index}
            alignment={'left'}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      }
    });
  }

  /**
   * @param options
   * function use get verticals based on division and entity
   **/
  function getVerticalsByEntityAndDivision(options) {
    let uniqueVerticals = [];

    if (
      options &&
      options.data &&
      options.data.hasOwnProperty('masterOrgDivisionId') &&
      options.data.masterOrgDivisionId !== null &&
      options.data.hasOwnProperty('masterOrgEntityId') &&
      options.data.masterOrgEntityId !== null &&
      options.data.hasOwnProperty('masterCountryId') &&
      options.data.masterCountryId !== null
    ) {
      const filteredVerticals =
        dropDownData['masterOrgVerticalId'].filter(
          (obj) =>
            obj.masterCountryId === options.data?.masterCountryId &&
            obj.masterOrgEntityId === options.data?.masterOrgEntityId &&
            obj.masterOrgDivisionId === options.data?.masterOrgDivisionId
        ) ?? [];
      uniqueVerticals = [...new Map(filteredVerticals?.map((item) => [item['id'], item])).values()];
    } else if (
      options &&
      options.data &&
      options.data.hasOwnProperty('masterOrgDivisionId') &&
      options.data.masterOrgDivisionId !== null &&
      options.data.hasOwnProperty('masterOrgEntityId') &&
      options.data.masterOrgEntityId === null &&
      options.data.hasOwnProperty('masterCountryId') &&
      options.data.masterCountryId !== null
    ) {
      const filteredVerticals =
        dropDownData['masterOrgVerticalId'].filter(
          (obj) =>
            obj.masterCountryId === options.data?.masterCountryId &&
            obj.masterOrgDivisionId === options.data?.masterOrgDivisionId
        ) ?? [];
      uniqueVerticals = [...new Map(filteredVerticals?.map((item) => [item['id'], item])).values()];
    } else if (
      options &&
      options.data &&
      options.data.hasOwnProperty('id') &&
      options.data.masterOrgDivisionId !== null &&
      options.data.hasOwnProperty('masterOrgEntityId') &&
      options.data.masterOrgEntityId !== null
    ) {
      const filteredVerticals =
        dropDownData['masterOrgVerticalId'].filter(
          (obj) =>
            obj.masterOrgEntityId === options.data?.masterOrgEntityId &&
            obj.masterOrgDivisionId === options.data?.masterOrgDivisionId
        ) ?? [];
      uniqueVerticals = [
        ...new Map(filteredVerticals?.map((item) => [item['masterOrgVerticalId'], item])).values()
      ];
    } else if (isObjectEmpty(options)) {
      const filteredVerticals =
        dropDownData && dropDownData.hasOwnProperty('masterOrgVerticalId')
          ? dropDownData['masterOrgVerticalId']
          : [];
      uniqueVerticals = [...new Map(filteredVerticals?.map((item) => [item['id'], item])).values()];
    } else {
      const filteredVerticals =
        dropDownData['masterOrgVerticalId'].filter(
          (obj) => obj.masterOrgDivisionId === options.data?.masterOrgDivisionId
        ) ?? [];
      uniqueVerticals = [...new Map(filteredVerticals?.map((item) => [item['id'], item])).values()];
    }

    return uniqueVerticals.length > 0
      ? uniqueVerticals.sort((a, b) =>
        a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase())
      )
      : [];
  }

  /**
   * @param options
   * function use get countries by division or entity
   **/
  function getCountriesByDivisionOrEntity(options) {
    let uniqueCountries = [];
    if (
      options &&
      options.data &&
      options.data.hasOwnProperty('masterOrgDivisionId') &&
      options.data.masterOrgDivisionId !== null &&
      options.data.hasOwnProperty('masterOrgEntityId') &&
      options.data.masterOrgEntityId !== null
    ) {
      const filteredVerticals =
        dropDownData['masterCountryId'].filter(
          (obj) =>
            obj.uniqueKey ===
            `${options.data.masterOrgDivisionId} - ${options.data.masterOrgEntityId}`
        ) ?? [];
      uniqueCountries = [...new Map(filteredVerticals?.map((item) => [item['id'], item])).values()];
    } else if (
      (options &&
        options.data &&
        options.data.hasOwnProperty('masterOrgDivisionId') &&
        options.data.masterOrgDivisionId !== null &&
        !options.data.hasOwnProperty('masterOrgEntityId')) ||
      options.data?.masterOrgEntityId === null
    ) {
      const filteredVerticals =
        dropDownData['masterCountryId'].filter(
          (obj) => obj.masterOrgDivisionId === options.data?.masterOrgDivisionId
        ) ?? [];
      uniqueCountries = [...new Map(filteredVerticals?.map((item) => [item['id'], item])).values()];
    } else {
      const filteredVerticals =
        dropDownData && dropDownData.hasOwnProperty('masterCountryId')
          ? dropDownData['masterCountryId']
          : [];
      uniqueCountries = [...new Map(filteredVerticals?.map((item) => [item['id'], item])).values()];
    }

    return uniqueCountries.length > 0
      ? uniqueCountries.sort((a, b) =>
        a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase())
      )
      : [];
  }

  /**
   * @param options
   * function use get Entities by division
   **/
  function getEntitiesByDivision(options) {
    let uniqueEntities = [];
    if (
      options &&
      options.data &&
      options.data.hasOwnProperty('masterOrgDivisionId') &&
      options.data.masterOrgDivisionId !== null
    ) {
      const filteredEntities =
        dropDownData['masterOrgEntityId']?.filter(
          (d) => d.masterOrgDivisionId === options.data?.masterOrgDivisionId
        ) ?? [];
      uniqueEntities = [...new Map(filteredEntities?.map((item) => [item['id'], item])).values()];
    } else {
      const filteredEntities =
        dropDownData && dropDownData.hasOwnProperty('masterOrgEntityId')
          ? dropDownData['masterOrgEntityId']
          : [];
      uniqueEntities = [...new Map(filteredEntities?.map((item) => [item['id'], item])).values()];
    }

    return uniqueEntities.length > 0
      ? uniqueEntities.sort((a, b) =>
        a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase())
      )
      : [];
  }

  /**
   * @param props
   * custom component to display multi select box
   **/
  const CustomDTagThisComp = (props) => {
    const { masterOrgDivisionId, masterOrgEntityId, masterCountryId, masterOrgVerticalId } =
      props.data.data;

    function onValueChanged(e) {
      props.data.setValue(e.value);
    }

    function onSelectionChanged() {
      props.data.component.updateDimensions();
    }

    const vet =
      dropDownData &&
        dropDownData.hasOwnProperty('masterOrgVerticalId') &&
        dropDownData['masterOrgVerticalId']?.length
        ? dropDownData['masterOrgVerticalId']
        : [];

    return (
      <TagBox
        dropDownOptions={{ width: 'auto' }}
        disabled={masterOrgDivisionId === null}
        dataSource={
          props.data.column.dataField !== 'masterLevelId'
            ? props.data.column.lookup.dataSource
            : getLevels(
              props.data.column.lookup.dataSource,
              vet,
              masterOrgDivisionId,
              masterOrgEntityId,
              masterCountryId,
              masterOrgVerticalId
            )
        }
        defaultValue={props.data.value}
        valueExpr="id"
        displayExpr={'label'}
        showSelectionControls={true}
        maxDisplayedTags={3}
        showMultiTagOnly={false}
        applyValueMode="instantly"
        searchEnabled={true}
        onValueChanged={onValueChanged}
        onSelectionChanged={onSelectionChanged}
      />
    );
  };

  /**
   * @param e
   * initialize new row in the data-grid
   **/
  const onInitNewRow = (e) => {
    window.scrollTo(0, 0);
    e.data.status = true;
    e.data.newRow = true;
    e.data.can_approve = false;
    e.data.masterOrgDivisionId = null;
    e.data.masterOrgEntityId = null;
    e.data.masterCountryId = null;
    e.data.masterOrgVerticalId = null;
    e.data.userId = userId;
    e.data.masterModuleId = masterModuleId;
    setAutoWidth(false);
    setAddEditMode(true);
  };

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'save',
        text: 'SUBMIT',
        disabled: !hasDataChanged,
        visible: permissions && permissions.canCreate && allowAdding,
        onClick: async () => {
          const ds = [...dataSource];
          if (ds.some((d) => !d.masterLevelId?.length)) {
            toast.error('Levels are required.');
          } else {
            await postData(dataSource);
            setHasDataChanged(false);
          }
        }
      }
    });
  }

  /**
   * @param e
   * Manage post api call to save data and validation for if any request is missing headcount while having replacement true
   **/
  function onSave(e) {
    if (e && e.changes.length) {
      if (e.changes[0].type === 'remove') {
        const dsCopy = [...dataSource];
        const filteredDs = dsCopy.length ? dsCopy.filter((ds) => ds.id !== e.changes[0].key) : [];
        if (filteredDs && filteredDs.length) {
          setDataSource(filteredDs);
          setHasDataChanged(true);
        } else {
          setDataSource([]);
          setHasDataChanged(false);
        }
      } else {
        const dd = e.changes[0].data;
        let finalData = [];
        finalData.push(dd);
        let result = [];
        let finalRes = [];
        if (dataSource && dataSource.length) {
          result = _.unionBy(finalData, dataSource);
          finalRes = [...new Map(result.map((item) => [item['id'], item])).values()];
        } else result.push(dd);
        setDataSource(finalRes);
        setHasDataChanged(true);
      }
    }
    setAutoWidth(true);
    setAddEditMode(false);
  }

  /**
   * @param e
   * validate row before saving
   **/
  function onRowValidating(e) {
    if (e && e.isValid) {
      if (e.newData) {
        const { masterOrgDivisionId, masterOrgEntityId, masterCountryId, masterOrgVerticalId } =
          e.newData;
        const d = [...dataSource];
        if (masterOrgDivisionId && d.length) {
          const c = d.filter((r) => r.masterOrgDivisionId === masterOrgDivisionId);
          if (
            d.length > 0 &&
            c &&
            c.length &&
            c[0]?.masterOrgEntityId === null &&
            c[0]?.masterCountryId === null &&
            c[0]?.masterOrgVerticalId === null
          ) {
            e.isValid = false;
            e.errorText = 'User already has full access';
          } else if (c && c.length > 0) {
            if (masterOrgEntityId) {
              const c = d.filter(
                (r) =>
                  r.masterOrgDivisionId === masterOrgDivisionId &&
                  r.masterOrgEntityId === masterOrgEntityId
              );
              if (c && c.length > 0) {
                if (masterCountryId) {
                  const c = d.filter(
                    (r) =>
                      r.masterOrgDivisionId === masterOrgDivisionId &&
                      r.masterOrgEntityId === masterOrgEntityId &&
                      r.masterCountryId === masterCountryId
                  );
                  if (c && c.length > 0) {
                    if (masterOrgVerticalId) {
                      const c = d.filter(
                        (r) =>
                          r.masterOrgDivisionId === masterOrgDivisionId &&
                          r.masterOrgEntityId === masterOrgEntityId &&
                          r.masterCountryId === masterCountryId &&
                          r.masterOrgVerticalId === masterOrgVerticalId
                      );
                      if (c && c.length > 0) {
                        e.isValid = false;
                        e.errorText = 'Duplicate Combination Found';
                      }
                    } else {
                      e.isValid = false;
                      e.errorText = 'Duplicate Combination Found';
                    }
                  }
                } else {
                  e.isValid = false;
                  e.errorText = 'Duplicate Combination Found';
                }
              }
            } else {
              e.isValid = false;
              e.errorText = 'Duplicate Combination Found';
            }
          } else {
            if (
              masterOrgDivisionId &&
              masterOrgEntityId &&
              masterCountryId === null &&
              masterOrgVerticalId === null &&
              d.length
            ) {
              const c = d.filter(
                (r) =>
                  r.masterOrgDivisionId === masterOrgDivisionId &&
                  r.masterOrgEntityId === masterOrgEntityId
              );
              if (c && c.length > 0) {
                e.isValid = false;
                e.errorText = 'Duplicate Combination Found';
              }
            } else if (
              masterOrgDivisionId &&
              masterOrgEntityId &&
              masterCountryId &&
              masterOrgVerticalId === null &&
              d.length > 0
            ) {
              const c = d.filter(
                (r) =>
                  r.masterOrgDivisionId === masterOrgDivisionId &&
                  r.masterOrgEntityId === masterOrgEntityId &&
                  r.masterCountryId === masterCountryId
              );
              if (c && c.length > 0) {
                e.isValid = false;
                e.errorText = 'Duplicate Combination Found';
              }
            } else if (
              masterOrgDivisionId &&
              masterOrgEntityId &&
              masterCountryId &&
              masterOrgVerticalId &&
              d.length
            ) {
              const c = d.filter(
                (r) =>
                  r.masterOrgDivisionId === masterOrgDivisionId &&
                  r.masterOrgEntityId === masterOrgEntityId &&
                  r.masterCountryId === masterCountryId &&
                  r.masterOrgVerticalId === masterOrgVerticalId
              );
              if (c && c.length > 0) {
                e.isValid = false;
                e.errorText = 'Duplicate Combination Found';
              }
            }
          }
        }
      }
    }
  }

  /**
   * function used to handle delete part of data-grid
   **/
  const manageDelete = async () => {
    let newData = [...dataSource];
    handleDeleteRequest(async () => {
      const deleteFromApi = selectedRowKeys.filter(
        (a) => a.hasOwnProperty('id') && !a.hasOwnProperty('newRow')
      );
      const deleteFromTable = selectedRowKeys.filter((a) => a.hasOwnProperty('newRow'));
      const result = deleteFromApi.map((a) => a.id);
      if (deleteFromApi.length > 0 && deleteFromTable.length > 0) {
        await handleDelete(
          result,
          userId,
          selectedRowKeys.length === dataSource.length,
          masterModuleId
        );
      } else if (deleteFromApi && deleteFromApi.length > 0) {
        await handleDelete(
          result,
          userId,
          selectedRowKeys.length === dataSource.length,
          masterModuleId
        );
      } else if (deleteFromTable && deleteFromTable.length > 0) {
        deleteFromTable.map((a) => {
          newData = newData.filter((item) => item.key !== a.key);
        });
        setDataSource(newData);
      }
      setHasDataChanged(false);
      setSelectedRowKeys([]);
    });
  };

  /**
   * custom function using useMemo to avoid re-renders unless the states listed are changed
   **/
  const Comp = useMemo(() => {
    try {
      return (
        <div id="data-grid-demo">
          {hasSelected > 0 ? (
            <React.Fragment>
              <br />
              <MDAlert color="light">
                <MDTypography variant="subtitle2">
                  {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? 'item' : 'items'
                    }`}
                </MDTypography>

                <Divider orientation="vertical" color="dark" flexItem />

                <MDBox>
                  <Grid container spacing={2}>
                    {permissions && permissions.canDelete ? (
                      <Grid item>
                        <DEButton
                          stylingMode={'contained'}
                          type={'danger'}
                          icon="trash"
                          onClick={() => manageDelete()}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </MDBox>
              </MDAlert>
            </React.Fragment>
          ) : (
            ''
          )}
          <DataGrid
            id="grid"
            onToolbarPreparing={onToolbarPreparing}
            showBorders={true}
            onRowExpanding={onRowExpanding}
            columnAutoWidth={true}
            onSaved={onSave}
            showColumnLines={true}
            showRowLines={true}
            rowAlternationEnabled={true}
            ref={dataGridRef}
            onInitNewRow={onInitNewRow}
            onSelectionChanged={onSelectionChanged}
            allowColumnResizing={true}
            loadPanel={{ enabled: isLoading }}
            disabled={isLoading}
            dataSource={dataSource}
            key={defaultKey ?? 'id'}
            keyExpr={defaultKey ?? 'id'}
            onEditorPreparing={onEditorPreparing}
            onRowValidating={onRowValidating}
          >
            {addEditMode ? null : <Scrolling showScrollbar="always" mode="standard" />}

            {allowSelection ? (
              <Selection
                allowSelectAll={true}
                mode="multiple"
                selectAllMode={'page'}
                showCheckBoxesMode={'always'}
              />
            ) : null}
            <HeaderFilter visible={true} allowSearch={true} />
            <SearchPanel visible={true} />
            <Export enabled={true} allowExportSelectedData={true} />
            <Editing
              newRowPosition={'first'}
              refreshMode={'repaint'}
              mode="cell"
              allowUpdating={permissions && permissions.canCreate && allowUpdating}
              allowAdding={permissions && permissions.canCreate && allowAdding}
              allowDeleting={allowDeleting}
            />
            {dataColumns && dataColumns.length ? renderField(dataColumns) : null}
          </DataGrid>
        </div>
      );
    } catch (e) {
    }
  }, [
    dataSource,
    dataColumns,
    dropDownData,
    hasDataChanged,
    selectedRowKeys,
    data,
    isLoading,
    autoWidth,
    addEditMode
  ]);

  return (
    <React.Fragment>
      <DetectNavigationBlocker
        setIsDataChanged={setHasDataChanged}
        isDataChanged={hasDataChanged}
      />
      {Comp}
    </React.Fragment>
  );
}
