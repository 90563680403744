

const colors = {
    'Parent Company': '#212129',
    // Content: '#58A75D',
    // 'Customer Experience': '#67232E',
    // 'Internal Digital Transformation': '#A5B985',
    // 'Experience Design': '#00B0A3',
    // Commerce: '#16ABE0',
    // 'Data Sciences': '#9D833E',
    // Consultancy: '#D93D7A'
  };
  export const nodesLegend = Object.entries(colors)
    .filter((_, i) => i)
    .map((e) => ({ name: e[0], color: e[1] }));
  
  export const dataNodes = JSON.parse(localStorage.getItem('dataNodes')) ??  [
    { id: 'Parent Company', parentId: '' },
    { id: 'Content', parentId: 'Parent Company' },
    { id: 'Customer Experience', parentId: 'Parent Company' },
    { id: 'Internal Digital Transformation', parentId: 'Parent Company' },
    { id: 'Experience Design', parentId: 'Parent Company' },
    { id: 'Commerce', parentId: 'Parent Company' },
    { id: 'Data Sciences', parentId: 'Parent Company' },
    { id: 'Consultancy', parentId: 'Parent Company' },

    { id: 'Content Intelligence', parentId: 'Content' },
    { id: 'Partnerships', parentId: 'Content' },
    { id: 'Original Asset Creation', parentId: 'Content' },
    { id: 'Gaming & eSports', parentId: 'Content' },
    { id: 'Web3', parentId: 'Content' },
    { id: 'Content Distribution', parentId: 'Content' },

    { id: 'Strategy & Transformation', parentId: 'Consultancy' },
    { id: 'Creative Labs', parentId: 'Consultancy' },

    { id: 'User Experience', parentId: 'Customer Experience' },
    {
      id: 'Experimentation & Conversion Optimisation',
      parentId: 'Customer Experience'
    },
    { id: 'Strategy, Transformation & Growth', parentId: 'Customer Experience' },
    { id: 'CRM & Marketing Automation (B2B/C)', parentId: 'Customer Experience' },
    { id: 'CX Factory', parentId: 'Customer Experience' },
    { id: 'Personalisation & Loyalty', parentId: 'Customer Experience' },

    { id: 'Planning & Strategy', parentId: 'Commerce' },
    { id: 'Commerce Content', parentId: 'Commerce' },
    { id: 'Performance', parentId: 'Commerce' },

    { id: 'Development', parentId: 'Internal Digital Transformation' },
    { id: 'Business Analysis', parentId: 'Internal Digital Transformation' },
    { id: 'Product', parentId: 'Internal Digital Transformation' },

    { id: 'Strategy & Growth', parentId: 'Experience Design' },
    { id: 'Creative & Web Services', parentId: 'Experience Design' },
    { id: 'SEO', parentId: 'Experience Design' },

    { id: 'Analytics', parentId: 'Data Sciences' },
    { id: 'AdTech', parentId: 'Data Sciences' },
    { id: 'Research', parentId: 'Data Sciences' },
    { id: 'Business Intelligence', parentId: 'Data Sciences' },
    { id: 'MarTech', parentId: 'Data Sciences' },

    {
      id: 'Agile Studio',
      parentId: 'Original Asset Creation',
      contactInfo: {
        lead: 'Houssam Elboutari',
        email: 'houssam.elboutari@publicisgroupe.com'
      }
    },
    {
      id: 'Augmented Reality: Face & World Lenses',
      parentId: 'Original Asset Creation',
      contactInfo: {
        lead: 'Houssam Elboutari',
        email: 'houssam.elboutari@publicisgroupe.com'
      }
    },
    {
      id: 'Social Commerce',
      parentId: 'Original Asset Creation',
      contactInfo: {
        lead: 'Sajid Ismail',
        email: 'sajid.ismail@publicisgroupe.com'
      }
    },

    {
      id: 'Social Strategy Development',
      parentId: 'Original Asset Creation',
      contactInfo: {
        lead: 'Mohamed Abu Gabal',
        email: 'mohamed.abugabal@publicisgroupe.com'
      }
    },
    {
      id: 'Social Management',
      parentId: 'Original Asset Creation',
      contactInfo: {
        lead: 'Mohamed Abu Gabal',
        email: 'mohamed.abugabal@publicisgroupe.com'
      }
    },
    {
      id: 'Social Content Production',
      parentId: 'Original Asset Creation',
      contactInfo: {
        lead: 'Mohamed Abu Gabal',
        email: 'mohamed.abugabal@publicisgroupe.com'
      }
    },
    {
      id: 'Crisis Management',
      parentId: 'Original Asset Creation',
      contactInfo: {
        lead: 'Mohamed Abu Gabal',
        email: 'mohamed.abugabal@publicisgroupe.com'
      }
    },
    {
      id: 'Community Building and Engagement',
      parentId: 'Original Asset Creation',
      contactInfo: {
        lead: 'Mohamed Abu Gabal',
        email: 'mohamed.abugabal@publicisgroupe.com'
      }
    },

    {
      id: 'Education & Workshops',
      parentId: 'Gaming & eSports',
      contactInfo: {
        lead: 'Hanting Zhu',
        email: 'hanting.zhu@publicisgroupe.com'
      }
    },
    {
      id: 'Strategy, non-gaming brands',
      parentId: 'Gaming & eSports',
      contactInfo: {
        lead: 'Hanting Zhu',
        email: 'hanting.zhu@publicisgroupe.com'
      }
    },
    {
      id: 'Strategy, gaming brands',
      parentId: 'Gaming & eSports',
      contactInfo: {
        lead: 'Hanting Zhu',
        email: 'hanting.zhu@publicisgroupe.com'
      }
    },
    {
      id: 'Gaming social content, operation and community',
      parentId: 'Gaming & eSports',
      contactInfo: {
        lead: 'Hanting Zhu',
        email: 'hanting.zhu@publicisgroupe.com'
      }
    },
    {
      id: 'Gaming influencer strategy & management',
      parentId: 'Gaming & eSports',
      contactInfo: {
        lead: 'Hanting Zhu',
        email: 'hanting.zhu@publicisgroupe.com'
      }
    },
    {
      id: 'In-game media',
      parentId: 'Gaming & eSports',
      contactInfo: {
        lead: 'Hanting Zhu',
        email: 'hanting.zhu@publicisgroupe.com'
      }
    },
    {
      id: 'Sponsorships and partnerships',
      parentId: 'Gaming & eSports',
      contactInfo: {
        lead: 'Hanting Zhu',
        email: 'hanting.zhu@publicisgroupe.com'
      }
    },
    {
      id: 'Activations, Experiences, Tournaments & Events',
      parentId: 'Gaming & eSports',
      contactInfo: {
        lead: 'Hanting Zhu',
        email: 'hanting.zhu@publicisgroupe.com'
      }
    },
    {
      id: 'Web3 Education & Workshops',
      parentId: 'Web3',
      contactInfo: {
        lead: 'Hanting Zhu',
        email: 'hanting.zhu@publicisgroupe.com'
      }
    },
    {
      id: 'Strategy & Consulting',
      parentId: 'Web3',
      contactInfo: {
        lead: 'Hanting Zhu',
        email: 'hanting.zhu@publicisgroupe.com'
      }
    },
    {
      id: 'Custom Branded Metaverse Experience & Content',
      parentId: 'Web3',
      contactInfo: {
        lead: 'Hanting Zhu',
        email: 'hanting.zhu@publicisgroupe.com'
      }
    },
    {
      id: 'NFT drop strategy ',
      parentId: 'Web3',
      contactInfo: {
        lead: 'Hanting Zhu',
        email: 'hanting.zhu@publicisgroupe.com'
      }
    },

    {
      id: 'Native content distribution',
      parentId: 'Content Distribution',
      contactInfo: {
        lead: 'Sajid Ismail',
        email: 'sajid.ismail@publicisgroupe.com'
      }
    },
    {
      id: 'C2C',
      parentId: 'Content Distribution',
      contactInfo: {
        lead: 'Sajid Ismail',
        email: 'sajid.ismail@publicisgroupe.com'
      }
    },

    {
      id: 'Marketing Strategy',
      parentId: 'Strategy & Transformation',
      contactInfo: {
        lead: 'Zeina Madanat',
        email: 'zeina.madanat@publicisgroupe.com'
      }
    },
    {
      id: 'Design Thinking Workshops',
      parentId: 'Strategy & Transformation',
      contactInfo: {
        lead: 'Zeina Madanat',
        email: 'zeina.madanat@publicisgroupe.com'
      }
    },
    {
      id: 'Digital Marketing Transformation',
      parentId: 'Strategy & Transformation',
      contactInfo: {
        lead: 'Zeina Madanat',
        email: 'zeina.madanat@publicisgroupe.com'
      }
    },
    {
      id: 'Digital Accelerator Program',
      parentId: 'Strategy & Transformation',
      contactInfo: {
        lead: 'Zeina Madanat',
        email: 'zeina.madanat@publicisgroupe.com'
      }
    },

    {
      id: 'Web3 Primer',
      parentId: 'Creative Labs',
      contactInfo: {
        lead: 'Sneha Sathe',
        email: 'sneha.sathe@publicisgroupe.com'
      }
    },
    {
      id: 'Community Management',
      parentId: 'Creative Labs',
      contactInfo: {
        lead: 'Sneha Sathe',
        email: 'sneha.sathe@publicisgroupe.com'
      }
    },
    {
      id: 'NFT Strategy',
      parentId: 'Creative Labs',
      contactInfo: {
        lead: 'Sneha Sathe',
        email: 'sneha.sathe@publicisgroupe.com'
      }
    },
    {
      id: 'Metaverse Experiences',
      parentId: 'Creative Labs',
      contactInfo: {
        lead: 'Sneha Sathe',
        email: 'sneha.sathe@publicisgroupe.com'
      }
    },

    {
      id: 'Competitive Analysis',
      parentId: 'User Experience',
      contactInfo: {
        lead: 'Chris Corney',
        email: 'chris.corney@publicisgroupe.com'
      }
    },
    {
      id: 'Business & Technical Ideation',
      parentId: 'User Experience',
      contactInfo: {
        lead: 'Chris Corney',
        email: 'chris.corney@publicisgroupe.com'
      }
    },
    {
      id: 'User Personas',
      parentId: 'User Experience',
      contactInfo: {
        lead: 'Chris Corney',
        email: 'chris.corney@publicisgroupe.com'
      }
    },

    {
      id: 'Research & Interview Reporting',
      parentId: 'User Experience',
      contactInfo: {
        lead: 'Chris Corney',
        email: 'chris.corney@publicisgroupe.com'
      }
    },
    {
      id: 'Sitemapping & Information Architecture',
      parentId: 'User Experience',
      contactInfo: {
        lead: 'Chris Corney',
        email: 'chris.corney@publicisgroupe.com'
      }
    },
    {
      id: 'User Journey Map',
      parentId: 'User Experience',
      contactInfo: {
        lead: 'Chris Corney',
        email: 'chris.corney@publicisgroupe.com'
      }
    },
    {
      id: 'Wireframe & Rapid Prototyping',
      parentId: 'User Experience',
      contactInfo: {
        lead: 'Chris Corney',
        email: 'chris.corney@publicisgroupe.com'
      }
    },
    {
      id: 'Style Guides & Specifications',
      parentId: 'User Experience',
      contactInfo: {
        lead: 'Chris Corney',
        email: 'chris.corney@publicisgroupe.com'
      }
    },
    {
      id: 'Design paterns / Style Guides / Modular Design',
      parentId: 'User Experience',
      contactInfo: {
        lead: 'Chris Corney',
        email: 'chris.corney@publicisgroupe.com'
      }
    },
    {
      id: 'Usability Reports',
      parentId: 'User Experience',
      contactInfo: {
        lead: 'Chris Corney',
        email: 'chris.corney@publicisgroupe.com'
      }
    },
    {
      id: 'Design Thinking & Innovation Workshops',
      parentId: 'User Experience',
      contactInfo: {
        lead: 'Chris Corney',
        email: 'chris.corney@publicisgroupe.com'
      }
    },

    {
      id: 'Experimentation & Conversion Strategic Services',
      parentId: 'Experimentation & Conversion Optimisation',
      contactInfo: {
        lead: 'Chris Corney',
        email: 'chris.corney@publicisgroupe.com'
      }
    },
    {
      id: 'End-to-End CX Transformation',
      parentId: 'Strategy, Transformation & Growth',
      contactInfo: {
        lead: 'Chris Corney',
        email: 'chris.corney@publicisgroupe.com'
      }
    },
    {
      id: 'Organisational Strategy',
      parentId: 'Strategy, Transformation & Growth',
      contactInfo: {
        lead: 'Chris Corney',
        email: 'chris.corney@publicisgroupe.com'
      }
    },
    {
      id: 'Vendor Selection & Technology PoV, Architecture & Solution Design',
      parentId: 'CRM & Marketing Automation (B2B/C)',
      contactInfo: {
        lead: 'Chris Corney',
        email: 'chris.corney@publicisgroupe.com'
      }
    },
    {
      id: 'End-To-End Lead Management',
      parentId: 'CRM & Marketing Automation (B2B/C)',
      contactInfo: {
        lead: 'Chris Corney',
        email: 'chris.corney@publicisgroupe.com'
      }
    },
    {
      id: 'Salesforce, Adobe & Oracle Delivery Services',
      parentId: 'CX Factory',
      contactInfo: {
        lead: 'Chris Corney',
        email: 'chris.corney@publicisgroupe.com'
      }
    },
    {
      id: 'Loyalty Strategy & Program Design',
      parentId: 'Personalisation & Loyalty',
      contactInfo: {
        lead: 'Chris Corney',
        email: 'chris.corney@publicisgroupe.com'
      }
    },

    {
      id: 'Strategy, New business and organic growth',
      parentId: 'Strategy & Growth',
      contactInfo: {
        lead: 'Nigel Keelson-Anfu',
        email: 'nigel.keelson-anfu@publicisgroupe.com'
      }
    },

    {
      id: 'Website Development',
      parentId: 'Creative & Web Services',
      contactInfo: {
        lead: 'Nigel Keelson-Anfu',
        email: 'nigel.keelson-anfu@publicisgroupe.com'
      }
    },
    {
      id: 'Website Migration',
      parentId: 'Creative & Web Services',
      contactInfo: {
        lead: 'Nigel Keelson-Anfu',
        email: 'nigel.keelson-anfu@publicisgroupe.com'
      }
    },
    {
      id: 'Website Maintenance',
      parentId: 'Creative & Web Services',
      contactInfo: {
        lead: 'Nigel Keelson-Anfu',
        email: 'nigel.keelson-anfu@publicisgroupe.com'
      }
    },
    {
      id: 'CMS Management',
      parentId: 'Creative & Web Services',
      contactInfo: {
        lead: 'Nigel Keelson-Anfu',
        email: 'nigel.keelson-anfu@publicisgroupe.com'
      }
    },
    {
      id: 'Creative Banners',
      parentId: 'Creative & Web Services',
      contactInfo: {
        lead: 'Nigel Keelson-Anfu',
        email: 'nigel.keelson-anfu@publicisgroupe.com'
      }
    },

    {
      id: 'CRO',
      parentId: 'SEO',
      contactInfo: { lead: 'Ajesh Nair', email: 'ajesh.nair@publicisgroupe.com' }
    },
    {
      id: 'Technical',
      parentId: 'SEO',
      contactInfo: { lead: 'Ajesh Nair', email: 'ajesh.nair@publicisgroupe.com' }
    },
    {
      id: 'Discoverability Audit',
      parentId: 'SEO',
      contactInfo: { lead: 'Ajesh Nair', email: 'ajesh.nair@publicisgroupe.com' }
    },
    {
      id: 'On-Page/Content Optimization',
      parentId: 'SEO',
      contactInfo: { lead: 'Ajesh Nair', email: 'ajesh.nair@publicisgroupe.com' }
    },
    {
      id: 'App Store optimization',
      parentId: 'SEO',
      contactInfo: { lead: 'Ajesh Nair', email: 'ajesh.nair@publicisgroupe.com' }
    },
    {
      id: 'Content Development',
      parentId: 'SEO',
      contactInfo: { lead: 'Ajesh Nair', email: 'ajesh.nair@publicisgroupe.com' }
    },
    {
      id: 'Content Outreach',
      parentId: 'SEO',
      contactInfo: { lead: 'Ajesh Nair', email: 'ajesh.nair@publicisgroupe.com' }
    },
    {
      id: 'Voice Search',
      parentId: 'SEO',
      contactInfo: { lead: 'Ajesh Nair', email: 'ajesh.nair@publicisgroupe.com' }
    },
    {
      id: 'Google Business Profile',
      parentId: 'SEO',
      contactInfo: { lead: 'Ajesh Nair', email: 'ajesh.nair@publicisgroupe.com' }
    },
    {
      id: 'Video (+YT optimization)',
      parentId: 'SEO',
      contactInfo: { lead: 'Ajesh Nair', email: 'ajesh.nair@publicisgroupe.com' }
    },
    {
      id: 'Organic Shopping',
      parentId: 'SEO',
      contactInfo: { lead: 'Ajesh Nair', email: 'ajesh.nair@publicisgroupe.com' }
    },
    {
      id: 'Wikipedia',
      parentId: 'SEO',
      contactInfo: { lead: 'Ajesh Nair', email: 'ajesh.nair@publicisgroupe.com' }
    },
    {
      id: 'One Search',
      parentId: 'SEO',
      contactInfo: { lead: 'Ajesh Nair', email: 'ajesh.nair@publicisgroupe.com' }
    },
    {
      id: 'PubClicks',
      parentId: 'SEO',
      contactInfo: { lead: 'Ajesh Nair', email: 'ajesh.nair@publicisgroupe.com' }
    },
    {
      id: 'Meta Search',
      parentId: 'SEO',
      contactInfo: { lead: 'Ajesh Nair', email: 'ajesh.nair@publicisgroupe.com' }
    },

    {
      id: 'Digital Attribution',
      parentId: 'Analytics',
      contactInfo: {
        lead: 'Aazim Haque',
        email: 'aazim.haque@publicisgroupe.com'
      }
    },
    {
      id: 'Marketing Mix Modelling',
      parentId: 'Analytics',
      contactInfo: {
        lead: 'Aazim Haque',
        email: 'aazim.haque@publicisgroupe.com'
      }
    },
    {
      id: 'RFM & Basket Analysis',
      parentId: 'Analytics',
      contactInfo: {
        lead: 'Maazin Haque',
        email: 'maazin.haque@publicisgroupe.com'
      }
    },
    {
      id: 'Process Automation',
      parentId: 'Analytics',
      contactInfo: {
        lead: 'Maazin Haque',
        email: 'maazin.haque@publicisgroupe.com'
      }
    },

    {
      id: 'AdOperations',
      parentId: 'AdTech',
      contactInfo: {
        lead: 'Anjesh Menon',
        email: 'anjesh.menon@publicisgroupe.com'
      }
    },
    {
      id: 'UTM/ CID TAAG',
      parentId: 'AdTech',
      contactInfo: {
        lead: 'Anjesh Menon',
        email: 'anjesh.menon@publicisgroupe.com'
      }
    },
    {
      id: 'Verification',
      parentId: 'AdTech',
      contactInfo: {
        lead: 'Anjesh Menon',
        email: 'anjesh.menon@publicisgroupe.com'
      }
    },

    {
      id: 'Media Measurement',
      parentId: 'MarTech',
      contactInfo: {
        lead: 'Vipin Vijayan',
        email: 'vipin.vijayan@publicisgroupe.com'
      }
    },

    {
      id: 'Site Analytics',
      parentId: 'MarTech',
      contactInfo: {
        lead: 'Vipin Vijayan',
        email: 'vipin.vijayan@publicisgroupe.com'
      }
    },
    {
      id: 'App Analytics',
      parentId: 'MarTech',
      contactInfo: {
        lead: 'Vipin Vijayan',
        email: 'vipin.vijayan@publicisgroupe.com'
      }
    },
    {
      id: 'Custom Bidding',
      parentId: 'MarTech',
      contactInfo: {
        lead: 'Vipin Vijayan',
        email: 'vipin.vijayan@publicisgroupe.com'
      }
    },
    {
      id: 'Customer Data Platform Management',
      parentId: 'MarTech',
      contactInfo: {
        lead: 'Vipin Vijayan',
        email: 'vipin.vijayan@publicisgroupe.com'
      }
    },
    {
      id: 'Conversion API',
      parentId: 'MarTech',
      contactInfo: {
        lead: 'Vipin Vijayan',
        email: 'vipin.vijayan@publicisgroupe.com'
      }
    },

    {
      id: 'Brand Health Tracker',
      parentId: 'Research',
      contactInfo: { lead: 'Fahad Ali', email: 'fahad.ali@publicisgroupe.com' }
    },

    {
      id: 'Market Sizing',
      parentId: 'Research',
      contactInfo: { lead: 'Fahad Ali', email: 'fahad.ali@publicisgroupe.com' }
    },
    {
      id: 'Moments Mapper',
      parentId: 'Research',
      contactInfo: { lead: 'Fahad Ali', email: 'fahad.ali@publicisgroupe.com' }
    },
    {
      id: 'Propeller',
      parentId: 'Research',
      contactInfo: { lead: 'Fahad Ali', email: 'fahad.ali@publicisgroupe.com' }
    },
    {
      id: 'Oculus Eye Tracking',
      parentId: 'Research',
      contactInfo: { lead: 'Fahad Ali', email: 'fahad.ali@publicisgroupe.com' }
    },
    {
      id: 'Touchpoints POE',
      parentId: 'Research',
      contactInfo: { lead: 'Fahad Ali', email: 'fahad.ali@publicisgroupe.com' }
    },
    {
      id: 'Score Sponsorship Evaluation',
      parentId: 'Research',
      contactInfo: { lead: 'Fahad Ali', email: 'fahad.ali@publicisgroupe.com' }
    },
    {
      id: 'Conjoint',
      parentId: 'Research',
      contactInfo: { lead: 'Fahad Ali', email: 'fahad.ali@publicisgroupe.com' }
    },
    {
      id: 'Pricing research',
      parentId: 'Research',
      contactInfo: { lead: 'Fahad Ali', email: 'fahad.ali@publicisgroupe.com' }
    },
    {
      id: 'MaxDiff',
      parentId: 'Research',
      contactInfo: { lead: 'Fahad Ali', email: 'fahad.ali@publicisgroupe.com' }
    },

    {
      id: 'Mystery Shopping',
      parentId: 'Research',
      contactInfo: { lead: 'Fahad Ali', email: 'fahad.ali@publicisgroupe.com' }
    },
    {
      id: 'CSAT - Customer Satisfaction',
      parentId: 'Research',
      contactInfo: { lead: 'Fahad Ali', email: 'fahad.ali@publicisgroupe.com' }
    },
    {
      id: 'DNA Profiler',
      parentId: 'Research',
      contactInfo: { lead: 'Fahad Ali', email: 'fahad.ali@publicisgroupe.com' }
    },

    {
      id: 'Social Listening',
      parentId: 'Research',
      contactInfo: {
        lead: 'Hanin Hamade',
        email: 'hanin.hamade@publicisgroupe.com'
      }
    },
    {
      id: 'Influencer Evaluation',
      parentId: 'Research',
      contactInfo: {
        lead: 'Hanin Hamade',
        email: 'hanin.hamade@publicisgroupe.com'
      }
    },
    {
      id: 'Journey Mapping',
      parentId: 'Research',
      contactInfo: {
        lead: 'Hanin Hamade',
        email: 'hanin.hamade@publicisgroupe.com'
      }
    },
    {
      id: 'Persona Building',
      parentId: 'Research',
      contactInfo: {
        lead: 'Hanin Hamade',
        email: 'hanin.hamade@publicisgroupe.com'
      }
    },
    {
      id: 'Research Communities',
      parentId: 'Research',
      contactInfo: {
        lead: 'Hanin Hamade',
        email: 'hanin.hamade@publicisgroupe.com'
      }
    },

    {
      id: 'Category Exploration',
      parentId: 'Research',
      contactInfo: { lead: 'Reim Dihan', email: 'reim.dihan@publicisgroupe.com' }
    },
    {
      id: 'UX Research',
      parentId: 'Research',
      contactInfo: { lead: 'Reim Dihan', email: 'reim.dihan@publicisgroupe.com' }
    },

    {
      id: 'Usage & Attitudes',
      parentId: 'Research',
      contactInfo: {
        lead: 'Sabreena Jamal',
        email: 'sabreena.jamal@publicisgroupe.com'
      }
    },
    {
      id: 'Content Connector',
      parentId: 'Research',
      contactInfo: {
        lead: 'Sabreena Jamal',
        email: 'sabreena.jamal@publicisgroupe.com'
      }
    },
    {
      id: 'Campaign Evaluation',
      parentId: 'Research',
      contactInfo: {
        lead: 'Sabreena Jamal',
        email: 'sabreena.jamal@publicisgroupe.com'
      }
    },

    {
      id: 'Competitive Audits',
      parentId: 'Research',
      contactInfo: {
        lead: 'Sabreena Jamal',
        email: 'sabreena.jamal@publicisgroupe.com'
      }
    },
    {
      id: 'App Feature Audit',
      parentId: 'Research',
      contactInfo: {
        lead: 'Sabreena Jamal',
        email: 'sabreena.jamal@publicisgroupe.com'
      }
    },
    {
      id: 'Market Segmentation',
      parentId: 'Research',
      contactInfo: {
        lead: 'Sabreena Jamal',
        email: 'sabreena.jamal@publicisgroupe.com'
      }
    },
    {
      id: 'B2B Research',
      parentId: 'Research',
      contactInfo: {
        lead: 'Sabreena Jamal',
        email: 'sabreena.jamal@publicisgroupe.com'
      }
    },

    {
      id: 'Dashboarding',
      parentId: 'Business Intelligence',
      contactInfo: {
        lead: 'Muhammad Omer',
        email: 'muhammad.omer@publicisgroupe.com'
      }
    },
    {
      id: 'Data Engineering',
      parentId: 'Business Intelligence',
      contactInfo: {
        lead: 'Muhammad Omer',
        email: 'muhammad.omer@publicisgroupe.com'
      }
    },
    {
      id: 'Cloud Technologies',
      parentId: 'Business Intelligence',
      contactInfo: {
        lead: 'Muhammad Omer',
        email: 'muhammad.omer@publicisgroupe.com'
      }
    },

    {
      id: 'Web Development',
      parentId: 'Business Intelligence',
      contactInfo: {
        lead: 'Mueez Ahmed',
        email: 'mueez.ahmed@publicisgroupe.com'
      }
    },

    {
      id: 'Content Strategy',
      parentId: 'Content Intelligence',
      contactInfo: {
        lead: 'Sajid Ismail',
        email: 'sajid.ismail@publicisgroupe.com'
      }
    },
    {
      id: 'Content Intelligence Platform',
      parentId: 'Content Intelligence',
      contactInfo: {
        lead: 'Sajid Ismail',
        email: 'sajid.ismail@publicisgroupe.com'
      }
    },
    {
      id: 'Content Creators Outreach',
      parentId: 'Partnerships',
      contactInfo: { lead: 'Lea Nahed', email: 'lea.nahed@publicisgroupe.com' }
    },
    {
      id: 'Influencer Marketing Outreach',
      parentId: 'Partnerships',
      contactInfo: { lead: 'Lea Nahed', email: 'lea.nahed@publicisgroupe.com' }
    },
    {
      id: 'Media & Digital Partnerships',
      parentId: 'Partnerships',
      contactInfo: {
        lead: 'Sajid Ismail',
        email: 'sajid.ismail@publicisgroupe.com'
      }
    },

    {
      id: 'Automation',
      parentId: 'Development',
      contactInfo: {
        lead: 'Mueez Ahmed',
        email: 'mueez.ahmed@publicisgroupe.com'
      }
    },

    {
      id: 'Monitoring process performance',
      parentId: 'Product',
      contactInfo: {
        lead: 'Samer Hajjar/Hiba Safieddine',
        email: 'samer.hajjar@publicisgroupe.com/ hiba.safieddine@publicisgroupe.com'
      }
    },
    {
      id: 'Develop control measures',
      parentId: 'Product',
      contactInfo: {
        lead: 'Samer Hajjar/Hiba Safieddine',
        email: 'samer.hajjar@publicisgroupe.com/ hiba.safieddine@publicisgroupe.com'
      }
    },
    {
      id: 'Solution documentation',
      parentId: 'Product',
      contactInfo: {
        lead: 'Samer Hajjar/Hiba Safieddine',
        email: 'samer.hajjar@publicisgroupe.com/ hiba.safieddine@publicisgroupe.com'
      }
    },

    {
      id: 'Problem/Challenge identification',
      parentId: 'Business Analysis',
      contactInfo: {
        lead: 'Karim Hamdan',
        email: 'karim.hamdan@publicisgroupe.com'
      }
    },
    {
      id: 'Process Analysis',
      parentId: 'Business Analysis',
      contactInfo: {
        lead: 'Karim Hamdan',
        email: 'karim.hamdan@publicisgroupe.com'
      }
    },
    {
      id: 'Solution Design and development',
      parentId: 'Business Analysis',
      contactInfo: {
        lead: 'Karim Hamdan',
        email: 'karim.hamdan@publicisgroupe.com'
      }
    },

    {
      id: 'Retail Media Strategy',
      parentId: 'Planning & Strategy',
      contactInfo: { lead: 'Ryan', email: 'ryan@publicisgroupe.com' }
    },
    {
      id: 'Retail Media Planning',
      parentId: 'Planning & Strategy',
      contactInfo: { lead: 'Ryan', email: 'ryan@publicisgroupe.com' }
    },
    {
      id: 'Retail Media Reporting & Analysis',
      parentId: 'Planning & Strategy',
      contactInfo: { lead: 'Ryan', email: 'ryan@publicisgroupe.com' }
    },
    {
      id: 'Retail Media Evaluation Modelling',
      parentId: 'Planning & Strategy',
      contactInfo: { lead: 'Ryan', email: 'ryan@publicisgroupe.com' }
    },

    {
      id: 'Content Audit',
      parentId: 'Commerce Content',
      contactInfo: { lead: 'Siham', email: 'siham.arif@publicisgroupe.com' }
    },
    {
      id: 'Brand shops',
      parentId: 'Commerce Content',
      contactInfo: { lead: 'Siham', email: 'siham.arif@publicisgroupe.com' }
    },
    {
      id: 'PDP Creation',
      parentId: 'Commerce Content',
      contactInfo: { lead: 'Siham', email: 'siham.arif@publicisgroupe.com' }
    },
    {
      id: 'Emerchandising',
      parentId: 'Commerce Content',
      contactInfo: { lead: 'Siham', email: 'siham.arif@publicisgroupe.com' }
    },
    {
      id: 'Retail media creatives design',
      parentId: 'Commerce Content',
      contactInfo: { lead: 'Siham', email: 'siham.arif@publicisgroupe.com' }
    },
    {
      id: 'Retail SEO',
      parentId: 'Commerce Content',
      contactInfo: { lead: 'Siham', email: 'siham.arif@publicisgroupe.com' }
    },
    {
      id: 'Platform Monetization (Citrus)',
      parentId: 'Commerce Content',
      contactInfo: { lead: 'Siham', email: 'siham.arif@publicisgroupe.com' }
    },

    {
      id: 'Digital Shelf Analytics',
      parentId: 'Performance',
      contactInfo: { lead: 'Ryan', email: 'ryan@publicisgroupe.com' }
    },
    {
      id: 'Retail Media Analytics',
      parentId: 'Performance',
      contactInfo: { lead: 'Ryan', email: 'ryan@publicisgroupe.com' }
    },
    {
      id: 'Performance Activation & Optimization - DSP',
      parentId: 'Performance',
      contactInfo: { lead: 'Ryan', email: 'ryan@publicisgroupe.com' }
    },
    {
      id: 'Performance Activation & Optimization - Search',
      parentId: 'Performance',
      contactInfo: { lead: 'Ryan', email: 'ryan@publicisgroupe.com' }
    },
    {
      id: 'Attribution Analytics',
      parentId: 'Performance',
      contactInfo: { lead: 'Ryan', email: 'ryan@publicisgroupe.com' }
    }
  ];
  
  const map = new Map();
  dataNodes.forEach((item) => {
    item.children = [];
    map.set(item.id, item);
    item.name = item.id;
    if (colors[item.id]) {
      item.color = colors[item.id];
    } else if (colors[item.parentId]) {
      item.color = colors[item.parentId];
    }
  });
  
  export let rootNodes = null;
  dataNodes.forEach((item) => {
    const parent = map.get(item.parentId);
    if (!item.color) {
      item.color = parent?.color;
    }
    if (parent && parent.children) {
      parent.children.push(item);
    } else {
      rootNodes = item;
    }
  });
  