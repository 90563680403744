// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// custom styles for the NotificationItem
import menuItem from 'examples/Items/NotificationItem/styles';

const NotificationItem = ({ icon, title, redirectLink, id, markNotificationsAsRead, handleCloseMenu, ...rest }) => {

  async function routeToRedirect(redirectLink) {
    await markNotificationsAsRead(id, redirectLink)
    handleCloseMenu()
  }

  return <MenuItem onClick={async () => { await routeToRedirect(redirectLink) }} {...rest} sx={(theme) => menuItem(theme, redirectLink)}>
    <MDBox
      component={redirectLink ? 'div' : Link}
      py={0.5}
      display="flex"
      alignItems="center"
      lineHeight={1}
    >
      <MDTypography variant="body1" color="secondary" lineHeight={0.75}>
        {icon}
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" sx={{ ml: 1 }}>
        <Link
          style={{
            textDecoration: 'underline',
            color: 'inherit',
            cursor: 'pointer'
          }}
        >
          {title}
        </Link>
      </MDTypography>
    </MDBox>
  </MenuItem>
}

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
};

export default NotificationItem;