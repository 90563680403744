import React from 'react';
import { Card, Col } from 'antd';
import FileUploader from 'devextreme-react/file-uploader';

const UploadTemplateCard = ({ handleFileChange, handleFileUpload }) => (
    <Col align="middle" span={16} xs={24}>
        <Card
            style={{
                width: '500px',
                borderRadius: '0.75rem',
                boxShadow: '0rem 0.25rem 0.375rem -0.0625rem rgba(33, 33, 41, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(33, 33, 41, 0.06)',
            }}
        >
            <Card.Meta style={{ textAlign: 'center', justifyContent: 'center' }} title="Upload Template" />
            <div style={{ width: '400px', alignItems: 'middle' }}>
                <FileUploader
                    multiple={false}
                    accept=".xlsx"
                    uploadMode="useButtons"
                    onValueChanged={handleFileChange}
                    uploadFile={handleFileUpload}
                />
            </div>
        </Card>
    </Col>
);

export default UploadTemplateCard;
