import React, { useContext, useEffect } from "react";
import { Context } from "utils/context/store/Store";
import useRoles from 'utils/hooks/useRoles';
import BaseLayOut from "../../components/Layout/BaseLayOut";
import usePagePermissions from "../../utils/hooks/usePagePermissions";
import FunctionalAccessDataGrid from "./DataTable/FunctionalAccessDataGrid";

function ManageRoles({ routeKey, pageName }) {

  const [{ roles }, dispatch] = useContext(Context)
  const { getPermissionsForPage } = usePagePermissions(routeKey)
  const { getRoles, deleteRoles, updateOrCreateRoles, isLoading } = useRoles(routeKey)

  useEffect(() => { }, [roles])

  useEffect(async () => {
    await getRoles()
  }, [])

  return (
    <BaseLayOut pageTitle={pageName ?? "Manage Functional Security"}>
      <FunctionalAccessDataGrid loading={isLoading} columns={roles.columns} rows={roles.rows} dropDownData={roles.dropdownValues} objToAdd={roles.newRowObject} postData={updateOrCreateRoles} handleDelete={deleteRoles} isRoles={true} routeKey={routeKey} permissions={getPermissionsForPage()} allowSelection={true} allowAdding={true} allowDeleting={true} allowUpdating={true}/>
    </BaseLayOut>
  );
}

export default ManageRoles;