export const validateOrgLevel = (value, prevValues, userDataAccess) => {
  const { masterOrgDivisionId, masterOrgEntityId, masterOrgVerticalId, masterOrgFunctionId } =
    value.formula;

  if (masterOrgDivisionId) {
    strategies.divisionSelected(value, prevValues, userDataAccess);
  }
  if (masterOrgEntityId) {
    strategies.entitySelected(value, prevValues, userDataAccess);
  }
  if (masterOrgVerticalId) {
    strategies.verticalSelected(value, prevValues, userDataAccess);
  }
  if (masterOrgFunctionId) {
    strategies.functionSelected(value, prevValues, userDataAccess);
  }

  return prevValues;
};

const strategies = {
  divisionSelected: (value, prevValues, userDataAccess) => {
    const divisionIds = value.transactions.map((e) => e.masterOrgDivisionId);
    prevValues["masterOrgDivisionId"] = divisionIds;
  },

  entitySelected: (value, prevValues, userDataAccess) => {
    const entityIds = value.transactions.map((e) => e.masterOrgEntityId);
    prevValues["masterOrgEntityId"] = entityIds;

    const divisionIds = filterAndMap(
      userDataAccess["masterOrgEntityId"],
      "masterOrgEntityId",
      "masterOrgDivisionId",
      entityIds
    );
    prevValues["masterOrgDivisionId"] = divisionIds;
  },

  verticalSelected: (value, prevValues, userDataAccess) => {
    const verticalIds = value.transactions.map((e) => e.masterOrgVerticalId);

    let filteredVerticals = userDataAccess["masterOrgVerticalId"].filter((e) =>
      verticalIds.includes(e["masterOrgVerticalId"])
    );

    if (value.formula.masterOrgEntityId) {
      const entityIds = value.transactions.map((e) => e.masterOrgEntityId);
      filteredVerticals = filteredVerticals.filter((e) => entityIds.includes(e.masterOrgEntityId));
    } else if (value.formula.masterOrgDivisionId) {
      const divisionIds = value.transactions.map((e) => e.masterOrgDivisionId);
      filteredVerticals = filteredVerticals.filter((e) =>
        divisionIds.includes(e.masterOrgDivisionId)
      );
    }

    prevValues["masterOrgVerticalId"] = filteredVerticals.map((e) => e.masterOrgVerticalId);
    if (!value.formula.masterOrgEntityId) {
      prevValues["masterOrgEntityId"] = filteredVerticals.map((e) => e.masterOrgEntityId);
    }
    if (!value.formula["masterOrgDivisionId"])
      prevValues["masterOrgDivisionId"] = filteredVerticals.map((e) => e.masterOrgDivisionId);
  },

  functionSelected: (value, prevValues, userDataAccess) => {
    const functionsIds = value.transactions.map((e) => e.masterOrgFunctionId);

    const allFunctions = userDataAccess["masterOrgFunctionId"].filter((e) =>
      functionsIds.includes(e.masterOrgFunctionId)
    );
    let verticalIds = [];
    if (value.formula.masterOrgVerticalId) {
      verticalIds = value.transactions.map((e) => e.masterOrgVerticalId);
    } else {
      verticalIds = allFunctions.map((e) => e.masterOrgVerticalId);
    }

    let filteredVerticals = userDataAccess["masterOrgVerticalId"].filter((e) =>
      verticalIds.includes(e["masterOrgVerticalId"])
    );

    if (value.formula.masterOrgEntityId) {
      const entityIds = value.transactions.map((e) => e.masterOrgEntityId);
      filteredVerticals = filteredVerticals.filter((e) => entityIds.includes(e.masterOrgEntityId));
    } else if (value.formula.masterOrgDivisionId) {
      const divisionIds = value.transactions.map((e) => e.masterOrgDivisionId);
      filteredVerticals = filteredVerticals.filter((e) =>
        divisionIds.includes(e.masterOrgDivisionId)
      );
    }

    prevValues["masterOrgVerticalId"] = filteredVerticals.map((e) => e.masterOrgVerticalId);

    let filteredFunctions = userDataAccess["masterOrgFunctionId"].filter((e) =>
      prevValues["masterOrgVerticalId"].includes(e["masterOrgVerticalId"])
    );

    if (value.formula.masterOrgEntityId) {
      const entityIds = value.transactions.map((e) => e.masterOrgEntityId);
      filteredFunctions = filteredFunctions.filter((e) => entityIds.includes(e.masterOrgEntityId));
    } else if (value.formula.masterOrgDivisionId) {
      const divisionIds = value.transactions.map((e) => e.masterOrgDivisionId);
      filteredFunctions = filteredFunctions.filter((e) =>
        divisionIds.includes(e.masterOrgDivisionId)
      );
    }

    prevValues["masterOrgFunctionId"] = filteredFunctions.map((e) => e.masterOrgFunctionId);

    prevValues["masterOrgEntityId"] = filteredFunctions.map((e) => e.masterOrgEntityId);

    prevValues["masterOrgDivisionId"] = filteredFunctions.map((e) => e.masterOrgDivisionId);
  },
};

const filterAndMap = (data, filterField, mapField, filterValues) =>
  data.filter((e) => filterValues.includes(e[filterField])).map((e) => e[mapField]);
