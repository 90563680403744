import { useEffect, useMemo, useRef, useState } from "react";
import DataGrid, {
  AsyncRule,
  Column,
  Editing,
  Export,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  RequiredRule,
  Scrolling,
  SearchPanel,
  EmailRule, Button, Selection,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import {
  CustomDateBox,
} from "../../../../components/CustomDataGridComponents";
import { handleDeleteRequest, DEButton, getUserInfo, createSanitizeAsyncRule } from "../../../../utils/services/Helpers";
import MDAlert from "../../../../components/MDAlert";
import MDTypography from "../../../../components/MDTypography";
import Divider from "@mui/material/Divider";
import MDBox from "../../../../components/MDBox";
import Grid from "@mui/material/Grid";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
const _ = require('lodash')

export default function MasterCapabilityDataGrid({ rows, columns, dropDownData, isLoading, permissions, postAPI, handleDelete }) {

  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasDataChanged, setHasDataChanged] = useState(false)
  const [autoWidth, setAutoWidth] = useState(true)
  const dataGridRef = useRef();
  const [addEditMode, setAddEditMode] = useState(false)
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.")

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
      setSelectedRowKeys([])
    }
  }, []);
  useEffect(() => { }, [dataSource]);
  useEffect(() => { }, [dataColumns]);
  useEffect(() => { setDataSource(rows) }, [rows]);
  useEffect(() => {
    // if (rows && rows.length <= 0 && columns && columns.length) {
    //   setTimeout(() => addRow(dataGridRef, onInitNewRow), 200)
    // }
    setDataColumns(columns)
  }, [columns]);

  /**
   * @param e
   * function use perform operation when data-grid editor is not prepared e.g enable/disable field etc
   **/
  async function onEditorPreparing(e) {

    if (e && e.dataField === "masterOrgEntityId" || e.dataField === "masterCountryId" || e.dataField === "masterOrgVerticalId") {
      let gridInstance = dataGridRef.current.instance;
      let editRowKey = gridInstance.option("editing.editRowKey");
      let index = gridInstance.getRowIndexByKey(editRowKey);
      const masterOrgDivisionId = gridInstance.cellValue(index, "masterOrgDivisionId");
      if (!masterOrgDivisionId)
        e.editorOptions.disabled = true
      else
        e.editorOptions.disabled = false
    }

  }


  /**
   * @param selectedRowKeys
   * @param selectedRowsData
   * used to get selected rows detail of data-grid
   **/
  function onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
    const final = selectedRowsData.filter((val) => val.count > 0);
    setSelectedRowKeys(selectedRowsData)
  }

  /**
   * get selected rows
   **/
  const hasSelected = selectedRowKeys.length > 0

  /**
   * @param col
   * @param dropDownData
   * function use to handle rendering of fields
   **/
  function renderField(col, dropDownData) {
    if (col.type === "select") {
      return <Column editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
        setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value);
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
        <Lookup allowClearing
          dataSource={col.dataIndex === "userId" || col.dataIndex === "workflowAccessId" ? (options) => col.dataIndex === "userId" ? getUsersByModule(options) : getWorkflowAccessByModule(options) : dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : []}
          displayExpr="label" valueExpr="id" />
      </Column>;
    }
    else if (col.type === "date") {
      return <Column alignment={"left"} dataType={"date"} editCellComponent={CustomDateBox} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        format={"yyyy-MM-dd"}
        // format={'dd-MM-yyyy'}
        caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        {
          col.dataIndex === "endDate" ? <AsyncRule
            message="end date cannot be less than start date"
            validationCallback={async (e) => {
              if (e && e.data) {
                if (e.data.startDate && e.data.endDate) {
                  return e?.data?.startDate ? e.data.endDate >= e.data.startDate : true
                }
                else
                  return e?.value && e.data?.startDate ? e.value >= e.data.startDate : true
              }

            }}
          /> : null
        }
      </Column>;
    }
    else if (col.type === "actions") {
      return <Column alignment={"center"} width={"auto"} allowEditing={col.editable} allowSearch={col.is_searchable} allowSorting={col.is_sortable}
        type="buttons" dataField={col.dataIndex} caption={col.title} fixed={false}>
        <Button name="delete" visible={(e) => e?.row?.data?.newRow} icon={"trash"} />

      </Column>
    }
    else if (col.type === "string") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.dataIndex === "email" ? <EmailRule /> : null
        }
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>;
    }
    else if (col.type === "checkbox") {
      return <Column dataType="boolean"
        showEditorAlways={true} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value)
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.dataIndex === "email" ? <EmailRule /> : null
        }
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
  }

  /**
   * @param e
   * initialize new row in the data-grid
   **/
  const onInitNewRow = (e) => {
    window.scrollTo(0, 0)
    e.data.newRow = true
    e.data.status = true
    setAutoWidth(false)
    setAddEditMode(true)
  }


  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SUBMIT",
          disabled: !hasDataChanged || addEditMode,
          visible: permissions && permissions.canCreate,
          onClick: async function () { await postAPI(dataSource) },
        }
      }
    );
  }

  /**
   * @param e
   * Manage pre api call to save data and validation
   **/
  function onSave(e) {
    if (e && e.changes.length) {
      if (e.changes[0].type === "remove") {
        const dsCopy = [...dataSource]
        const filteredDs = dsCopy.length ? dsCopy.filter(ds => ds.id !== e.changes[0].key) : []
        if (filteredDs && filteredDs.length) {
          setDataSource(filteredDs)
          setHasDataChanged(true)
        }
        else {
          setDataSource([])
          setHasDataChanged(false)
        }
      }
      else {
        const dd = e.changes[0].data;
        let finalData = []
        finalData.push(dd)
        let result = []
        if (dataSource && dataSource.length) {
          result = _.unionBy(finalData, dataSource);
        }
        else result.push(dd)

        setDataSource(result)
        setHasDataChanged(true)

      }
    }
    setAutoWidth(true)
    setAddEditMode(false)
    console.log("saved from Master Capability data grid", e);
  }

  /**
   * @param e
   * validate row before saving
   **/
  function onRowValidating(e) {
    const { id } = e.newData.hasOwnProperty('id') ? e.newData : e.oldData
    const { capability } = e.newData.hasOwnProperty('capability') ? e.newData : e.oldData
    if ((id !== null || id !== undefined) && (capability !== null || capability !== undefined) && dataSource.some(d => d.capability === capability && id !== d.id)) {
      e.isValid = false
      e.errorText = "Capability Name Should be Unique"
    }

  }

  /**
   * function used to handle delete part of data-grid
   **/
  const manageDelete = async () => {
    const newRecords = dataSource.filter(ds => ds.hasOwnProperty('newRow'))
    let newData = [...dataSource]
    handleDeleteRequest(async () => {
      const deleteFromApi = selectedRowKeys.filter(a => a.hasOwnProperty('id') && !a.hasOwnProperty('newRow'))
      const deleteFromTable = selectedRowKeys.filter(a => a.hasOwnProperty('newRow'))
      const result = deleteFromApi.map(a => a.id);
      if (deleteFromApi.length > 0 && deleteFromTable.length > 0) {
        await handleDelete(result)
      }
      else if (deleteFromApi && deleteFromApi.length > 0) {
        await handleDelete(result, newRecords)
      }
      else if (deleteFromTable && deleteFromTable.length > 0) {
        const ids = _.map(deleteFromTable, 'id')
        const fData = newData.filter((item) => !ids.includes(item.id))
        await handleDelete([], fData)
      }
      setHasDataChanged(false)
      setSelectedRowKeys([])
    })
  };

  return (
    <>
      <DetectNavigationBlocker setIsDataChanged={setHasDataChanged} isDataChanged={hasDataChanged} />
      <div id="data-grid-demo">
        {hasSelected > 0
          ?
          <>
            <br />
            <MDAlert color="light">

              <MDTypography variant="subtitle2">
                {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? "item" : "items"}`}
              </MDTypography>

              <Divider orientation="vertical" color="dark" flexItem />

              <MDBox>
                <Grid container spacing={2}>
                  {
                    permissions && permissions.canDelete
                      ? <Grid item >
                        <DEButton stylingMode={"contained"} type={"danger"} icon="trash" onClick={() => manageDelete()} />
                      </Grid> : null
                  }
                </Grid >
              </MDBox >
            </MDAlert >
          </>
          : ""
        }

        <DataGrid id="grid"
          onToolbarPreparing={onToolbarPreparing}
          showBorders={true}
          columnAutoWidth={autoWidth} onSaved={onSave}
          showColumnLines={true} showRowLines={true} rowAlternationEnabled={true}
          ref={dataGridRef} onInitNewRow={onInitNewRow}
          onSelectionChanged={onSelectionChanged} allowColumnResizing={true}
          disabled={isLoading} dataSource={dataSource} key="id" keyExpr="id"
          onEditorPreparing={onEditorPreparing} onRowValidating={onRowValidating}>
          <Paging defaultPageSize={25} />
          <Pager visible={true} showNavigationButtons={true} showInfo={true} displayMode={"full"} />
          {
            addEditMode ? null : <Scrolling showScrollbar="always" mode="standard" />
          }
          <HeaderFilter visible={true} allowSearch={true} />
          <SearchPanel visible={true} />
          <Export enabled={true} allowExportSelectedData={true} />
          <Editing newRowPosition={"first"} refreshMode={"repaint"} mode="cell" allowDeleting={permissions && permissions.canDelete} allowUpdating={permissions && permissions.canCreate} allowAdding={permissions && permissions.canCreate} />
          <Selection allowSelectAll={true} mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} />
          {
            dataColumns && dataColumns.length ? dataColumns.map((d) => renderField(d, dropDownData)) : null
          }
        </DataGrid>
      </div>
    </>
  );
}