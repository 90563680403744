import React, { useContext, useEffect } from "react";
import usePagePermissions from "utils/hooks/usePagePermissions";
import BaseLayOut from "../../components/Layout/BaseLayOut";
import useManageDepartmentClients from "../../utils/hooks/useManageDepartmentClients";
import { Context } from "utils/context/store/Store";
import useBulkUpload from "utils/hooks/useBulkUpload";
import ManageEntityClientsGrid from "./components/ManageDepartmentClientsGrid";

function ManageEntityClients({ routeKey, pageName }) {
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const { getEntityClients, updateOrCreateEntityClients, deleteEntityClients, isLoading } =
    useManageDepartmentClients(routeKey);
  const { bulkUpload } = useBulkUpload(routeKey)
  const [{ entityClients }] = useContext(Context);

  const getLights = async () => {
    await getEntityClients();
  };

  useEffect(() => {
    getLights();
  }, []);

  return (
    <BaseLayOut pageTitle={pageName || "Manage Entity Clients"}>
      <ManageEntityClientsGrid
        isLoading={isLoading}
        rows={entityClients.rows ?? []}
        columns={entityClients.columns}
        permissions={getPermissionsForPage(routeKey)}
        dropDownData={entityClients.dropdownValues}
        postData={updateOrCreateEntityClients}
        handleDelete={deleteEntityClients}
        allowSelection
        allowAdding={true}
        allowDeletingFromApi={true}
        allowBulkUploading
        bulkUploadApi={bulkUpload}
        apiCallback={getEntityClients}
        tableName={entityClients.tableName}
        uploadTemplateLink={entityClients.uploadTemplateLink}
        orgStructureLink={entityClients.orgStructureLink}
      />
    </BaseLayOut>
  );
}

export default ManageEntityClients;
