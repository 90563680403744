export const FILTER_KEYS = [
  "masterOrgDivisionId",
  "masterOrgEntityId",
  "masterOrgVerticalId",
  "masterOrgFunctionId",
  "masterLevelId",
  "masterCountryId",
  "masterDepartmentId",
  "masterClientId",
];

export const consolidateData = (prevTransactions) => {
  return Object.entries(prevTransactions).reduce((acc, [_, value]) => {
    Object.keys(value).forEach((key) => {
      if (!acc[key]) {
        acc[key] = [];
      }
      // If the value is an array, spread it into the push call
      if (Array.isArray(value[key])) {
        acc[key].push(...value[key]);
      } else {
        acc[key].push(value[key]);
      }
    });
    return acc;
  }, {});
};

export const filterItemsByKeys = (items, prevTrans) => {
  const itemIncluded = (prevTrans, item, key) => {
    // If the key doesn't exist in item, or its value is null, the condition is met
    if (!item.hasOwnProperty(key) || item[key] === null) return true;

    // If the key exists in item and in prevTrans, and prevTrans[key] is an array that includes item[key], the condition is met
    if (
      prevTrans.hasOwnProperty(key) &&
      Array.isArray(prevTrans[key]) &&
      prevTrans[key].includes(item[key])
    )
      return true;

    // In all other cases, the condition is not met
    return false;
  };

  if (Object.keys(prevTrans).length) {
    return items.filter((item) => FILTER_KEYS.every((key) => itemIncluded(prevTrans, item, key)));
  }
  return items;
};

// Helper function to handle combination update
export const updateCombination = (
  accordionID,
  combinationID,
  combinationUpdate,
  updateFunction
) => {
  updateFunction(accordionID, combinationID, combinationUpdate);
};

// Helper function to update dataAccessValidations
export const updateDataAccessValidations = (
  accordionID,
  combinationID,
  consolidatedData,
  updateFunction,
  combinationsData
) => {
  updateFunction((prevDataAccessValidations) => ({
    ...prevDataAccessValidations,
    combinationsData,
    [accordionID]: {
      ...prevDataAccessValidations[accordionID],
      transactions: {
        ...prevDataAccessValidations[accordionID]?.transactions,
        [combinationID]: consolidatedData,
      },
    },
  }));
};

// Helper function to get the selected combination keys from the tree checkbox data source
export const getCheckedCombinationsKeys = (selectedFormula) => {
  return selectedFormula
    .filter((e) => e.isChecked)
    ?.reduce((acc, e) => ({ ...acc, [e.value]: e.isChecked }), {});
};

// Helper function to get the combinations list based on the selected formula keys
export const getCombinationsByKeys = (selectedFormula, userDataAccess) => {
  return selectedFormula
    .filter((formula) => formula.isChecked)
    .map((formula) => {
      return {
        data: userDataAccess[formula.value],
        value: formula.value,
      };
    });
};
// Helper function to update selected types dataAccessValidations
export const updateSelectedTypesDataAccessValidations = (
  accordionID,
  combinationID,
  combinationVal,
  setDataAccessValidations,
  combinationsData
) => {
  setDataAccessValidations((prevDataAccessValidations) => ({
    ...prevDataAccessValidations,
    combinationsData,
    [accordionID]: {
      ...prevDataAccessValidations[accordionID],
      selectedTypes: {
        ...prevDataAccessValidations[accordionID]?.selectedTypes,
        [combinationID]: combinationVal.value,
      },
    },
  }));
};

// Helper function to handle "HFMS" value case
export const handleHFMSValueCase = (
  accordionID,
  combinationID,
  userDataAccess,
  setSelectedFormula,
  setTreeDataSource,
  getCombination
) => {
  setSelectedFormula(null);

  if (userDataAccess.hfms) {
    // Create new data for treeDataSource
    const hfmsData = userDataAccess.hfms.map((e) => ({
      ...e,
      // Check if the value of e is in the checkedNodes of the combination
      isChecked: getCombination(accordionID, combinationID)
        ?.formula?.checkedNodes?.map(Number)
        .includes(e.value),
    }));
    // Set the treeDataSource with the new data
    setTreeDataSource([{ value: "0", label: "Select All", children: hfmsData }]);
  }
};

// Helper function to handle "Market" value case
export const handleMarketValueCase = (
  accordionID,
  combinationID,
  userDataAccess,
  setSelectedFormula,
  setTreeDataSource,
  getCombination
) => {
  setSelectedFormula(null);

  if (userDataAccess.market) {
    // Create new data for treeDataSource
    const marketData = userDataAccess.market.map((e) => ({
      ...e,
      // Check if the value of e is in the checkedNodes of the combination
      isChecked: getCombination(accordionID, combinationID)
        ?.formula?.checkedNodes?.map(Number)
        .includes(e.value),
    }));
    // Set the treeDataSource with the new data
    setTreeDataSource([{ value: "0", label: "Select All", children: marketData }]);
  }
};

// Helper function to handle "Master Employee" value case
export const handleMasterEmployeeValueCase = (
  accordionID,
  combinationID,
  userDataAccess,
  setSelectedFormula,
  setTreeDataSource,
  getCombination
) => {
  setSelectedFormula(null);

  if (userDataAccess.master_employee) {
    // Create new data for treeDataSource
    const masterEmployeeData = userDataAccess.master_employee.map((e) => ({
      ...e,
      // Check if the value of e is in the checkedNodes of the combination
      isChecked: getCombination(accordionID, combinationID)
        ?.formula?.checkedNodes?.map(Number)
        .includes(e.value),
    }));
    // Set the treeDataSource with the new data
    setTreeDataSource([{ value: "0", label: "Select All", children: masterEmployeeData }]);
  }
};

// Helper function to handle "Master Departments" value case
export const handleMasterDepartmentValueCase = (
  accordionID,
  combinationID,
  userDataAccess,
  setSelectedFormula,
  setTreeDataSource,
  getCombination
) => {
  setSelectedFormula(null);

  if (userDataAccess.master_departments) {
    // Create new data for treeDataSource
    const masterDepartmentData = userDataAccess.master_departments.map((e) => ({
      ...e,
      // Check if the value of e is in the checkedNodes of the combination
      isChecked: getCombination(accordionID, combinationID)
        ?.formula?.checkedNodes?.map(Number)
        .includes(e.value),
    }));
    // Set the treeDataSource with the new data
    setTreeDataSource([{ value: "0", label: "Select All", children: masterDepartmentData }]);
  }
};

// Helper function to handle "Master Departments" value case
export const handleMasterClientValueCase = (
  accordionID,
  combinationID,
  userDataAccess,
  setSelectedFormula,
  setTreeDataSource,
  getCombination
) => {
  setSelectedFormula(null);

  if (userDataAccess.master_client) {
    // Create new data for treeDataSource
    const masterClientsData = userDataAccess.master_client.map((e) => ({
      ...e,
      // Check if the value of e is in the checkedNodes of the combination
      isChecked: getCombination(accordionID, combinationID)
        ?.formula?.checkedNodes?.map(Number)
        .includes(e.value),
    }));
    // Set the treeDataSource with the new data
    setTreeDataSource([{ value: "0", label: "Select All", children: masterClientsData }]);
  }
};

// Helper function to handle formula result
export const handleFormulaResult = (
  combinationVal,
  dataAccessFormulasData,
  accordionID,
  combinationID,
  setSelectedFormula,
  setTreeDataSource,
  getCombination
) => {
  // Find the formulaResult that matches the combinationVal
  const formulaResult = dataAccessFormulasData.find((formula) => {
    return formula.value === combinationVal.value;
  });
  const savedCombination = getCombination(accordionID, combinationID);
  const masterIds = {
    COUNTRY: "masterCountryId",
    LEVEL: "masterLevelId",
    Departments: "masterDepartmentId",
    Clients: "masterClientId",
  };
  const editModeFormula = savedCombination?.formula;
  // If combination exists in the formulaResult
  if (formulaResult?.combination) {
    // Create the final result for formula
    const formulaFinalResult = formulaResult.combination.map((formula) => ({
      ...formula,
      isChecked:
        masterIds[combinationVal.value] === formula.value
          ? true
          : editModeFormula
          ? editModeFormula[formula.value] ?? false
          : false,
      isDisabled: masterIds[combinationVal.value] === formula.value,
    }));
    // Set selectedFormula to formulaFinalResult and reset treeDataSource
    setSelectedFormula(formulaFinalResult);
    setTreeDataSource(null);
  } else {
    // If neither combinationFormula nor combination in formulaResult exist, reset selectedFormula and treeDataSource
    setSelectedFormula(null);
    setTreeDataSource(null);
  }
};
