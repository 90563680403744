import React, { useContext, useEffect } from "react";
import usePagePermissions from "utils/hooks/usePagePermissions";
import BaseLayOut from "../../components/Layout/BaseLayOut";
import useManageLights from "utils/hooks/useManageLights";
import { Context } from "utils/context/store/Store";
import ManageLightsGrid from "./components/manage-lights-grid";
import useBulkUpload from "utils/hooks/useBulkUpload";

function ManageLights({ routeKey, pageName }) {
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const { getCVLights, isLoading, updateOrCreateCVLight, deleteCVLight } =
    useManageLights(routeKey);
  const { bulkUpload } = useBulkUpload(routeKey)
  const [{ lights }] = useContext(Context);

  const getLights = async () => {
    await getCVLights();
  };

  useEffect(() => {
    getLights();
  }, []);

  return (
    <BaseLayOut pageTitle={pageName || "Manage Lights"}>
      <ManageLightsGrid
        isLoading={isLoading}
        rows={lights.rows ?? []}
        columns={lights.columns}
        permissions={getPermissionsForPage(routeKey)}
        dropDownData={lights.dropdownValues}
        postData={updateOrCreateCVLight}
        handleDelete={deleteCVLight}
        allowSelection
        allowAdding
        allowDeletingFromApi
        bulkUploadApi={bulkUpload}
        apiCallback={getCVLights}
        tableName={lights.tableName}
        uploadTemplateLink={lights.uploadTemplateLink}
        orgStructureLink={lights.orgStructureLink}
      />
    </BaseLayOut>
  );
}

export default ManageLights;
