import DataGrid, { Column, Button, Export, SearchPanel, HeaderFilter, Paging, Pager, LoadPanel, Scrolling } from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { useEffect, useState, useMemo } from "react";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { convertToLocalDateTime, DEButton, getUserInfo } from "utils/services/Helpers";
import useApprovals from "utils/hooks/useApprovals";
import useManageHeadCounts from "utils/hooks/useManageHeadCounts";
import { Button as ButtonN } from "devextreme-react";

export default function GroupsDataGrid({ rows, columns, isLoading, routeKey, permissions, hasMore, setTab, setrIdToFilter, setPage, transactionType = 'hc_transaction', fetchDataFromApi = null }) {

  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const navigate = useNavigate();
  const { withdrawGroup, cancelGroup } = useApprovals()
  const { getAllGroups } = useManageHeadCounts(routeKey)

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
    }
  }, []);
  useEffect(() => { }, [dataSource]);
  useEffect(() => { }, [dataColumns]);
  useEffect(() => {
    setDataSource(rows);
  }, [rows]);
  useEffect(() => {
    setDataColumns(columns);
  }, [columns]);

  /**
   * @param col
   * @param dropDownData
   * function use to handle rendering of fields
   **/
  function renderField(col) {
    if (col.type === "actions") {
      return <Column type="buttons" dataField={col.dataIndex} caption={col.title} width={"250"}>
        <Button disabled={(e) => e && e.row && e.row.data.hasOwnProperty('cancelled') && e.row.data.cancelled === true} text={"View"} name={"view"}
          component={(props) =>
            props?.data?.data?.gStatus?.toLowerCase() === "draft"
              ? <Link to={transactionType === 'transaction_transfer'
                ? `/views/group/${props.data.data.id}/manage-supervisor`
                : `/views/group/${props.data.data.id}/request/${props.data.data.masterRequestTypeId}/type/${props.data.data.masterHeadcountTypeId ?? "leaver"}/manage-headcount`}>
                <DEButton stylingMode={"contained"} type={"normal"} text={"View"} />
              </Link>
              : <Link to={"#"}
                onContextMenu={(e) => {
                  localStorage.setItem('rId', props.data.data.id)
                  localStorage.setItem('transactionType', transactionType === 'transaction_transfer' ? "transaction_transfer" : props.data.data.masterHeadcountTypeId ? "hc_transaction" : "transaction_leaver")
                  if (props.data.data.delegatedUserId !== null) localStorage.setItem('dgId', props.data.data.delegatedUserId)
                }} onClick={(e) => {
                  e.preventDefault();

                  setTab(transactionType === 'transaction_transfer' ? 0 : props.data.data.masterHeadcountTypeId ? 0 : 1);
                  setPage(false);
                  setrIdToFilter(props.data.data.id);
                }}>
                <DEButton stylingMode={"contained"} type={"normal"} text={"View"} />
              </Link>
          } />

        <Button visible={(e) => e && e.row && e.row.data && e.row.data.userId === getUserInfo().id && permissions && permissions.canCreate && e.row.data.canWithdraw} text={"withdraw"} name={"withdraw"}
          component={(props) =>
            <Link to={`#`}>
              <DEButton stylingMode={"contained"} type={"danger"} text={`${props.data.data.gStatus === 'draft' ? 'Delete Request' : 'Drop Request'}`} onClick={async () => await withdrawGroup(props.data.data, fetchDataFromApi, props.data.data.gStatus === 'draft' ? 'delete' : 'drop', transactionType)} />
            </Link>
          } />
      </Column>
    }
    else if (col.type === "date") {
      return <Column dataField={col.dataIndex} cellComponent={(props) => <>{convertToLocalDateTime(props.data.data.createdAt)}</>} caption={col.title} alignment={"left"} />
    }
    else {
      return <Column dataField={col.dataIndex} caption={col.title} encodeHtml={col.hasOwnProperty('encodeHtml') ? col.encodeHtml : true} alignment={"left"} />
    }
  }

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      visible: permissions && permissions.canCreate,
      options: {
        icon: 'add',
        onClick: () => navigate(transactionType === 'transaction_transfer' ? '/views/manage-supervisor' : '/views/manage-headcount')
      }
    });
  }

  return (
    <div id="GroupsDataGrid">
      {
        !isLoading ?
          <DataGrid
            onToolbarPreparing={onToolbarPreparing}
            allowColumnResizing={true} dataSource={dataSource} keyExpr="id" showBorders={true}>
            <LoadPanel enabled={isLoading} visible={isLoading} />
            <Scrolling showScrollbar="always" mode="standard" />
            <HeaderFilter visible={true} allowSearch={true} />
            <SearchPanel visible={true} />
            <Paging defaultPageSize={25} />
            <Pager visible={true} showNavigationButtons={true} showInfo={true} displayMode={"full"} />
            {
              dataColumns && dataColumns.length ? dataColumns.map((d) => {
                return renderField(d);
              }) : null
            }
            <Export enabled={true} allowExportSelectedData={true} />
          </DataGrid>
          : null
      }
    </div>
  );
}
