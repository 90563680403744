export const validateCountryOrg = (value, prevValues) => {
  const { masterOrgDivisionId, masterOrgEntityId } = value.formula;

  if (masterOrgDivisionId) {
    strategies.divisionSelected(value, prevValues );
  }
  if (masterOrgEntityId) {
    strategies.entitySelected(value, prevValues);
  }

  return prevValues;
};

const strategies = {
  divisionSelected: (value, prevValues) => {
    const divisionIds = value.transactions.map((e) => e.masterOrgDivisionId);
    prevValues["masterOrgDivisionId"] = divisionIds;

  },

  entitySelected: (value, prevValues) => {
    const entityIds = value.transactions.map((e) => e.masterOrgEntityId);
    prevValues["masterOrgEntityId"] = entityIds;
    prevValues["masterOrgDivisionId"] = value.transactions.map((e) => e.masterOrgDivisionId);
  },
};
