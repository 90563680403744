import React, { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { ConfirmProvider } from "material-ui-confirm";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "utils/context";
import headerLogo from "assets/images/logos/publicismedia.png";
import { ToastContainer } from "react-toastify";
import useNavigation from "./utils/hooks/useNavigation";
import { callCreateUpdateDeviceTokenApi, getUserInfo, onMessageListener } from "./utils/services/Helpers";
import "./App.css"
import "assets/fonts/GothamNarrow-Book.otf"
import useNotification from "./utils/hooks/useNotification";
import publicisGroupeText from "assets/images/logos/publicis-groupe-text.svg"

const App = React.memo(() => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const { fetchNavigations, routes } = useNavigation();
  const { getNotifications } = useNotification();

  if (!Boolean(parseInt(process.env.REACT_APP_ENABLE_CONSOLE_LOG))) console.log = () => { }
  if (!Boolean(parseInt(process.env.REACT_APP_ENABLE_CONSOLE_WARN))) console.warn = () => { }
  if (!Boolean(parseInt(process.env.REACT_APP_ENABLE_CONSOLE_ERROR))) console.error = () => { }
  if (!Boolean(parseInt(process.env.REACT_APP_ENABLE_CONSOLE_TABLE))) console.table = () => { }

  const history = useNavigate()
  const location = useLocation();
  const search = useLocation().search;
  const rId = new URLSearchParams(search).get("rId") ?? null
  const dgId = new URLSearchParams(search).get("dgId") ?? null

  useEffect(() => {
    const handlePageLoad = () => {
      // setPageLoaded(true);
      console.log('Page fully loaded');
      // Your logic after the page is fully loaded

      if (rId !== null) localStorage.setItem('rId',rId)
      if (dgId !== null) localStorage.setItem('dgId',dgId)
      const dd = async () => await fetchData();
      dd()

    };

    // Add event listener for page load
    window.addEventListener('load', handlePageLoad);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('load', handlePageLoad);
    };

  }, []);

  const fetchData = async () => {
    await fetchNavigations();

    if (getUserInfo()?.id !== null && getUserInfo()?.id !== undefined) {
      await getNotifications();
      await callCreateUpdateDeviceTokenApi();
    }

    if (getUserInfo()?.id !== null && location.pathname !== '/') {
      history(location.pathname);
    }

    if (getUserInfo()?.id !== null && location.pathname === '/') {
      history('views/home');
    }

    onMessageListener().then(async (res) => {
      await getNotifications();
    });
  };

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ToastContainer style={{ fontSize: '12px' }} />
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <ConfirmProvider defaultOptions={{
          confirmationButtonProps: { autoFocus: true }
        }}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? headerLogo : headerLogo}
                brandName={publicisGroupeText}
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            {getRoutes(routes)}
            {
              routes && routes.length === 2
                ? <Route path="*" element={<Navigate to="/views/not-found" />} />
                : null

            }
          </Routes>
        </ConfirmProvider>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <ToastContainer style={{ fontSize: '14px', width: "400px" }} />
      <ConfirmProvider defaultOptions={{
        confirmationButtonProps: { autoFocus: true }
      }}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? headerLogo : headerLogo}
              brandName={publicisGroupeText}
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          {
            routes && routes.length === 2
              ? <Route path="*" element={<Navigate to="/views/not-found" />} />
              : null

          }
        </Routes>
      </ConfirmProvider>
    </ThemeProvider>
  );
})
export default App
