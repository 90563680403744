import { useIsLoading } from "./useIsLoading";
import request from "../services/Http";
import { toast } from "react-toastify";

export default function useFunctionalAccessPriority(routeKey){
  const {isLoading, setIsLoading} = useIsLoading()

  async function createUpdatePriority(data)
  {
    setIsLoading(true)
    try {
      const res = await request.post('functional-access-priority', data)
      if (res)
      {
        toast.success(res.data.data)
        setIsLoading(false)
      }
    }catch (e) {}
    setIsLoading(false)

  }

  return {createUpdatePriority, isLoading}
}
