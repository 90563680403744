import request from "../services/Http"
import { useContext } from "react";
import { Context } from "../context/store/Store";
import { SET_IS_LOADING, SET_NEW_WF_STEP, SET_WORKFLOWS, SET_WORKFLOWS_COLUMNS } from "../context/store/Constants";
import {
    setColumnsForDataTable,
    isJSONValid,
    addKeyAndIndexFieldToGenericArrayOfObjects,
    getPageProperties, getTableColumns,
} from "../services/Helpers";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIsLoading } from "./useIsLoading";
import _ from "lodash";

export default function useWorkflows(routeKey) {

    const [{ workflows, pagesAndPermissions, newWfStep }, dispatch] = useContext(Context)
    const { isLoading, setIsLoading } = useIsLoading()

    async function getWorkflows(data_by) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        dispatch({
            type: SET_WORKFLOWS,
            payload: []
        });
        try {
            const res = await request.get(data_by)
            const properties = getPageProperties(pagesAndPermissions, routeKey)
            const table = getTableColumns(properties.page.table, data_by)
            let columns = table.columns
            let rows = isJSONValid(res.data.data) ?? {}
            let newRowObject = isJSONValid(table.row.defaultObj) ?? {}
            let dropdownValues = isJSONValid(res.data.dropdownValues) ?? {}
            let uploadTemplateLink = res.data.uploadTemplate ?? null
            let orgStructureLink = res.data.orgStructureLink ?? null
            let tableName = res.data.tableName ?? null

            if (newWfStep && newWfStep.hasOwnProperty('apiDelete') && newWfStep.apiDelete && newWfStep.rows && newWfStep.rows.length) {

                if (data_by === "workflow") {
                    rows.map(wf => {
                        if (newWfStep.rows[0].workflowId === wf.id) {
                            let index = wf.workflow_step.length + 1
                            let pId = null
                            let newPId = null
                            newWfStep.rows.map(ws => {
                                if (ws.parentId === -1 && ws.conditionType !== 'sub-process') {
                                    ws.id = index
                                    ws.sequence = index
                                    ws.uniqueKey = index
                                    index++
                                } else if (ws.parentId === -1 && ws.conditionType === 'sub-process') {
                                    pId = _.cloneDeep(ws['id'])
                                    ws.id = index
                                    ws.sequence = index
                                    ws.uniqueKey = index
                                    newPId = index
                                    index++
                                } else if (ws.parentId === pId) {
                                    ws.id = index
                                    ws.sequence = index
                                    ws.uniqueKey = index
                                    ws.parentId = newPId
                                    index++
                                }
                            })
                            wf.workflow_step = [...wf.workflow_step, ...newWfStep.rows]
                        }
                    })
                } else {
                    rows = [...rows, ...newWfStep.rows]
                }

                dispatch({
                    type: SET_NEW_WF_STEP,
                    payload: {}
                });

            }

            // if (data_by === "workflow") {
            //     rows.forEach((row) => {
            //         row.workflow_accesses.count = row.workflow_accesses.length
            //     })
            // }

            const dataTableData = { columns: columns, rows: addKeyAndIndexFieldToGenericArrayOfObjects(rows), newRowObject: newRowObject, dropdownValues: dropdownValues, apiDelete: false, uploadTemplateLink, orgStructureLink, tableName }

            dispatch({
                type: SET_WORKFLOWS_COLUMNS,
                payload: setColumnsForDataTable(columns, dropdownValues)
            });
            dispatch({
                type: SET_WORKFLOWS,
                payload: dataTableData
            });
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function updateOrCreateWorkflows(data, val) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.post(val, { workflows: data })
            toast.success(res.data.data)
            await getWorkflows(val)
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function deleteWorkflows(workflows, val) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.delete(val, {
                data: {
                    workflows: workflows
                }
            })
            await getWorkflows(val)
            toast.success(res.data.data);
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function deleteWorkflowSteps(approvals, workflowId = null, val) {
        setIsLoading(true)
        try {
            const res = await request.delete('workflow-approval', {
                data: {
                    workflowId: workflowId,
                    approvals: approvals
                }
            })
            await getWorkflows(val)
            // await getApprovalsByWorkflow(workflowId)
            toast.success(res.data.data)
        }
        catch (e) { }
        setIsLoading(false)
    }

    return { getWorkflows, updateOrCreateWorkflows, deleteWorkflows, deleteWorkflowSteps, isLoading }
}