import request from '../services/Http';
import { useContext, useState } from "react";
import { Context } from '../context/store/Store';
import { SET_IS_LOADING, SET_HC_REQUEST_TYPE, SET_DATA_ACCESS } from '../context/store/Constants';
import { isJSONValid } from '../services/Helpers';

export default function useHcRequestType() {
  const [{ hcRequestType, headcountRequest }, dispatch] = useContext(Context);
  const [modalPermissions, setModalPermissions] = useState({})

  async function getHcRequestType(skipLoading = false) {
    if (!skipLoading) {
      dispatch({ type: SET_IS_LOADING, payload: true });
    }
    setModalPermissions({});
    try {
      const res = await request.get(`headcount/request-type`);
      const data = isJSONValid(res.data.data) ?? {};
      const permissions = res.data.permissions;
      const internalEmpData = JSON.parse(localStorage.getItem('internalEmpData'));
      setModalPermissions(permissions);
      dispatch({ type: SET_HC_REQUEST_TYPE, payload: data });
    } catch (e) { }

    if (!skipLoading) {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function getUserDataAccess(userId = null, masterModuleId = null) {
    dispatch({ type: SET_DATA_ACCESS, payload: {} });
    try {
      const res = await request.get(`data-access/module/${userId}/${masterModuleId}`);
      if (res) {
        dispatch({
          type: SET_DATA_ACCESS,
          payload: res.data
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function getHCRequestWithDataAccess(userId = null, masterModuleId = null) {
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      await getUserDataAccess(userId, masterModuleId);
      const res = await request.get(`headcount/request-type`);
      const data = isJSONValid(res.data.data) ?? {};
      dispatch({ type: SET_HC_REQUEST_TYPE, payload: data });
    } catch (err) {
      console.error(err);
    }
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  return { getHcRequestType, getHCRequestWithDataAccess, modalPermissions };
}
